import React from 'react';
import * as Icons from 'components/icons';
import * as Utils from 'utils';
import { FieldProps, IChangeEvent, ISubmitEvent } from '@rjsf/core';
import { DocumentStatusEnum } from 'trace-backend-sdk';
import { useIntl } from 'lib/intl';
import { JsonForm } from 'components/form-elements/JsonForms/components/JsonForm/JsonForm';
import { Box, Container } from 'components/layout';
import { MultiSelectAggregate } from 'components/form-elements/JsonForms/components/MultiSelectAggregate/MultiSelectAggregate';
import { OtherField } from 'components/form-elements/JsonForms/components/OtherField';
import { useDocumentParams } from 'pages';
import { DocumentsService, DocumentTemplateId } from 'services/documents';
import { InlineLoadingIndicator } from 'components/LoadingIndicator';
import { OutlinedTextWidget } from 'components/form-elements/JsonForms/components/Text';
import {
  DocumentTitleContent,
  DocumentTitleDate,
  DocumentTitleIconWrapper,
  DocumentTitleTemplateName,
  DocumentTitleWrapper,
} from 'components/DocumentTitleWrapper/DocumentTitleWrapper';
import { useLocaleDateFormat } from 'utils/hooks/use-locale-date-format';
import { generateContext } from 'utils/context';
import { PictureTake } from 'components/form-elements/JsonForms/components/PictureTake';
import { Button, Label } from 'components/form-elements';
import { Spacer } from 'components/Spacer/Spacer';
import {
  Temperature,
  TemperatureToggle,
} from 'components/TemperatureToggle/TemperatureToggle';
import { TimerText } from 'components/Timer/Timer';
import { ReliningFieldWrapper } from '../components/ReliningFieldWrapper';
import { ReliningImageWithTitle } from '../components/ReliningImageWithTitle';
import { ReliningBatch } from '../components/ReliningBatch';
import { ReliningCalculator } from '../components/ReliningCalculator';
import { DocumentSectionWrapper } from '../../document/components/DocumentSectionWrapper/DocumentSectionWrapper';
import { ReliningImage } from '../components/ReliningImage';
import { DeleteDocument } from '../../document/components/DeleteDocument/DeleteDocument';
import { ReliningTemperatureSelect } from '../components/ReliningTemperatureSelect';
import { celsiusToFahrenheit, fahrenheitToCelsius } from '../utils/temperature';
import { HardeningTimer } from '../components/HardeningTimer';
import { uiSchema } from '../assets/ui-schema';
import { DocumentCertificateAccess } from '../../document/components/DocumentCertificateAccess/DocumentCertificateAccess';
import { ReliningSchemaFinal } from '../../../global/types/templates';

function mapDotSeparatedNumber(values: number[]) {
  return parseFloat(values.join('.'));
}

function InstallationPressure(props: FieldProps) {
  return (
    <MultiSelectAggregate
      {...props}
      onAggregateValues={mapDotSeparatedNumber}
      variant="gray"
      fieldSeparator={(
        <Box
          sx={{
            width: '1px',
            alignSelf: 'stretch',
            backgroundColor: '#3e3e53',
            isolation: 'isolate',
          }}
        />
      )}
    >
      {props.children}
    </MultiSelectAggregate>
  );
}

function HardeningTimeEstimate(props: FieldProps) {
  return (
    <MultiSelectAggregate
      {...props}
      backgroundLightness="light"
      variant="running"
      fieldSeparator={<TimerText sx={{ mb: 4 }}>:</TimerText>}
      containerSx={{ px: 10 }}
    />
  );
}

function Hardening(props: FieldProps) {
  return (
    <MultiSelectAggregate
      {...props}
      onAggregateValues={mapDotSeparatedNumber}
      variant="deepBlue"
      fieldSeparator={(
        <Box
          sx={{
            width: '1px',
            alignSelf: 'stretch',
            backgroundColor: '#5168a1',
            isolation: 'isolate',
          }}
        />
      )}
    >
      {props.children}
    </MultiSelectAggregate>
  );
}

function DoubleBorderField(props: FieldProps) {
  return (
    <ReliningFieldWrapper>
      <props.registry.fields.ObjectField {...props} />
    </ReliningFieldWrapper>
  );
}

/*
 * Document metadata fields, needed because it implements the
 * document title within its HTML element.
 * */
function Metadata(props: FieldProps) {
  const intl = useIntl();
  const { localeDateFormat } = useLocaleDateFormat();
  const { documentId, templateId, dateCreated, isCompleted } = useReliningFormContext();

  return (
    <DocumentSectionWrapper>
      <DocumentTitleWrapper>
        <DocumentTitleIconWrapper>
          <Icons.ReliningSplash />
        </DocumentTitleIconWrapper>

        <DocumentTitleContent>
          {dateCreated && (
            <DocumentTitleDate isDocumentCompleted={isCompleted}>
              {localeDateFormat(dateCreated)}
            </DocumentTitleDate>
          )}

          <DocumentTitleTemplateName>
            {intl.formatMessage({
              id: 'relining.templateName',
            })}
          </DocumentTitleTemplateName>
        </DocumentTitleContent>

        <Box
          sx={{
            display: 'inline-flex',
            ml: 'auto',
            alignSelf: 'baseline',
          }}
        >
          <DeleteDocument templateId={templateId} documentId={documentId} />
        </Box>
      </DocumentTitleWrapper>

      {isCompleted && (
        <React.Fragment>
          <DocumentCertificateAccess />
          <Spacer y={8} />
        </React.Fragment>
      )}

      <props.registry.fields.ObjectField {...props} />
    </DocumentSectionWrapper>
  );
}

function TemperatureInPipeImage(props: FieldProps) {
  const intl = useIntl();

  return (
    <React.Fragment>
      <Label
        variant="jsonInputLabel"
        labelIntlId="relining.temperatureInPipe"
      />

      <Spacer y={3} />

      <PictureTake
        {...props}
        title={intl.formatMessage({
          id: 'relining.temperatureInPipe',
        })}
        imageName="temperatureInPipe"
        retakeWarningIntlId="generic.confirm.areYouSure"
        TitleIcon={(
          <Box
            sx={{
              p: '6px',
              borderRadius: 'rounded',
              backgroundColor: 'grey.800',
            }}
          >
            <Icons.Thermostat color="white.50" />
          </Box>
        )}
        ActionButton={(
          <Button
            prependIcon={<Icons.CameraMui color="white.50" />}
            intlId="relining.batch.cameraButton"
          />
        )}
      />
    </React.Fragment>
  );
}
/*
 * Wraps the functionality of selecting temperature scales (F or C)
 * and selecting a temperature, using a custom widget.
 *
 * Implements some cross-widget interactions, such as converting
 * the temperature value when the temperature scale changes.
 * */
function HeatHardeningField(props: FieldProps) {
  function onTemperatureChange(temperature: number) {
    props.onChange({
      ...props.formData,
      temperature,
    });
  }

  function onTemperatureScaleChange(temperatureScale: Temperature) {
    /*
     * If the temperature scale changed *to* Celsius, then the current
     * temperature value is in Fahrenheit and we need to convert it
     * to Celsius. The same applies in the opposite case.
     *
     * So, if we had selected 40C and then changed the scale from C -> F,
     * then we expect the temperature value to change to F equivalent of 40C, which is 104F.
     * */
    const newTemperature = temperatureScale === 'celsius'
      ? fahrenheitToCelsius(props.formData.temperature)
      : celsiusToFahrenheit(props.formData.temperature);

    props.onChange({
      ...props.formData,
      temperature: newTemperature,
      temperatureScale,
    });
  }

  return (
    <React.Fragment>
      <ReliningTemperatureSelect
        disabled={props.disabled}
        value={props.formData.temperature}
        onChange={(newValue) => onTemperatureChange(newValue)}
        temperatureScale={props.formData.temperatureScale}
      />

      <Spacer y={8} />

      <TemperatureToggle
        disabled={props.disabled}
        value={props.formData.temperatureScale}
        onChange={(newValue) => onTemperatureScaleChange(newValue)}
      />
    </React.Fragment>
  );
}

const widgets = {
  other: OtherField,
  outlinedText: OutlinedTextWidget,
};

const fields = {
  hardeningTimer: HardeningTimer,
  imageWithTitle: ReliningImageWithTitle,
  installationPressure: InstallationPressure,
  hardening: Hardening,
  batch: ReliningBatch,
  doubleBorderField: DoubleBorderField,
  calculator: ReliningCalculator,
  metadata: Metadata,
  reliningImage: ReliningImage,
  temperatureInPipeImage: TemperatureInPipeImage,
  heatHardening: HeatHardeningField,
  timeEstimate: HardeningTimeEstimate,
};

const [useReliningFormContext, ReliningFormContext] = generateContext<{
  documentId: string;
  templateId: DocumentTemplateId;
  dateCreated?: string;
  isCompleted: boolean;
  onTimerReset:(documentData: Record<string, any>) => void;
  onImageRemove: (documentData: Record<string, any>) => void;
}>();

export function ReliningPage() {
  const params = useDocumentParams();
  const { document, isLoading, hasDocumentWritePermission } = DocumentsService.useGetDocument(params.documentId, params.templateId);

  const [formData, setFormData] = Utils.Document.useDocumentFormState() as unknown as [
      ReliningSchemaFinal,
      React.Dispatch<React.SetStateAction<ReliningSchemaFinal>>
    ];
  const onSubmitDocument = Utils.Document.useSubmitDocument();
  const onUpdateDocument = Utils.Document.useUpdateDocumentData();

  const onChange = React.useCallback(
    (e: IChangeEvent) => {
      setFormData(e.formData);
      onUpdateDocument(e.formData);
    },
    [onUpdateDocument, setFormData],
  );

  const onSubmit = React.useCallback(
    (e: ISubmitEvent<any>) => {
      onSubmitDocument(e.formData);
    },
    [onSubmitDocument],
  );

  const formContext = React.useMemo(
    () => ({
      documentId: params.documentId,
      templateId: params.templateId,
      dateCreated: document?.dateCreated,
      isCompleted: document?.status === DocumentStatusEnum.Completed,
      onTimerReset: (documentData: Record<string, any>) => {
        setFormData(documentData as any);
      },
      onImageRemove: (documentData: Record<string, any>) => {
        setFormData(documentData as any);
      },
    }),
    [
      document?.dateCreated,
      document?.status,
      params.documentId,
      params.templateId,
      setFormData,
    ],
  );

  if (isLoading) {
    return <InlineLoadingIndicator />;
  }

  const isCompleted = document?.status === DocumentStatusEnum.Completed;
  const isDisabled = !hasDocumentWritePermission || isCompleted;

  return (
    <Container variant="spacer" sx={{ pt: 0 }}>
      <ReliningFormContext value={formContext}>
        <JsonForm
          formContext={formContext}
          disabled={isDisabled}
          widgets={widgets}
          fields={fields}
          formData={formData}
          onChange={onChange}
          onSubmit={onSubmit}
          name="relining"
          schema={document?.template.templateSchemaFinal ?? {}}
          uiSchema={JSON.parse(JSON.stringify(uiSchema))}
        >
          {!isDisabled && (
            <Button
              sx={{ mt: 4 }} intlId="document.nextStep"
              type="submit"
            />
          )}
        </JsonForm>
      </ReliningFormContext>
    </Container>
  );
}

export { useReliningFormContext };
