import * as ReactRouter from 'react-router-dom';
import React from 'react';
import { AuthorizationSuperAdmin } from 'components/authorization/AuthorizationSuperAdmin';
import { Routing } from 'global/routing';
import { Box } from 'components/layout';
import { AdminWorkspaces } from './components/Workspaces/AdminWorkspaces';
import { AdminWorkspace } from './components/Workspace/AdminWorkspace';

export function Admin() {
  const match = ReactRouter.useRouteMatch();
  const history = ReactRouter.useHistory();

  return (
    <AuthorizationSuperAdmin
      onAuthorizeFail={() => {
        history.replace(Routing.HOME.getPath());
      }}
    >
      <Box
        sx={{
          display: 'flex',
          height: '100%',
          width: '100%',
        }}
      >
        <ReactRouter.Switch>
          <ReactRouter.Route
            path={`${match.path}/workspaces/edit/:workspaceId`}
          >
            <AdminWorkspace />
          </ReactRouter.Route>

          <ReactRouter.Route path={`${match.path}/workspaces`}>
            <AdminWorkspaces />
          </ReactRouter.Route>

          <ReactRouter.Redirect to={`${match.path}/workspaces`} />
        </ReactRouter.Switch>
      </Box>
    </AuthorizationSuperAdmin>
  );
}
