import { ReliningImageNameEnum } from '@trace/types';
import { UiSchemaCustom } from '../../../components/form-elements/JsonForms/types';

export const uiSchema: UiSchemaCustom = {
  metadata: {
    'ui:field': 'metadata',
  },
  preparations: {
    'ui:title': 'relining.preparations.title',
    preparationType: {
      'ui:widget': 'checkboxes',
    },
    preparationTypeOther: {
      'ui:widget': 'other',
    },
    pipeTypeOther: {
      'ui:widget': 'other',
    },
  },
  lining: {
    'ui:widget': 'checkboxes',
  },
  liningOther: {
    'ui:widget': 'other',
  },
  liningInstallationEquipment: {
    'ui:widget': 'textarea',
  },

  eventDescription: {
    'ui:title': 'relining.eventDescription',
    preparationsDescription: {
      'ui:widget': 'textarea',
    },
    preparationsImage: {
      'ui:imageTitle': 'relining.preparationsDescriptionImageTitle',
      'ui:field': 'reliningImage',
      'ui:imageName': ReliningImageNameEnum.PREPARATIONS,
      'ui:buttonLabel': 'relining.preparations.imageTitle',
    },
    preparationsImageTwo: {
      'ui:imageTitle': 'relining.preparationsDescriptionImageTitle',
      'ui:field': 'reliningImage',
      'ui:imageName': ReliningImageNameEnum.PREPARATIONS_TWO,
      'ui:buttonLabel': 'relining.preparations.imageTitle',
    },
    preparationsImageThree: {
      'ui:imageTitle': 'relining.preparationsDescriptionImageTitle',
      'ui:field': 'reliningImage',
      'ui:imageName': ReliningImageNameEnum.PREPARATIONS_THREE,
      'ui:buttonLabel': 'relining.preparations.imageTitle',
    },
  },

  result: {
    'ui:widget': 'radio',
  },

  calculator: {
    'ui:title': 'relining.calculator.title',
    'ui:field': 'calculator',
  },

  hardeningTimeEstimate: {
    'ui:field': 'timeEstimate',
  },

  batches: {
    'ui:title': 'relining.title.batchNumber',
    liner: {
      batchImage: {
        'ui:imageTitle': 'relining.liner.batchNumber',
        'ui:field': 'reliningImage',
        'ui:imageName': ReliningImageNameEnum.LINER,
        'ui:hideCaption': true,
      },
      batchNumber: {
        'ui:title': 'relining.liner.batchNumber',
        'ui:widget': 'outlinedText',
      },
      'ui:field': 'batch',
    },
    componentBase: {
      batchImage: {
        'ui:imageTitle': 'relining.componentBase.batchNumber',
        'ui:field': 'reliningImage',
        'ui:imageName': ReliningImageNameEnum.BASE,
        'ui:hideCaption': true,
      },
      batchNumber: {
        'ui:title': 'relining.componentBase.batchNumber',
        'ui:widget': 'outlinedText',
      },
      'ui:field': 'batch',
    },
    componentHardener: {
      batchImage: {
        'ui:imageTitle': 'relining.componentHardener.batchNumber',
        'ui:field': 'reliningImage',
        'ui:imageName': ReliningImageNameEnum.HARDENER,
        'ui:hideCaption': true,
      },
      batchNumber: {
        'ui:title': 'relining.componentHardener.batchNumber',
        'ui:widget': 'outlinedText',
      },
      'ui:field': 'batch',
    },
  },

  tightnessControl: {
    'ui:title': 'relining.tightnessControl.title',
    start: {
      'ui:field': 'reliningImage',
      'ui:imageName': ReliningImageNameEnum.TIGHTNESS_CONTROL_START,
      'ui:imageType': 'start',
      'ui:imageTitle': 'relining.tightnessControl.start.title',
    },
    end: {
      'ui:field': 'reliningImage',
      'ui:imageName': ReliningImageNameEnum.TIGHTNESS_CONTROL_END,
      'ui:imageType': 'end',
      'ui:imageTitle': 'relining.tightnessControl.end.title',
    },
  },
  visualControlComment: {
    'ui:widget': 'textarea',
  },

  inspection: {
    'ui:title': 'relining.inspection.title',
    start: {
      'ui:field': 'reliningImage',
      'ui:imageName': ReliningImageNameEnum.INSPECTION_START,
      'ui:imageType': 'start',
      'ui:imageTitle': 'relining.inspection.start.title',
    },
    end: {
      'ui:field': 'reliningImage',
      'ui:imageName': ReliningImageNameEnum.INSPECTION_END,
      'ui:imageType': 'end',
      'ui:imageTitle': 'relining.inspection.end.title',
    },
  },

  installationPressure: {
    'ui:field': 'installationPressure',
  },
  temperatureInPipe: {
    'ui:field': 'temperatureInPipeImage',
  },
  hardening: {
    'ui:field': 'hardening',
  },

  openingBranches: {
    'ui:rawOptions': true,
  },

  describeDeviation: {
    'ui:widget': 'textarea',
  },

  other: {
    'ui:widget': 'textarea',
  },

  showHeatHardening: {
    'ui:widget': 'switch',
  },
  heatHardening: {
    'ui:field': 'heatHardening',
  },
  hardeningTime: {
    'ui:field': 'hardeningTimer',
  },

  __DEPENDENTS__: {
    describeDeviation: [
      {
        field: 'result',
        enum: ['deviation', 'approvedDeviation'],
      },
    ],
    'preparations.preparationTypeOther': [
      {
        field: 'preparations.preparationType',
        enum: ['other'],
      },
    ],
    'preparations.pipeTypeOther': [
      {
        field: 'preparations.pipeType',
        enum: ['other'],
      },
    ],
    liningOther: [
      {
        field: 'lining',
        enum: ['other'],
      },
    ],
    liningInstallationEquipment: [
      {
        field: 'lining',
        enum: ['installationEquipment'],
      },
    ],
    heatHardening: ['showHeatHardening'],

    'inspection.end': ['inspection.start.imageUrl'],
    'tightnessControl.end': ['tightnessControl.start.imageUrl'],

    'eventDescription.preparationsImageTwo': [
      { resolveBy: 'or' },
      'eventDescription.preparationsImage.imageUrl',
      'eventDescription.preparationsImageTwo.imageUrl',
    ],
    'eventDescription.preparationsImageThree': [
      { resolveBy: 'or' },
      'eventDescription.preparationsImageTwo.imageUrl',
      'eventDescription.preparationsImageThree.imageUrl',
    ],
  },
};
