/* eslint-disable camelcase */
import * as ReactRouter from 'react-router-dom';
import * as React from 'react';
import { Routing } from 'global/routing';
import { ProfileView } from 'pages/Profile/components/ProfileView/ProfileView';
import { ProfileEdit } from 'pages/Profile/components/ProfileEdit/ProfileEdit';
import { Page } from 'components/seo/Page';

export function Profile() {
  return (
    <ReactRouter.Switch>
      <Page
        titleIntlId="page.profile.edit"
        path={Routing.PROFILE.Edit}
        component={ProfileEdit}
      />

      <Page
        titleIntlId="page.profile.view"
        path={Routing.PROFILE.View}
        component={ProfileView}
      />

      <ReactRouter.Redirect to={Routing.PROFILE.View} />
    </ReactRouter.Switch>
  );
}
