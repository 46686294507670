import { identify, makeRequest, Service } from 'utils/http';
import { updateDocumentApi } from 'services/documents/update-document/api';
import { FinalizeDocumentRequest } from 'trace-backend-sdk';
import { DocumentRequestBase } from 'services/documents/types';

type Data = DocumentRequestBase & {
  // eslint-disable-next-line @typescript-eslint/ban-types
  documentData: object;
  recipients: FinalizeDocumentRequest['recipients'];
};

export async function finalizeDocumentApi(data: Data) {
  await updateDocumentApi({
    templateId: data.templateId,
    documentId: data.documentId,
    documentData: data.documentData,
  });

  return makeRequest(
    Service.finalizeDocument(
      data.documentId,
      data.templateId,
      { recipients: data.recipients },
      await identify(),
    ),
  );
}
