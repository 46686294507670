import * as React from 'react';

import { useModal } from 'store/modals';
import { PdfViewer } from 'components/PdfViewer/PdfViewer';
import { BaseModal } from 'components/modals/BaseModal';

type PdfModalProps = {
  src?: string;
};

export function PdfModal({ src }: PdfModalProps) {
  const { closeModal } = useModal();

  return (
    <BaseModal
      open
      onClose={closeModal}
      contentSx={{
        zIndex: 'highest',
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {src && (
        <PdfViewer
          onClose={() => {
            closeModal();
          }}
          pdfLink={src}
        />
      )}
    </BaseModal>
  );
}
