import React from 'react';
import * as ReactRouterDom from 'react-router-dom';

import { DocumentEdit } from 'pages/Document/components/DocumentEdit/DocumentEdit';
import { DocumentAddRecipients } from 'pages/Document/components/DocumentAddRecipients';
import {
  createRoutePath,
  DocumentTab,
  DocumentTabLink,
  Routing,
} from 'global/routing';
import { DocumentTemplateId } from 'services/documents';

export const Document: React.FC = () => {
  const match = ReactRouterDom.useRouteMatch();

  return (
    <ReactRouterDom.Switch>
      <ReactRouterDom.Route path={`${match.path}/edit`}>
        <DocumentEdit />
      </ReactRouterDom.Route>

      <ReactRouterDom.Route path={`${match.path}/recipients`}>
        <DocumentAddRecipients />
      </ReactRouterDom.Route>

      <ReactRouterDom.Redirect to={`${match.url}/edit`} />
    </ReactRouterDom.Switch>
  );
};

export function useDocumentParams() {
  return ReactRouterDom.useParams<{
    templateId: DocumentTemplateId;
    documentId: string;
    tab: DocumentTab;
  }>();
}

type DocumentStep = 'edit' | 'recipients' | 'success';
export function getDocumentStepTab({
  documentId,
  tab,
  templateId,
  documentStep,
}: DocumentTabLink & { documentStep: DocumentStep }) {
  return createRoutePath(
    Routing.DOCUMENT.Links.DocumentTab({
      tab,
      documentId,
      templateId,
    }),
    documentStep,
  );
}
