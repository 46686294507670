import { identify, makeRequest, Service } from 'utils/http';

type Data = {
  workspaceId: string;
  providerApiKey: string;
  providerServerId: string;
};

export async function adminAddWorkspaceToTraceProviderApi({
  workspaceId,
  providerApiKey,
  providerServerId,
}: Data) {
  return makeRequest(
    Service.adminAddWorkspaceTraceProvider(
      workspaceId,
      {
        apiKey: providerApiKey,
        serverId: providerServerId,
      },
      await identify(),
    ),
  );
}
