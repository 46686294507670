import * as React from 'react';
import { ContainerProps } from 'theme-ui';
import { Text, TextProps } from '../../../../components/typography';
import { Container } from '../../../../components/layout';

export function PaymentsTitle({ intlId }: Pick<TextProps, 'intlId'>) {
  return (
    <Text
      sx={{ fontWeight: 600, mb: 5, lineHeight: 2, fontSize: '23px' }}
      variant="headline2"
      intlId={intlId}
      as="h2"
    />
  );
}

export function PaymentsDescription({ intlId }: Pick<TextProps, 'intlId'>) {
  return (
    <Text
      sx={{ mb: 5 }}
      variant="body1"
      intlValues={{
        bold: (message: string) => (
          <Text sx={{ fontWeight: 500 }}>{message}</Text>
        ),
      }}
      intlId={intlId}
      as="p"
    />
  );
}

export function PaymentsContainer({
  children,
  className,
}: Pick<ContainerProps, 'children' | 'className'>) {
  return (
    <Container
      className={className} variant="maxWidthWrapper"
      sx={{ p: 9 }}
    >
      {children}
    </Container>
  );
}
