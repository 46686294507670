import * as ThemeUI from 'theme-ui';
import React from 'react';
import { Box, Flex } from 'components/layout';
import { Text } from 'components/typography';

type ZoomFileInputProps = {
  zoomValue: string | number;
  disabled: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  children?: React.ReactNode;
  min?: number;
  max?: number;
  step?: number;
};

export function ZoomFileInput({
  min = 1,
  max = 10,
  step = 0.1,
  ...props
}: ZoomFileInputProps) {
  return (
    <Box sx={{ width: '100%' }}>
      <Flex
        sx={{
          justifyContent: 'space-between',
          pb: 2,
        }}
      >
        {props.children}
      </Flex>
      <ThemeUI.Slider
        sx={{
          cursor: `${props.disabled ? 'no-drop' : 'pointer'}`,
          '-webkit-appearance': 'none',
          width: '100%',
          backgroundColor: 'transparent',
          '&:focus': {
            outline: 'none',
          },

          '&::-webkit-slider-runnable-track': {
            width: '100%',
            height: '1px',
            cursor: 'pointer',
            backgroundColor: '#707070',
          },
          '&::-webkit-slider-thumb': {
            cursor: `${props.disabled ? 'no-drop' : 'pointer'}`,
            height: '36px',
            width: '36px',
            borderRadius: '50%',
            backgroundColor: `${props.disabled ? 'grey.400' : 'secondary.500'}`,
            '-webkit-appearance': 'none',
            marginTop: '-18px',
          },

          '&::-moz-range-track': {
            width: '100%',
            height: '1px',
            cursor: 'pointer',
            backgroundColor: '#707070',
          },
          '&::-moz-range-thumb': {
            cursor: `${props.disabled ? 'no-drop' : 'pointer'}`,
            height: '36px',
            width: '36px',
            borderRadius: '50%',
            backgroundColor: `${props.disabled ? 'grey.400' : 'secondary.500'}`,
          },

          '&::-ms-track': {
            width: '100%',
            height: '1px',
            cursor: 'pointer',
            backgroundColor: `${props.disabled ? 'grey.400' : 'secondary.500'}`,
          },

          '&::-ms-thumb': {
            cursor: `${props.disabled ? 'no-drop' : 'pointer'}`,
            height: '36px',
            width: '36px',
            borderRadius: '50%',
            backgroundColor: '#0075ff',
          },
        }}
        value={props.zoomValue}
        min={min}
        step={step}
        max={max}
        onChange={props.onChange}
        disabled={props.disabled}
      />
      <Flex
        sx={{
          justifyContent: 'space-between',
          pt: 3,
          pb: 10,
        }}
      >
        <Text
          intlId="settings.fileUpload.zoomOut"
          sx={{
            backgroundColor: 'transparent',
            color: '#9E9EAF',
            fontSize: 'sm',
          }}
        />
        <Text
          intlId="settings.fileUpload.zoomIn"
          sx={{
            backgroundColor: 'transparent',
            color: '#9E9EAF',
            fontSize: 'sm',
          }}
        />
      </Flex>
    </Box>
  );
}
