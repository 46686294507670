import { BottomMenuModal } from 'components/modals/ModalContainer/components/BottomMenuModal';
import { ComponentModal } from 'components/modals/ComponentModal';
import { CameraModal, PdfModal } from './components';

export const Modals = {
  componentModal: ComponentModal,
  inviteUsersModal: ComponentModal,
  cameraModal: CameraModal,
  pdfModal: PdfModal,
  bottomMenuModal: BottomMenuModal,
} as const;
