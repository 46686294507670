import { ComposeOptions } from './types';

/**
 * @internal For use within the URL module
 * */
export function normalizeQuery(query: ComposeOptions['query']) {
  if (!query) {
    return {};
  }

  const result: Record<string, string> = {};

  for (const key in query) {
    if (query[key] != null) {
      result[key] = String(query[key]);
    }
  }

  return result;
}

/**
 * @internal For use within the URL module
 * */
export function mapSearchParamsToObject(searchParams: URLSearchParams) {
  const result: Record<string, string> = {};

  searchParams.forEach((value, key) => {
    result[key] = value;
  });

  return result;
}
