import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as Icons from 'components/icons';
import * as ThemeUI from 'theme-ui';
import 'react-html5-camera-photo/build/css/index.css';
import Camera, {
  CameraProps,
  FACING_MODES,
  IMAGE_TYPES,
} from 'react-html5-camera-photo';
import { useModal } from 'store/modals';
import { Box } from 'components/layout';
import { useRollbar } from 'lib/rollbar';
import { useNotifications } from 'components/notifications/notifications';
import { checkBase64String } from 'utils/base64/check-base64-string';

type CameraModalProps = CameraProps;
type CameraState = 'open' | 'closed';

export function CameraModal(props: CameraModalProps) {
  const [cameraState, setCameraState] = React.useState<CameraState>('closed');
  const rollbar = useRollbar();
  const notifications = useNotifications();

  const { closeModal, modalType } = useModal();

  const cameraElement = (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 'highest',
        height: '100%',
        display: cameraState === 'open' ? 'block' : 'none',

        '& > div': {
          height: '100% !important',
          width: '100% !important',
        },

        /**
         * The default styles for camera element used "vh"
         * units which don't work as expected on mobile devices.
         * */
        '& video': {
          height: '100% !important',
          width: '100% !important',
        },
      }}
    >
      <Camera
        idealFacingMode={FACING_MODES.ENVIRONMENT}
        idealResolution={{ width: 1920, height: 1080 }}
        imageType={IMAGE_TYPES.JPG}
        imageCompression={0.97}
        isMaxResolution
        isImageMirror={false}
        isSilentMode={false}
        sizeFactor={1}
        isFullscreen
        isDisplayStartCameraError={false}
        onCameraError={(error) => {
          closeModal();
          rollbar.error('[CameraModal][onCameraError]', error);

          if (/permission denied/i.test(error.message)) {
            notifications.error({
              description: 'errors.camera.permissionDenied',
              title: 'errors.camera.title',
            });
          } else {
            notifications.error({
              description: 'errors.geolocation.generic',
            });
          }

          if (props.onCameraError) {
            props.onCameraError(error);
          }
        }}
        onCameraStart={() => {
          setCameraState('open');
        }}
        {...props}
        onTakePhotoAnimationDone={(dataUri) => {
          /*
           * If you take the picture too fast, before the camera has
           * had a chance to initialize, the resulting image will
           * be a broken one. In this case, we want to inform the user
           * that the image is invalid and that they should try again.
           * */
          try {
            checkBase64String(dataUri);
            if (props.onTakePhotoAnimationDone) {
              props.onTakePhotoAnimationDone(dataUri);
            }
          } catch (error) {
            notifications.error({
              title: 'errors.camera.invalidImage.title',
              description: 'errors.camera.invalidImage.description',
            });
          } finally {
            closeModal();
          }
        }}
      />

      {modalType === 'cameraModal' && cameraState === 'open' && (
        <ThemeUI.IconButton
          sx={{
            width: 'fit-content',
            position: 'absolute',
            top: '32px',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
          onClick={() => {
            closeModal();
          }}
        >
          <Icons.CancelFilled width={48} fill="rgba(255, 255, 255, 0.7)" />
        </ThemeUI.IconButton>
      )}
    </Box>
  );

  return portalToBody(cameraElement);
}

function portalToBody(element: React.ReactElement) {
  return ReactDOM.createPortal(element, document.body);
}
