import {
  Dependent,
  DependentExtended,
  ResolveBy,
} from 'components/form-elements/JsonForms/types';
import _ from 'lodash-es';

export function getDotSeparatedId(id: string) {
  return id.replace(/_/g, '.').replace(/root\./, '');
}

export function isExtendedDependent(
  dependent: Dependent,
): dependent is DependentExtended {
  return typeof dependent === 'object';
}

/**
 * Dependents exist as a string -> Dependent[] mapping.
 *
 * We need to resolve them to become string -> boolean mapping,
 * where each string is the dependee (the field which will *not* render,
 * unless all of its dependents are resolved to true), and each value is a
 * boolean value, indicating whether all dependents resolved to true.
 * */
export function reduceDependentsToBooleanMap(
  dependents: Record<string, Dependent[]>,
  formData: Record<string, any>,
) {
  const result: Record<string, boolean> = {};

  for (const key in dependents) {
    const dependent = _.get(dependents, key);
    result[key] = resolveDependents(dependent, formData);
  }

  return result;
}

type DependentKey = keyof Omit<DependentExtended, 'field' | 'resolveBy'>;
export type DependentResolvers = {
  [K in DependentKey]: (value: any, dependent: DependentExtended[K]) => boolean;
};

/**
 * Any added key to `DependentExtended` needs to be given a resolver,
 * which will take a field value and a dependent, and will resolve to
 * a boolean (meaning the dependent is successfully resolved).
 * */
const DEPENDENT_RESOLVERS: DependentResolvers = {
  // eslint-disable-next-line eqeqeq
  enum: (value, dependent) => dependent?.some((val) => val === value) ?? true,
  pattern: (value, dependent) => dependent?.test(value) ?? true,
};

const RESOLVE_BY_METHOD: Record<ResolveBy['resolveBy'], 'every' | 'some'> = {
  and: 'every',
  or: 'some',
};

function isResolveBy(value: Dependent): value is ResolveBy {
  return typeof value !== 'string' && 'resolveBy' in value;
}

function resolveDependents(
  dependents: Dependent[],
  formData: Record<string, unknown>,
) {
  const { resolveBy } = dependents.find(isResolveBy) ?? {};
  const resolveByMethod = resolveBy ? RESOLVE_BY_METHOD[resolveBy] : 'every';

  /*
   * Remove the `resolveBy` dependent, because it is only
   * used for specifying the mode and should not be resolved
   * as a normal dependent.
   * */
  const validDependents = dependents.filter(
    (dep) => !isResolveBy(dep),
  ) as Exclude<Dependent, ResolveBy>[];

  return validDependents[resolveByMethod]((dependent) => {
    if (isExtendedDependent(dependent)) {
      return resolveExtendedDependent(dependent, formData);
    }

    /**
     * If dependent is not an object, then its a string
     * and it can be resolved simply by checking for its
     * value existence.
     * */
    const value = _.get(formData, dependent);

    if (Array.isArray(value)) {
      return resolveArrayDependency(value);
    }

    return Boolean(value);
  });
}

function resolveArrayDependency(arr: any[]) {
  return arr.length > 0;
}

function resolveExtendedDependent(
  dependent: DependentExtended,
  formData: Record<string, unknown>,
) {
  const dependentKeys = Object.keys(dependent) as DependentKey[];

  return dependentKeys.some((dependentKey) => {
    const dependentValue = dependent[dependentKey];

    const fieldValue = _.get(formData, dependent.field);
    const dependentFn = DEPENDENT_RESOLVERS[dependentKey];

    if (!dependentValue || !dependentFn) {
      return false;
    }

    /**
     * In the case of a value coming from an array type,
     * loop through all its values, and try to resolve if
     * any of them fulfill the dependent.
     *
     * An example of the situation described above:
     * ```
     * dependent: {
     *   field: 'test',
     *   enum: 'test'
     * },
     *
     * value: ['val1', 'val2', 'test']
     * ```
     * */
    if (Array.isArray(fieldValue)) {
      for (const fieldVal of fieldValue) {
        // @ts-expect-error Cannot be certain of the resolved function at runtime,
        // can't bother right now to type it, it works as verified in tests.
        if (dependentFn(fieldVal, dependentValue)) {
          return true;
        }
      }
    }

    // @ts-expect-error Same as above
    return dependentFn(fieldValue, dependentValue);
  });
}

export { mapJsonFormErrors } from './map-jsonform-errors';
