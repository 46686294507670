export const shadows = {
  /**
   * TODO: Rework shadows to be generic
   *  (use incrementing numbers to signify shadow intensity)
   *
   * We should not have shadows of the type 'sideMenu', because
   * that shadow might need to be used in a place which is not
   * a side menu, in which case it makes no sense for it to be
   * called 'sideMenu'.
   * */

  homeCard: {
    elevation: '0pt 3pt 8pt #00000024',
  },
  homeHeadline: {
    elevation: '0pt 3pt 6pt #00000029',
  },
  userCard: {
    elevation: '0pt 3pt 18pt #00000014',
  },
  documentCard: {
    elevation: '0pt 3pt 6pt #0000000D',
  },
  modal: '0 3px 16px #9E9EAF5E',

  1: '0 3px 6px #00000029',
  2: '0 3px 6px #0000000F',

  '0dp': 'none',
  '1dp':
    '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
  '2dp':
    '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.20)',
  '3dp':
    '0 3px 4px 0 rgba(0,0,0,0.14), 0 3px 3px -2px rgba(0,0,0,0.12), 0 1px 8px 0 rgba(0,0,0,0.20)',
  '4dp':
    '0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.20)',
  '6dp':
    '0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.20)',
  '8dp':
    '0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.20)',
  '9dp':
    '0 9px 12px 1px rgba(0,0,0,0.14), 0 3px 16px 2px rgba(0,0,0,0.12), 0 5px 6px -3px rgba(0,0,0,0.20)',
  '12dp':
    '0 12px 17px 2px rgba(0,0,0,0.14), 0 5px 22px 4px rgba(0,0,0,0.12), 0 7px 8px -4px rgba(0,0,0,0.20)',
  '16dp':
    '0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -5px rgba(0,0,0,0.20)',
  '24dp':
    '0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.20)',
};
