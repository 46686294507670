import { AjvError } from '@rjsf/core';

const errorTranslationKeyResolvers = {
  required: (property: string, params: Record<string, string>) =>
    `errors.${params.missingProperty}.required`,
  minItems(property: string, params: Record<string, string>) {
    switch (Number(params.limit)) {
      case 1:
        return `errors.${property}.minOneItem`;
      default:
        return '';
    }
  },
  minimum(property: string) {
    return `errors.${property}.minimum`;
  },
} as const;

function resolveAjvErrorTranslationKey(error: AjvError) {
  const resolverKey = error.name;
  // @ts-expect-error Key error
  const resolver = errorTranslationKeyResolvers[resolverKey];

  if (!resolver) return error;

  return {
    ...error,
    /**
     * Properties are in the format of ".path.deep.property",
     * so we omit the first dot, because its added in the resolvers.
     * */
    message: resolver(error.property.slice(1), error.params) as string,
  };
}

const isPresent = <T>(val: T | null): val is T => val != null;

function mapJsonFormErrors(errors: AjvError[]) {
  return errors.map(resolveAjvErrorTranslationKey).flat().filter(isPresent);
}

export { mapJsonFormErrors, resolveAjvErrorTranslationKey };
