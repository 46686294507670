import * as ReactQuery from 'react-query';
import { InferMutationOptions } from 'utils/types';
import { finalizeDocumentApi } from 'services/documents/finalize-document/api';
import { QUERY_KEY_DOCUMENT } from '../get-document';

export function useFinalizeDocument(
  options?: InferMutationOptions<typeof finalizeDocumentApi>,
) {
  const queryClient = ReactQuery.useQueryClient();
  const { mutate: finalizeDocument, ...rest } = ReactQuery.useMutation(
    'finalizeDocument',
    finalizeDocumentApi,
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries(
          QUERY_KEY_DOCUMENT(variables.documentId, variables.templateId),
        );

        if (options?.onSuccess) {
          await options.onSuccess(data, variables, context);
        }
      },
    },
  );

  return {
    finalizeDocument,
    ...rest,
  };
}
