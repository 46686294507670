import * as React from 'react';
import * as R from 'remeda';
import { DocumentsService } from 'services/documents';
import { PageLoadingIndicator } from 'components/PageLoadingIndicator/PageLoadingIndicator';
import { useSearchQuery } from 'utils/url/use-search-query';
import { Container } from 'components/layout';
import { Text } from 'components/typography';
import { Button } from 'components/form-elements';
import { DocumentList } from '../../features/document/components/DocumentList';

export function Search() {
  const { search } = useSearchQuery();

  const {
    isLoading: isDocumentsLoading,
    data,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = DocumentsService.usePaginatedDocuments(
    undefined,
    undefined,
    undefined,
    undefined,
    search,
  );

  const documents = R.flatMap(data?.pages ?? [], (page) => page.documents);

  if (isDocumentsLoading) {
    return (
      <PageLoadingIndicator
        loadingTextIntlId="search.loadingData"
        loadingTextIntlValues={{
          searchQuery: search,
        }}
      />
    );
  }

  return (
    <Container variant="spacer">
      {documents.length > 0 && (
        <Text
          as="p"
          sx={{
            textAlign: 'center',
            my: 4,
            color: 'grey.400',
          }}
          intlId="search.results"
          intlValues={{
            results: data?.pages[0].total,
            searchTerm: search,
          }}
        />
      )}

      <DocumentList documents={documents} />

      {hasNextPage && (
        <Button
          isLoading={isFetchingNextPage}
          onClick={() => {
            fetchNextPage();
          }}
          intlId="documents.loadMoreButton"
        />
      )}
    </Container>
  );
}
