import { convertLocationPrecision } from 'utils/geolocation/convert-location-precision';

export function formatLocation({
  longitude,
  latitude,
}: {
  latitude: string | number;
  longitude: string | number;
}) {
  return [
    convertLocationPrecision(latitude),
    convertLocationPrecision(longitude),
  ].join(', ');
}
