import * as React from 'react';
import { UsersService } from 'services/users/';
import { TemplateIdEnum, ModifyPermissionRequestItem } from 'trace-backend-sdk';
import { WorkspaceService } from 'services/workspace';
import { PermissionsService } from '../index';

export function useUpdateModulePermissions() {
  const { allUsers } = UsersService.useGetUsers();
  const { revokeTemplatePermission, isLoading: revokingPermissions } = PermissionsService.useRevokeTemplatePermission();
  const { grantTemplatePermission, isLoading: grantingPermissions } = PermissionsService.useGrantTemplatePermission();

  const { workspaceData } = WorkspaceService.useGetWorkspace();
  const [modulePermissions, setModulePermissions] = React.useState<
    TemplateIdEnum[]
  >([]);

  React.useEffect(() => {
    if (workspaceData?.availableTemplates) {
      setModulePermissions(workspaceData?.availableTemplates);
    }
  }, [workspaceData]);

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setModulePermissions((prev: Array<TemplateIdEnum>) => [
        ...prev,
        e.target.value as TemplateIdEnum,
      ]);
    } else {
      let tempPermissions: Array<TemplateIdEnum> = [...modulePermissions];
      tempPermissions = tempPermissions.filter((el) => el !== e.target.value);
      setModulePermissions([...tempPermissions]);
    }
  };

  const onSaveHandler = () => {
    const userIdsArray: string[] = allUsers.map((user) => user.userId);
    const shouldRevoke: ModifyPermissionRequestItem[] = [];
    const shouldGrant: ModifyPermissionRequestItem[] = [];

    modulePermissions.forEach((template: TemplateIdEnum) => {
      if (!workspaceData?.availableTemplates?.includes(template)) {
        shouldGrant.push({
          templateId: template,
          userIds: [...userIdsArray],
        });
      }
    });

    workspaceData?.availableTemplates?.forEach((template: TemplateIdEnum) => {
      if (!modulePermissions.includes(template)) {
        shouldRevoke.push({
          templateId: template,
          userIds: [...userIdsArray],
        });
      }
    });

    if (shouldGrant.length) {
      grantTemplatePermission({
        items: [...shouldGrant],
      });
    }

    if (shouldRevoke.length) {
      revokeTemplatePermission({
        items: [...shouldRevoke],
      });
    }
  };

  return {
    modulePermissions,
    onChangeHandler,
    onSaveHandler,
    isUpdatingPermissions: (grantingPermissions || revokingPermissions) ?? true,
  };
}
