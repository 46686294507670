import * as ReactQuery from 'react-query';
import React from 'react';
import { DocumentsService } from '../../services/documents';
import { useRedirect } from '../redirect';
import { QUERY_KEY_DOCUMENT } from '../../services/documents/get-document';
import { getDocumentStepTab, useDocumentParams } from '../../pages';

/*
 * Abstracts the common use case of submitting a document
 * and navigating to the recipients tab.
 * */
export function useSubmitDocument() {
  const { documentId, templateId, tab } = useDocumentParams();
  const { updateDocument } = DocumentsService.useUpdateDocument();
  const queryClient = ReactQuery.useQueryClient();
  const { onRedirect } = useRedirect('');

  return React.useCallback(
    (formData: Record<string, any>) => {
      updateDocument(
        {
          documentData: formData,
          documentId,
          templateId,
        },
        {
          onSuccess: async () => {
            /**
             * 1. Update request
             * 2. Invalidate, so that recipients gets fresh value in its query
             * 3. Navigate to recipients
             * */
            await queryClient.invalidateQueries(
              QUERY_KEY_DOCUMENT(documentId, templateId),
            );
            onRedirect(
              getDocumentStepTab({
                documentId: parseInt(documentId, 10),
                templateId,
                documentStep: 'recipients',
                tab,
              }),
            );
          },
        },
      );
    },
    [documentId, onRedirect, queryClient, tab, templateId, updateDocument],
  );
}
