import React from 'react';
import * as Rhf from 'react-hook-form';
import * as _ from 'lodash-es';

import { Input, InputProps } from 'components/form-elements';
import { Colors } from 'theming/foundations/colors';
import { LabelledFileInput } from 'components/form-elements/labels/LabelledFileInput';
import { LocaleKey } from 'translations';

function composeFieldName(...names: string[]) {
  return names.join('.');
}

export interface FieldEditTextProps extends InputProps {
  labelIntlId?: LocaleKey;
  backgroundColor?: Colors;
  name: string;
  type?: string;
  isLabelFloated?: boolean;
  isAlwaysFloating?: boolean;
}

export const FieldFileUpload: React.FC<FieldEditTextProps> = ({
  children,
  sx,
  id,
  labelIntlId,
  backgroundColor = 'white.100',
  name,
  required,
  isLabelFloated = true,
  /**
   * Workaround for problems with browser autofill.
   * We should figure out a way to make the label trigger
   * float even with browser autofill, not only user input.
   * */
  isAlwaysFloating = false,
  ...props
}) => {
  const [isActive, setIsActive] = React.useState(false);

  const formMethods = Rhf.useFormContext();
  const { errors } = Rhf.useFormState({
    control: formMethods.control,
  });

  const errorMessage: string | undefined = _.get(
    errors,
    composeFieldName(name, 'message'),
  );

  const fieldValues = formMethods.register(name);

  return (
    <LabelledFileInput
      backgroundColor={backgroundColor}
      labelIntlId={labelIntlId}
      sx={sx}
      id={id}
      errorMessage={errorMessage}
      isActive={isAlwaysFloating || isActive}
      isFocused={isAlwaysFloating || isActive} // these values are equal for inputs
      disabled={props.disabled}
      required={required}
      isLabelFloated={isLabelFloated}
    >
      <Input
        onFocus={() => setIsActive(true)}
        id={id}
        sx={{
          backgroundColor,
          px: 4,
        }}
        type="file"
        {...props}
        {...fieldValues}
        name={fieldValues.name}
        ref={fieldValues.ref}
      >
        {children}
      </Input>
    </LabelledFileInput>
  );
};
