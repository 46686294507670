import * as ReactQuery from 'react-query';
import { InferMutationOptions } from 'utils/types';
import { updateDocumentAssigneeApi } from 'services/documents/update-document-assignee/api';

export function useUpdateDocumentAssignee(
  options?: InferMutationOptions<typeof updateDocumentAssigneeApi>,
) {
  const { mutate: updateDocumentAssignee, ...rest } = ReactQuery.useMutation(
    'updateDocumentAssignee',
    updateDocumentAssigneeApi,
    options,
  );

  return {
    updateDocumentAssignee,
    ...rest,
  };
}
