import React from 'react';
import { IntlProps } from 'translations';
import { StyleObject } from 'theming';
import {
  Link as GlobalLink,
  LinkProps as GlobalLinkProps,
} from 'components/links';

export interface LinkProps extends IntlProps, GlobalLinkProps {
  sx?: StyleObject;
}

export const Link: React.FC<LinkProps> = ({ intlId, to, sx }) => (
  <GlobalLink
    to={to} intlId={intlId}
    variant="action-link" sx={sx}
  />
);
