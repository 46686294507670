/** @jsxImportSource theme-ui */
import Popup from 'reactjs-popup';
import { FocusThief } from 'components/FocusThief/FocusThief';
import React from 'react';
import { useModal } from 'store/modals';
import { Flex } from 'components/layout';

export function BottomMenuModal({ children }: { children: React.ReactNode }) {
  const { closeModal } = useModal();

  return (
    <Popup
      onClose={() => {
        closeModal();
      }}
      open
      nested
      sx={{
        '&-content': {
          margin: '0 !important',
          marginTop: 'auto !important',
          width: '100%',
        },

        '&-overlay': {
          backgroundColor: 'overlay.300',
        },
      }}
      modal
    >
      <FocusThief />

      <Flex
        sx={{
          borderTopLeftRadius: 1,
          borderTopRightRadius: 1,
          width: '100%',
          backgroundColor: 'white.50',
          flexDirection: 'column',
          alignItems: 'center',
          py: 9,
          pb: 72,
          boxShadow: '24dp',
        }}
      >
        {children}
      </Flex>
    </Popup>
  );
}
