/** @jsxImportSource theme-ui */

import * as React from 'react';
import { Text } from 'components/typography';
import { Box, Flex } from 'components/layout';
import { Radio } from 'theme-ui';
import { WorkspaceLayout } from 'components/workspace-layout';
import { IntlCustom } from 'lib';
import { GenericFn } from 'utils/types';
import { Button } from 'components/form-elements';
import {
  PaymentsContainer,
  PaymentsDescription,
  PaymentsTitle,
} from '../components/select-modules/Common';
import { PaymentsService, PaymentsUtils } from '..';
import { Triangle } from './SelectModulesPage';
import { usePaymentsFormContext } from './PaymentsPage';

export function SelectPaymentPlanPage({
  setCurrentStep,
}: {
  setCurrentStep: GenericFn;
}) {
  const [selectedPlan, setSelectedPlan] = React.useState<string>('card');

  const onPlanChange = (plan: string) => setSelectedPlan(plan);

  const intl = IntlCustom.useIntl();

  const { invalidateWorkspaceOnTimeout } = PaymentsUtils.useInvalidateWorkspaceOnTimeout();

  const {
    createPaymentsFreeSubscription,
    isLoading: isCreatingFreeSubscription,
  } = PaymentsService.useCreatePaymentsFreeSubscription({
    onSuccess: async () => {
      await invalidateWorkspaceOnTimeout();
    },
  });
  const { pricesAggregator } = usePaymentsFormContext();

  const paymentPlanOptions = [
    {
      id: 'card',
      titleIntl: 'payment.selectPaymentPlan.card',
      descriptionIntl: 'payment.selectPaymentPlan.cardDescription',
    },
    {
      id: 'invoice',
      titleIntl: 'payment.selectPayementPlan.invoice',
      descriptionIntl: 'payment.selectPaymentPlan.invoiceDescription',
    },
  ];

  async function onPlanSubmit() {
    if (selectedPlan === 'card') {
      setCurrentStep();
    }

    if (selectedPlan === 'invoice') {
      createPaymentsFreeSubscription({
        stripePrices: pricesAggregator.values.map((priceId) => ({
          stripePriceId: priceId,
        })),
      });
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box
        sx={{
          backgroundColor: 'bg.50',
          filter: 'drop-shadow(0px 2px 8px rgb(0 0 0 / 14%))',
        }}
      >
        <PaymentsContainer sx={{ py: 6, position: 'relative' }}>
          <WorkspaceLayout.Logo />
          <PaymentsTitle intlId="payment.selectPaymentPlan.pageTitle" />
          <PaymentsDescription intlId="payment.selectPaymentPlan.pageDescription" />
          <Triangle />
        </PaymentsContainer>
      </Box>

      <Flex sx={{ flexDirection: 'column', flexGrow: 1, mt: 30 }}>
        <PaymentsContainer
          sx={{ minHeight: '100%', display: 'flex', flexDirection: 'column' }}
        >
          <Box sx={{ flexGrow: 1, position: 'relative' }}>
            <Flex sx={{ flexDirection: 'column', gap: '20px', mb: 20 }}>
              {paymentPlanOptions.map((option) => (
                <PaymentPlanCardWrapper key={option.id}>
                  <Box
                    onClick={() => onPlanChange(option.id)}
                    sx={{ ':hover': { cursor: 'pointer' } }}
                  >
                    <Radio
                      sx={{
                        mr: '20px',
                        color:
                          selectedPlan === option.id
                            ? 'secondary.600'
                            : 'darkgrey.800',
                      }}
                      value={option.id}
                      checked={selectedPlan === option.id}
                      onChange={() => onPlanChange(option.id)}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      flexDirection: 'column',
                      columnGap: '5px',
                    }}
                  >
                    <Text
                      sx={{
                        color:
                          selectedPlan === option.id
                            ? 'primary.900'
                            : 'darkgrey.800',
                        textTransform: 'uppercase',
                        fontSize: 20,
                        fontWeight: selectedPlan === option.id ? 600 : 500,
                      }}
                    >
                      {intl.formatMessage({
                        id: option.titleIntl,
                      })}
                    </Text>

                    <Text
                      as="p"
                      sx={{
                        color: 'darkgrey.800',
                        fontSize: 13,
                        fontWeight: 400,
                      }}
                    >
                      {intl.formatMessage({
                        id: option.descriptionIntl,
                      })}
                    </Text>
                  </Box>
                </PaymentPlanCardWrapper>
              ))}
            </Flex>
          </Box>

          <Button
            isLoading={isCreatingFreeSubscription}
            onClick={() => onPlanSubmit()}
            intlId="payment.selectPaymentPlan.addPayment"
          />
        </PaymentsContainer>
      </Flex>
    </Box>
  );
}

function PaymentPlanCardWrapper({ children }: { children: React.ReactNode }) {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        backgroundColor: 'white.50',
        borderRadius: '20px',
        padding: '20px',
        boxShadow: '6dp',
      }}
    >
      {children}
    </Box>
  );
}
