import React from 'react';

import { WidgetProps, utils } from '@rjsf/core';
import { useIntl } from 'lib/intl';
import { Select } from '../../Forms/SelectField';

const { asNumber, guessType } = utils;

const nums = new Set(['number', 'integer']);

/**
 * This is a silly limitation in the DOM where option change event values are
 * always retrieved as strings.
 */
const processValue = (schema: any, value: any) => {
  // "enum" is a reserved word, so only "type" and "items" can be destructured
  const { type, items } = schema;
  if (value === '') {
    return undefined;
  }
  if (type === 'array' && items && nums.has(items.type)) {
    return value.map(asNumber);
  }
  if (type === 'boolean') {
    return value === 'true';
  }
  if (type === 'number') {
    return asNumber(value);
  }

  // If type is undefined, but an enum is present, try and infer the type from
  // the enum values
  if (schema.enum) {
    if (schema.enum.every((x: any) => guessType(x) === 'number')) {
      return asNumber(value);
    }
    if (schema.enum.every((x: any) => guessType(x) === 'boolean')) {
      return value === 'true';
    }
  }

  return value;
};

export const SelectWidget = ({
  schema,
  id,
  options,
  label,
  required,
  disabled,
  readonly,
  value,
  multiple,
  autofocus,
  onChange,
  onBlur,
  onFocus,
  uiSchema,
  rawErrors = [],
}: WidgetProps) => {
  const { enumOptions, enumDisabled } = options;
  const intl = useIntl();

  const emptyValue = uiSchema['ui:emptyValue'] ?? multiple ? [] : '';

  const _onChange = ({
    target: { value },
  }: React.ChangeEvent<{ name?: string; value: unknown }>) =>
    onChange(processValue(schema, value));
  // eslint-disable-next-line max-len
  const _onBlur = ({ target: { value } }: React.FocusEvent<HTMLInputElement>) =>
    onBlur(id, processValue(schema, value));
  const _onFocus = ({
    target: { value },
  }: React.FocusEvent<HTMLInputElement>) =>
    onFocus(id, processValue(schema, value));

  return (
    <Select
      backgroundColor="outlinedTextWidget.background"
      isLabelAlwaysLifted
      name={label}
      label={intl.formatMessage({
        id: label,
        defaultMessage: schema.title ?? '',
      })}
      value={typeof value === 'undefined' ? emptyValue : value}
      required={required}
      disabled={disabled || readonly}
      autoFocus={autofocus}
      isError={rawErrors.length > 0}
      // @ts-expect-error asd
      onChange={(event) => _onChange(event)}
      onBlur={_onBlur}
      onFocus={_onFocus}
    >
      {emptyValue === '' && (
        <option
          style={{ color: '#b7b7b7' }}
          value={emptyValue}
          disabled={disabled}
        >
          {intl.formatMessage({
            id: uiSchema['ui:placeholder'] ?? 'fields.select.placeholder',
          })}
        </option>
      )}
      {(enumOptions as any).map(
        ({ value, label: optionLabel }: any, i: number) => {
          // eslint-disable-next-line eqeqeq
          const disabled: any = enumDisabled && (enumDisabled as any).indexOf(value) != -1;
          return (
            <option
              key={i} value={value}
              disabled={disabled}
            >
              {uiSchema['ui:rawOptions']
                ? optionLabel
                : intl.formatMessage({
                  id: [label, optionLabel].join('.'),
                })}
            </option>
          );
        },
      )}
    </Select>
  );
};
