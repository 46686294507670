import React from 'react';
import { Card as GlobalCard } from 'components/cards';
import { Image, ImageProps } from 'components/Image';
import { CardProps } from 'components/cards/Card/Card';
import { Text, TextProps } from '../../typography';
import { Label, LabelProps } from './components';

type HomepageCardLayoutType = React.FC<CardProps> & {
  Image: React.ElementType<ImageProps>;
  Text: React.ElementType<TextProps>;
  Label: React.ElementType<LabelProps>;
};

export const HomepageCard: HomepageCardLayoutType = ({
  children,
  intlId,
  sx,
  onClick,
}) => (
  <GlobalCard
    variant="home"
    intlId={intlId}
    sx={{
      position: 'relative',
      backgroundColor: 'white.50',
      cursor: 'pointer',
      ...sx,
    }}
    onClick={onClick}
  >
    {children}
  </GlobalCard>
);

HomepageCard.Image = Image;
HomepageCard.Text = Text;
HomepageCard.Label = Label;
