import * as ReactQuery from 'react-query';
import React from 'react';
import { useNotifications } from 'components/notifications/notifications';
import { ErrorType } from 'utils/http';
import { CurrentAuthenticatedUserError } from 'utils/auth/errors';

import * as NotificationsService from './notifications';
import * as AdminService from './admin';

export { NotificationsService, AdminService };

const queryClient = new ReactQuery.QueryClient({
  defaultOptions: {
    queries: {
      // TODO: configure optimal values for caching
      // staleTime: Infinity, // Change to milliseconds in case you want to refetch data in the background
      // cacheTime: Infinity, // Change to milliseconds in case you want to garbage collect unused data
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

export const ServiceProvider: React.FC = ({ children }) => {
  const notifications = useNotifications();

  queryClient.setDefaultOptions({
    queries: {
      onError: (error) => {
        if (error == null || !shouldShowNotification(error as Error)) {
          return;
        }

        notifications.error({
          // @ts-expect-error Figure out how to type the BE errors
          description:
            (error as ErrorType)?.message ?? ('errors.defaultMessage' as const),
        });
      },
    },
    mutations: {
      onError: (error) => {
        if (error == null || !shouldShowNotification(error as Error)) {
          return;
        }

        notifications.error({
          // @ts-expect-error Figure out how to type the BE errors
          description:
            (error as ErrorType)?.message ?? ('errors.defaultMessage' as const),
        });
      },
    },
  });

  return (
    <ReactQuery.QueryClientProvider client={queryClient}>
      {children}
    </ReactQuery.QueryClientProvider>
  );
};

function shouldShowNotification(error: Error) {
  if (error instanceof CurrentAuthenticatedUserError) {
    return false;
  }

  return true;
}
