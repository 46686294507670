import * as Lsm from 'little-state-machine';
import * as _ from 'lodash-es';
import * as Utils from 'utils/index';
import { useHistory } from 'react-router-dom';
import React from 'react';
import { parseQuery } from 'utils/url';
import { Environment } from 'global/environment';
import { useNotifications } from 'components/notifications/notifications';
import { Auth } from 'aws-amplify';
import { AuthActions } from 'store';
import { GenericFn } from 'utils/types';
import { Routing } from 'global/routing';
import { buildCognitoUserSession } from 'utils/auth/build-cognito-user-session';
import { getSearchQuery } from '../functions/get-search-query';
import { LOCAL_STORAGE_KEYS, LocalStorage } from '../local-storage';

/**
 * There are five parameters that Cognito uses and stores in local storage.
 * They are described below, and are the ones we need to filter out in order
 * to insert them into the redirect URL.
 * */

const COGNITO_SESSION_PARAMETERS = [
  'idToken',
  'refreshToken',
  'accessToken',
  'LastAuthUser',
  'clockDrift',
];

function isCognitoSessionParameter(param: string) {
  return COGNITO_SESSION_PARAMETERS.some((sessionParam) =>
    param.includes(sessionParam));
}

/**
 * Given an object of local storage entries (this object can be obtained
 * through the "currentAuthenticatedUser" function), extract all the Cognito
 * Identity keys, and filter only the ones that are related to the session.
 * */
function getCognitoStorageEntries(
  amplifyStorage: Storage,
  currentUserUsername: string,
) {
  const getCognitoSessionParameterName = (fullKey: string) =>
    _.last(fullKey.split('.'));

  return _.fromPairs(
    _.toPairs(amplifyStorage)
      .filter(([amplifyStorageKey]) =>
        amplifyStorageKey.includes('CognitoIdentityServiceProvider'))
      .filter(([amplifyStorageKey]) =>
        isCognitoSessionParameter(amplifyStorageKey))
      .filter(([amplifyStorageKey]) => {
        /*
         * Get only the entries which correspond to the:
         * 1. Current user id
         * 2. Current user pool id
         *
         * This makes sure that if there are multiple sets of
         * entries inside the local storage, we only get the
         * ones that are relevant to the current user.
         *
         * This avoids the bug where a user gets redirected to
         * a workspace with credentials that are invalid in that
         * workspace.
         * */
        const [
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          serviceProviderConstant,
          userPoolId,
          authenticatedUsername,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          sessionParameterName,
        ] = amplifyStorageKey.split('.');

        /*
         * Edge case where the amplify storage parameter has only
         * three sections between the dots, instead of four.
         *
         * This is the key in question:
         * CognitoIdentityServiceProvider.{user-pool-id}.LastAuthUser
         *
         * All other relevant keys have a format of:
         * CognitoIdentityServiceProvider.{user-pool-id}.{user-id}.{parameter-name}
         * */
        if (authenticatedUsername === 'LastAuthUser') return true;

        return (
          authenticatedUsername === currentUserUsername
          && userPoolId === Environment.USER_POOL_WEB_CLIENT_ID
        );
      })
      .map(([key, value]) => [getCognitoSessionParameterName(key), value]),
  );
}

/**
 * Function for redirecting the user to the appropriate subdomain,
 * based on their workspace.
 * Will not redirect if the user is already on the correct subdomain.
 *
 * If we have a tenant id, then the current user has a workspace,
 * meaning we can obtain the workspace name, and figure out whether
 * a redirect is needed to that workspace domain.
 * */
export async function redirectToWorkspaceDomain({
  amplifyStorage,
  tenantId,
  currentUserUsername,
  onStartRedirect,
  workspaceName,
}: {
  tenantId?: string;
  amplifyStorage: Storage;
  currentUserUsername: string;
  onStartRedirect?: GenericFn;
  workspaceName: string;
}) {
  if (tenantId) {
    if (!workspaceName) {
      return;
    }

    const currentUrl = new URL(window.location.href);
    const redirectUrl = new URL(
      `${currentUrl.protocol}//${workspaceName}.${
        Environment.DOMAIN_NAME ?? 'localhost:3000'
      }`,
    );

    redirectUrl.pathname = Routing.LOGIN.getPath();
    redirectUrl.search = getSearchQuery((oldParams) => ({
      ...oldParams,
      ...getCognitoStorageEntries(amplifyStorage, currentUserUsername),
      lang: LocalStorage.getItem(LOCAL_STORAGE_KEYS.LANG, '') ?? '',
    }));

    // Save the value in a primitive, rather than
    // keeping it in URL object
    const redirectString = redirectUrl.toString();

    /**
     * The "host" is the part of the URL after the protocol.
     * We need to check if the host matches, and redirect if it doesn't
     *
     * Example:
     * Default host is "trace.com", but we want organizations to
     * use their own subdomains, meaning if I log in using my account,
     * and my organization is called "Example", I should be redirected
     * to "example.trace.com".
     *
     * In the above case, I will be redirected because "trace" !== "example.trace"
     * */
    if (currentUrl.host !== redirectUrl.host) {
      onStartRedirect?.();

      setTimeout(async () => {
        await Auth.signOut();
        window.location.replace(redirectString);
      }, 0);
    }
  }
}

/**
 * Hook to intercept the URL parameters extracted from the Cognito
 * session and create a new session using those URL parameters.
 *
 * This is a top-level hook, and it should be run as soon as the
 * application is initialized.
 *
 * When run, it will effectively "log in" the user using the
 * session parameters from the previous subdomain (the one that
 * the user was on, prior to being redirected).
 * */
export function useAuthenticateFromWorkspaceRedirect() {
  const notifications = useNotifications();
  const history = useHistory();

  const [workspaceRedirectStatus, setWorkspaceRedirectStatus] = React.useState<
    'pending' | 'resolved' | 'rejected'
  >('pending');

  const { setCurrentUserId } = Utils.Auth.useCurrentUserId();

  React.useEffect(() => {
    const searchQuery = parseQuery(history.location.search);

    if (
      searchQuery.idToken
      && searchQuery.accessToken
      && searchQuery.refreshToken
    ) {
      try {
        if (!Environment.USER_POOL_ID) {
          throw new Error('Missing user pool id');
        }

        if (!Environment.USER_POOL_WEB_CLIENT_ID) {
          throw new Error('Missing web client id');
        }

        const { cognitoUserSession, cognitoUser } = buildCognitoUserSession({
          clientId: Environment.USER_POOL_WEB_CLIENT_ID,
          userPoolId: Environment.USER_POOL_ID,
          idToken: searchQuery.idToken,
          refreshToken: searchQuery.refreshToken,
          accessToken: searchQuery.accessToken,
        });

        cognitoUser.setSignInUserSession(cognitoUserSession);

        setCurrentUserId(cognitoUser.getUsername());
        setWorkspaceRedirectStatus('resolved');
      } catch (error) {
        setWorkspaceRedirectStatus('rejected');
        notifications.error({
          description: 'errors.workspaceRedirect',
        });
      }
    } else {
      setWorkspaceRedirectStatus('resolved');
    }

    history.replace({
      ...history.location,
      search: getSearchQuery((oldParams) =>
        _.fromPairs(
          _.toPairs(oldParams).filter(
            ([key]) => !COGNITO_SESSION_PARAMETERS.includes(key),
          ),
        )).toString(),
    });
    // This should never run more than once per app initialization.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    workspaceRedirectStatus,
  };
}

export function useIsWorkspaceRedirectInProgress() {
  const { actions, state } = Lsm.useStateMachine({
    setIsWorkspaceRedirectInProgress:
      AuthActions.setIsWorkspaceRedirectInProgress,
  });

  return React.useMemo(
    () => ({
      isWorkspaceRedirectInProgress: state.isWorkspaceRedirectInProgress,
      setIsWorkspaceRedirectInProgress:
        actions.setIsWorkspaceRedirectInProgress,
    }),
    // Don't add actions to deps array
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.isWorkspaceRedirectInProgress],
  );
}
