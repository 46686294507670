import { identify, makeRequest, Service } from 'utils/http';
import { DocumentRequestBase } from 'services/documents/types';

type Data = DocumentRequestBase & {
  imageName: string;
  latitude: string;
  longitude: string;
  timestamp: number;
};

export async function confirmDocumentImageApi({
  documentId,
  imageName,
  templateId,
  ...data
}: Data) {
  return makeRequest(
    Service.confirmDocumentImage(
      documentId,
      imageName,
      templateId,
      data,
      await identify(),
    ),
  );
}
