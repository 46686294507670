import React from 'react';
import { Text, TextProps } from './Text';

type H1Props = TextProps;

export function H1({ children, ...props }: H1Props) {
  return (
    <Text
      {...props}
      as="h1"
      sx={{
        ...props.sx,
        fontSize: `${28 / 16}rem`,
        fontWeight: 500,
        lineHeight: 2,
      }}
    >
      {children}
    </Text>
  );
}
