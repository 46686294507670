import { makeRequest, Service, identify } from 'utils/http';
import { DocumentRequestBase } from 'services/documents/types';

type Data = DocumentRequestBase;

export async function getDocumentRecipientsApi(data: Data) {
  return makeRequest(
    Service.getDocumentRecipients(
      data.documentId,
      data.templateId,
      await identify(),
    ),
  );
}
