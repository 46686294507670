/** @jsxImportSource theme-ui */
import React from 'react';
import * as _ from 'lodash-es';
import * as Intl from 'lib/intl';
import TextareaAutosize, {
  TextareaAutosizeProps,
} from 'react-textarea-autosize';
import { Box } from 'components/layout';
import { Text } from 'components/typography';
import { Colors } from 'theming/foundations/colors';
import { useLocaleTimeFormat } from 'utils/hooks/use-locale-date-format';
import { FormattedDateParts } from 'lib/intl';
import { StyleObject } from '../../../theming';

export type BaseWidgetData = {
  timestamp: number;
  caption: string;
};

export function QuickTraceWidgetWrapper({
  children,
  hasError,
}: {
  children: React.ReactNode;
  hasError: boolean;
}) {
  return (
    <Box
      sx={{
        ...(hasError && {
          borderTop: '4px solid',
          borderColor: 'error',
        }),

        p: 4,
        backgroundColor: 'white.50',
        boxShadow: '2dp',
        borderRadius: 0,
      }}
    >
      {children}
    </Box>
  );
}

export function QuickTraceWidgetIconWrapper({
  children,
  color = 'primary.500',
  size = 47,
}: {
  children: React.ReactNode;
  color?: Colors;
  size?: number;
}) {
  return (
    <Box
      sx={{
        height: size,
        width: size,
        borderRadius: '50%',
        backgroundColor: color,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {children}
    </Box>
  );
}

export function QuickTraceWidgetTitle({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Text
      as="h2"
      sx={{
        fontWeight: 600,
        fontSize: 'md',
        color: 'darkgrey.800',
        textTransform: 'capitalize',
      }}
    >
      {children}
    </Text>
  );
}

export function QuickTraceWidgetTimestamp({
  timestamp,
  placeholder,
}: {
  timestamp?: number;
  placeholder: string;
}) {
  const { localeTimeFormat } = useLocaleTimeFormat();

  if (!timestamp) {
    return (
      <QuickTraceWidgetDescription>{placeholder}</QuickTraceWidgetDescription>
    );
  }

  const formattedDate = (
    <FormattedDateParts
      value={new Date(timestamp)}
      day="2-digit"
      year="numeric"
      month="short"
    >
      {(parts) => {
        const [day, month, year] = parts.filter(
          ({ type }) => type === 'day' || type === 'month' || type === 'year',
        );

        return (
          <Text>
            {day.value} {month.value} {year.value}
          </Text>
        );
      }}
    </FormattedDateParts>
  );

  const formattedTime = localeTimeFormat(new Date(timestamp));

  return (
    <QuickTraceWidgetDescription>
      {formattedDate}, {formattedTime}
    </QuickTraceWidgetDescription>
  );
}

function QuickTraceWidgetDescription({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Text as="p" sx={{ fontSize: 'md', color: 'grey.300' }}>
      {children}
    </Text>
  );
}

export function QuickTraceWidgetBodyWrapper({
  children,
  sx,
}: {
  children: React.ReactNode;
  sx?: StyleObject;
}) {
  return <Box sx={{ mx: 3, flex: 1, ...sx }}>{children}</Box>;
}

export function QuickTraceWidgetCaptionInput(props: TextareaAutosizeProps) {
  const intl = Intl.useIntl();

  const currentLength = (typeof props.value === 'string' && props.value.length) || 0;
  const maxLength = 200;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <TextareaAutosize
        {...props}
        onChange={(e) => {
          if (props.onChange) {
            props.onChange(
              _.set(e, 'target.value', e.target.value.slice(0, maxLength)),
            );
          }
        }}
        value={props.value ?? ''}
        sx={{
          '&': {
            resize: 'none',
          },
          borderRadius: 18,
          backgroundColor: 'bg.50',
          border: 'none',
          px: 5,
          py: 2,
          fontSize: 'md',

          '&::placeholder': {
            color: '#9E9EAF',
          },
        }}
        placeholder={intl.formatMessage({
          id: 'quickCapture.caption.placeholder',
        })}
      />

      {!props.disabled && (
        <Text
          sx={{
            fontSize: 'sm',
            color: 'grey.300',
            alignSelf: 'flex-end',
            marginRight: 2,
            marginTop: 2,
          }}
        >
          {currentLength}/{maxLength}
        </Text>
      )}
    </Box>
  );
}

export function QuickTraceWidgetContent({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Box
      sx={{
        px: 5,
        py: 4,
        borderTop: '1px solid',
        fontSize: 'md',
        borderTopColor: 'rgba(110,128,186,0.3)',
      }}
    >
      {children}
    </Box>
  );
}
