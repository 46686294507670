import React from 'react';
import { Box } from 'components/layout';
import { Text } from 'components/typography';
import * as Icons from 'components/icons';
import { useModal } from 'store/modals';
import { Currency } from 'components/Currency';

type ManageModulesCardProps = {
  pricePerCertificate: number;
  currency: string;
  moduleName: string;
  ModuleIcon: React.ReactNode;
  children?: React.ReactNode;
  moduleModalText: string;
};

export function ManageModulesCard({
  ModuleIcon,
  pricePerCertificate,
  currency,
  moduleName,
  children,
  moduleModalText,
}: ManageModulesCardProps) {
  return (
    <ModulesCardWrapper>
      {ModuleIcon}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
          minWidth: 'fit-content',
          px: 2,
          gap: '5px',
        }}
      >
        <ManageModulesCardTitle>{moduleName}</ManageModulesCardTitle>
        <ManageModulesCardCurrency
          value={pricePerCertificate}
          currency={currency}
        />
        <ManageModulesInfoModal text={moduleModalText} title={moduleName} />
      </Box>
      {children}
    </ModulesCardWrapper>
  );
}

export function ModulesCardWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        backgroundColor: 'white.50',
        borderRadius: '20px',
        pt: 4,
        pb: 5,
        px: 4,
        boxShadow: '6dp',
      }}
    >
      {children}
    </Box>
  );
}

function ManageModulesCardTitle({ children }: { children: React.ReactNode }) {
  return (
    <Text
      as="h3"
      sx={{
        color: 'primary.900',
        fontSize: 'lg',
        textTransform: 'uppercase',
        fontWeight: 600,
      }}
    >
      {children}
    </Text>
  );
}

function ManageModulesCardCurrency({
  value,
  currency,
}: {
  value: number;
  currency: string;
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontSize: '2xl',
        color: 'primary.300',
        fontWeight: 500,
      }}
    >
      <Currency valueCents={value} currency={currency} />
      <Text
        sx={{
          ml: 1,
          fontSize: 'sm',
          fontWeight: 300,
          textTransform: 'capitalize',
        }}
        intlId="module.manage.card.addon.perCertificate"
      />
    </Box>
  );
}

function ManageModulesInfoModal({
  text,
  title,
}: {
  text: string;
  title: string;
}) {
  const { openModal, closeModal } = useModal();
  return (
    <Text
      as="a"
      sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
      onClick={() =>
        openModal({
          modalType: 'componentModal',
          modalProps: {
            children: (
              <Box sx={{ p: 4, position: 'relative' }}>
                <Box
                  sx={{
                    position: 'absolute',
                    top: '12px',
                    right: '15px',
                    cursor: 'pointer',
                  }}
                  onClick={closeModal}
                >
                  <Icons.Close width={18} sx={{ fill: '#8686A1' }} />
                </Box>
                <Text
                  as="h4"
                  sx={{
                    pb: 2,
                    mb: 2,
                    borderWidth: '1px',
                    borderBottom: 'solid',
                    borderColor: 'grey.200',
                    color: 'secondary.400',
                    fontWeight: 'normal',
                    fontSize: 18,
                  }}
                >
                  {title}
                </Text>
                <Text as="p" sx={{ color: 'grey.900' }}>
                  {text}
                </Text>
              </Box>
            ),
          },
        })}
    >
      <Box sx={{ marginRight: '5px' }}>
        <Icons.Info fill="#67B1F5" width={16} />
      </Box>
      <Text
        as="span"
        sx={{
          textDecoration: 'underline',
          color: 'secondary.300',
          fontSize: '14px',
        }}
        intlId="module.manage.card.modal.buttonLabel"
      />
    </Text>
  );
}
