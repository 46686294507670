import React from 'react';
import * as Intl from 'lib/intl';
import { Box, Flex } from 'components/layout';
import { Text } from 'components/typography';
import { colors } from 'theming/foundations/colors';
import * as ThemeUi from 'theme-ui';
import { Currency } from '../../../../components/Currency';
import { StyleObject } from '../../../../theming';
import * as Icons from '../../../../components/icons';
import { useLocaleDateFormat } from '../../../../utils/hooks/use-locale-date-format';
import { BillingPageTitle } from './BillingPageTitle';

type TableRow = {
  billedDate: string;
  billedTotalCents: number;
  invoiceUrl?: string;
  currency: string;
  paidAt?: string;
};

type BillingHistoryTableProps = {
  rows: TableRow[];
};

export function BillingHistoryTable({ rows }: BillingHistoryTableProps) {
  const intl = Intl.useIntl();
  const { localeDateFormat } = useLocaleDateFormat();

  return (
    <Wrapper>
      <BillingPageTitle>
        {intl.formatMessage({
          id: 'payments.billing.billingHistory.title',
        })}
      </BillingPageTitle>

      <Table>
        <TableHead>
          <Row color="#000" sx={{ display: 'block' }}>
            <HeadColumn>
              {intl.formatMessage({
                id: 'payments.billing.billingHistory.date',
              })}
            </HeadColumn>
            <HeadColumn>
              {intl.formatMessage({
                id: 'payments.billing.billingHistory.sum',
              })}
            </HeadColumn>
            <HeadColumn>
              {intl.formatMessage({
                id: 'payments.billing.billingHistory.receipt',
              })}
            </HeadColumn>
          </Row>
        </TableHead>

        {rows.length === 0 ? (
          <Text
            as="p" sx={{ p: 3 }}
            variant="body2"
          >
            {intl.formatMessage({ id: 'payments.billing.noHistory' })}
          </Text>
        ) : (
          <Body>
            {rows.map((row) => (
              <Row key={row.invoiceUrl}>
                <Column>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {row.paidAt != null && (
                      <Box
                        sx={{
                          display: 'inline-flex',
                          position: 'relative',
                          left: -2,
                          top: '-1px',
                          zIndex: -1,
                        }}
                      >
                        <Icons.CheckCircleFilled
                          width={16}
                          fill="tertiary1.600"
                        />
                      </Box>
                    )}
                    <Text sx={{ flexShrink: 0 }}>
                      {localeDateFormat(row.billedDate)}
                    </Text>
                  </Box>
                </Column>
                <Column>
                  <Currency
                    maximumFractionDigits={2}
                    valueCents={row.billedTotalCents}
                    currency={row.currency}
                  />
                </Column>
                <Column>
                  <Invoice invoiceUrl={row.invoiceUrl} />
                </Column>
              </Row>
            ))}
          </Body>
        )}
      </Table>
    </Wrapper>
  );
}

function Wrapper({ children }: { children: React.ReactNode }) {
  return <Flex flexDirection="column">{children}</Flex>;
}

function Table({ children }: { children: React.ReactNode }) {
  return (
    <Flex
      flexDirection="column"
      sx={{
        color: 'darkgrey.800',
        fontWeight: 500,
        px: 3,
        backgroundImage: `
        radial-gradient(
        circle at 0 20%,
        rgba(255, 255, 255, 0) 12px,
        white 0, #fff, rgba(255, 255, 255, 0)),
        radial-gradient(
        circle at 100% 20%,
        rgba(255, 255, 255, 0) 12px,
        white 0, #fff, rgba(255, 255, 255, 0)
        )`,
        filter: 'drop-shadow(0 4px 5px #00000029)',
        '-webkit-filter': 'drop-shadow(0 4px 5px #00000029)',
        position: 'relative',
        '&:after': {
          content: '""',
          position: 'absolute',
          height: '6px',
          width: '100%',
          background:
            // eslint-disable-next-line max-len
            'radial-gradient(circle, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0) 50%, #FBFBFB 50%, #FBFBFB 100% ) 0px -2px / 15px 25px repeat-x',
          bottom: '-4px',
          left: 0,
        },
      }}
    >
      <Box
        as="table"
        sx={{
          borderCollapse: 'collapse',
          maxHeight: 400,
          overflow: 'auto',
          display: 'block',
          //for firefox
          scrollbarWidth: 'none',
          //for IE and Edge
          '-ms-overflow-style': 'none',
          //for safari and chrome
          '::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {children}
      </Box>
    </Flex>
  );
}

function TableHead({ children }: { children: React.ReactNode }) {
  return (
    <Box
      as="thead"
      sx={{
        display: 'block',
        position: 'sticky',
        top: 0,
        backgroundColor: 'white.50',
        color: '#000',
        fontWeight: 500,
        fontSize: 'lg',
      }}
    >
      {children}
    </Box>
  );
}

function Body({ children }: { children: React.ReactNode }) {
  return (
    <Box as="tbody" sx={{ fontSize: 'md' }}>
      {children}
    </Box>
  );
}

function Row({
  children,
  color = 'darkgrey.800',
  sx,
}: {
  children: React.ReactNode;
  color?: string;
  sx?: StyleObject;
}) {
  return (
    <Box
      as="tr"
      sx={{
        color,
        backgroundImage: `linear-gradient(to right, ${colors.grey[300]} 50%, white 50%)`,
        backgroundPosition: 'bottom',
        backgroundSize: '10px 1px',
        backgroundRepeat: 'repeat-x',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}

const COL_PADDING = 2;
function Column({
  children,
  sx,
}: {
  children?: React.ReactNode;
  sx?: StyleObject;
}) {
  return (
    <Box
      as="td"
      sx={{
        py: '13px',
        px: COL_PADDING,
        textAlign: 'left',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}

function HeadColumn({ children }: { children: React.ReactNode }) {
  return <Column sx={{ px: COL_PADDING + 3 }}>{children}</Column>;
}

function Invoice({ invoiceUrl }: Pick<TableRow, 'invoiceUrl'>) {
  const intl = Intl.useIntl();

  return (
    <ThemeUi.Link
      sx={{
        color: 'secondary.900',
        fontSize: 'md',
        textDecoration: 'underline',
        fontWeight: '500',

        ...(invoiceUrl == null && {
          color: 'disabled',
        }),
      }}
      href={invoiceUrl}
      download
    >
      {intl.formatMessage({
        id: 'payments.billing.billingHistory.receipt.invoice',
      })}
    </ThemeUi.Link>
  );
}
