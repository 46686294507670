import * as ReactQuery from 'react-query';
import { identify, makeRequest, Service } from '../../../utils/http';
import { InferQueryOptions } from '../../../utils/types';

async function getPaymentDiscountsApi() {
  return makeRequest(Service.getPaymentDiscounts(await identify()));
}

export const QUERY_KEY_PAYMENT_DISCOUNTS = ['paymentDiscounts'];

function useGetPaymentDiscounts(
  options?: InferQueryOptions<typeof getPaymentDiscountsApi>,
) {
  return ReactQuery.useQuery(
    QUERY_KEY_PAYMENT_DISCOUNTS,
    getPaymentDiscountsApi,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      // Refetch the query after 5 minutes
      staleTime: 5 * 60 * 100,
      ...options,
    },
  );
}

export { useGetPaymentDiscounts };
