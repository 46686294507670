import * as ReactQuery from 'react-query';
import { InferMutationOptions } from 'utils/types';
import { updateWorkspaceImageApi } from 'services/workspace/update-workspace-image/api';
import { ErrorType } from 'utils/http';
import { QUERY_KEY_WORKSPACE } from 'services/workspace/get-workspace';

export function useUpdateWorkspaceImage(
  options?: InferMutationOptions<typeof updateWorkspaceImageApi>,
) {
  const queryClient = ReactQuery.useQueryClient();

  const {
    mutate,
    isLoading,
    isError,
    error,
    isSuccess,
  } = ReactQuery.useMutation('updateWorkspaceImage', updateWorkspaceImageApi, {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries(QUERY_KEY_WORKSPACE);

      if (options?.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
  });
  return {
    updateWorkspaceImage: mutate,
    isLoading,
    isError,
    error: error as ErrorType,
    isSuccess,
  };
}
