import * as ReactQuery from 'react-query';
import { Auth } from 'aws-amplify';
import { InferQueryOptions } from 'utils/types';
import { QUERY_KEY_CURRENT_USER } from 'utils/auth/use-current-authenticated-user';
import { QUERY_KEY_WORKSPACE } from 'services/workspace/get-workspace';
import { QUERY_KEY_LOGGED_IN_USER } from 'services/users/get-logged-in-user/hook';

export function useRefreshSession(options?: InferQueryOptions<() => void>) {
  const queryClient = ReactQuery.useQueryClient();

  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    data,
    refetch: refreshSession,
    ...rest
  } = ReactQuery.useQuery(
    ['refreshSession'],
    async () => {
      const sessionUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();
      await sessionUser.refreshSession(
        currentSession.getRefreshToken(),
        async () => {
          await Promise.all([
            queryClient.invalidateQueries(QUERY_KEY_CURRENT_USER),
            queryClient.invalidateQueries(QUERY_KEY_WORKSPACE),
            queryClient.invalidateQueries(QUERY_KEY_LOGGED_IN_USER),
          ]);
        },
      );
    },
    {
      ...options,
      cacheTime: 0,
      enabled: false,
    },
  );

  return {
    refreshSession,
    ...rest,
  };
}
