import { Language } from '../../translations';

type Country = {
  alpha2: string;
};

const LANGUAGE_TO_COUNTRY: Record<Language, Country> = {
  en: { alpha2: 'gb' },
  no: { alpha2: 'no' },
  sv: { alpha2: 'se' },
};

function getCountryFromLanguage(language: Language): Country {
  return LANGUAGE_TO_COUNTRY[language];
}

export { getCountryFromLanguage };
