import * as React from 'react';
import { WidgetProps } from '@rjsf/core';
import * as ThemeUI from 'theme-ui';
import { Box, Flex } from 'components/layout';
import CheckmarkIcon from 'assets/images/Checkmark.svg';
import { Label } from 'components/form-elements/labels';
import { FormattedMessage } from 'lib/intl';
import { LocaleKey } from '../../../../translations';
import { Spacer } from '../../../Spacer/Spacer';

function Radio(props: any) {
  const { name, onChange } = useRadioGroup();

  return (
    <ThemeUI.Label sx={{ width: 'fit-content' }}>
      <Box>
        <ThemeUI.Input
          disabled={props.disabled}
          checked={props.checked}
          onChange={() => {
            if (props.onChange) {
              props.onChange(props.value);
            }

            if (onChange) {
              onChange(props.value);
            }
          }}
          type="radio"
          name={name}
          sx={{
            opacity: 0,
            position: 'absolute',
            zIndex: -1,
            width: 1,
            height: 1,
            overflow: 'hidden',
            minWidth: 0,
            boxSizing: 'border-box',
            
            '&:checked + div': {
              borderColor: 'primary.900',
              backgroundColor: 'secondary.100',
              display: 'flex',
              fontWeight: 600,
              color: 'primary.900',
            },

            '&:checked + div::before': {
              content: '""',
              display: 'inline-flex',
              backgroundImage: `url("${CheckmarkIcon}")`,
              height: 4,
              width: 4,
              mr: 2,
            },
          }}
        />

        <Box
          sx={{
            px: 5,
            py: 2,
            border: '2px solid #9E9EAF',
            borderRadius: '99px',
            backgroundColor: 'white.50',
            fontSize: '14px',
            textTransform: 'capitalize',
            cursor: props.disabled ? 'not-allowed' : 'pointer',
          }}
        >
          <FormattedMessage id={props.label} />
        </Box>
      </Box>
    </ThemeUI.Label>
  );
}

export const RadioWidget = ({
  id,
  schema,
  options,
  value,
  disabled,
  readonly,
  label,
  onChange,
  onBlur,
  onFocus,
  formContext,
  required,
  rawErrors = [],
}: WidgetProps) => {
  const { enumOptions, enumDisabled } = options;

  const _onChange = (value: string) => {
    // eslint-disable-next-line eqeqeq
    onChange(schema.type == 'boolean' ? value !== 'false' : value);
  };
  const _onBlur = ({ target: { value } }: React.FocusEvent<HTMLInputElement>) =>
    onBlur(id, value);
  const _onFocus = ({
    target: { value },
  }: React.FocusEvent<HTMLInputElement>) => onFocus(id, value);

  const row = options ? options.inline : false;

  const fieldName = id.replace(/root_/, '');
  const radioLabelTranslationKey = (label: string) =>
    [formContext.name, fieldName, label].join('.');

  return (
    <React.Fragment>
      <Label
        isError={rawErrors.length > 0}
        required={required}
        sx={{
          variant: 'forms.jsonInputLabel',
        }}
        htmlFor={id}
        labelIntlId={(label || schema.title) as LocaleKey}
      />

      <Spacer y={3} />

      <RadioGroup
        name={id}
        value={`${value}`}
        row={row as boolean}
        onChange={_onChange}
        onBlur={_onBlur}
        onFocus={_onFocus}
      >
        {(enumOptions as any).map((option: any, i: number) => {
          // eslint-disable-next-line eqeqeq
          const itemDisabled = enumDisabled && (enumDisabled as any).indexOf(option.value) != -1;

          return (
            <Radio
              checked={option.value === value}
              label={radioLabelTranslationKey(option.label)}
              value={option.value}
              key={i}
              disabled={disabled || itemDisabled || readonly}
            />
          );
        })}
      </RadioGroup>
    </React.Fragment>
  );
};

const RadioGroupContext = React.createContext<any>({});
function useRadioGroup() {
  return React.useContext(RadioGroupContext);
}

function RadioGroup(props: any) {
  const value = React.useMemo(
    () => ({
      name: props.name,
      onChange: props.onChange,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.name],
  );

  return (
    <RadioGroupContext.Provider value={value}>
      <Flex
        sx={{
          flexWrap: 'wrap',
          margin: -1,
          '& > *': {
            margin: (theme) => `${theme.space?.[1] ?? 4}px !important`,
          },
        }}
      >
        {props.children}
      </Flex>
    </RadioGroupContext.Provider>
  );
}
