import { signIn, SignInObjectType } from 'utils/auth/sign-in';
import * as Utils from 'utils';

export async function signInApi(signInObject: SignInObjectType) {
  const data = await signIn(signInObject);

  await Utils.Auth.redirectToWorkspaceDomain({
    amplifyStorage: localStorage,
    tenantId: data.signInUserSession.idToken.payload.tenant_id,
    currentUserUsername: data.attributes.sub,
    workspaceName: data.signInUserSession.idToken.payload.tenant_subdomain,
  });

  return data;
}
