import * as React from 'react';
import * as Rhf from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { Routing } from 'global/routing';
import { WorkspaceLayout } from 'components/workspace-layout';
import { Forms } from 'components/form-elements';
import PhoneImg from 'assets/images/phone.svg';
import WavesImg from 'assets/images/waves.svg';
import { AuthService } from 'services/auth';
import { Constants } from 'global/constants';
import { Text } from 'components/typography';
import { Container, Flex } from 'components/layout';
import { Notification } from 'components/Notification/Notification';
import { Image } from 'components/Image';
import { Link } from 'components/links';
import { ValidationSchemas } from '../../utils/validation';
import { FloatingLanguageSwitcher } from '../../features/language/components/FloatingLanguageSwitcher';

const schema = Joi.object({
  email: Joi.string().required().regex(Constants.EMAIL_REGEX).messages({
    'string.empty': 'login.form.input.email.error.required',
    'string.pattern.base': 'login.form.input.email.error.validEmail.message',
  }),

  password: ValidationSchemas.Register.PASSWORD,
});

export const Login = () => {
  const { isLoading, signIn, error, isSuccess } = AuthService.useSignIn({
    onError() {},
  });

  const formMethods = Rhf.useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: joiResolver(schema),
  });

  return (
    <Container
      variant="page"
      sx={{
        px: '0px',
        height: '100%',
        justifyContent: 'flex-start',
        overflowX: 'hidden',
      }}
    >
      <WorkspaceLayout sx={{ px: 4 }}>
        <WorkspaceLayout.Logo />
        <WorkspaceLayout.Headline intlId="login.headline" />
        <Forms.Provider
          sx={{ gap: 4 }}
          onValid={async (data) => {
            signIn({
              email: data.email,
              password: data.password,
            });
          }}
          name="login"
          {...formMethods}
        >
          <Forms.FieldEditText
            onMapChange={(value) => value.trim()}
            isAlwaysFloating
            labelIntlId="login.form.input.email.label"
            name="email"
            required
            autoFocus
          />

          <Forms.FieldEditText
            isAlwaysFloating
            labelIntlId="login.form.input.password.label"
            name="password"
            required
            type="password"
          />

          <WorkspaceLayout.Link
            to={Routing.FORGOT_PASSWORD.getPath()}
            intlId="login.form.link.password.label"
          />

          {error != null && (
            <Notification
              sx={{ mb: 2 }} severity="error"
              variant="alert"
            >
              <Notification.Description>
                <Text>{error.message}</Text>
              </Notification.Description>
            </Notification>
          )}

          <Forms.SubmitButton
            data-testid="login-button"
            disabled={isSuccess}
            isLoading={isLoading}
          >
            <Text intlId="login.form.button.label" variant="button.text" />
          </Forms.SubmitButton>

          <Text sx={{ fontSize: 'md' }}>
            <b>
              <Text as="span" intlId="login.newCustomer" />
            </b>
            <br />
            <Link
              to={Routing.REGISTER.getPath()}
              intlId="login.form.link.createOrganisation.label"
            />
          </Text>
        </Forms.Provider>
      </WorkspaceLayout>

      <Flex
        alignItems="flex-start"
        justifyContent="center"
        sx={{
          backgroundImage: `url('${WavesImg}')`,
          mt: 4,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'top',
          flexShrink: 1,
          flexGrow: 1,
          width: '130%',
        }}
      >
        <Image src={PhoneImg} />
      </Flex>

      <FloatingLanguageSwitcher />
    </Container>
  );
};
