import React from 'react';
import * as Intl from 'lib/intl';
import axios from 'axios';
import { DocumentStatusEnum } from 'trace-backend-sdk';
import { DocumentCertificatePreviewButton } from '../../../../pages/Document/components/DocumentCertificatePreviewButton';
import { Button } from '../../../../components/form-elements';
import { downloadFile } from '../../../../utils/functions/download-file';
import {
  DocumentsService,
  DocumentTemplateId,
} from '../../../../services/documents';
import { useDocumentParams } from '../../../../pages';
import { WorkspaceService } from '../../../../services/workspace';
import { Flex } from '../../../../components/layout';

// TODO: Translate these
const CERTIFICATE_NAME: Record<DocumentTemplateId, string> = {
  'pressure-test': 'Provtryck',
  'quick-capture': 'Quick Capture',
  relining: 'Relining',
};

export function DocumentCertificateAccess() {
  const params = useDocumentParams();
  const intl = Intl.useIntl();

  const { workspaceData } = WorkspaceService.useGetWorkspace();
  const { document } = DocumentsService.useGetDocument(
    params.documentId,
    params.templateId,
  );
  const isCompleted = document?.status === DocumentStatusEnum.Completed;

  const { isLoading: isCertificateLoading, documentCertificateUrl } = DocumentsService.useGetDocumentCertificate(
    params.documentId,
    params.templateId,
    {
      enabled: isCompleted,
    },
  );

  return (
    <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
      <DocumentCertificatePreviewButton
        isDocumentCompleted={isCompleted}
        certificateUrl={documentCertificateUrl}
      />

      <Button
        disabled={isCertificateLoading}
        onClick={async (event) => {
          if (!documentCertificateUrl) {
            return;
          }

          event.preventDefault();

          const { data: pdfBlob } = await axios.get(documentCertificateUrl, {
            responseType: 'blob',
          });
          downloadFile(
            pdfBlob,
            intl.formatMessage(
              {
                id: 'document.certificateName',
              },
              {
                workspaceName: workspaceData?.name,
                documentId: params.documentId,
                templateName: CERTIFICATE_NAME[params.templateId],
              },
            ),
          );
        }}
        intlId="viewDocument.recipients.button.download.label"
        sx={{ maxWidth: 400, mt: 3 }}
      />
    </Flex>
  );
}
