import { Box } from 'theme-ui';

type Props = {
  x?: number | string;
  y?: number;
};

export function Spacer({ y = 0, x = 0 }: Props) {
  return <Box sx={{ display: 'inline-block', minHeight: y, minWidth: x }} />;
}
