import { identify, makeRequest, Service } from 'utils/http';
import { DocumentRequestBase } from 'services/documents/types';

type Data = DocumentRequestBase;

export async function getDocumentCertificateApi(data: Data) {
  return makeRequest(
    Service.getDocumentCertificate(
      data.documentId,
      data.templateId,
      await identify(),
    ),
  );
}
