export type DocumentIdType = 'PT';

export type DocumentRequestBase = {
  templateId: DocumentTemplateId;
  documentId: string;
};

export const TEMPLATE_IDS = {
  PRESSURE_TEST: 'pressure-test',
  QUICK_CAPTURE: 'quick-capture',
  RELINING: 'relining',
} as const;

type TemplateIdExtractor<T> = {
  [K in keyof T]: T[K];
}[keyof T];

export type DocumentTemplateId = TemplateIdExtractor<typeof TEMPLATE_IDS>;
