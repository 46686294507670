import * as React from 'react';

import { Field, SelectProps } from 'components/form-elements';

import * as Icons from 'components/icons';
import * as Rhf from 'react-hook-form';
import { IconButton } from 'components/form-elements/buttons/IconButton';
import { LocaleKey } from 'translations';
import * as ThemeUI from 'theme-ui';
import { useIntl } from 'lib/intl';
import { Colors } from '../../../../theming/foundations/colors';

interface DropdownSelectProps extends SelectProps {
  labelIntlId?: LocaleKey;
  name: string;
  backgroundColor?: Colors;
  helperText?: React.ReactNode;
}

export const SelectField: React.FC<DropdownSelectProps> = ({
  children,
  id,
  placeholder,
  labelIntlId,
  name,
  required,
  backgroundColor,
  disabled,
  autoFocus,
  helperText,
}) => {
  const intl = useIntl();
  const formMethods = Rhf.useFormContext();

  const {
    field: { value, ref, onChange, onBlur, name: fieldName },
    fieldState: { error },
  } = Rhf.useController({
    control: formMethods.control,
    name,
  });

  return (
    <Select
      helperText={helperText}
      value={value}
      required={required}
      placeholder={placeholder}
      id={id}
      label={
        labelIntlId
          ? intl.formatMessage({
            id: labelIntlId,
          })
          : ''
      }
      isError={Boolean(error?.message)}
      onBlur={onBlur}
      onChange={onChange}
      name={fieldName}
      ref={ref}
      backgroundColor={backgroundColor}
      disabled={disabled}
      autoFocus={autoFocus}
    >
      {children}
    </Select>
  );
};

export const Select = React.forwardRef<
  HTMLSelectElement,
  {
    label: string;
    isError: boolean;
    onBlur: React.FocusEventHandler;
    onChange: React.ChangeEventHandler;
    onFocus?: React.FocusEventHandler;
    name: string;
    placeholder?: string;
    required?: boolean;
    autoComplete?: string;
    isLabelAlwaysLifted?: boolean;
    id?: string;
    disabled?: boolean;
    children?: React.ReactNode | React.ReactNodeArray;
    value: string;
    backgroundColor?: Colors;
    autoFocus?: SelectProps['autoFocus'];
    helperText?: DropdownSelectProps['helperText'];
  }
>(
  (
    {
      isLabelAlwaysLifted = false,
      required = false,
      label,
      isError,
      value,
      disabled,
      autoComplete,
      onBlur,
      onChange,
      name,
      placeholder,
      children,
      onFocus,
      backgroundColor,
      autoFocus,
      helperText,
    },
    ref,
  ) => {
    const [isActive, setIsActive] = React.useState(false);

    const isLabelLifted = label && (isLabelAlwaysLifted || isActive || value);
    const isPlaceholderVisible = !label || isLabelLifted;

    return (
      <Field
        backgroundColor={backgroundColor}
        name={name}
        isThemeUiSelect
        label={label}
        isError={isError}
        isLabelAlwaysLifted={isLabelAlwaysLifted}
        hasValue={Boolean(value)}
        required={required}
        isActive={isActive}
        helperText={helperText}
      >
        <ThemeUI.Select
          autoFocus={autoFocus}
          ref={ref}
          arrow={(
            <IconButton
              sx={{
                position: 'absolute',
                right: '14px',
                alignSelf: 'center',
                pointerEvents: 'none',
                fill: 'none',
                color: 'rgba(0, 0, 0, 0.54)',
              }}
              width="23px"
            >
              <Icons.ChevronDownFilled />
            </IconButton>
          )}
          value={value}
          disabled={disabled}
          name={name}
          id={name}
          autoComplete={autoComplete}
          placeholder={isPlaceholderVisible ? placeholder : ''}
          onFocus={(event) => {
            if (onFocus) {
              onFocus(event);
            }
            setIsActive(true);
          }}
          onBlur={(event) => {
            onBlur(event);
            setIsActive(false);
          }}
          onChange={onChange}
          sx={{
            border: 'none',
            width: '100%',
            height: '1.1876em',
            padding: '18.5px 14px',
            display: 'block',
            minWidth: 0,
            background: 'none',
            boxSizing: 'content-box',
            letterSpacing: 'inherit',
            outline: 'none',
          }}
        >
          {children}
        </ThemeUI.Select>
      </Field>
    );
  },
);
