import * as React from 'react';
import { useHistory } from 'react-router-dom';

export function useRedirect(path: string, shouldRedirect = false) {
  const history = useHistory();

  React.useEffect(() => {
    if (shouldRedirect) {
      history.push(path);
    }
  }, [history, path, shouldRedirect]);

  const onRedirect = React.useCallback(
    (redirectPath: string = path) => history.push(redirectPath),
    [path, history],
  );

  return {
    onRedirect,
  };
}
