import * as Utils from 'utils';
import React from 'react';
import { WorkspaceStatusEnum } from 'trace-backend-sdk';
import { WorkspaceService } from '../../services/workspace';

type AuthRule<T> = {
  action: 'allow' | 'deny';
  resource: T;
};

type WorkspaceRole = 'admin' | 'user';
type AuthorizationProps = {
  children: React.ReactNode;
  roles?: WorkspaceRole[];
  /*
   * Render the children for these statuses.
   * */
  workspaceStatuses?: AuthRule<WorkspaceStatusEnum>[];
  renderOnAuthFail?: React.ReactNode;
};

export function Authorization({
  children,
  roles,
  renderOnAuthFail,
  workspaceStatuses,
}: AuthorizationProps) {
  const { currentUser } = Utils.Auth.useCurrentAuthenticatedUser();
  const { workspaceData } = WorkspaceService.useGetWorkspace();

  const isAllowedWorkspace = workspaceStatuses
    ? resolveRules(workspaceStatuses, workspaceData?.status)
    : true;

  const isAllowedUser = roles
    ? currentUser
      && roles.includes(
        currentUser.signInUserSession.idToken.payload.role as WorkspaceRole,
      )
    : true;

  if (isAllowedUser && isAllowedWorkspace) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  return React.isValidElement(renderOnAuthFail) ? (
    <React.Fragment>{renderOnAuthFail}</React.Fragment>
  ) : null;
}

function resolveRules<T>(rules: AuthRule<T>[], matchAgainst: T) {
  const allowRulesPass = rules
    .filter((rule) => rule.action === 'allow')
    .every((rule) => rule.resource === matchAgainst);
  const denyRulesPass = rules
    .filter((rule) => rule.action === 'deny')
    .every((rule) => rule.resource !== matchAgainst);

  return allowRulesPass && denyRulesPass;
}
