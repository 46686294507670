import * as ReactQuery from 'react-query';
import React from 'react';
import { NotificationMessage } from 'services/notifications/types';
import { QUERY_KEY_NOTIFICATIONS } from 'services/notifications/get-all-notifications/hook';
import { useWebsocketsContext } from 'components/WebsocketsProvider/WebsocketsProvider';

export function useNotificationMessagesWebsocket() {
  const queryClient = ReactQuery.useQueryClient();
  const { webSocket } = useWebsocketsContext();

  React.useEffect(() => {
    if (!webSocket) {
      return () => {};
    }

    function addNotificationToQueryCache(ev: MessageEvent) {
      const notification = JSON.parse(ev.data) as NotificationMessage;

      queryClient.setQueryData(
        QUERY_KEY_NOTIFICATIONS,
        ({ pages, pageParams }: any) => ({
          pages: [
            {
              ...pages[0],
              notifications: [notification, ...pages[0].notifications],
            },
            ...pages.slice(1),
          ],
          pageParams,
        }),
      );
    }

    webSocket.addEventListener('message', addNotificationToQueryCache);

    return () => {
      webSocket.removeEventListener('message', addNotificationToQueryCache);
    };
  }, [queryClient, webSocket]);
}
