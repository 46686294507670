import { FieldProps } from '@rjsf/core';
import { useModal } from 'store/modals';
import { Box } from 'components/layout';
import { Button, Divider } from 'theme-ui';
import * as Icons from 'components/icons';
import * as Intl from 'lib/intl';
import { breakpoints } from 'utils/breakpoints';
import { QuickTraceWidgetIconWrapper } from 'components/quick-trace/components/QuickTraceWidget';
import { Text } from 'components/typography';
import React from 'react';
import { DocumentRequestBase, DocumentsService } from 'services/documents';
import { DocumentStatusEnum, DocumentWidgetEnum } from 'trace-backend-sdk';
import { useQueryClient } from 'react-query';
import { QUERY_KEY_DOCUMENT } from 'services/documents/get-document';
import { useLocaleDateFormat } from 'utils/hooks/use-locale-date-format';
import {
  DocumentTitleContent,
  DocumentTitleDate,
  DocumentTitleIconWrapper,
  DocumentTitleTemplateName,
  DocumentTitleWrapper,
} from 'components/DocumentTitleWrapper/DocumentTitleWrapper';
import { DocumentCertificateAccess } from 'features/document/components/DocumentCertificateAccess/DocumentCertificateAccess';
import { DeleteDocument } from 'features/document/components/DeleteDocument/DeleteDocument';
import { useDocumentParams } from 'pages';
import { DocumentSectionWrapper } from 'features/document/components/DocumentSectionWrapper/DocumentSectionWrapper';
import { Spacer } from '../../Spacer/Spacer';

export function QuickTracePrimaryWrapperField(props: FieldProps) {
  const params = useDocumentParams();
  const intl = Intl.useIntl();
  const { openModal } = useModal();
  const { localeDateFormat } = useLocaleDateFormat();
  const { document } = DocumentsService.useGetDocument(
    params.documentId,
    params.templateId,
  );

  const { ObjectField } = props.registry.fields;

  if (!document) {
    return null;
  }

  const isCompleted = document.status === DocumentStatusEnum.Completed;

  return (
    <React.Fragment>
      <DocumentSectionWrapper>
        <DocumentTitleWrapper>
          <DocumentTitleIconWrapper>
            <Icons.QuickCaptureSplash />
          </DocumentTitleIconWrapper>

          <DocumentTitleContent>
            <DocumentTitleDate
              isDocumentCompleted={
                document.status === DocumentStatusEnum.Completed
              }
            >
              {localeDateFormat(document.dateCreated)}
            </DocumentTitleDate>

            <DocumentTitleTemplateName>
              {intl.formatMessage({
                id: 'quickCapture.templateName',
              })}
            </DocumentTitleTemplateName>
          </DocumentTitleContent>

          <Box
            sx={{
              display: 'inline-flex',
              ml: 'auto',
              alignSelf: 'baseline',
            }}
          >
            <DeleteDocument
              templateId={params.templateId}
              documentId={params.documentId}
            />
          </Box>
        </DocumentTitleWrapper>

        {isCompleted && (
          <React.Fragment>
            <DocumentCertificateAccess />
            <Spacer y={8} />
          </React.Fragment>
        )}

        <ObjectField {...props}>{props.children}</ObjectField>

        {!isCompleted && !props.disabled && (
          <Box
            sx={{
              position: 'absolute',
              bottom: -35,
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          >
            <Button
              disabled={props.disabled}
              onClick={() => {
                openModal({
                  modalType: 'bottomMenuModal',
                  modalProps: {
                    children: (
                      <AddNewWidgetMenu
                        templateId={props.formContext.templateId}
                        documentId={props.formContext.documentId}
                      />
                    ),
                  },
                });
              }}
              type="button"
              variant="text"
              sx={{
                p: 0,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Box
                sx={{
                  p: 2,
                  backgroundColor: 'tertiary1.300',
                  color: 'white.50',
                  borderRadius: 'rounded',
                }}
              >
                <Icons.Add />
              </Box>

              <Text
                sx={{
                  color: '#9E9EAF',
                  fontSize: 'sm',
                  textTransform: 'capitalize',
                }}
              >
                {intl.formatMessage({
                  id: 'quickCapture.addSection',
                })}
              </Text>
            </Button>
          </Box>
        )}
      </DocumentSectionWrapper>
      <Spacer y={12} />
    </React.Fragment>
  );
}

function AddNewWidgetMenu({
  templateId,
  documentId,
}: Pick<DocumentRequestBase, 'documentId' | 'templateId'>) {
  const { closeModal } = useModal();
  const intl = Intl.useIntl();

  const queryClient = useQueryClient();
  const { addDocumentWidget } = DocumentsService.useAddDocumentWidget({
    onSuccess: async (data, variables) => {
      queryClient.setQueryData(
        QUERY_KEY_DOCUMENT(variables.documentId, variables.templateId),
        data,
      );
      closeModal();
    },
  });

  function onAddWidget(widget: DocumentWidgetEnum) {
    addDocumentWidget({
      documentId,
      templateId,
      widget,
    });
  }

  return (
    <Box sx={{ px: 5, width: '100%' }}>
      <Icons.ExpandLess sx={{ mx: 'auto', mt: -4 }} fill="#2C3E50" />

      <Divider sx={{ borderColor: 'bg.50' }} />

      <Box
        sx={{
          width: 'fit-content',
          mx: breakpoints({
            _: '0',
            md: 'auto',
          }),
        }}
      >
        <Button
          onClick={() => {
            onAddWidget(DocumentWidgetEnum.Gps);
          }}
          sx={{
            width: '100%',
          }}
          variant="quickTrace.createWidget"
        >
          <QuickTraceWidgetIconWrapper color="secondary.300" size={40}>
            <Icons.Gps color="white.50" />
          </QuickTraceWidgetIconWrapper>
          <Text sx={{ ml: 6 }}>
            {intl.formatMessage({
              id: 'quickCapture.gps.title',
            })}
          </Text>
        </Button>

        <Button
          onClick={() => onAddWidget(DocumentWidgetEnum.File)}
          sx={{
            width: '100%',
          }}
          variant="quickTrace.createWidget"
        >
          <QuickTraceWidgetIconWrapper color="tertiary1.200" size={40}>
            <Icons.FileUpload fill="white.50" />
          </QuickTraceWidgetIconWrapper>
          <Text sx={{ ml: 6 }}>
            {intl.formatMessage({
              id: 'quickCapture.file.title',
            })}
          </Text>
        </Button>

        <Button
          onClick={() => onAddWidget(DocumentWidgetEnum.Image)}
          sx={{
            width: '100%',
          }}
          variant="quickTrace.createWidget"
        >
          <QuickTraceWidgetIconWrapper color="tertiary2.700" size={40}>
            <Icons.ImageCapture fill="white.50" />
          </QuickTraceWidgetIconWrapper>
          <Text sx={{ ml: 6 }}>
            {intl.formatMessage({
              id: 'quickCapture.image.title',
            })}
          </Text>
        </Button>
      </Box>
    </Box>
  );
}
