import React from 'react';
import * as ThemeUI from 'theme-ui';
import * as _ from 'lodash-es';
import * as ReactIntl from 'lib/intl';

import { Colors, colors } from 'theming/foundations/colors';

import { Label } from 'components/form-elements/labels/Label';
import { Flex } from 'components/layout';
import { ShouldRender } from 'components/ShouldRender';
import { LocaleKey } from 'translations';

type LabelledInputProps = ThemeUI.InputProps &
  ThemeUI.SelectProps & {
    error?: boolean;
    errorMessage?: string;
    labelIntlId?: LocaleKey;
    backgroundColor?: Colors;
    isActive?: boolean;
    isFocused?: boolean;
    isLabelFloated?: boolean;
  };

export const LabelledFileInput: React.FC<LabelledInputProps> = ({
  id,
  backgroundColor = 'white.50',
  labelIntlId,
  sx,
  error,
  errorMessage,
  isActive,
  isFocused,
  isLabelFloated = true,
  children,
  ...props
}) => {
  const isError = error || !!errorMessage;

  function getBorderColor() {
    if (isError) {
      return 'error';
    }
    if (props.disabled) {
      return 'grey.300';
    }
    if (isFocused && isActive) {
      // label can be active without focus (if input is not empty)
      return 'secondary.500';
    }
    return 'grey.300';
  }

  function getLabelColor() {
    if (isLabelFloated) {
      return 'grey.300';
    }
    if (!isLabelFloated) {
      return 'darkgrey.800';
    }
    return 'grey.300';
  }

  function getLabelFontSize() {
    if (isLabelFloated) {
      return 'lg';
    }
    if (!isLabelFloated) {
      return 'sm';
    }
    return 'lg';
  }

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        gap: 0,
        ...sx,
      }}
    >
      <ThemeUI.Box
        className={isActive && isLabelFloated ? 'label-active' : ''}
        sx={{
          boxSizing: 'border-box',
          mt: 2,
          opacity: props.disabled ? '0.5' : 1,
          p: 0,
          position: 'relative',
          width: '100%',

          '&.label-active': {
            '& label': {
              backgroundImage: `linear-gradient(to top, ${_.get(
                colors,
                backgroundColor,
              )} 50%, transparent 50%)`,
              color: getBorderColor(),
              left: isActive && isLabelFloated ? 4 : '0px',
              px: 1,
              top: '6px',
              transform: 'translateY(-50%) scale(0.9)',
            },
          },
          '& *:not(div)': {
            borderColor: getBorderColor(),
            '&:focus': {
              borderWidth: '2px',
            },
          },
        }}
      >
        <Label
          htmlFor={id}
          required={props.required} // we don't wont to show "*" without label
          labelIntlId={labelIntlId}
          sx={{
            backgroundImage: 'none',
            color: isError ? 'error' : getLabelColor(),
            left: isLabelFloated && isActive ? 4 : '0px',
            lineHeight: 1,
            pointerEvents: 'none',
            position: isLabelFloated ? 'absolute' : 'relative',
            px: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            transition: 'all 100ms ease',
            width: 'auto',
            zIndex: 1,
            fontSize: getLabelFontSize(),
          }}
        />
        {children}
      </ThemeUI.Box>

      <ShouldRender when={Boolean(errorMessage)}>
        <ThemeUI.Text // TODO: create a separate component
          sx={{
            mt: 3,
            fontSize: 'sm',
            minHeight: '1.25em', // should match line-height value
            lineHeight: 2,
            color: getBorderColor(),
          }}
        >
          <ReactIntl.FormattedMessage id={errorMessage} />
        </ThemeUI.Text>
      </ShouldRender>
    </Flex>
  );
};
