import React from 'react';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import * as Constants from 'global/constants';
import * as Icons from 'components/icons';
import { useWindowWidth } from 'utils/hooks/use-window-width';
import { Text } from 'components/typography';
import { Document, Page, pdfjs } from 'react-pdf';
import { Box, Flex } from 'components/layout';
import { PageLoadingIndicator } from 'components/PageLoadingIndicator/PageLoadingIndicator';
import { IconButton } from 'components/form-elements/buttons/IconButton';

type Props = {
  onClose: () => void;
  pdfLink: string;
};

const ZOOM = {
  MAX: 1.6,
  MIN: 1.0,
};

export function PdfViewer(props: Props) {
  const [scale, setScale] = React.useState(ZOOM.MIN);
  const [numPages, setNumPages] = React.useState<number | null>(null);
  const [hasFirstPageLoaded, setHasFirstPageLoaded] = React.useState(false);
  const width = useWindowWidth();

  React.useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
  }, []);

  const allPages = Array(numPages)
    .fill(null)
    .map((_, index) => index);
  const [firstPage, restPages] = [[allPages[0]], allPages.slice(1)];

  const normalizedWidth = Math.min(Constants.DEVICE_WIDTHS.TABLET, width);

  return (
    <Box sx={{ width: 'fit-content', height: '100%' }}>
      <Flex
        alignItems="center"
        justifyContent="flex-start"
        gap={6}
        sx={{
          backgroundColor: 'primary.900',
          py: 3,
          px: 5,
          color: 'white.50',
          fontSize: '2xl',
          cursor: 'pointer',
          flexShrink: 0,
          borderBottom: '1px solid #fff',
        }}
      >
        <IconButton
          onClick={() => {
            if (props.onClose) {
              props.onClose();
            }
          }}
          sx={{ fontSize: '3xl' }}
        >
          <Icons.Back />
        </IconButton>

        <Text
          intlId="generic.button.goBack"
          sx={{ fontWeight: 300, fontSize: ['lg', '2xl'] }}
        />

        <Box
          sx={{
            display: 'flex',
            ml: 'auto',
          }}
        >
          <IconButton
            disabled={scale <= ZOOM.MIN}
            onClick={() => setScale((s) => s - 0.1)}
          >
            <Icons.Remove />
          </IconButton>

          <Box
            sx={{
              mx: 1,
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 'rounded',
              backgroundColor: 'primary.400',
              minWidth: 80,
            }}
          >
            <Text sx={{ fontSize: 'md' }}>{Math.round(scale * 100)}%</Text>
          </Box>

          <IconButton
            disabled={scale >= ZOOM.MAX}
            onClick={() => setScale((s) => s + 0.1)}
          >
            <Icons.Add />
          </IconButton>
        </Box>
      </Flex>

      <Box
        onClick={(e) => {
          e.preventDefault();
          if ('tagName' in e.target && 'href' in e.target) {
            // @ts-expect-error Already checked for tagname
            if (e.target.tagName.toLowerCase() === 'a') {
              // @ts-expect-error Already checked for href
              window.open(e.target.href, '_blank');
            }
          }
        }}
        sx={{ width: normalizedWidth, height: '100%', overflow: 'auto' }}
      >
        <Document
          loading={<PageLoadingIndicator />}
          onLoadSuccess={({ numPages }) => setNumPages(numPages)}
          file={props.pdfLink}
        >
          {firstPage.map((pageIndex) => (
            <Page
              scale={scale}
              loading={<React.Fragment />}
              onLoadSuccess={() => {
                setHasFirstPageLoaded(true);
              }}
              width={normalizedWidth}
              pageNumber={pageIndex + 1}
            />
          ))}

          {/* To gain a bit on performance, we wait for the first page to load and then load the rest */}
          {hasFirstPageLoaded
            && restPages.map((pageIndex) => (
              <Page
                scale={scale}
                loading={<React.Fragment />}
                width={normalizedWidth}
                pageNumber={pageIndex + 1}
              />
            ))}
        </Document>
      </Box>
    </Box>
  );
}
