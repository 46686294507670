import { FieldProps } from '@rjsf/core';
import * as Icons from 'components/icons';
import * as Intl from 'lib/intl';
import * as _ from 'lodash-es';
import { useModal } from 'store/modals';
import {
  BaseWidgetData,
  QuickTraceWidgetBodyWrapper,
  QuickTraceWidgetCaptionInput,
  QuickTraceWidgetIconWrapper,
  QuickTraceWidgetTimestamp,
  QuickTraceWidgetTitle,
  QuickTraceWidgetWrapper,
} from 'components/quick-trace/components/QuickTraceWidget';
import { Flex } from 'components/layout';
import React from 'react';
import { Image } from 'theme-ui';
import { Button } from 'components/form-elements';
import { DocumentsService } from 'services/documents';
import { DocumentWidgetEnum } from 'trace-backend-sdk';
import { getBlobFromBase64 } from 'utils/base64/get-blob-from-base64';
import { getOutOfContextFieldName } from '../../form-elements/JsonForms/utils/get-out-of-context-field-name';
import { QuickTraceWidgetMenu } from './QuickTraceWidgetMenu';

type ImageCaptureData = BaseWidgetData & { url: string };

export function QuickTraceImageField(props: FieldProps<ImageCaptureData>) {
  const intl = Intl.useIntl();

  const hasData = !_.isEmpty(props.formData);
  const hasError = !_.isEmpty(props.errorSchema);

  const { openModal, closeModal } = useModal();

  const { uploadDocumentWidget, isLoading: isUploading } = DocumentsService.useUploadDocumentWidget({
    onSuccess: (data) => {
      if ('url' in data) {
        onPreserveChange({
          url: data.url,
          timestamp: data.timestamp,
        });
      }
    },
  });

  function onPreserveChange(data: Partial<ImageCaptureData>) {
    props.onChange({
      ...props.formData,
      ...data,
    });
  }

  return (
    <QuickTraceWidgetWrapper hasError={hasError}>
      <Flex sx={{ mb: 6, alignItems: 'center' }}>
        <QuickTraceWidgetIconWrapper color="tertiary2.700">
          <Icons.ImageCapture />
        </QuickTraceWidgetIconWrapper>

        <QuickTraceWidgetBodyWrapper>
          <QuickTraceWidgetTitle>
            {intl.formatMessage({
              id: 'quickCapture.image.title',
            })}
          </QuickTraceWidgetTitle>
          <QuickTraceWidgetTimestamp
            placeholder={intl.formatMessage({
              id: 'quickCapture.image.subTitle',
            })}
            timestamp={props.formData.timestamp}
          />
        </QuickTraceWidgetBodyWrapper>

        <QuickTraceWidgetMenu
          hasWidgetData={hasData}
          templateId={props.formContext.templateId}
          documentId={props.formContext.documentId}
          widgetKey={getOutOfContextFieldName(props.name)}
        />
      </Flex>

      {props.formData.url && (
        <React.Fragment>
          <Image
            src={`${props.formData.url}?time=${performance.now()}`}
            sx={{ width: '100%', mb: 4, borderRadius: 1 }}
          />

          <QuickTraceWidgetCaptionInput
            disabled={props.disabled}
            value={props.formData.caption}
            onChange={(e) => onPreserveChange({ caption: e.target.value })}
          />
        </React.Fragment>
      )}

      {!hasData && (
        <Button
          disabled={props.disabled}
          isLoading={isUploading}
          sx={{
            backgroundColor: 'tertiary2.900',
          }}
          variant="secondary"
          onClick={() => {
            openModal({
              modalType: 'cameraModal',
              modalProps: {
                onTakePhotoAnimationDone: async (imageData) => {
                  closeModal();
                  uploadDocumentWidget({
                    documentId: props.formContext.documentId,
                    templateId: props.formContext.templateId,
                    widgetType: DocumentWidgetEnum.Image,
                    widgetKey: getOutOfContextFieldName(props.name),
                    widgetData: {
                      type: 'image',
                      image: await getBlobFromBase64(imageData),
                      caption: props.formData.caption,
                    },
                  });
                },
              },
            });
          }}
        >
          {intl.formatMessage({
            id: 'quickCapture.image.action',
          })}
        </Button>
      )}
    </QuickTraceWidgetWrapper>
  );
}
