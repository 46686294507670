import * as ReactQuery from 'react-query';
import { identify, makeRequest, Service } from '../../../utils/http';
import { InferMutationOptions } from '../../../utils/types';
import { useInvalidateWorkspaceOnTimeout } from '../utils';
import { QUERY_KEY_SUBSCRIPTION } from './get-subscription';
import { QUERY_KEY_UPCOMING_INVOICE } from './get-upcoming-invoice';

async function reactivateSubscriptionApi() {
  return makeRequest(Service.reactivateSubscription(await identify()));
}

function useReactivateSubscription(
  options?: InferMutationOptions<typeof reactivateSubscriptionApi>,
) {
  const { invalidateWorkspaceOnTimeout } = useInvalidateWorkspaceOnTimeout();
  const queryClient = ReactQuery.useQueryClient();

  return ReactQuery.useMutation(
    'reactivateSubscription',
    reactivateSubscriptionApi,
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await Promise.all([
          invalidateWorkspaceOnTimeout(),
          queryClient.invalidateQueries(QUERY_KEY_SUBSCRIPTION),
          queryClient.invalidateQueries(QUERY_KEY_UPCOMING_INVOICE),
          options?.onSuccess?.(data, variables, context),
        ]);
      },
    },
  );
}

export { useReactivateSubscription };
