import * as ReactRouterDom from 'react-router-dom';
import React from 'react';

/*
 * Builds a safe search query which doesn't overwrite the existing
 * query parameters. You need to provide an object with key:value
 * pairs for the new values of the search query.
 *
 * Existing query parameters will be updated with values provided
 * in the object if they have the same key.
 *
 * You can provide undefined as a value to delete a search param.
 * */
export function useBuildSafeSearchQuery() {
  const location = ReactRouterDom.useLocation();

  const buildSafeSearchQuery = React.useCallback(
    (searchQuery: Record<string, string | undefined>) => {
      const params = new URLSearchParams(location.search);

      for (const [key, value] of Object.entries(searchQuery)) {
        if (value) {
          params.set(key, value);
        } else {
          params.delete(key);
        }
      }

      return params.toString();
    },
    [location.search],
  );

  return { buildSafeSearchQuery };
}
