import * as React from 'react';
import { NavLink, LinkProps } from 'components/links';

export const Tab: React.FC<LinkProps & { disabled?: boolean }> = ({
  disabled = false,
  ...props
}) => (
  <NavLink
    sx={{
      px: 4,
      paddingTop: '15px',
      paddingBottom: '11px',
      flexGrow: 1,
      textAlign: 'center',
      borderBottom: '3px solid',
      borderBottomColor: 'transparent',
      opacity: '0.6',
      color: 'primary.900',
      fontSize: 'md',

      '&.active': {
        borderBottomColor: 'secondary.900',
      },

      '&.active, &:hover': {
        opacity: 1,
      },

      ...(disabled && {
        pointerEvents: 'none',
        opacity: 0.2,
      }),
    }}
    replace
    {...props}
  />
);
