import { Box } from 'components/layout';
import React from 'react';
import { StyleObject } from '../../theming';

export function DoubleBorderWrapper({
  children,
  sx,
}: {
  children: React.ReactNode;
  sx?: StyleObject;
}) {
  return (
    <Box
      sx={{
        ...sx,

        borderBottom: '1px solid #DFDFDF',
        position: 'relative',

        '&::before': {
          content: '""',
          position: 'absolute',
          height: '1px',
          backgroundColor: 'white.50',
          bottom: 0,
          left: 0,
          right: 0,
          width: '100%',
        },
      }}
    >
      {children}
    </Box>
  );
}
