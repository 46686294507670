import * as ReactQuery from 'react-query';
import { identify, makeRequest, Service } from '../../utils/http';
import { InferMutationOptions } from '../../utils/types';

type Data = Parameters<typeof Service.removeExternalUsers>[0];

async function removeExternalUsersApi(data: Data) {
  return makeRequest(Service.removeExternalUsers(data, await identify()));
}

export function useRemoveExternalUsers(
  options?: InferMutationOptions<typeof removeExternalUsersApi>,
) {
  return ReactQuery.useMutation(removeExternalUsersApi, options);
}
