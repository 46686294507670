import { Environment } from 'global/environment';

type Data = {
  latitude: string;
  longitude: string;
};

export function getLocationMapLink(data: Data) {
  return `${Environment.BACKEND_API}/locations/maps?latitude=${data.latitude}&longitude=${data.longitude}`;
}
