import * as React from 'react';

import { GenericFn } from 'utils/types';
import { useGetLatest } from './use-get-latest';

export function useAsyncDebounce<TFn extends GenericFn>(fn: TFn, timeout = 0) {
  const getFn = useGetLatest(fn);

  return React.useMemo(() => {
    let timeoutId: any = null;

    // @ts-expect-error It returns correctly, can't be bothered to type it right now
    const debounce: TFn = (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = null;
      }

      timeoutId = setTimeout(getFn(), timeout, ...args);
    };

    return debounce;
  }, [getFn, timeout]);
}
