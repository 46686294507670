import * as React from 'react';
import { Box, Flex } from 'components/layout';
import { Text } from 'components/typography';
import { intervalToDuration } from 'date-fns';
import { StyleObject } from 'theming';
import { Label } from 'components/form-elements';
import { LocaleKey } from 'translations';
import { Spacer } from '../Spacer/Spacer';

type Props = {
  startTime: string | Date;
  endTime?: string | Date;
  variant?: 'running' | 'done';
  labelIntlId?: LocaleKey;
  wrapperSx?: StyleObject;
  variantSx?: StyleObject;
  isIdle?: boolean;
};

export type TimerState = {
  hours: string;
  minutes: string;
  seconds: string;
};

export function Timer({ variant = 'running', ...props }: Props) {
  const [timerValues, setTimerValues] = React.useState<TimerState>(() => {
    if (props.isIdle) {
      return getTimerFormattedDuration(new Date(), new Date());
    }

    if (props.endTime) {
      return getTimerFormattedDuration(
        new Date(props.startTime),
        new Date(props.endTime),
      );
    }

    return getTimerFormattedDuration(new Date(props.startTime), new Date());
  });

  React.useLayoutEffect(() => {
    if (props.endTime || props.isIdle) {
      return;
    }

    const intervalId = setInterval(() => {
      setTimerValues(
        getTimerFormattedDuration(new Date(props.startTime), new Date()),
      );
    }, 1000);

    // eslint-disable-next-line consistent-return
    return () => {
      clearInterval(intervalId);
    };
  }, [props.endTime, props.isIdle, props.startTime]);

  const { hours, minutes, seconds } = props.endTime
    ? getTimerFormattedDuration(
      new Date(props.startTime),
      new Date(props.endTime),
    )
    : timerValues;

  return (
    <Box sx={props.wrapperSx}>
      <Label
        labelIntlId={props.labelIntlId}
        sx={{ variant: 'forms.jsonInputLabel' }}
      />

      <Spacer y={3} />

      <Flex
        sx={{
          variant: `cards.timer.${variant}`,
          ...props.variantSx,
        }}
      >
        <TimerText label="pressureTest.hours">{hours}</TimerText>

        <TimerText sx={{ alignSelf: 'flex-start', mt: '-2px' }}>:</TimerText>

        <TimerText label="pressureTest.minutes">{minutes}</TimerText>

        <TimerText sx={{ alignSelf: 'flex-start', mt: '-2px' }}>:</TimerText>

        <TimerText label="pressureTest.seconds">{seconds}</TimerText>
      </Flex>
    </Box>
  );
}

export function TimerText({
  children,
  label,
  sx,
}: {
  children: React.ReactNode | React.ReactNodeArray;
  label?: LocaleKey;
  sx?: StyleObject;
}) {
  return (
    <Text
      as="p"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '40px',
        color: '#F8F8FC',
        isolation: 'isolate',
        ...sx,
      }}
    >
      {children}
      {label && (
        <Text
          sx={{
            fontSize: '13px',
          }}
          intlId={label}
        />
      )}
    </Text>
  );
}

function getTimerFormattedDuration(startDate: Date, endDate: Date) {
  const { days, hours, minutes, seconds } = intervalToDuration({
    start: startDate,
    end: endDate,
  });

  return {
    hours: padTimerEntry((hours ?? 0) + (days ?? 0) * 24),
    minutes: padTimerEntry(minutes),
    seconds: padTimerEntry(seconds),
  };
}

export function padTimerEntry(time?: number) {
  return time ? String(time).padStart(2, '0') : '00';
}
