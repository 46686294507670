import React from 'react';
import * as Utils from 'utils';
import * as ReactRouter from 'react-router-dom';
import * as Rhf from 'react-hook-form';
import Joi from 'joi';
import { Container, Flex } from 'components/layout';
import { WorkspaceLayout } from 'components/workspace-layout';
import { Forms } from 'components/form-elements';
import { joiResolver } from '@hookform/resolvers/joi';
import { Text } from 'components/typography';
import { useSearchQuery } from 'utils/url/use-search-query';
import { UsersService } from 'services/users';
import { Routing } from 'global/routing';
import { TermsAndConditionsText } from 'components/TermsAndConditionsText/TermsAndConditionsText';
import { Image } from 'components/Image';
import { Link } from 'components/links';
import WavesImg from 'assets/images/reverted-waves.svg';
import PhoneImg from 'assets/images/phone-with-hand.svg';
import { ValidationSchemas } from 'utils/validation';
import { LOCAL_STORAGE_KEYS, useLocalStorage } from '../../utils/local-storage';
import { FloatingLanguageSwitcher } from '../../features/language/components/FloatingLanguageSwitcher';

const schema = Joi.object({
  firstName: ValidationSchemas.Register.FIRST_NAME,
  lastName: ValidationSchemas.Register.LAST_NAME,
  password: ValidationSchemas.Register.PASSWORD,
  termsAndConditions: ValidationSchemas.Common.TERMS_AND_CONDITIONS,
});

export const RegisterInvited = () => {
  const [assumedEmails] = useLocalStorage<string[]>(
    LOCAL_STORAGE_KEYS.ASSUMED_EMAILS,
  );

  const formMethods = Rhf.useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      password: '',
      termsAndConditions: false,
    },
    resolver: joiResolver(schema),
  });
  const history = ReactRouter.useHistory();
  const searchQuery = useSearchQuery();

  const { isLoading: isRefreshSessionLoading } = Utils.Auth.useRefreshSession({
    onSuccess: () => {
      history.replace(Routing.HOME.getPath());
    },
  });

  const {
    confirmUser,
    isLoading,
    isSuccess: isCreateUserSuccess,
  } = UsersService.useCreateInvitedUser();

  if (assumedEmails?.includes(searchQuery.username)) {
    return <ReactRouter.Redirect to={Routing.LOGIN.getPath()} />;
  }

  return (
    <Container
      variant="page"
      sx={{
        px: '0px',
        height: '100%',
        justifyContent: 'flex-start',
        overflowX: 'hidden',
      }}
    >
      <WorkspaceLayout sx={{ px: 5 }}>
        <WorkspaceLayout.Logo />
        <WorkspaceLayout.Headline intlId="createAccount.headline" />

        <Forms.Provider
          name="createAccount"
          onValid={(data) => {
            confirmUser({
              firstName: data.firstName,
              lastName: data.lastName,
              newPassword: data.password,
              oldPassword: searchQuery.password,
              username: searchQuery.username,
            });
          }}
          {...formMethods}
        >
          <Forms.FieldEditText
            labelIntlId="createAccount.form.input.firstName.label"
            placeholderIntlId="createAccount.form.input.firstName.placeholder"
            name="firstName"
            required
            autoComplete="off"
          />

          <Forms.FieldEditText
            labelIntlId="createAccount.form.input.lastName.label"
            placeholderIntlId="createAccount.form.input.lastName.placeholder"
            name="lastName"
            required
            autoComplete="off"
          />

          <Forms.FieldEditText
            labelIntlId="createAccount.form.input.password.label"
            placeholderIntlId="createAccount.form.input.password.placeholder"
            name="password"
            type="password"
            required
            autoComplete="new-password"
          />

          <Forms.CheckboxField name="termsAndConditions">
            <TermsAndConditionsText />
          </Forms.CheckboxField>

          <Forms.SubmitButton
            disabled={isCreateUserSuccess}
            isLoading={isLoading || isRefreshSessionLoading}
          >
            <Text intlId="createAccount.button.label" variant="button.text" />
          </Forms.SubmitButton>
          <Link
            to={Routing.LOGIN.getPath()}
            intlId="createAccount.existingUser"
          />
        </Forms.Provider>
      </WorkspaceLayout>

      <Flex
        alignItems="flex-start"
        justifyContent="center"
        sx={{
          position: 'relative',
          backgroundImage: `url('${WavesImg}')`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'top',
          flexShrink: 1,
          flexGrow: 1,
          px: '0px',
          justifyContent: 'center',
          alignItems: 'flex-end',
        }}
      >
        <Flex
          sx={{
            maxWidth: '500px',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
          }}
        >
          <Image src={PhoneImg} sx={{ pr: 11 }} />
        </Flex>
      </Flex>

      <FloatingLanguageSwitcher />
    </Container>
  );
};
