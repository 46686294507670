import { createTypedVariant } from 'theming';

export type TextVariants = keyof typeof text;

export const text = createTypedVariant({
  body1: {
    color: 'darkgrey.800',
    fontSize: 'lg',
    fontWeight: 300,
  },
  body2: {
    color: '#707070',
    fontSize: 'md',
  },
  body3: {
    color: 'darkgrey.800',
    fontSize: '13px',
    textTransform: 'capitalize',
  },
  subtitle: {
    color: 'secondary.500',
    fontSize: 'lg',
    textTransform: 'capitalize',
  },
  subtitle1: {
    color: 'textprimary.900',
    fontSize: 'lg',
    fontWeight: 500,
    textTransform: 'capitalize',
  },
  subtitle2: {
    color: '#1A1D21',
    fontSize: 'md',
  },
  subtitle3: {
    color: 'textprimary.900',
    fontSize: '2xl',
    fontWeight: 300,
  },
  'label.error': {
    color: 'error',
    fontSize: 'sm',
    fontWeight: 300,
  },
  'caption.small': {
    color: 'darkgrey.800',
    fontSize: '13px',
    textTransform: 'capitalize',
  },
  caption: {
    color: 'darkgrey.800',
    fontSize: '13px',
    textTransform: 'uppercase',
  },
  headline1: {
    color: 'textprimary.900',
    fontSize: '26px',
    fontWeight: 300,
  },
  headline2: {
    color: 'textprimary.900',
    fontSize: '26px',
    fontWeight: 500,
  },
  headline3: {
    color: '#F8F8FC',
    fontSize: '2xl',
    textAlign: 'right',
  },
  'button.text': {
    fontSize: 'xl',
    textTransform: 'uppercase',
    color: 'white.50',
  },
  'inactive.text': {
    color: 'grey.800',
    fontSize: 'lg',
    fontWeight: 300,
  },
  'focused.text': {
    color: 'darkgrey.800',
    fontSize: 'lg',
    fontWeight: 300,
  },
  'label.focused': {
    color: 'secondary.500',
    fontSize: 'sm',
    fontWeight: 300,
  },
  'disabled.text': {
    color: '#4D4D6561',
    fontSize: 'lg',
    fontWeight: 300,
  },
  sideMenu: {
    color: 'primary.900',
    fontSize: '16px',
    fontWeight: '500',
  },
  sideMenuLight: {
    color: 'subtitle2',
    fontSize: '14px',
    fontWeight: 300,
  },
  homeSubtitle: {
    color: 'primary.900',
    fontSize: 'lg',
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  cardLabelSmall: {
    color: '#9E9EAF',
    fontSize: 'sm',
  },
  userCardName: {
    color: 'darkgrey.800',
    lineHeight: 2,
    fontSize: 'lg',
    fontWeight: 400,
  },
  userCardTitle: {
    color: 'tertiary1.300',
    fontSize: 'sm',
    lineHeight: 2,
  },
  singleLine: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  multiLine: {
    textAlign: 'left',
  },
  h2: {
    color: 'textprimary.900',
    fontWeight: 400,
    fontSize: 'xl',
  },
});
