import * as ReactRouter from 'react-router-dom';
import React from 'react';

const GOOGLE_ANALYTICS_ID = 'G-SL9W2NKV40';

declare global {
  interface Window {
    gtag?: (config: string, id: string, options: Record<string, any>) => void;
  }
}

function useGoogleAnalyticsRouting() {
  const { listen } = ReactRouter.useHistory();

  React.useEffect(() => {
    return listen((location) => {
      if (window.gtag) {
        window.gtag('config', GOOGLE_ANALYTICS_ID, {
          page_path: location.pathname,
        });
      }
    });
  }, [listen]);
}

export { GOOGLE_ANALYTICS_ID, useGoogleAnalyticsRouting };
