import * as _ from 'lodash-es';
import * as Utils from 'utils';
import { Dependent } from 'components/form-elements/JsonForms/types';
import { reduceDependentsToBooleanMap } from 'components/form-elements/JsonForms/utils/index';

/**
 * TODO:
 * Currently, this processing will always remove the dependee fields whenever their dependent is false.
 * In the future, it should also take into account the initial value of the dependee, and set it to that
 * if there is one.
 * */

/**
 * Helper to process the form data and remove all keys in the form data
 * object, for which the associated keys in the resolved dependents object
 * return "false".
 *
 * Ex.
 * We have a form where you can order a hamburger.
 * You can optionally check a checkbox for whether you want fries, or not.
 *
 * If you check the checkbox, a new field appears for
 * "type of fries" (ex. "french fries, or "sweet potato fries").
 *
 * You select "french fries", but then change your mind and de-select the fries checkbox.
 * The expected behavior here, is that the field for "type of fries" gets cleared (i.e, set to empty string).
 *
 * This function will clear all of the fields,
 * for which the dependents ("fries" checkbox) are false ("fries" checkbox unchecked).
 * */
export function getDependentProcessedFormData(
  formData: Record<string, any>,
  dependents: Record<string, Dependent[]>,
) {
  const newFormData = { ...formData };
  const newDependents = reduceDependentsToBooleanMap(dependents, formData);

  for (const [dependentKey, dependentValue] of Object.entries(newDependents)) {
    if (dependentValue === false) {
      _.set(newFormData, dependentKey, undefined);
    }
  }

  return Utils.Http.sanitizeDeep(newFormData);
}
