import * as ReactQuery from 'react-query';
import { ErrorType } from 'utils/http';
import { InferMutationOptions } from 'utils/types';
import { updateUserAPI } from './api';

export function useUpdateUser(
  options?: InferMutationOptions<typeof updateUserAPI>,
) {
  const { mutate, isLoading, isError, error } = ReactQuery.useMutation(
    'update-user',
    updateUserAPI,
    options,
  );

  return {
    updateUser: mutate,
    isLoading,
    isError,
    error: error as ErrorType,
  };
}
