import { UiSchemaCustom } from 'components/form-elements/JsonForms/types';

export const uiSchema: UiSchemaCustom = {
  kontrollAvser: {
    'ui:widget': 'radio',
  },
  kontrollmetod: {
    'ui:widget': 'radio',
  },
  kontrollmetodAnnat: {
    'ui:widget': 'underlinedInput',
  },
  resultat: {
    'ui:widget': 'radio',
  },
  kontrolltryck: {
    'ui:field': 'kontrolltryckAggregate',
  },
  berakningstryck: {
    'ui:field': 'berakningstryckAggregate',
  },
  apparaterBehallareRorledninigarAvseddaForOvrigt: {
    'ui:widget': 'underlinedInput',
  },
  provtid: {
    'ui:field': 'timerAggregate',
  },
  apparaterBehallareRorledninigarAvseddaFor: {
    'ui:widget': 'checkboxes',
  },
  rorledningar: {
    'ui:widget': 'checkboxes',
  },
  rorledningarOvrigt: {
    'ui:widget': 'underlinedInput',
  },
  system: {
    'ui:widget': 'checkboxes',
  },
  systemOvrigt: {
    'ui:widget': 'underlinedInput',
  },
  start: {
    'ui:detached': true,
    'ui:field': 'startPicture',
  },
  beskrivAvvikelse: {
    'ui:widget': 'textarea',
  },
  ovrigt: {
    'ui:widget': 'textarea',
  },
  /**
   * Picture field options:
   * - `titleText` - Text value for the picture card title
   * */
  end: {
    'ui:field': 'endPicture',
  },
  __DEPENDENTS__: {
    end: ['start.imageUrl'],
    resultat: ['end.imageUrl'],
    beskrivAvvikelse: [
      'end.imageUrl',
      {
        field: 'resultat',
        enum: ['avvikelse', 'godkantMedAvvikelse'],
      },
    ],
    ovrigt: ['end.imageUrl'],

    kontrollmetodAnnat: [
      {
        field: 'kontrollmetod',
        enum: ['annat'],
      },
    ],

    rorledningarOvrigt: [
      {
        field: 'rorledningar',
        enum: ['ovrigt'],
      },
    ],

    apparaterBehallareRorledninigarAvseddaForOvrigt: [
      {
        field: 'apparaterBehallareRorledninigarAvseddaFor',
        enum: ['ovrigt'],
      },
    ],

    systemOvrigt: [
      {
        field: 'system',
        enum: ['ovrigt'],
      },
    ],
  },
};
