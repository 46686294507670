import React from 'react';
import * as z from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Forms } from 'components/form-elements';
import { useRedirect } from 'utils/redirect';
import { Routing } from 'global/routing';
import { Box } from 'components/layout';
import { InlineLoadingIndicator } from 'components/LoadingIndicator';
import { isQueryLoading } from 'utils/functions/is-query-loading';
import { Text } from 'components/typography';
import { useNotifications } from 'components/notifications/notifications';
import { PaymentsService, PaymentsUtils } from '../../index';
import { StripeCardElement } from '../StripeCardElement';

const schema = z.object({
  cardholderName: z
    .string()
    .min(1, 'payments.paymentInformation.cardholder.required'),
});

export function ManagePaymentInformation() {
  const notifications = useNotifications();
  const { onRedirect } = useRedirect(Routing.HOME.getPath());

  const {
    stripeConfirmCard,
    failedSetupIntentId,
    resetFailedSetupIntentId,
    isConfirmingCard,
  } = PaymentsUtils.useConfirmCardSetup({
    onSuccess: () => {
      onRedirect();
    },
  });

  const updateCustomerPaymentMethod = PaymentsService.useUpdateCustomerPaymentMethod({
    onSuccess: ({ clientSecret }) => {
      if (clientSecret) {
        stripeConfirmCard(clientSecret);
      } else {
        onCardUpdateSuccess();
      }
    },
  });

  const updateSetupIntent = PaymentsService.useUpdateSetupIntent({
    onSuccess: ({ clientSecret }) => {
      if (clientSecret) {
        stripeConfirmCard(clientSecret);
      } else {
        onCardUpdateSuccess();
      }
    },
  });

  const stripeCreatePaymentMethod = PaymentsUtils.useCreatePaymentMethod({
    onSuccess: (data) => {
      updateCustomerPaymentMethod.mutate({
        paymentMethodId: data.paymentMethod.id,
      });
    },
  });

  const getActivePaymentMethod = PaymentsService.useGetActivePaymentMethod({
    onSuccess: ({ cardholderName }) => {
      if (cardholderName && !formMethods.getValues('cardholderName')) {
        formMethods.setValue('cardholderName', cardholderName);
      }
    },
  });

  const formMethods = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      cardholderName: getActivePaymentMethod.data?.cardholderName ?? '',
    },
    mode: 'onChange',
  });

  function onCardUpdateSuccess() {
    const redirectTo = Routing.HOME.getPath();

    notifications.success({
      description: 'payments.manageInformation.notification.success',
      tag: [redirectTo],
    });
    resetFailedSetupIntentId();
    onRedirect(redirectTo);
  }

  function onSaveCard() {
    formMethods.trigger().then(() => {
      if (failedSetupIntentId) {
        return updateSetupIntent.mutate({
          setupIntentId: failedSetupIntentId,
          cardholderName: formMethods.getValues('cardholderName'),
        });
      }

      return stripeCreatePaymentMethod.mutate({
        cardholderName: formMethods.getValues('cardholderName'),
      });
    });
  }

  if (
    isQueryLoading(
      getActivePaymentMethod.isLoading,
      getActivePaymentMethod.data,
    )
  ) {
    return <InlineLoadingIndicator />;
  }

  const { postalCode, expiryYear, expiryMonth, last4Digits } = getActivePaymentMethod.data;
  const hasCardInformation = expiryMonth && expiryYear && last4Digits;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
      <Forms.Provider
        {...formMethods} name="paymentInformation"
        sx={{ mb: 4 }}
      >
        <Forms.FieldEditText
          placeholderIntlId="John Doe"
          labelIntlId="payments.paymentInformation.cardholder"
          name="cardholderName"
        />
      </Forms.Provider>

      <StripeCardElement postalCode={postalCode} />

      <Box
        sx={{
          my: 6,
          flexGrow: 1,
          lineHeight: 1,
        }}
      >
        {hasCardInformation && (
          <Text
            variant="body2"
            intlId="payments.manageInformation.currentCardInfo"
            intlValues={{
              strong: (...parts: string[]) => <strong>{parts}</strong>,
              last4: last4Digits,
              month: padZeroMonth(expiryMonth),
              year: expiryYear,
            }}
          />
        )}
      </Box>

      <Button
        intlId="payments.manageInformation.update"
        isLoading={
          stripeCreatePaymentMethod.isLoading
          || updateSetupIntent.isLoading
          || updateCustomerPaymentMethod.isLoading
          || isConfirmingCard
        }
        onClick={() => onSaveCard()}
      />
    </Box>
  );
}

function padZeroMonth(month: number | undefined) {
  return String(month).padStart(2, '0');
}
