import * as ReactQuery from 'react-query';
import { identify, makeRequest, Service } from '../../../utils/http';
import { InferMutationOptions } from '../../../utils/types';
import { QUERY_KEY_ACTIVE_PAYMENT_METHOD } from './get-active-payment-method';

type Data = Parameters<typeof Service.updateCustomerPaymentMethod>[0];

async function updateCustomerPaymentMethodApi(data: Data) {
  return makeRequest(
    Service.updateCustomerPaymentMethod(data, await identify()),
  );
}

function useUpdateCustomerPaymentMethod(
  options?: InferMutationOptions<typeof updateCustomerPaymentMethodApi>,
) {
  const queryClient = ReactQuery.useQueryClient();
  return ReactQuery.useMutation(updateCustomerPaymentMethodApi, {
    ...options,
    onSuccess: async (data, variables, context) => {
      await Promise.all([
        queryClient.invalidateQueries(QUERY_KEY_ACTIVE_PAYMENT_METHOD),
        options?.onSuccess?.(data, variables, context),
      ]);
    },
  });
}

export { useUpdateCustomerPaymentMethod };
