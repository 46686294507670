import { generateContext } from 'utils/context';

type DependentsContext = {
  dependentsMap: Record<string, boolean>;
};

export const [
  useDependentsContext,
  DependentsContextProvider,
] = generateContext<DependentsContext>({
  dependentsMap: {},
});
