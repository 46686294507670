import * as Utils from 'utils';
import React from 'react';
import { GenericFn } from 'utils/types';
import { useGetLatest } from 'utils/hooks';

export function AuthorizationSuperAdmin({
  children,
  onAuthorizeFail,
}: {
  children: React.ReactNode;
  onAuthorizeFail?: GenericFn;
}) {
  const getOnAuthorizeFail = useGetLatest(onAuthorizeFail);
  const { currentUser } = Utils.Auth.useCurrentAuthenticatedUser();

  const isSuperAdmin = currentUser?.signInUserSession.idToken.payload.super_admin === 'true';

  React.useEffect(() => {
    if (currentUser && !isSuperAdmin) {
      getOnAuthorizeFail()?.();
    }
  }, [currentUser, getOnAuthorizeFail, isSuperAdmin]);

  if (!currentUser || !isSuperAdmin) {
    return null;
  }

  return <React.Fragment>{children}</React.Fragment>;
}
