import * as Utils from 'utils/index';
import * as React from 'react';
import { Hub } from 'aws-amplify';

export function useAmplifyStateListener() {
  const { setCurrentUserId } = Utils.Auth.useCurrentUserId();

  React.useEffect(() => {
    // 'any' because amplify doesn't export its listener type ......
    function listener(data: any) {
      // eslint-disable-next-line default-case
      switch (data.payload.event) {
        case 'tokenRefresh':
        case 'signIn': {
          setCurrentUserId(data.payload.data.username);
          break;
        }
        case 'tokenRefresh_failure':
        case 'signOut': {
          setCurrentUserId(null);
          break;
        }
      }
    }

    Hub.listen('auth', listener);

    return () => {
      Hub.remove('auth', listener);
    };
  }, [setCurrentUserId]);
}
