import React from 'react';
import * as ThemeUI from 'theme-ui';
import { StyleObject } from 'theming';

export interface IconButtonProps extends ThemeUI.IconButtonProps {
  width?: number | string;
  sx?: StyleObject;
  strokeWidth?: number | string;
}

export const IconButton: React.FC<IconButtonProps> = ({
  width,
  sx,
  children,
  onClick,
  ...props
}) => (
  <ThemeUI.IconButton
    type="button"
    sx={{
      cursor: 'pointer',
      width,

      '&:disabled': {
        opacity: 0.5,
        pointerEvents: 'none',
      },

      ...sx,
    }}
    onClick={onClick}
    {...props}
  >
    {children}
  </ThemeUI.IconButton>
);
