import * as ReactQuery from 'react-query';
import { InferMutationOptions } from 'utils/types';
import { createInvitedUserApi } from 'services/users/create-invited-user/api';
import { ErrorType } from 'utils/http';
import { QUERY_KEY_CURRENT_USER } from 'utils/auth/use-current-authenticated-user';
import { QUERY_KEY_LOGGED_IN_USER } from 'services/users/get-logged-in-user/hook';

export function useCreateInvitedUser(
  options?: InferMutationOptions<typeof createInvitedUserApi>,
) {
  const queryClient = ReactQuery.useQueryClient();

  const { mutate: confirmUser, ...rest } = ReactQuery.useMutation(
    'confirm-user',
    createInvitedUserApi,
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries(QUERY_KEY_CURRENT_USER);
        await queryClient.invalidateQueries(QUERY_KEY_LOGGED_IN_USER);

        if (options?.onSuccess) {
          await options.onSuccess(data, variables, context);
        }
      },
    },
  );

  return {
    confirmUser,
    ...rest,
    error: rest.error as ErrorType,
  };
}
