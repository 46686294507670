import * as ReactQuery from 'react-query';
import { ErrorType } from 'utils/http';
import { getExternalUsersAPI } from './api';

export const QUERY_KEY_EXTERNAL_USERS = ['external-users'];

export function useGetExternalUsers() {
  const { isLoading, isError, data, error } = ReactQuery.useQuery(
    QUERY_KEY_EXTERNAL_USERS,
    getExternalUsersAPI,
  );

  return {
    externalUsers: data?.externalUsers,
    isLoading,
    isError,
    error: error as ErrorType,
  };
}
