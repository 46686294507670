import * as React from 'react';
import * as Icons from 'components/icons';
import { Box, Flex } from 'components/layout';
import { Text } from 'components/typography';
import { IconButton } from 'components/form-elements/buttons/IconButton';
import { GenericFn } from 'utils/types';
import { StyleObject } from 'theming';
import { LocaleKey } from 'translations';

export type NotificationSeverity = 'info' | 'warning' | 'error' | 'success';

type Props = {
  titleIntlId?: LocaleKey;
  children?: React.ReactNode | React.ReactNodeArray;
  severity?: NotificationSeverity;
  onClose?: GenericFn;
  variant?: 'toast' | 'alert';
  sx?: StyleObject;
};

const DESCRIPTION_COLOR: Record<NotificationSeverity, string> = {
  success: '#097837',
  error: '#b10323',
  warning: '#fc752a',
  info: '#2f83e3',
};

const BACKGROUND_COLOR: Record<NotificationSeverity, string> = {
  info: '#e3f1fd',
  warning: '#ffecb8',
  error: '#ffcbd4',
  success: '#e2f9ed',
};

const ICON: Record<NotificationSeverity, React.ReactNode> = {
  info: <Icons.Info />,
  warning: <Icons.Warning />,
  success: <Icons.Success />,
  error: <Icons.Error />,
};

const DEFAULT_TITLE: Record<NotificationSeverity, LocaleKey> = {
  info: 'notification.info',
  success: 'notification.success',
  warning: 'notification.warning',
  error: 'notification.error',
};

const VARIANT_WIDTH = {
  toast: '320px',
  alert: '100%',
} as const;

export function Notification({
  severity = 'success',
  variant = 'alert',
  ...props
}: Props) {
  return (
    <Flex
      sx={{
        ...props.sx,

        boxShadow: variant === 'toast' && '2dp',
        width: VARIANT_WIDTH[variant],
        color: DESCRIPTION_COLOR[severity],
        alignItems: 'center',
        py: 4,
        px: 3,
        pl: 4,
        border: '1px solid',
        borderColor: DESCRIPTION_COLOR[severity],
        borderRadius: 2,
        backgroundColor: BACKGROUND_COLOR[severity],
      }}
    >
      <Box sx={{ alignSelf: 'flex-start', mt: '2px' }}>{ICON[severity]}</Box>

      <Flex
        sx={{
          gap: 1,
          px: 4,
          flexGrow: 1,
          flexDirection: 'column',
        }}
      >
        <Text
          as="h5"
          intlId={props.titleIntlId ?? DEFAULT_TITLE[severity]}
          sx={{
            fontWeight: 600,
            fontSize: 'lg',
          }}
        />
        {props.children}
      </Flex>

      {props.onClose && (
        <IconButton sx={{ p: 4 }} onClick={props.onClose}>
          <Icons.Close
            width={28}
            sx={{ '& > svg': { fill: 'currentcolor' } }}
          />
        </IconButton>
      )}
    </Flex>
  );
}

type DescriptionProps = {
  children?: React.ReactNode | React.ReactNodeArray;
};

Notification.Description = function Description(props: DescriptionProps) {
  return (
    <Text
      as="p"
      sx={{
        fontSize: '13px',
        lineHeight: 2,
      }}
    >
      {props.children}
    </Text>
  );
};
