import * as React from 'react';
import * as ReactRouter from 'react-router-dom';
import { DocumentsService } from 'services/documents';
import { Routing } from 'global/routing';
import { PageLoadingIndicator } from 'components/PageLoadingIndicator/PageLoadingIndicator';
import { StatusCodes } from '../../global/status-codes';
import { useNotifications } from '../../components/notifications/notifications';
import { LocaleKey } from '../../translations';

export function DocumentCreate() {
  const history = ReactRouter.useHistory();
  const params = Routing.DOCUMENT.Create.useCreateParams();
  const notifications = useNotifications();

  const { createDocument } = DocumentsService.useCreateDocument({
    onSuccess: (data) => {
      history.replace(
        Routing.DOCUMENT.Links.DocumentTab({
          documentId: data.documentId,
          templateId: data.template.id,
        }),
      );
    },
    onError: (error) => {
      if (error.statusCode === StatusCodes.UNAUTHORIZED) {
        notifications.error({
          description: error.message as LocaleKey,
          tag: [Routing.HOME.getPath()],
        });

        history.replace(Routing.HOME.getPath());
      }
    },
  });

  React.useEffect(() => {
    createDocument({ templateId: params.templateId });
    // We only want to run it once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <PageLoadingIndicator />;
}
