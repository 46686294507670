/** @jsxImportSource theme-ui */

import * as Intl from 'lib/intl';
import { ManagePaymentInformation } from '../components/ManagePaymentInformation/ManagePaymentInformation';
import { PaymentsContainer } from '../components/select-modules/Common';
import { BillingPageTitle } from '../components/Billing/BillingPageTitle';

export function ManagePaymentInformationPage() {
  const intl = Intl.useIntl();

  return (
    <PaymentsContainer
      sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}
    >
      <BillingPageTitle>
        {intl.formatMessage({
          id: 'payments.manageInformation.title',
        })}
      </BillingPageTitle>
      <ManagePaymentInformation />
    </PaymentsContainer>
  );
}
