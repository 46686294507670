import { ThemeProps, utils } from '@rjsf/core';
import { RadioWidget } from 'components/form-elements/JsonForms/components/Radio';
import { CheckboxesWidget } from 'components/form-elements/JsonForms/components/Checkboxes';
import { SelectWidget } from 'components/form-elements/JsonForms/components/Select';
import { TextWidget } from 'components/form-elements/JsonForms/components/Text';
import { TextareaWidget } from 'components/form-elements/JsonForms/components/TextareaWidget';
import { FieldTemplate } from 'components/form-elements/JsonForms/components/FieldTemplate';
import { ObjectFieldTemplate } from 'components/form-elements/JsonForms/components/ObjectFieldTemplate';
import { ErrorList } from 'components/form-elements/JsonForms/components/ErrorList';
import { mapJsonFormErrors } from 'components/form-elements/JsonForms/utils';
import { SwitchWidget } from './components/SwitchWidget';

const { getDefaultRegistry } = utils;

const { fields, widgets } = getDefaultRegistry();

const Widgets = {
  RadioWidget,
  CheckboxesWidget,
  SelectWidget,
  TextWidget,
  TextareaWidget,
  switch: SwitchWidget,
};

const Fields = {};

export const Theme: ThemeProps = {
  fields: {
    ...fields,
    ...Fields,
  },
  FieldTemplate,
  ObjectFieldTemplate,
  widgets: { ...widgets, ...Widgets },
  ErrorList,
  transformErrors(errors) {
    return mapJsonFormErrors(errors);
  },
};
