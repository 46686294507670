import { set } from 'lodash/fp';
import { GlobalState } from 'little-state-machine';

export function toggleSideBar(state: GlobalState) {
  return set('isSideBarOpen', !state.isSideBarOpen, state);
}

export function closeSideBar(state: GlobalState) {
  return set('isSideBarOpen', false, state);
}
