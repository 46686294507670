import * as ReactQuery from 'react-query';
import { InferQueryOptions } from '../../../utils/types';
import { getRunningDocumentsForUserApi } from './api';

export const QUERY_KEY_RUNNING_DOCUMENTS_FOR_USER = ['runningDocumentsForUser'];

export function useGetRunningDocumentsForUser(
  options?: InferQueryOptions<typeof getRunningDocumentsForUserApi>,
) {
  const { data, ...rest } = ReactQuery.useQuery(
    QUERY_KEY_RUNNING_DOCUMENTS_FOR_USER,
    getRunningDocumentsForUserApi,
    options,
  );

  return {
    runningDocuments: data?.runningDocuments ?? [],
    ...rest,
  };
}
