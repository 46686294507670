import * as React from 'react';
import { useModal } from 'store/modals';
import { Modals } from './modals';

/**
 * We probably don't want random renders to affect
 * the modal container. The modal container only
 * cares for the values of "useModal", which is
 * a context value.
 *
 * React.memo only works for prop changes, but
 * it will still re-render when the context value
 * changes, which is exactly what we want.
 * https://reactjs.org/docs/react-api.html#reactmemo
 * */
export const ModalContainer = React.memo(Modal);

function Modal() {
  const { modalType, modalProps } = useModal();

  if (!modalType) {
    return null;
  }

  const OpenedModal = Modals[modalType];

  return (
    <OpenedModal {...modalProps}>
      {/*@ts-expect-error Union messes up children prop */}
      {modalProps?.children}
    </OpenedModal>
  );
}
