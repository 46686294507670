import * as ReactQuery from 'react-query';
import { InferMutationOptions } from 'utils/types';
import { adminAddWorkspaceToTraceProviderApi } from 'services/admin/admin-add-trace-provider/api';

export function useAddWorkspaceToTraceProvider(
  options?: InferMutationOptions<typeof adminAddWorkspaceToTraceProviderApi>,
) {
  const { mutate, ...rest } = ReactQuery.useMutation(
    ['add-workspace-to-trace-provider'],
    adminAddWorkspaceToTraceProviderApi,
    options,
  );

  return {
    addWorkspaceToTraceProvider: mutate,
    ...rest,
  };
}
