/** @jsxImportSource theme-ui */

import { Button, Forms } from 'components/form-elements';
import * as z from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { Box } from 'components/layout';
import { WorkspaceLayout } from 'components/workspace-layout';
import { GenericFn } from 'utils/types';
import { Currency } from 'components/Currency';
import { WorkspaceService } from 'services/workspace';
import { PaymentsService, PaymentsUtils } from '../index';
import {
  PaymentsContainer,
  PaymentsTitle,
} from '../components/select-modules/Common';
import { StripeCardElement } from '../components/StripeCardElement';
import { usePaymentsFormContext } from './PaymentsPage';

const schema = z.object({
  cardholderName: z
    .string()
    .min(1, 'payments.paymentInformation.cardholder.required'),
});

export function PaymentInformationPage({
  onPreviousStep,
}: {
  onPreviousStep: GenericFn;
}) {
  const { workspaceData } = WorkspaceService.useGetWorkspace();
  const { invalidateWorkspaceOnTimeout } = PaymentsUtils.useInvalidateWorkspaceOnTimeout();
  const { stripeConfirmCard, failedSetupIntentId, isConfirmingCard } = PaymentsUtils.useConfirmCardSetup({
    onSuccess: async () => {
      await invalidateWorkspaceOnTimeout();
    },
  });
  const updateSetupIntent = PaymentsService.useUpdateSetupIntent({
    onSuccess: async ({ clientSecret }) => {
      if (clientSecret) {
        await stripeConfirmCard(clientSecret);
      } else {
        await invalidateWorkspaceOnTimeout();
      }
    },
  });
  const { createPaymentsSubscription, isLoading: isCreatingSubscription } = PaymentsService.useCreatePaymentsSubscription({
    onSuccess: async ({ clientSecret }) => {
      if (clientSecret) {
        await stripeConfirmCard(clientSecret);
      } else {
        await invalidateWorkspaceOnTimeout();
      }
    },
  });
  const { totalPrice, pricesAggregator } = usePaymentsFormContext();

  const formMethods = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      cardholderName: '',
    },
    mode: 'onChange',
  });

  const cardholderName = formMethods.watch('cardholderName');

  async function onPay() {
    formMethods.trigger().then(async () => {
      /*
       * If we have already created a subscription, and that
       * subscription required further setup via setup intent,
       * and that setup intent failed, then we want to update
       * the existing setup intent, not create a new subscription.
       * */
      if (failedSetupIntentId) {
        updateSetupIntent.mutate({
          setupIntentId: failedSetupIntentId,
          cardholderName,
        });
        return;
      }

      /*
       * If there is no failed setup intent, we can consider this
       * to be the first attempt at creating a subscription.
       * */
      createPaymentsSubscription({
        stripePrices: pricesAggregator.values.map((priceId) => ({
          stripePriceId: priceId,
        })),
        cardholderName,
      });
    });
  }

  return (
    <PaymentsContainer
      sx={{ minHeight: '100%', display: 'flex', flexDirection: 'column' }}
    >
      <WorkspaceLayout.Logo />

      <PaymentsTitle intlId="payments.paymentInformation.title" />

      <Forms.Provider
        {...formMethods} name="paymentInformation"
        sx={{ mb: 4 }}
      >
        <Forms.FieldEditText
          placeholderIntlId="John Doe"
          labelIntlId="payments.paymentInformation.cardholder"
          name="cardholderName"
        />
      </Forms.Provider>

      <StripeCardElement />

      <Box sx={{ minHeight: 8, flexGrow: 1 }} />

      <Button
        isLoading={
          isCreatingSubscription
          || isConfirmingCard
          || updateSetupIntent.isLoading
        }
        type="submit"
        onClick={() => onPay()}
        intlId="payments.paymentInformation.pay"
        intlValues={{
          amount: (
            <Currency
              valueCents={totalPrice}
              currency={workspaceData ? workspaceData.currency : 'sek'}
            />
          ),
        }}
      />
      <Button
        type="button"
        sx={{ mt: 4, mx: 'auto', width: 'fit-content' }}
        variant="text"
        onClick={() => onPreviousStep()}
        intlId="payments.paymentInformation.back"
      />
    </PaymentsContainer>
  );
}
