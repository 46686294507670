import * as ReactQuery from 'react-query';
import * as _ from 'lodash-es';
import { getAllNotificationsApi } from 'services/notifications/get-all-notifications/api';

export const QUERY_KEY_NOTIFICATIONS = ['messages'];

const INFINITE_QUERY_PAGE_LIMIT = 10;

export function useGetAllNotifications() {
  const { data, ...rest } = ReactQuery.useInfiniteQuery(
    QUERY_KEY_NOTIFICATIONS,
    ({ pageParam }) =>
      getAllNotificationsApi({
        limit: INFINITE_QUERY_PAGE_LIMIT,
        skip: pageParam,
      }),
    {
      getNextPageParam({ total: totalNotifications }, allPages) {
        const nextCursor = allPages.length * INFINITE_QUERY_PAGE_LIMIT;
        return totalNotifications > nextCursor ? nextCursor : undefined;
      },
    },
  );

  return {
    notificationMessages:
      _.flatten(data?.pages.map((page) => page.notifications)) ?? [],
    notificationsCount: data?.pages[0].total ?? 0,
    ...rest,
  };
}
