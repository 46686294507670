import { FieldProps } from '@rjsf/core';
import { useIntl } from 'lib/intl';
import React from 'react';
import { Text } from '../../../components/typography';
import { PictureTake } from '../../../components/form-elements/JsonForms/components/PictureTake';
import { Button } from '../../../components/form-elements';
import * as Icons from '../../../components/icons';
import { ReliningFieldWrapper } from './ReliningFieldWrapper';

export function ReliningImageWithTitle(props: FieldProps) {
  const intl = useIntl();

  return (
    <ReliningFieldWrapper>
      <Text
        as="h2" variant="headline2"
        sx={{ mb: 5, fontWeight: 400 }}
      >
        {intl.formatMessage({
          id: `${props.name}.title`,
        })}
      </Text>

      <PictureTake
        {...props}
        imageName={props.name}
        ActionButton={(
          <Button
            intlId="pressureTest.startTest"
            prependIcon={<Icons.CameraMui />}
          />
        )}
      />
    </ReliningFieldWrapper>
  );
}
