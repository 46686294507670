import * as React from 'react';
import * as Rhf from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';

import { WorkspaceLayout } from 'components/workspace-layout';
import { Forms } from 'components/form-elements';
import { Constants } from 'global/constants';
import { Text } from 'components/typography';
import { Container, Flex } from 'components/layout';
import { Routing } from 'global/routing';
import * as ReactRouter from 'react-router-dom';
import { AuthService } from 'services/auth';
import WavesImg from 'assets/images/waves.svg';
import { Image } from 'components/Image';
import PhoneImg from 'assets/images/phone.svg';

const schema = Joi.object({
  email: Joi.string().required().regex(Constants.EMAIL_REGEX).messages({
    'string.empty': 'forgotPassword.form.input.email.error.required',
    'string.pattern.base':
      'forgotPassword.form.input.email.error.validEmail.message',
  }),
});

export function ForgotPassword() {
  const history = ReactRouter.useHistory();
  const formMethods = Rhf.useForm({
    defaultValues: {
      email: '',
    },
    resolver: joiResolver(schema),
  });

  const { forgotPassword, isLoading: isForgotPasswordLoading } = AuthService.useForgotPassword({
    onSuccess: () => {
      history.replace(Routing.TRANSITIONS.getPasswordInstructionsPath());
    },
  });

  return (
    <Container
      variant="page"
      sx={{
        height: '100%',
        justifyContent: 'flex-start',
        overflowX: 'hidden',
        px: '0px',
      }}
    >
      <WorkspaceLayout sx={{ px: 4 }}>
        <WorkspaceLayout.Headline intlId="forgotPassword.headline" />
        <WorkspaceLayout.Paragraph intlId="forgotPassword.paragraph" />
        <Forms.Provider
          sx={{ gap: 4 }}
          name="forgotPassword"
          onValid={(data) => {
            forgotPassword({
              email: data.email,
            });
          }}
          {...formMethods}
        >
          <Forms.FieldEditText
            isAlwaysFloating
            labelIntlId="forgotPassword.form.input.email.label"
            name="email"
            required
          />

          <Forms.SubmitButton isLoading={isForgotPasswordLoading}>
            <Text
              intlId="forgotPassword.form.button.label"
              variant="button.text"
            />
          </Forms.SubmitButton>
        </Forms.Provider>
      </WorkspaceLayout>
      <Flex
        alignItems="flex-start"
        justifyContent="center"
        sx={{
          backgroundImage: `url('${WavesImg}')`,
          mt: 4,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'top',
          flexShrink: 1,
          flexGrow: 1,
          width: '130%',
        }}
      >
        <Image src={PhoneImg} />
      </Flex>
    </Container>
  );
}
