import React from 'react';
import * as ThemeUI from 'theme-ui';
import { CardVariants } from 'theming/variants/cards';
import { IntlProps } from 'translations';

export interface CardProps extends ThemeUI.CardProps, IntlProps {
  variant?: CardVariants;
}

export const Card: React.FC<CardProps> = ({
  sx,
  children,
  variant,
  ...props
}) => (
  <ThemeUI.Card
    sx={sx} variant={variant}
    {...props}
  >
    {children}
  </ThemeUI.Card>
);
