function getItem(key: string, defaultValue?: string) {
  try {
    const value = localStorage.getItem(key);
    if (value == null) {
      return defaultValue;
    }

    return JSON.parse(value);
  } catch (error) {
    return defaultValue;
  }
}

function setItem(key: string, value: string | Record<string, any>) {
  if (typeof value === 'string') {
    return localStorage.setItem(key, value);
  }

  return localStorage.setItem(key, JSON.stringify(value));
}

export { getItem, setItem };
