import * as React from 'react';
import * as Intl from 'lib/intl';
import * as _ from 'lodash-es';
import { Box } from 'components/layout';
import { Text } from 'components/typography';
import { Currency } from 'components/Currency';
import type { Property } from 'csstype';

type DiscountBracket = {
  // The starting point of this bracket
  fromCents: number;
  // The ending point of this bracket
  toCents: number;
  // The percentage discount in this bracket
  discountPercentage: number;
};

type DiscountTableProps = {
  brackets: DiscountBracket[];
  // The current value which we want to match against the brackets
  currentTotalCents: number;
  // Which currency to use (useful for formatting the values)
  currency?: string;
};

export function DiscountTable({
  brackets,
  currentTotalCents,
  currency = 'sek',
}: DiscountTableProps) {
  const intl = Intl.useIntl();

  return (
    <React.Fragment>
      <DiscountTableTitle>
        {intl.formatMessage({
          id: 'payments.discount.discountTable.title',
        })}
      </DiscountTableTitle>
      <DiscountTableDescription>
        {intl.formatMessage({
          id: 'payments.discount.discountTable.description',
        })}
      </DiscountTableDescription>

      <DiscountTableContent>
        <DiscountTableHead>
          <DiscountTableHeadRow>
            <DiscountTableColumn />
            <DiscountTableColumn>
              {intl.formatMessage({
                id: 'payments.discount.discountTable.billing',
              })}
            </DiscountTableColumn>
            <DiscountTableColumn textAlign="right">
              {intl.formatMessage({
                id: 'payments.discount.discountTable.discount',
              })}
            </DiscountTableColumn>
          </DiscountTableHeadRow>
        </DiscountTableHead>

        <DiscountTableBody>
          {brackets
            .sort((a, b) => a.discountPercentage - b.discountPercentage)
            .map((bracket, index) => (
              <DiscountTableRow
                isEven={index % 2 === 0}
                isActive={_.inRange(
                  currentTotalCents,
                  bracket.fromCents,
                  bracket.toCents,
                )}
                color="primary.400"
              >
                <DiscountTableColumn
                  isActive={_.inRange(
                    currentTotalCents,
                    bracket.fromCents,
                    bracket.toCents,
                  )}
                />
                <DiscountTableColumn>
                  <Currency
                    valueCents={bracket.fromCents}
                    currency={currency}
                  />
                  {bracket.toCents === Infinity
                  || bracket.toCents >= 2_000_000_000 ? (
                    <React.Fragment>
                      &nbsp;
                      <Text
                        as="span"
                        sx={{ fontWeight: 600 }}
                        intlId="payments.discount.discountTable.ellerMer"
                      />
                    </React.Fragment>
                    ) : (
                      <React.Fragment>
                      &nbsp; - &nbsp;
                        <Currency
                          valueCents={bracket.toCents}
                          currency={currency}
                        />
                      </React.Fragment>
                    )}
                </DiscountTableColumn>
                <DiscountTableColumn textAlign="right">
                  {bracket.discountPercentage} %
                </DiscountTableColumn>
              </DiscountTableRow>
            ))}
        </DiscountTableBody>
      </DiscountTableContent>
    </React.Fragment>
  );
}

function DiscountTableContent({ children }: { children: React.ReactNode }) {
  return (
    <Box
      as="table"
      sx={{
        isolation: 'isolate',
        width: '100%',
        borderCollapse: 'collapse',
        boxShadow: '4dp',
      }}
    >
      {children}
    </Box>
  );
}

function DiscountTableTitle({ children }: { children: React.ReactNode }) {
  return (
    <Text
      as="h2"
      sx={{
        mb: 3,
        fontWeight: 600,
      }}
    >
      {children}
    </Text>
  );
}

function DiscountTableDescription({ children }: { children: React.ReactNode }) {
  return (
    <Text
      as="p"
      sx={{
        mb: 5,
        fontWeight: 300,
      }}
    >
      {children}
    </Text>
  );
}

function DiscountTableHead({ children }: { children: React.ReactNode }) {
  return (
    <Box
      as="thead"
      sx={{
        zIndex: 1,
        position: 'sticky',
        top: 0,
        color: 'black',
        fontWeight: 500,
        fontSize: 'lg',
      }}
    >
      {children}
    </Box>
  );
}

function DiscountTableHeadRow({ children }: { children: React.ReactNode }) {
  return (
    <Box
      as="tr"
      sx={{
        color: 'black',
        fontWeight: 500,
        fontSize: 'lg',
        backgroundColor: 'white.50',
      }}
    >
      {children}
    </Box>
  );
}

function DiscountTableBody({ children }: { children: React.ReactNode }) {
  return (
    <Box as="tbody" sx={{ fontSize: 'md' }}>
      {children}
    </Box>
  );
}

function DiscountTableRow({
  children,
  color = 'black',
  isActive,
  isEven,
}: {
  children: React.ReactNode;
  color?: string;
  isActive?: boolean;
  isEven?: boolean;
}) {
  const evenRowBackground = isEven ? 'secondary.050' : '#EEF7FF';
  const activeRowBackground = isActive ? 'tertiary1.100' : evenRowBackground;

  return (
    <Box
      as="tr"
      sx={{
        backgroundColor: activeRowBackground,
        color,
        fontWeight: isActive ? 600 : 400,
      }}
    >
      {children}
    </Box>
  );
}

function DiscountTableColumn({
  children,
  textAlign = 'left',
  isActive = false,
}: {
  children?: React.ReactNode;
  textAlign?: Property.TextAlign;
  isActive?: boolean;
}) {
  return (
    <Box
      as="td"
      sx={{
        py: isActive ? '10px' : 4,
        width: isActive ? '20px' : 'unset',
        textAlign,
        '&:last-child': {
          pr: 8,
        },
      }}
    >
      {isActive ? (
        <Box
          sx={{ display: 'inline-flex', position: 'relative', bottom: '-2px' }}
        >
          <DiscountInRangeIcon />
        </Box>
      ) : (
        children
      )}
    </Box>
  );
}

function DiscountInRangeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24.037"
      viewBox="0 0 12.396 32.037"
    >
      <path
        id="Path_11811"
        data-name="Path 11811"
        d="M0,0H0L12.4,16.365,0,32.037H0Z"
        fill="#3ddc97"
      />
    </svg>
  );
}
