/* eslint-disable max-len */
import * as React from 'react';
import _ from 'lodash-es';
import { useDependentsContext } from './depdenents-context';
import { getDotSeparatedId } from './index';

/**
 * Component used to optimize the rendering of "Dependee" components.
 * This component should not be relevant to using JsonForm.
 * */
export function DependentGuard({
  fieldId,
  children,
}: {
  fieldId: string;
  children: React.ReactElement;
}) {
  const { dependentsMap } = useDependentsContext();
  const dependentValue = _.get(dependentsMap, getDotSeparatedId(fieldId));

  if (dependentValue === false) {
    return null;
  }

  /**
   * Relevant link for children rendering optimization technique
   * https://blog.isquaredsoftware.com/2020/05/blogged-answers-a-mostly-complete-guide-to-react-rendering-behavior/#component-render-optimization-techniques
   * */
  return children;
}
