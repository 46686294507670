import * as React from 'react';
import { CardProps, Card } from 'components/cards/Card/Card';
import { Text } from 'components/typography';
import { Box, Flex } from 'components/layout';
import * as Icons from 'components/icons';
import { LocaleKey } from 'translations';
import { isIgnoreCaseEqual } from 'utils/functions/is-ignore-case-equal';

import { Divider } from 'theme-ui';
import * as ReactRouterDom from 'react-router-dom';
import { GetAllUsersResponseUsers } from 'trace-backend-sdk';
import { Routing } from '../../../global/routing';
import { DocumentRequestBase } from '../../../services/documents';
import { Avatar } from '../../avatar';
import { useLocaleDateFormat } from '../../../utils/hooks/use-locale-date-format';
import { DocumentCardDefaultProps } from './components/DocumentCardDefault/DocumentCardDefault';

type DocumentCardType = CardProps & {
  completed?: boolean;
};

export const DocumentCard = ({
  completed = false,
  sx,
  onClick,
  children,
}: DocumentCardType) => (
  <Card
    variant="document"
    sx={{
      display: 'grid',
      gridTemplateAreas: `
          "title avatar"
          "address address"
          "templateName date"
        `,
      gap: 2,
      position: 'relative',
      overflow: 'hidden',
      ...sx,
    }}
    onClick={onClick}
  >
    {children}

    {completed && (
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: 2,
          height: '100%',
          backgroundColor: 'tertiary1.300',
        }}
      />
    )}
  </Card>
);

export function DocumentCardNew({
  documentId,
  templateId,
  children,
}: Pick<DocumentRequestBase, 'documentId' | 'templateId'> & {
  children: React.ReactNode;
}) {
  return (
    <Card
      as={ReactRouterDom.Link}
      // @ts-expect-error Error from "as" cast
      to={Routing.DOCUMENT.Links.DocumentTab({
        documentId: parseInt(documentId, 10),
        templateId,
      })}
      sx={{
        boxShadow: '4dp',
        borderRadius: 1,
        textDecoration: 'none',
      }}
    >
      {children}
    </Card>
  );
}

export function DocumentCardTitle(props: {
  templateNameIntl: LocaleKey;
  documentNumber?: DocumentCardDefaultProps['documentNumber'];
}) {
  return (
    <Box sx={{ gridArea: 'title', color: 'textprimary.900' }}>
      <Text variant="subtitle1" intlId={props.templateNameIntl} />
      <Text>{props.documentNumber && ` - ${props.documentNumber}`}</Text>
    </Box>
  );
}

export function DocumentCardNumber({
  Icon,
  children,
}: {
  children: React.ReactNode;
  Icon: React.ReactNode;
}) {
  return (
    <Flex sx={{ alignItems: 'center' }}>
      {Icon}

      <Text sx={{ ml: 4, fontSize: 'md', position: 'relative', top: '1px' }}>
        {children}
      </Text>
    </Flex>
  );
}

export function DocumentCardAvatarWrapper(props: {
  children: React.ReactNode;
}) {
  return (
    <Box
      sx={{
        gridArea: 'avatar',
        justifySelf: 'end',
        minWidth: 'fit-content',
      }}
    >
      {props.children}
    </Box>
  );
}

export function DocumentCardAddress(props: { children: React.ReactNode }) {
  return (
    <Text
      variant="subtitle"
      sx={{
        gridArea: 'address',
        color: 'secondary.500',
        height: '1.5em',
        lineHeight: 3,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {props.children}
    </Text>
  );
}

export function DocumentCardTemplateName(props: {
  templateId?: string;
  templateNameIntl: LocaleKey;
}) {
  const templateId = props.templateId && isIgnoreCaseEqual(props.templateId, 'pressure-test')
    ? 'PT'
    : props.templateId;

  return (
    <Flex
      gap={2}
      sx={{
        fill: 'tertiary1.300',
        alignItems: 'center',
        gridArea: 'templateName',
      }}
    >
      <Icons.Timer width="1em" />
      <Text variant="body3">
        <Text>{`${templateId} - `}</Text>
        <Text intlId={props.templateNameIntl} />
      </Text>
    </Flex>
  );
}

export function DocumentCardDate({
  dateCreated,
  dateFinished,
}: {
  dateFinished: string | undefined;
  dateCreated: string;
}) {
  const { localeDateFormat } = useLocaleDateFormat();

  return (
    <Text sx={{ fontSize: 'sm', flexShrink: 0 }}>
      {dateFinished
        ? localeDateFormat(dateFinished)
        : localeDateFormat(dateCreated)}
    </Text>
  );
}

export function DocumentCardHeader(props: {
  isCompleted: boolean;
  children: React.ReactNode;
}) {
  return (
    <Flex
      sx={{
        px: 4,
        py: 3,
        backgroundColor: props.isCompleted ? 'tertiary1.300' : 'secondary.900',
        justifyContent: 'space-between',
        color: 'white.50',
        alignItems: 'center',
        borderTopLeftRadius: 1,
        borderTopRightRadius: 1,
      }}
    >
      {props.children}
    </Flex>
  );
}

export function DocumentCardBody(props: { children: React.ReactNode }) {
  return (
    <Box
      sx={{
        backgroundColor: 'white.50',
        px: 4,
        py: 3,
        borderBottomLeftRadius: 1,
        borderBottomRightRadius: 1,
      }}
    >
      {props.children}
    </Box>
  );
}

export function DocumentCardDivider() {
  return <Divider sx={{ borderColor: '#E6E6E6' }} />;
}

function isDocumentCompleted(
  isCompleted: boolean,
  user: GetAllUsersResponseUsers | undefined,
): user is GetAllUsersResponseUsers {
  return isCompleted;
}

export function DocumentCardAvatar({
  completedBy,
  isCompleted,
  assignee,
}: {
  isCompleted: boolean;
  completedBy?: GetAllUsersResponseUsers;
  assignee?: GetAllUsersResponseUsers;
}) {
  if (isDocumentCompleted(isCompleted, completedBy) && Boolean(completedBy)) {
    return (
      <DocumentCardAvatarWrapper>
        <Avatar
          color={completedBy.user.avatarColor}
          firstName={completedBy.user.firstName}
          lastName={completedBy.user.lastName}
          active={completedBy.active}
          profileImageUrl={completedBy.profileImageUrl}
        />
      </DocumentCardAvatarWrapper>
    );
  }

  if (assignee) {
    return (
      <DocumentCardAvatarWrapper>
        <Avatar
          color={assignee.user.avatarColor}
          firstName={assignee.user.firstName}
          lastName={assignee.user.lastName}
          active={assignee.active}
          profileImageUrl={assignee.profileImageUrl}
        />
      </DocumentCardAvatarWrapper>
    );
  }

  return (
    <Box
      sx={{
        minWidth: 26,
        minHeight: 26,
        borderRadius: 'rounded',
        backgroundColor: 'disabledText',
      }}
    />
  );
}
