import { parseQuery } from './parse-query';
import { QueryRecord } from './types';
import { normalizeQuery } from './utils';

/**
 * Utility for creating a query string from an object of "key:value" pairs.
 * - "key" is the query param name
 * - "value" is the query param value
 *
 * @param query - The query object, or query string that you want to compose
 * @param updater - Updater object which will overwrite the properties in the query
 * */
export function composeQuery(
  query: QueryRecord | string,
  updater?: QueryRecord,
) {
  const parsedQuery = typeof query === 'string' ? parseQuery(query) : query;

  const searchQuery = new URLSearchParams(
    normalizeQuery({ ...parsedQuery, ...updater }),
  );

  return searchQuery.toString();
}
