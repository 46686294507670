import * as React from 'react';
import * as ReactRouter from 'react-router-dom';

import { Box, Flex } from 'components/layout';
import * as Tabs from 'components/Tabs';
import { Text } from 'components/typography';
import { Pressure } from 'pages/Pressure/Pressure';

import { useDocumentParams } from 'pages/Document/Document';
import { DocumentsService, DocumentTemplateId } from 'services/documents';
import { DocumentStatusEnum } from 'trace-backend-sdk';
import { DocumentRecipients } from 'pages/Document/components/DocumentEdit/components/DocumentRecipients';
import { QuickTracePage } from 'components/quick-trace/routes/QuickTracePage';
import { ReliningRoutes } from '../../../../features/relining';
import { LocaleKey } from '../../../../translations';
import { PageTitle } from '../../../../components/seo/PageTitle';
import { DocumentDetails } from './components/DocumentDetails';

const DOCUMENT_TITLE_INTL: Record<DocumentTemplateId, LocaleKey> = {
  relining: 'page.relining.view',
  'pressure-test': 'page.pressureTest.view',
  'quick-capture': 'page.quickCapture.view',
};

export function DocumentEdit() {
  const params = useDocumentParams();
  const match = ReactRouter.useRouteMatch();
  const { document } = DocumentsService.useGetDocument(
    params.documentId,
    params.templateId,
  );

  return (
    <Box
      sx={{
        maxWidth: 768,
        width: '100%',
        mx: 'auto',
        isolation: 'isolate',
      }}
    >
      <PageTitle
        titleIntl={DOCUMENT_TITLE_INTL[params.templateId]}
        intlValues={{
          documentId: params.documentId,
        }}
      />

      <Flex flexDirection="column">
        <Tabs.Wrapper>
          <Tabs.Tab to={`${match.url}/main`}>
            <Text intlId="viewDocument.main" />
          </Tabs.Tab>

          <Tabs.Tab to={`${match.url}/details`}>
            <Text intlId="viewDocument.details" />
          </Tabs.Tab>

          {document?.status === DocumentStatusEnum.Completed && (
            <Tabs.Tab to={`${match.url}/recipients`}>
              <Text intlId="viewDocument.recipients" />
            </Tabs.Tab>
          )}
        </Tabs.Wrapper>

        <ReactRouter.Switch>
          <ReactRouter.Route path={`${match.path}/main`}>
            {(() => {
              switch (params.templateId) {
                case 'pressure-test':
                  return <Pressure />;
                case 'quick-capture':
                  return <QuickTracePage />;
                case 'relining':
                  return <ReliningRoutes.ReliningPage />;
                default:
                  return null;
              }
            })()}
          </ReactRouter.Route>

          <ReactRouter.Route path={`${match.path}/details`}>
            <DocumentDetails />
          </ReactRouter.Route>

          <ReactRouter.Route path={`${match.path}/recipients`}>
            <DocumentRecipients />
          </ReactRouter.Route>

          <ReactRouter.Redirect to={`${match.path}/main`} />
        </ReactRouter.Switch>
      </Flex>
    </Box>
  );
}
