import * as React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { PageTitle } from 'components/seo/PageTitle';
import { LocaleKey } from 'translations';

type PageProps = RouteProps & {
  titleIntlId: LocaleKey;
  intlValues?: Record<string, string | number>;
};

/**
 * Component to be used instead of <Route />, to render a page title
 * at the given route.
 *
 * Usage is exactly the same as <Route />, except that it also accepts
 * a mandatory "titleIntlId" prop, which will become the title of the page.
 * */
export function Page({ titleIntlId, intlValues, ...props }: PageProps) {
  return (
    <React.Fragment>
      <PageTitle intlValues={intlValues} titleIntl={titleIntlId} />
      <Route {...props} />
    </React.Fragment>
  );
}
