import React from 'react';
import * as ReactRouterDom from 'react-router-dom';
import * as Pages from 'pages';
import { WorkspaceService } from 'services/workspace';
import { createRoutePath, Routing } from 'global/routing';
import * as Utils from 'utils';
import PwaPrompt from 'components/pwa-prompt';
import { TopBar } from 'components/menu/TopBar/TopBar';
import { SideMenu } from 'components/menu/SideMenu/SideMenu';
import { MainMenu } from 'components/menu/MainMenu/MainMenu';
import { Box, Flex } from 'components/layout';
import { Page } from 'components/seo/Page';
import {
  RunningTimers,
  RunningTimersBanner,
} from 'components/RunningTimersBanner/RunningTimersBanner';
import { PageLoadingIndicator } from 'components/PageLoadingIndicator/PageLoadingIndicator';
import { UsersService } from 'services/users';
import { SuccessConfirmation } from 'pages/Document/components/SuccessConfirmation';
import { NotificationMessagesButton } from 'components/messages/components/NotificationMessagesButton';
import { useNotificationMessagesWebsocket } from 'services/notifications/websocket/websocket';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import { NotificationsService } from 'services';
import { useBuildSafeSearchQuery } from 'utils/url';
import { useReferrerRedirect } from 'utils/hooks';
import { DocumentsService } from 'services/documents';
import { WorkspaceStatusEnum } from 'trace-backend-sdk';
import { IntlCustom } from '../../lib';
import { PermissionsRoutes } from '../../features/permissions';
import { PaymentsPage } from '../../features/payments/routes';
import { PaymentsRoutes } from '../../features/payments';
import { UsersRoutes } from '../../features/users';
import { GlobalLoadingIndicator } from '../../components/GlobalLoadingIndicator/GlobalLoadingIndicator';
import { useScrollToTopOnRouteChange } from '../../utils/hooks/use-scroll-to-top-on-route-change';
import { Authorization } from '../../components/authorization/Authorization';
import { WorkspacePastDueBanner } from '../../features/workspaces/components/WorkspacePastDueBanner';

const DemoPage = React.lazy(() => import('../../pages/Demo/index'));

export const Routes: React.FC = () => {
  const history = ReactRouterDom.useHistory();

  const { isAuthenticated } = Utils.Auth.useIsAuthenticated();

  const { workspaceData } = WorkspaceService.useGetWorkspace();

  const { currentUser, userAttributesFromToken } = Utils.Auth.useCurrentAuthenticatedUser();

  const { workspaceRedirectStatus } = Utils.Auth.useAuthenticateFromWorkspaceRedirect();
  const { isWorkspaceRedirectInProgress } = Utils.Auth.useIsWorkspaceRedirectInProgress();

  const { buildSafeSearchQuery } = useBuildSafeSearchQuery();

  if (isWorkspaceRedirectInProgress || workspaceRedirectStatus === 'rejected') {
    return null;
  }

  return (
    <ReactRouterDom.Switch>
      {/*Unprotected routes START*/}

      <Page titleIntlId="page.admin-panel" path={Routing.ADMIN.Default}>
        <Pages.Admin />
      </Page>

      <ReactRouterDom.Route path={Routing.DEMO.getPath()}>
        <React.Suspense fallback={<h1>Loading...</h1>}>
          <DemoPage />
        </React.Suspense>
      </ReactRouterDom.Route>

      <ReactRouterDom.Route
        path={Routing.CONFIRM_PASSWORD.getPath()}
        component={Pages.ConfirmPassword}
      />

      <ReactRouterDom.Route
        path={Routing.DOWNLOAD_CERTIFICATE.getPath()}
        component={Pages.Certificate}
      />

      {!isAuthenticated && (
        <ReactRouterDom.Switch>
          {/*TODO: Move to protected routes once the forgot password BE logic is done */}
          <ReactRouterDom.Route
            exact
            path={Routing.TRANSITIONS.getPasswordInstructionsPath()}
            component={Pages.PasswordInstructions}
          />

          <ReactRouterDom.Route
            exact
            path={Routing.LOGIN.getPath()}
            component={Pages.Login}
          />

          <ReactRouterDom.Route
            exact
            path={Routing.REGISTER.getPath()}
            component={Pages.Register}
          />

          <ReactRouterDom.Route
            exact
            path={Routing.REGISTER.getRegisterInvitedPath()}
            component={Pages.RegisterInvited}
          />

          <ReactRouterDom.Route
            path={Routing.FORGOT_PASSWORD.getPath()}
            component={Pages.ForgotPassword}
          />

          <ReactRouterDom.Redirect
            to={{
              pathname: Routing.LOGIN.getPath(),
              search: buildSafeSearchQuery({
                referrer: history.location.pathname,
              }),
            }}
          />
        </ReactRouterDom.Switch>
      )}

      {/*Unprotected routes END*/}

      {/*Locks START*/}
      {/*
        Render the create organization route only when
        the user is logged in, but has no workspace
      */}
      {currentUser != null && userAttributesFromToken?.tenant_id == null && (
        <ReactRouterDom.Route
          exact
          path={Routing.CREATE_ORGANISATION.getPath()}
          component={Pages.CreateWorkspace}
        />
      )}

      {/*
        It's important that this route is defined before the force
        redirect of "create organization", to avoid being locked by it
      */}
      <ReactRouterDom.Route
        exact
        path={Routing.TRANSITIONS.getCreateWorkspaceSuccessPath()}
      >
        <Pages.Congratulations />
      </ReactRouterDom.Route>

      {/* Lock user to the create workspace success screen while their workspace is being verified */}
      {workspaceData != null && !workspaceData.ready && (
        <ReactRouterDom.Redirect
          to={Routing.TRANSITIONS.getCreateWorkspaceSuccessPath()}
        />
      )}

      {/*
        If there is a logged in user, but they have no tenant ID,
        this means that the user must create an organization
        (they need to be locked to the create organization screen)
       */}
      {currentUser != null
        && userAttributesFromToken?.tenant_id == null
        && history.location.pathname !== Routing.CREATE_ORGANISATION.getPath() && (
          <ReactRouterDom.Redirect to={Routing.CREATE_ORGANISATION.getPath()} />
      )}

      <ReactRouterDom.Route path={Routing.TRANSITIONS.SuccessConfirmation}>
        <SuccessConfirmation />
      </ReactRouterDom.Route>

      {/*Protected routes START*/}
      <MainApplicationRoutes />
      {/* Protected routes END */}
    </ReactRouterDom.Switch>
  );
};

/**
 * Routes which contain the core flow of the application.
 * This is where we render the TopBar, SideMenu and the MainMenu.
 *
 * Note that the main application routes are implicitly protected,
 * meaning they cannot be accessed by unauthenticated users.
 * */
function MainApplicationRoutes() {
  const appContainerRef = React.useRef<HTMLDivElement | null>(null);
  useScrollToTopOnRouteChange(appContainerRef.current);

  const { loggedInUser } = UsersService.useGetLoggedInUser();
  const { workspaceData } = WorkspaceService.useGetWorkspace();
  const intl = IntlCustom.useIntl();

  NotificationsService.useGetAllNotifications();
  DocumentsService.useGetAllTemplates();

  useInitializeReferrerRoute();
  useNotificationMessagesWebsocket();

  if (!workspaceData) {
    return <PageLoadingIndicator />;
  }

  if (
    workspaceData.isStripeIntegrationSetup === false
    && workspaceData.status !== WorkspaceStatusEnum.ForeverFree
  ) {
    return (
      <Authorization
        renderOnAuthFail={(
          <ReactRouterDom.Redirect
            to={Routing.TRANSITIONS.getCreateWorkspaceSuccessPath()}
          />
        )}
        roles={['admin']}
      >
        <PaymentsPage />
      </Authorization>
    );
  }

  return (
    <Flex sx={{ height: '100%', isolation: 'isolate' }}>
      <SideMenu />

      <Flex
        sx={{
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <WorkspacePastDueBanner />

        <TopBar>
          <NotificationMessagesButton />
        </TopBar>

        <RunningTimersBanner />

        <Box
          ref={appContainerRef}
          sx={{
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            overflowY: 'auto',
            position: 'relative',
            backgroundColor: 'transparent',
          }}
        >
          <ErrorBoundary>
            <ReactRouterDom.Switch>
              <Page
                titleIntlId="page.notifications"
                path={Routing.MESSAGES.Default}
              >
                <ErrorBoundary>
                  <Pages.Messages />
                </ErrorBoundary>
              </Page>

              <Page
                titleIntlId="menu.main.home"
                exact
                path={Routing.HOME.getPath()}
                component={Pages.Home}
              />

              <Page
                titleIntlId="page.inviteUsers"
                path={Routing.USERS.InviteUsers.path}
                component={UsersRoutes.InviteNewUsersPage}
              />

              <Page
                titleIntlId="page.paymentHistory"
                path={Routing.PAYMENTS.PaymentHistory.path}
                component={PaymentsRoutes.PaymentHistoryPage}
              />

              <Page
                titleIntlId="billing.sideMenu.managePayment"
                path={Routing.PAYMENTS.ManagePaymentInfo.path}
                component={PaymentsRoutes.ManagePaymentInformationPage}
              />

              <Page
                titleIntlId="billing.sideMenu.manageSubscription"
                path={Routing.PAYMENTS.ManageSubscription.path}
                component={PaymentsRoutes.ManageSubscription}
              />

              <Page
                titleIntlId="page.discountSchedule"
                path={Routing.PAYMENTS.Billing.DiscountSchedule.path}
                component={PaymentsRoutes.DiscountSchedulePage}
              />

              <Page
                titleIntlId="page.upcomingPayment"
                path={Routing.PAYMENTS.UpcomingPayment.path}
                component={PaymentsRoutes.UpcomingPaymentPage}
              />

              <Page
                titleIntlId="page.userPermissions.manageProducts"
                path={Routing.PERMISSIONS.Default.path}
                component={PermissionsRoutes.UserPermissionsPage}
              />

              <Page
                titleIntlId="page.pressure.runningTimers"
                path={Routing.PRESSURE.Running}
              >
                <RunningTimers />
              </Page>

              <Page
                titleIntlId="menu.main.documents"
                path={createRoutePath(
                  Routing.DOCUMENTS.default,
                  Routing.DOCUMENTS.Params.project,
                )}
                component={Pages.Documents}
              />

              <ReactRouterDom.Redirect
                from="/documents"
                to={createRoutePath(
                  Routing.DOCUMENTS.default,
                  Routing.DOCUMENTS.DefaultProject,
                )}
              />

              <ReactRouterDom.Route
                path={createRoutePath(
                  Routing.DOCUMENT.default,
                  Routing.DOCUMENT.Params.templateId,
                  Routing.DOCUMENT.Params.documentId,
                )}
                component={Pages.Document}
              />

              <ReactRouterDom.Route
                exact
                path={Routing.DOCUMENT.Create.path}
                component={Pages.DocumentCreate}
              />

              <ReactRouterDom.Redirect
                to="/users/active"
                from={Routing.USERS.default}
                exact
              />

              <Page
                titleIntlId="users.pageTitle"
                exact
                path={Routing.USERS.getPath()}
                component={Pages.Users}
              />

              <ReactRouterDom.Route
                exact
                path={Routing.SIGN_OUT.getPath()}
                component={Pages.SignOut}
              />

              {/*TODO: Change to UserRole from backend*/}
              {loggedInUser?.role === 'admin' && (
                <ReactRouterDom.Route
                  exact
                  path={Routing.SETTINGS.getPath()}
                  component={Pages.Settings}
                />
              )}

              <ReactRouterDom.Route
                path={Routing.PROFILE.default}
                component={Pages.Profile}
              />

              <ReactRouterDom.Route path={Routing.SEARCH.Default}>
                <Pages.Search />
              </ReactRouterDom.Route>

              <ReactRouterDom.Route path="/404">
                <Pages.Page404 />
              </ReactRouterDom.Route>

              <ReactRouterDom.Redirect to={Routing.HOME.getPath()} />
            </ReactRouterDom.Switch>
          </ErrorBoundary>
        </Box>

        <MainMenu />
      </Flex>
      <GlobalLoadingIndicator />
      <PwaPrompt
        copyIosChrome={intl.formatMessage({
          id: 'addToHome.iosChrome',
        })}
        copyTitle={intl.formatMessage({
          id: 'addToHome.title',
        })}
        copyBody={intl.formatMessage({
          id: 'addToHome.description',
        })}
        copyClosePrompt={intl.formatMessage({
          id: 'addToHome.cancel',
        })}
      />
    </Flex>
  );
}

/*
 * Initialize the app with a referrer route (if present),
 * instead of the default navigation to the home page.
 * */
function useInitializeReferrerRoute() {
  const { onReferrerRedirect } = useReferrerRedirect();

  React.useEffect(() => {
    onReferrerRedirect(undefined, 'replace');
  }, [onReferrerRedirect]);
}
