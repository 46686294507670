import { WidgetProps } from '@rjsf/core';
import * as React from 'react';
import { Input } from 'components/form-elements/inputs';
import { StyleObject } from 'theming';

type Props = {
  sx?: StyleObject;
  placeholderIntlId?: string;
};

export function UnderlinedInput({
  onFocus,
  onChange,
  onBlur,
  id,
  options,
  placeholderIntlId,
  ...props
}: WidgetProps & Props) {
  const _onChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) =>
    onChange(value === '' ? options.emptyValue : value);
  const _onBlur = ({ target: { value } }: React.FocusEvent<HTMLInputElement>) =>
    onBlur(id, value);
  const _onFocus = ({
    target: { value },
  }: React.FocusEvent<HTMLInputElement>) => onFocus(id, value);

  return (
    <Input
      {...props}
      onChange={_onChange}
      onBlur={_onBlur}
      onFocus={_onFocus}
      variant="underlined"
      placeholderIntlId={placeholderIntlId}
    />
  );
}
