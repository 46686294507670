import * as React from 'react';
import * as Icons from 'components/icons';
import Select, { OptionTypeBase, SingleValueProps } from 'react-select';
import { Flex } from 'components/layout';
import { Text } from 'components/typography';
import { Button } from '../../../components/form-elements';

type ReliningTemperatureSelectProps = {
  value: number;
  onChange: (value: number) => void;
  options: number[];
  disabled?: boolean;
};

function mapOption(option: number) {
  return { label: String(option), value: option };
}

export function ReliningTemperatureSelectField({
  value,
  onChange,
  options,
  disabled,
}: ReliningTemperatureSelectProps) {
  function onIncrement() {
    onChange(value + 1);
  }

  function onDecrement() {
    onChange(value - 1);
  }

  const selectOptions = options.map(mapOption);

  return (
    <ReliningTemperatureSelectWrapper>
      <IncrementTemperature disabled={disabled} onClick={() => onIncrement()} />
      <Select
        isDisabled={disabled}
        components={{ SingleValue }}
        styles={{
          menu: (base) => ({
            ...base,
            height: '310px',
          }),
          container: (base) => ({
            ...base,
            display: 'flex',
            width: '104px',
            height: '104px',
            borderRadius: '50%',
          }),
          indicatorSeparator() {
            return { display: 'none' };
          },
          dropdownIndicator() {
            return { display: 'none' };
          },
          control: (base) => ({
            ...base,
            flex: 1,
            width: '104px',
            backgroundColor: '#fff',
            borderRadius: '50%',
            padding: '5px',
            borderColor: 'transparent',
            boxShadow:
              '0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.20)',
            '&:hover': {
              borderColor: 'transparent',
            },
          }),

          valueContainer: (base) => ({
            ...base,
            justifyContent: 'center',
            padding: '5px 0px',
          }),
          input: (base) => ({
            ...base,
            color: '#4D4D65B3',
            fontSize: '40px',
            width: '72px',
            textAlign: 'center',
          }),
          placeholder(base, state) {
            return {
              ...base,
              display:
                state.isFocused || state.selectProps.inputValue
                  ? 'none'
                  : 'block',
            };
          },
          option: (base) => ({
            ...base,
            color: '#4D4D65B3',
            fontSize: '16px',
          }),
        }}
        value={selectOptions.filter((option) => option.value === value)}
        options={selectOptions}
        onChange={(value) => {
          if (!value) return;
          onChange(value?.value);
        }}
        isSearchable={false}
      />
      <DecrementTemperature disabled={disabled} onClick={() => onDecrement()} />
    </ReliningTemperatureSelectWrapper>
  );
}

export function ReliningTemperatureSelectWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        position: 'relative',
        borderRadius: '50%',
        backgroundColor: '#E3E9EF',
        boxShadow: '12dp',
        width: '170px',
        height: '170px',
      }}
    >
      {children}
    </Flex>
  );
}

const SingleValue = (props: SingleValueProps<OptionTypeBase>) => (
  <React.Fragment>
    <Text
      {...props}
      as="p"
      sx={{
        color: '#8686A1',
        fontSize: '40px',
        fontWeight: 200,
      }}
    />
    <Text
      as="span"
      sx={{
        color: '#8686A1',
        fontSize: '30px',
        paddingBottom: '15px',
        fontWeight: 300,
      }}
    >
      &#176;
    </Text>
  </React.Fragment>
);

function IncrementTemperature({
  onClick,
  disabled,
}: {
  onClick?: React.MouseEventHandler;
  disabled?: boolean;
}) {
  return (
    <Button
      disabled={disabled}
      type="button"
      sx={{
        '&:disabled': {
          backgroundColor: 'transparent',
        },
        p: 0,
        width: 'fit-content',
        position: 'absolute',
        top: '12px',
        cursor: 'pointer',
        backgroundColor: 'transparent',
        border: 'none',
      }}
      onClick={onClick}
    >
      <Icons.ChevronUp
        width={20} color="#9e9eaf"
        strokeWidth="3"
      />
    </Button>
  );
}

function DecrementTemperature({
  onClick,
  disabled,
}: {
  onClick?: React.MouseEventHandler;
  disabled?: boolean;
}) {
  return (
    <Button
      disabled={disabled}
      variant="text"
      type="button"
      sx={{
        p: 0,
        position: 'absolute',
        bottom: '12px',
        cursor: 'pointer',
        backgroundColor: 'transparent',
        border: 'none',
      }}
      onClick={onClick}
    >
      <Icons.ChevronDown
        width={20} color="#9e9eaf"
        strokeWidth="2"
      />
    </Button>
  );
}
