import React from 'react';
import { Card as GlobalCard } from 'components/cards';
import { CardProps } from 'components/cards/Card/Card';
import { Text, TextProps } from 'components/typography';
import { Avatar, AvatarProps } from 'components/avatar';

type UserCardLayoutType = React.FC<CardProps> & {
  Avatar: React.ElementType<AvatarProps>;
  Name: React.ElementType<TextProps>;
  Email: React.ElementType<TextProps>;
  Title: React.ElementType<TextProps>;
};

export const UserCard: UserCardLayoutType = ({
  children,
  intlId,
  sx,
  onClick,
}) => (
  <GlobalCard
    variant="user" intlId={intlId}
    sx={sx} onClick={onClick}
  >
    {children}
  </GlobalCard>
);

UserCard.Avatar = Avatar;
UserCard.Name = Text;
UserCard.Email = Text;
UserCard.Title = Text;
