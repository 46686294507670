import * as ReactQuery from 'react-query';
import { InferMutationOptions } from '../../../utils/types';
import { updateWorkspaceApi } from './api';

export function useUpdateWorkspace(
  options?: InferMutationOptions<typeof updateWorkspaceApi>,
) {
  const { mutate: updateWorkspace, ...rest } = ReactQuery.useMutation(
    ['updateWorkspace'],
    updateWorkspaceApi,
    options,
  );

  return {
    updateWorkspace,
    ...rest,
  };
}
