import React from 'react';

import { Box, Flex } from 'components/layout';
import { Text } from 'components/typography';
import { Link } from 'components/links';
import { LocaleKey } from 'translations';
import * as ThemeUI from 'theme-ui';
import { GenericFn } from 'utils/types';

export function SideMenuFooter({ children }: { children: React.ReactNode }) {
  return (
    <Box
      sx={{
        px: 8,
        py: 6,
        mt: 'auto',
        boxShadow: '8dp',
        width: '100%',
        backgroundColor: 'bg.50',
      }}
    >
      {children}
    </Box>
  );
}

export function MenuItemWithIcon({
  to,
  intlId,
  onClick,
  icon,
}: {
  to?: string;
  intlId: LocaleKey;
  onClick?: GenericFn;
  icon: React.ReactNode;
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#9e9eaf21',
        },
      }}
    >
      {to ? (
        <Link
          onClick={onClick}
          variant="basic"
          to={to}
          sx={{ display: 'flex', py: 2, flex: '1' }}
        >
          <Box
            sx={{
              minWidth: '30px',
              maxWidth: '30px',
              display: 'flex',
              justifyContent: 'center',
              marginRight: '12px',
            }}
          >
            {icon}
          </Box>
          <Text
            sx={{
              color: 'darkgrey.800',
              fontSize: 'lg',
            }}
            variant="sideMenuLight"
            intlId={intlId}
          />
        </Link>
      ) : (
        <ThemeUI.Button
          onClick={onClick}
          variant="text"
          sx={{ padding: 0, display: 'flex', py: 2, flex: '1' }}
        >
          <Box
            sx={{
              minWidth: '30px',
              maxWidth: '30px',
              display: 'flex',
              justifyContent: 'center',
              marginRight: '12px',
            }}
          >
            {icon}
          </Box>
          <Text
            sx={{
              color: 'darkgrey.800',
              fontSize: 'lg',
            }}
            variant="sideMenuLight"
            intlId={intlId}
          />
        </ThemeUI.Button>
      )}
    </Box>
  );
}

export function SideMenuFooterMenuHeading({
  icon,
  title,
  chevronIcon,
  toggleFooterMenuVisibility,
  isTextOverflowHidden,
}: {
  icon: React.ReactNode;
  chevronIcon: React.ReactNode;
  title: string;
  toggleFooterMenuVisibility: React.MouseEventHandler<HTMLButtonElement>;
  isTextOverflowHidden: boolean;
}) {
  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      <ThemeUI.Button
        onClick={toggleFooterMenuVisibility}
        variant="text"
        sx={{
          display: 'flex',
          alignItems: 'center',
          p: 0,
          textAlign: 'start',
        }}
      >
        <Box sx={{ marginRight: '12px', minWidth: 'auto' }}>{icon}</Box>

        <Text
          sx={{
            mr: 2,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            marginRight: '12px',
            color: 'primary.900',
            fontWeight: '500',
            fontSize: isTextOverflowHidden ? '16px' : '14px',
          }}
          variant={isTextOverflowHidden ? 'singleLine' : 'multiLine'}
        >
          {title}
        </Text>
        <Flex
          sx={{
            alignSelf: 'flex-start',
            alignItems: 'center',
            justifyContent: 'center',
            width: 24,
            height: 24,
            position: 'relative',
            top: '1px',
          }}
        >
          {chevronIcon}
        </Flex>
      </ThemeUI.Button>
    </Box>
  );
}
