export const DISCOUNT_TABLE_BRACKETS = [
  {
    fromCents: 0,
    toCents: 999_00,
    discountPercentage: 2,
  },
  {
    fromCents: 1000_00,
    toCents: 1999_00,
    discountPercentage: 4,
  },
  {
    fromCents: 2000_00,
    toCents: 2999_00,
    discountPercentage: 6,
  },
  {
    fromCents: 3000_00,
    toCents: 3999_00,
    discountPercentage: 8,
  },
  {
    fromCents: 4000_00,
    toCents: 3999_00,
    discountPercentage: 10,
  },
  {
    fromCents: 5000_00,
    toCents: 7499_00,
    discountPercentage: 12,
  },
  {
    fromCents: 7500_00,
    toCents: 9999_00,
    discountPercentage: 14,
  },
  {
    fromCents: 10000_00,
    toCents: 12499_00,
    discountPercentage: 16,
  },
  {
    fromCents: 12500_00,
    toCents: 14999_00,
    discountPercentage: 18,
  },
  {
    fromCents: 15000_00,
    toCents: 19999_00,
    discountPercentage: 20,
  },
  {
    fromCents: 20000_00,
    toCents: 24999_00,
    discountPercentage: 22,
  },
  {
    fromCents: 25000_00,
    toCents: Infinity,
    discountPercentage: 25,
  },
];
