import axios from 'axios';
import { identify, makeRequest, Service } from 'utils/http';

type Data = {
  profileImage: File;
  userId?: string;
};

export async function updateUserProfileImageApi(data: Data) {
  if (!data.userId) return;

  const { updateImageUrl } = await makeRequest(
    Service.updateUserProfileImage(await identify()),
  );

  await axios.put(updateImageUrl, data.profileImage);
}
