import React from 'react';
import * as ThemeUI from 'theme-ui';
import { Text } from '../typography';
import { FieldEditTextProps } from './Forms/FieldEditText';

export function Field(props: {
  label: string;
  isError: boolean;
  required: boolean;
  name: string;
  isLabelAlwaysLifted: boolean;
  children: React.ReactNode | React.ReactNodeArray;
  isActive: boolean;
  hasValue: boolean;
  isPlaceholderVisible?: boolean;
  isHoverEffectVisible?: boolean;
  backgroundColor?: FieldEditTextProps['backgroundColor'];
  /*
   * ThemeUI select wraps a div around the select box which
   * is not targetable by sx prop. So we need to target it
   * via the select wrapper when it is a ThemeUI select.
   * */
  isThemeUiSelect?: boolean;
  helperText?: React.ReactNode;
}) {
  const isLabelLifted = props.label
    && (props.isLabelAlwaysLifted || props.isActive || props.hasValue);

  return (
    <ThemeUI.Box>
      <ThemeUI.Box
        sx={{
          width: '100%',
          position: 'relative',
          display: 'inline-flex',
          flexDirection: 'column',
          isolation: 'isolate',
          backgroundColor: props.backgroundColor ?? 'white.100',
          ...(props.isHoverEffectVisible && {
            '@media screen and (min-width: 1280px)': {
              '&:hover': {
                '> .field-label': {
                  transform: 'translate(14px, -6px) scale(0.75)',
                  opacity: 1,
                  color: getLabelColor({
                    isError: props.isError,
                    isActive: props.isActive,
                    isHover: true,
                  }),
                },
              },
            },
          }),
        }}
      >
        <ThemeUI.Label
          className="field-label"
          htmlFor={props.name}
          sx={{
            fontSize: '1rem',
            lineHeight: 1,
            letterSpacing: '0.00938em',
            padding: '0px',
            position: 'absolute',
            transformOrigin: 'top left',
            transform: 'translate(14px, 20px) scale(1)',
            ...(isLabelLifted && {
              transform: 'translate(14px, -6px) scale(0.75)',
            }),
            pointerEvents: 'none',
            top: 0,
            left: 0,
            zIndex: 1,
            transition:
              // eslint-disable-next-line max-len
              'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,opacity 200ms cubic-bezier(0.0, 0, 0.2, 1)',
            opacity:
              isLabelLifted || props.isError
                ? 1
                : props.isPlaceholderVisible
                  ? 0
                  : 0.4,
            color: getLabelColor({
              isError: props.isError,
              isActive: props.isActive,
            }),

            ...(props.required && {
              '&::after': {
                content: '"*"',
                pl: 1,
              },
            }),
          }}
        >
          {props.label}
        </ThemeUI.Label>

        <ThemeUI.Box
          sx={{
            position: 'relative',
            borderRadius: '4px',
            display: 'inline-flex',
            fontSize: '1rem',
            boxSizing: 'border-box',
            lineHeight: '1.1876em',

            ...(props.isHoverEffectVisible && {
              '@media screen and (min-width: 1280px)': {
                '&:hover': {
                  '> .field-border, .field-border:hover': {
                    borderColor: getBorderColor({
                      isError: props.isError,
                      isActive: props.isActive,
                      isHover: true,
                    }),
                    borderWidth: '2px',
                  },
                  '.field-legend': {
                    pr: '10px',
                    maxWidth: 'unset',
                  },
                },
              },
            }),

            ...(props.isThemeUiSelect && {
              '& > div': {
                width: '100%',
              },
            }),
          }}
        >
          {props.children}

          <ThemeUI.Box
            className="field-border"
            sx={{
              position: 'absolute',
              top: '-5px',
              left: 0,
              right: 0,
              bottom: 0,
              padding: '0 8px',
              paddingRight: '0px',
              pointerEvents: 'none',
              borderStyle: 'solid',
              borderWidth: props.isActive ? '2px' : '1px',
              borderRadius: 'inherit',
              borderColor: getBorderColor({
                isError: props.isError,
                isActive: props.isActive,
              }),
            }}
            as="fieldset"
          >
            <ThemeUI.Box
              className="field-legend"
              sx={{
                width: 'auto',
                height: '11px',
                display: 'block',
                padding: 0,
                textAlign: 'left',
                visibility: 'hidden',
                fontSize: '0.75em',
                transition: 'max-width 50ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
                ...(isLabelLifted && {
                  pr: props.required ? '18px' : '10px',
                }),
                ...(!isLabelLifted && {
                  maxWidth: '0.01px',
                }),
              }}
              as="legend"
            >
              {props.label}
            </ThemeUI.Box>
          </ThemeUI.Box>
        </ThemeUI.Box>
      </ThemeUI.Box>

      {props.helperText && !props.isError && (
        <Text
          as="p"
          sx={{
            mt: 1,
            px: 1,
            lineHeight: 2,
            fontSize: '12px',

            '& > *': {
              fontSize: '12px',
            },
          }}
        >
          {props.helperText}
        </Text>
      )}
    </ThemeUI.Box>
  );
}

function getLabelColor({ isError = false, isActive = false, isHover = false }) {
  if (isError) {
    return 'error';
  }

  if (isActive) {
    return 'secondary.500';
  }

  if (isHover) {
    return 'secondary.500';
  }

  return 'inherit';
}

function getBorderColor({
  isError = false,
  isActive = false,
  isHover = false,
}) {
  if (isError) {
    return 'error';
  }

  if (isActive) {
    return 'secondary.500';
  }

  if (isHover) {
    return 'secondary.500';
  }

  return '#9E9EAF';
}
