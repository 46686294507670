import * as _ from 'lodash-es';

/*
 * Because JSON form field names are composed of the "actual" name
 * preceded by the name of the form, we need this function to
 * obtain the "actual" name of the field if needed.
 *
 * Example:
 * The form which renders the field called "image" is called "quick-capture".
 * The name of the field as defined in the JSON schema is "image", but the
 * name of the associated form input will be "quick-capture.image".
 *
 * "image" is the "actual" or "out-of-context" name of the field, while
 * "quick-capture.image" is the full name, or the "in-context" one.
 * */
export function getOutOfContextFieldName(name: string) {
  const result = _.last(name.split('.'));

  if (!result) {
    throw new Error(`Invalid field name ${name}`);
  }

  return result;
}
