import * as ReactQuery from 'react-query';
import { InferQueryOptions } from 'utils/types';
import { adminGetWorkspaceApi } from 'services/admin/admin-get-workspace/api';

export const QUERY_KEY_ADMIN_GET_WORKSPACE = (workspaceId: string) => [
  'admin-get-workspace',
  workspaceId,
];

export function useAdminGetWorkspace(
  workspaceId: string,
  options?: InferQueryOptions<typeof adminGetWorkspaceApi>,
) {
  const { data, ...rest } = ReactQuery.useQuery(
    QUERY_KEY_ADMIN_GET_WORKSPACE(workspaceId),
    () => adminGetWorkspaceApi({ workspaceId }),
    options,
  );

  return {
    workspace: data,
    ...rest,
  };
}
