import { radii } from './radii';
import { breakpoints } from './breakpoints';
import { colors } from './colors';
import { root } from './root';
import { typography } from './typography';
import { spacing } from './spacing';
import { shadows } from './shadows';
import { zIndices } from './z-indices';

export const foundations = {
  breakpoints,
  colors,
  shadows,
  radii,
  styles: {
    root,
  },
  sizes: {
    container: '1024px',
    containerNarrow: '768px',
    ...spacing,
  },
  space: spacing,
  zIndices,
  ...typography,
};
