import { set } from 'lodash/fp';
import { GlobalState } from 'little-state-machine';

export function setIsAuthenticated(state: GlobalState, payload: boolean) {
  return set('isAuthenticated', payload, state);
}

export function setCurrentUserId(state: GlobalState, payload: string | null) {
  return set('currentUserId', payload, state);
}

export function setIsWorkspaceRedirectInProgress(
  state: GlobalState,
  payload: boolean,
) {
  return set('isWorkspaceRedirectInProgress', payload, state);
}
