import * as React from 'react';
import * as ReactRouter from 'react-router-dom';
import { Box, Container, Flex } from 'components/layout';
import ManOnBoat from 'assets/images/ManOnBoat.svg';
import { Text } from 'components/typography';
import { Image } from 'components/Image';
import { Button } from 'components/form-elements';
import { Routing } from 'global/routing';

export function Page404() {
  const history = ReactRouter.useHistory();

  return (
    <Container
      variant="page"
      sx={{
        height: '100%',
        backgroundColor: 'white.50',
      }}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          maxWidth: 300,
          height: '100%',
        }}
      >
        <Box
          sx={{
            textAlign: 'center',
            fontWeight: 500,
            color: 'primary.900',
            fontSize: '5xl',
            lineHeight: 1,
          }}
        >
          <Text sx={{ fontSize: '60px' }}>404</Text>
          <br />
          <Text intlId="404.pageNotFound" />
        </Box>

        <Box sx={{ my: 48 }}>
          <Image src={ManOnBoat} sx={{ width: '100%' }} />
        </Box>

        <Button
          intlId="404.goHome"
          onClick={() => {
            history.replace(Routing.HOME.getPath());
          }}
        />
      </Flex>
    </Container>
  );
}
