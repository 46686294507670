import { Box, Flex } from 'components/layout';
import { Image } from 'components/Image';
import TraceLogo from 'assets/images/default_logo.svg';
import { Text } from 'components/typography';
import { Link } from 'components/links';
import { Button } from 'components/form-elements';
import React from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { useRollbar } from 'lib/rollbar';

function ErrorFallback() {
  return (
    <Flex
      sx={{
        flex: 1,
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          backgroundColor: 'secondary.050',
          p: 6,
          borderRadius: 1,
          maxWidth: 320,
          alignSelf: 'center',
          mx: 'auto',
        }}
      >
        <Box sx={{ mb: 6 }}>
          <Image src={TraceLogo} />
        </Box>

        <Text
          sx={{ fontWeight: 600, fontSize: 'lg' }}
          as="h1"
          intlId="error-boundary.title"
        />

        <Text
          as="p"
          sx={{ fontSize: 'md', my: 2 }}
          intlId="error-boundary.description"
          intlValues={{
            emailLink: (
              <Link
                to="#"
                variant="default"
                onClick={(e) => {
                  window.location = 'mailto:trace@support.com' as any;
                  e.preventDefault();
                }}
              >
                info@tra-ce.com
              </Link>
            ),
          }}
        />

        <Button
          onClick={() => {
            window.location.reload();
          }}
          sx={{ fontSize: 'md', mt: 6 }}
          variant="secondary"
          intlId="error-boundary.action-button"
        />
      </Box>
    </Flex>
  );
}

export function ErrorBoundary({ children }: { children: React.ReactNode }) {
  const rollbar = useRollbar();

  return (
    <ReactErrorBoundary
      onError={(error) => {
        rollbar.error(error);
      }}
      FallbackComponent={ErrorFallback}
    >
      {children}
    </ReactErrorBoundary>
  );
}
