import { useCreateWorkspace } from 'services/workspace/create-workspace';
import { useGetWorkspace } from 'services/workspace/get-workspace';
import { useUpdateWorkspaceImage } from 'services/workspace/update-workspace-image';
import { useUpdateWorkspace } from './update-workspace/hook';

export const WorkspaceService = {
  useCreateWorkspace,
  useGetWorkspace,
  useUpdateWorkspaceImage,
  useUpdateWorkspace,
};
