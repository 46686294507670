import * as TraceUtils from '@trace/utils';
import { FieldProps } from '@rjsf/core';
import { parseInt } from 'lodash-es';
import React from 'react';
import { DocumentSectionWrapper } from '../../document/components/DocumentSectionWrapper/DocumentSectionWrapper';
import { Box } from '../../../components/layout';
import { Text } from '../../../components/typography';
import { Spacer } from '../../../components/Spacer/Spacer';
import { LocaleKey } from '../../../translations';

export function ReliningCalculator(props: FieldProps) {
  function getCalculatorValues() {
    if (
      props.formData.epoxyType
      && props.formData.dimensionMillimeters
      && props.formData.materials
      && props.formData.lengthOfPipe
    ) {
      const epoxyType = TraceUtils.Relining.EpoxyTypeParser.parse(
        props.formData.epoxyType,
      );
      const material = TraceUtils.Relining.MaterialParser.parse(
        props.formData.materials,
      );

      const formatNumber = (num: number) => parseFloat(num.toPrecision(3));

      const {
        kiloTotal,
        kiloCompositionB,
        kiloCompositionA,
        kiloPerMeter,
        rollerDimensions,
      } = TraceUtils.Relining.getCalculatorValues({
        epoxyType,
        material,
        length: parseFloat(props.formData.lengthOfPipe),
        dimensionMillimeters: parseInt(props.formData.dimensionMillimeters, 10),
      });

      return {
        kiloTotal: formatNumber(kiloTotal),
        kiloCompositionA: formatNumber(kiloCompositionA),
        kiloCompositionB: formatNumber(kiloCompositionB),
        kiloPerMeter: formatNumber(kiloPerMeter),
        rollerDimensions: formatNumber(rollerDimensions),
      };
    }

    return {
      kiloTotal: 0,
      rollerDimensions: 0,
      kiloCompositionB: 0,
      kiloCompositionA: 0,
      kiloPerMeter: 0,
    };
  }

  const calculatorValues = getCalculatorValues();

  return (
    <DocumentSectionWrapper>
      <props.registry.fields.ObjectField {...props} />

      <React.Fragment>
        <Spacer y={8} />
        <CalculatorResultsList {...calculatorValues} />
      </React.Fragment>
    </DocumentSectionWrapper>
  );
}

type CalculatorResultsListProps = ReturnType<
  typeof TraceUtils.Relining.getCalculatorValues
>;
function CalculatorResultsList({
  kiloCompositionA,
  kiloCompositionB,
  kiloPerMeter,
  rollerDimensions,
  kiloTotal,
}: CalculatorResultsListProps) {
  return (
    <Box
      sx={{
        '--padding-x': (theme) => `${theme.space?.[6]}px`,
        '--radius': (theme) => theme.radii?.[2],

        borderRadius: 'var(--radius)',
        color: 'darkgrey.800',
      }}
    >
      <Box
        sx={{
          borderTopLeftRadius: 'var(--radius)',
          borderTopRightRadius: 'var(--radius)',
          backgroundColor: '#E6EAF1',
          px: 'var(--padding-x)',
          py: 4,
          boxShadow: '0px 3px 6px inset #00000029',
        }}
      >
        <Text
          as="h2"
          intlId="relining.calculatorResults.title"
          sx={{ fontSize: '2xl', fontWeight: 400 }}
        />
      </Box>

      <Box
        as="ul"
        sx={{
          boxShadow: '0px -2px 6px inset #DEDEDEFF',
          borderBottomLeftRadius: 'var(--radius)',
          borderBottomRightRadius: 'var(--radius)',

          listStyle: 'none',
          p: 0,

          backgroundColor: 'bg.50',
          px: 'var(--padding-x)',
          py: 6,
        }}
      >
        <CalculatorResultsListItem label="relining.calculatorResults.kiloPerMeter">
          {kiloPerMeter}
        </CalculatorResultsListItem>

        <CalculatorResultsListItem label="relining.calculatorResults.kiloTotal">
          {kiloTotal}
        </CalculatorResultsListItem>

        <CalculatorResultsListItem label="relining.calculatorResults.kiloCompA">
          {kiloCompositionA}
        </CalculatorResultsListItem>

        <CalculatorResultsListItem label="relining.calculatorResults.kiloCompB">
          {kiloCompositionB}
        </CalculatorResultsListItem>

        <CalculatorResultsListItem label="relining.calculatorResults.rollerDimensions">
          {rollerDimensions}
        </CalculatorResultsListItem>
      </Box>
    </Box>
  );
}

function CalculatorResultsListItem({
  children,
  label,
}: {
  children: React.ReactNode;
  label: LocaleKey;
}) {
  return (
    <Box
      as="li"
      sx={{
        display: 'flex',
        justifyContent: 'space-between',

        '&:not(:last-of-type)': {
          mb: 4,
          pb: 1,
          borderBottom: '2px solid',
          borderColor: '#DCDDE4',
        },
      }}
    >
      <Text sx={{ fontWeight: 500 }}>{children}</Text>
      <Text sx={{ fontWeight: 300 }} intlId={label} />
    </Box>
  );
}
