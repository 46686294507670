import React from 'react';
import * as Utils from 'utils';
import * as ReactIntl from 'lib/intl';
import * as Rhf from 'react-hook-form';
import { WorkspaceService } from 'services/workspace';
import { Forms } from 'components/form-elements';
import { WorkspaceLayout } from 'components/workspace-layout';
import { Box, Container } from 'components/layout';
import { CreateWorkspaceRequestCurrencyEnum } from 'trace-backend-sdk';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { ValidationSchemas } from '../../utils/validation';
import {
  getSupportedCountries,
  getSupportedLanguages,
} from '../../utils/intl/get-supported-countries';
import { useIntlContext } from '../../translations';
import { FloatingLanguageSwitcher } from '../../features/language/components/FloatingLanguageSwitcher';

const CurrencyEnum = z.nativeEnum(CreateWorkspaceRequestCurrencyEnum);

const formSchema = z.object({
  organizationName: ValidationSchemas.Company.ORGANIZATION_NAME,
  street: ValidationSchemas.Company.STREET,
  zip: ValidationSchemas.Company.ZIP_CODE,
  city: ValidationSchemas.Company.CITY,
  country: ValidationSchemas.Company.COUNTRY,
  organizationNumber: ValidationSchemas.Common.ORGANIZATION_NUMBER,
  website: ValidationSchemas.Company.WEBSITE,
  language: z.string(),
  currency: CurrencyEnum,
});

export const CreateWorkspace: React.FC = () => {
  const intl = ReactIntl.useIntl();
  const intlContext = useIntlContext();

  const { refreshSession, isLoading: isRefreshSessionLoading } = Utils.Auth.useRefreshSession();

  const { isLoading, createWorkspace, isSuccess } = WorkspaceService.useCreateWorkspace({
    onSuccess: async () => {
      await refreshSession();
    },
  });

  const formMethods = Rhf.useForm<z.infer<typeof formSchema>>({
    defaultValues: {
      website: '',
      country: 'se',
      organizationName: '',
      city: '',
      organizationNumber: '',
      street: '',
      zip: '',
      language: intlContext.activeLanguage,
      currency: CreateWorkspaceRequestCurrencyEnum.Sek,
    },
    resolver: zodResolver(formSchema),
  });
  return (
    <Container variant="page">
      <WorkspaceLayout>
        <WorkspaceLayout.Logo />

        <WorkspaceLayout.Headline intlId="createWorkspace.intro" />

        <Forms.Provider
          onValid={(data) =>
            createWorkspace({
              ...data,
              currency: data.currency as CreateWorkspaceRequestCurrencyEnum,
            })}
          name="create-organisation"
          {...formMethods}
        >
          <Forms.FieldEditText
            id="createWorkspace.form.input.name"
            name="organizationName"
            labelIntlId="createWorkspace.form.input.name.label"
            required
            autoComplete="off"
          />

          <Forms.FieldEditText
            id="createWorkspace.form.input.name"
            name="organizationNumber"
            labelIntlId="createWorkspace.form.input.orgNumber.label"
            required
            autoComplete="organization-number"
          />

          <Forms.FieldEditText
            id="createWorkspace.form.input.street"
            name="street"
            labelIntlId="createWorkspace.form.input.street.label"
            required
            autoComplete="off"
          />

          <Forms.FieldEditText
            id="createWorkspace.form.input.zip"
            name="zip"
            labelIntlId="createWorkspace.form.input.zip.label"
            required
            autoComplete="off"
          />

          <Forms.FieldEditText
            id="createWorkspace.form.input.city"
            name="city"
            labelIntlId="createWorkspace.form.input.city.label"
            required
            autoComplete="off"
          />

          <Forms.SelectField
            id="createWorkspace.form.input.country"
            name="country"
            labelIntlId="createWorkspace.form.input.country.label"
            defaultChecked
            required
            autoComplete="off"
          >
            {getSupportedCountries().map((alpha2) => (
              <option
                value={alpha2}
                key={`createWorkspace-country-select.option__${alpha2}`}
              >
                {intl.formatMessage({ id: `country.${alpha2}` })}
              </option>
            ))}
          </Forms.SelectField>

          <Forms.SelectField
            id="createWorkspace.form.input.country"
            name="language"
            labelIntlId="createWorkspace.form.input.language"
            defaultChecked
            required
            autoComplete="off"
            helperText={intl.formatMessage({
              id: 'editWorkspace.form.input.language.helperText',
            })}
          >
            {getSupportedLanguages().map((language) => (
              <option value={language} key={language}>
                {intl.formatMessage({ id: `language.${language}` })}
              </option>
            ))}
          </Forms.SelectField>

          <Forms.SelectField
            id="createWorkspace.form.input.currency"
            name="currency"
            labelIntlId="createWorkspace.form.input.currency.label"
            defaultChecked
            required
            autoComplete="off"
          >
            {Object.values(CreateWorkspaceRequestCurrencyEnum).map(
              (currency) => (
                <option value={currency} key={currency}>
                  {intl.formatMessage({ id: `currency.${currency}` })}
                </option>
              ),
            )}
          </Forms.SelectField>

          <Forms.FieldEditText
            id="createWorkspace.form.input.website"
            name="website"
            labelIntlId="createWorkspace.form.input.website.label"
            autoComplete="off"
          />

          <Box sx={{ mt: 2 }}>
            <Forms.SubmitButton
              isLoading={isLoading || isRefreshSessionLoading}
              disabled={isSuccess}
            >
              <ReactIntl.FormattedMessage id="createWorkspace.form.button.next.text" />
            </Forms.SubmitButton>
          </Box>
        </Forms.Provider>
      </WorkspaceLayout>

      <FloatingLanguageSwitcher />
    </Container>
  );
};
