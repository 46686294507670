import * as Utils from 'utils';
import * as Intl from 'lib/intl';
import * as _ from 'lodash-es';
import { set as fpSet } from 'lodash/fp';
import { QuickTraceGpsField } from 'components/quick-trace/components/QuickTraceGpsField';
import { QuickTraceImageField } from 'components/quick-trace/components/QuickTraceImageField';
import { QuickTraceFileField } from 'components/quick-trace/components/QuickTraceFileField';
import { QuickTracePrimaryWrapperField } from 'components/quick-trace/components/QuickTracePrimaryWrapperField';
import React from 'react';
import { JsonForm } from 'components/form-elements/JsonForms/components/JsonForm/JsonForm';
import { DocumentsService } from 'services/documents';
import { useDocumentParams } from 'pages';
import { AjvError, IChangeEvent, ISubmitEvent } from '@rjsf/core';
import { DocumentStatusEnum } from 'trace-backend-sdk';
import { Button } from '../../form-elements';
import { InlineLoadingIndicator } from '../../LoadingIndicator';
import { generateContext } from '../../../utils/context';
import { pipe } from '../../../utils/functions/pipe';
import { resolveAjvErrorTranslationKey } from '../../form-elements/JsonForms/utils/map-jsonform-errors';

const fields = {
  gpsCapture: QuickTraceGpsField,
  imageCapture: QuickTraceImageField,
  fileCapture: QuickTraceFileField,
  primaryWrapperField: QuickTracePrimaryWrapperField,
};

const [useQuickTraceContext, QuickTraceContextProvider] = generateContext<{
  onDeleteWidget:(widgetKey: string) => void;
  onClearWidget: (widgetKey: string) => void;
}>();

function getWidgetKey(property: string) {
  /*
   * Widget keys with a dash in-between such as "FILE-1" will have property
   * names of the form ".main['FILE-1']", so we split at the single quotes
   * to obtain the key inside the array accessor.
   * */
  if (property.includes("['")) {
    return property.split("'")[1];
  }

  /*
   * Widget keys without a dash (the first of each widget) will have property
   * names of the form ".main.FILE", so we split at the dot to obtain the key.
   * */
  return property.split('.')[2];
}

function getWidgetKeyParts(widgetKey: string) {
  const parts = widgetKey.split('-');

  // If length is one, then the key is in form "FILE" and is the first one
  if (parts.length === 1) {
    return {
      widgetKey: parts[0],
      widgetIndex: 1,
    };
  }

  // If length is two, then the key is in form "FILE-1" and is the second one
  if (parts.length === 2) {
    return {
      widgetKey: parts[0],
      widgetIndex: Number(parts[1]) + 1,
    };
  }

  throw new Error('Widget key is invalid');
}

const WIDGET_REQUIRED_ERROR_MESSAGES = {
  file: 'errors.quickCapture.file.required',
  gps: 'errors.quickCapture.gps.required',
  image: 'errors.quickCapture.image.required',
};

function QuickTraceForm() {
  const params = useDocumentParams();
  const intl = Intl.useIntl();
  const {
    document,
    isLoading: isDocumentLoading,
    hasDocumentWritePermission,
  } = DocumentsService.useGetDocument(params.documentId, params.templateId);

  const [formData, setFormData] = Utils.Document.useDocumentFormState();
  const onSubmitDocument = Utils.Document.useSubmitDocument();
  const onUpdateDocument = Utils.Document.useUpdateDocumentData();

  const onDeleteWidget = React.useCallback(
    (widgetKey: string) => {
      setFormData((oldData) => {
        const newData = { ...oldData };
        delete newData.main[widgetKey];

        return newData;
      });
    },
    [setFormData],
  );

  const onClearWidget = React.useCallback(
    (widgetKey: string) => {
      setFormData((oldData) => fpSet(`main.${widgetKey}`, {}, oldData));
    },
    [setFormData],
  );

  const quickTraceContext = React.useMemo(
    () => ({
      onDeleteWidget,
      onClearWidget,
    }),
    [onDeleteWidget, onClearWidget],
  );

  const formContext = React.useMemo(
    () => ({
      documentId: params.documentId,
      templateId: params.templateId,
      dateCreated: document?.dateCreated,
    }),
    [document?.dateCreated, params.documentId, params.templateId],
  );

  const onChange = React.useCallback(
    (e: IChangeEvent) => {
      setFormData(e.formData);
      onUpdateDocument(e.formData);
    },
    [onUpdateDocument, setFormData],
  );

  const onSubmit = React.useCallback(
    (e: ISubmitEvent<any>) => {
      onSubmitDocument(e.formData);
    },
    [onSubmitDocument],
  );

  const transformErrors = React.useCallback(
    (errors: AjvError[]) => {
      const mappedErrors = errors.map((error) => {
        const { widgetIndex, widgetKey } = pipe(
          error.property,
          getWidgetKey,
          getWidgetKeyParts,
        );

        // @ts-expect-error Key error
        const errorMessage = WIDGET_REQUIRED_ERROR_MESSAGES[widgetKey.toLowerCase()];
        if (errorMessage) {
          return fpSet(
            'message',
            intl.formatMessage({ id: errorMessage }, { widgetIndex }),
            error,
          );
        }

        return resolveAjvErrorTranslationKey(error);
      });

      return _.uniqBy(mappedErrors, (error) => error.message);
    },
    [intl],
  );

  const isCompleted = document?.status === DocumentStatusEnum.Completed;
  const isDisabled = isCompleted || !hasDocumentWritePermission;

  if (isDocumentLoading) {
    return <InlineLoadingIndicator />;
  }

  return (
    <QuickTraceContextProvider value={quickTraceContext}>
      <JsonForm
        transformErrors={transformErrors}
        disabled={isDisabled}
        onSubmit={onSubmit}
        formContext={formContext}
        formData={formData}
        onChange={onChange}
        name="quickCapture"
        schema={
          document?.customTemplateSchemaFinal
          ?? document?.template.templateSchemaFinal
          ?? {}
        }
        uiSchema={document?.uiSchema}
        fields={fields}
      >
        {!isCompleted && (
          <Button
            disabled={isDisabled}
            intlId="document.nextStep"
            type="submit"
            sx={{ mt: 8 }}
          />
        )}
      </JsonForm>
    </QuickTraceContextProvider>
  );
}

export { QuickTraceForm, useQuickTraceContext };
