import * as React from 'react';
import { useModal } from 'store/modals';
import { BaseModal } from 'components/modals/BaseModal';

export function ComponentModal({ children }: { children: React.ReactNode }) {
  const { closeModal } = useModal();

  return (
    <BaseModal
      onClose={() => closeModal()}
      open
      contentSx={{
        backgroundColor: 'white.50',
        borderRadius: '8px',
        boxShadow: '4dp',
        width: '100%',
        maxWidth: 420,
      }}
    >
      {children}
    </BaseModal>
  );
}
