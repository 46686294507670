import * as ReactQuery from 'react-query';
import { fetchAvatarImageApi } from './api';

/*
 * Helper for fetching an image from an S3 link
 * and creating an object URL from the resulting blob
 * to be used in an image's src.
 * */
export function useFetchAvatarImage(imageUrl?: string) {
  const { data: image, ...rest } = ReactQuery.useQuery(
    ['image', imageUrl],
    async () =>
      // This query won't run if imageUrl == null, so non-null assertion is fine here
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      fetchAvatarImageApi({ imageUrl: imageUrl! }),
    {
      enabled: imageUrl != null,
      select: ({ data }) => URL.createObjectURL(data),
      onError() {},
      /*
       * The browser will cache the image data and use
       * the ETag returned from S3 to know when the
       * data at the "imageUrl" has changed, and when
       * to refetch the image (instead of taking it from disk cache).
       *
       * The above means we don't need to *additionally* cache the
       * image in React Query.
       * */
      cacheTime: 0,
      retry: false,
    },
  );

  return {
    image,
    shouldShowFallback: image == null,
    ...rest,
  };
}
