import * as React from 'react';
import * as ReactRouter from 'react-router-dom';
import { IntlProps } from 'translations';
import * as Rhf from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';

import { AuthService } from 'services/auth';
import { Routing } from 'global/routing';
import { StyleObject } from 'theming';
import { Forms } from 'components/form-elements';

import { WorkspaceLayout } from 'components/workspace-layout';
import { Constants } from 'global/constants';
import { Text } from 'components/typography';
import { Box, Container, Flex } from 'components/layout';
import { ValidationSchemas } from 'utils/validation';
import countryCallingCodes from 'translations/countries/country-calling-codes.json';
import { TermsAndConditionsText } from 'components/TermsAndConditionsText/TermsAndConditionsText';
import { CountryCallingCodeSelect } from 'components/form-elements/selects/CountryCallingCodeSelect/CountryCallingCodeSelect';
import { FloatingLanguageSwitcher } from '../../features/language/components/FloatingLanguageSwitcher';

export interface AccountProps extends IntlProps {
  sx?: StyleObject;
}

const schema = Joi.object({
  firstName: ValidationSchemas.Register.FIRST_NAME,
  lastName: ValidationSchemas.Register.LAST_NAME,
  workEmail: Joi.string().required().regex(Constants.EMAIL_REGEX).messages({
    'string.empty': 'createAccount.form.input.workEmail.error.required',
    'string.pattern.base': 'createAccount.form.input.workEmail.error.message',
  }),
  phoneNumber: Joi.string().optional().allow(''),
  countryCallingCode: Joi.string().optional(),
  password: ValidationSchemas.Register.PASSWORD,
  privacyAndTermsAndConditions: ValidationSchemas.Common.TERMS_AND_CONDITIONS,
});

export const Register: React.FC<AccountProps> = () => {
  const history = ReactRouter.useHistory();
  const { signIn, isLoading: isSignInLoading } = AuthService.useSignIn({
    onSuccess: () => {
      history.replace(Routing.CREATE_ORGANISATION.getPath());
    },
  });

  const {
    signUp,
    isLoading: isSignUpLoading,
    isSuccess: isSignUpSuccess,
  } = AuthService.useSignUp({
    onSuccess: async (data, variables) => {
      signIn({
        email: variables.workEmail,
        password: variables.password,
      });
    },
  });

  const formMethods = Rhf.useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      workEmail: '',
      phoneNumber: '',
      password: '',
      privacyAndTermsAndConditions: false,
      countryCallingCode: countryCallingCodes.SE,
    },
    resolver: joiResolver(schema),
  });

  const {
    field: { value, name, onBlur, onChange },
  } = Rhf.useController({
    name: 'countryCallingCode',
    control: formMethods.control,
  });

  return (
    <Container variant="page">
      <WorkspaceLayout>
        <WorkspaceLayout.Logo />
        <WorkspaceLayout.Headline intlId="createAccount.headline" />
        <WorkspaceLayout.Paragraph
          intlId="createAccount.paragraph"
          sx={{ fontWeight: 350 }}
        />
        <Forms.Provider
          onValid={(data) => {
            signUp({
              firstName: data.firstName,
              password: data.password,
              lastName: data.lastName,
              workEmail: data.workEmail,
              phoneNumber: data.phoneNumber,
              countryCallingCode: data.countryCallingCode,
            });
          }}
          name="createAccount"
          {...formMethods}
        >
          <Forms.FieldEditText
            labelIntlId="createAccount.form.input.firstName.label"
            placeholderIntlId="createAccount.form.input.firstName.placeholder"
            name="firstName"
            required
            autoComplete="off"
          />

          <Forms.FieldEditText
            labelIntlId="createAccount.form.input.lastName.label"
            placeholderIntlId="createAccount.form.input.lastName.placeholder"
            name="lastName"
            required
            autoComplete="off"
          />

          <Forms.FieldEditText
            onMapChange={(value) => value.trim()}
            labelIntlId="createAccount.form.input.workEmail.label"
            placeholderIntlId="createAccount.form.input.workEmail.placeholder"
            name="workEmail"
            required
            autoComplete="off"
          />

          <Flex
            sx={{
              '& > *:first-child': {
                minWidth: 130,
                mr: 4,
              },
            }}
          >
            <CountryCallingCodeSelect
              name={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
            />

            <Forms.FieldEditText
              labelIntlId="createAccount.form.input.phoneNumber.label"
              name="phoneNumber"
              autoComplete="off"
            />
          </Flex>

          <Forms.FieldEditText
            labelIntlId="createAccount.form.input.password.label"
            placeholderIntlId="createAccount.form.input.password.placeholder"
            name="password"
            required
            type="password"
            autoComplete="new-password"
          />

          <Forms.CheckboxField name="privacyAndTermsAndConditions">
            <TermsAndConditionsText />
          </Forms.CheckboxField>

          <Box sx={{ mt: 2 }}>
            <Forms.SubmitButton
              disabled={isSignUpSuccess}
              isLoading={isSignUpLoading || isSignInLoading}
            >
              <Text intlId="createAccount.button.label" variant="button.text" />
            </Forms.SubmitButton>
          </Box>
        </Forms.Provider>
      </WorkspaceLayout>

      <FloatingLanguageSwitcher />
    </Container>
  );
};
