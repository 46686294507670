import React from 'react';
import * as Icons from 'components/icons';
import * as ThemeUI from 'theme-ui';
import * as Utils from 'utils';
import { FieldProps } from '@rjsf/core';
import { Button } from 'components/form-elements/buttons';
import { Box, Flex } from 'components/layout';
import { Image } from 'components/Image';
import { Text } from 'components/typography';
import { useModal } from 'store/modals';
import { ConfirmationDialog } from 'components/confirmation-dialog/ConfirmationDialog';
import { GenericFn } from 'utils/types';
import { DocumentsService, DocumentTemplateId } from 'services/documents';
import { useLocaleTimeFormat } from 'utils/hooks/use-locale-date-format';
import { LocaleKey } from 'translations';
import { getLocationMapLink } from 'services/location/get-location-map-link';
import { formatLocation } from 'utils/geolocation/format-location';
import { useRollbar } from 'lib/rollbar';
import { FormattedDateParts } from 'lib/intl';

/**
 * TODO: Add reducer implementation for the three states that picture can be in:
 * - Camera open
 * - Picture taken (has data)
 * - Picture not taken (no data)
 * - Action for "Retake", wiping end data
 * */

type Props = FieldProps & {
  children?: React.ReactNode;
  ActionButton?: React.ReactNode;
  retakeWarningIntlId?: LocaleKey;
  imageName: string;
  onRetake?: GenericFn;
  onPictureTake?: GenericFn;
};

export type PictureData = {
  imageUrl: string;
  timestamp: number;
  latitude: string;
  longitude: string;
};

export function PictureTake(props: Props) {
  const { closeModal, openModal } = useModal();
  const { getGeolocationPosition, geolocationStatus } = Utils.Hooks.useGetGeolocationPosition();
  const rollbar = useRollbar();

  const { uploadDocumentImage, isLoading: isUploadImageLoading } = DocumentsService.useUploadDocumentImage({
    onSuccess: (response, variables) => {
      props.onChange({
        ...variables.data,
        timestamp: response.timestamp,
        imageUrl: `${response.imageUrl}?v=${variables.data.timestamp}`,
      });

      if (props.onPictureTake) {
        props.onPictureTake();
      }
    },
  });

  const hasImageData = props.formData.imageUrl != null;

  async function onTakePhotoAnimationDone(dataUri: string) {
    closeModal();
    const queryObserver = await getGeolocationPosition();

    rollbar.info(
      `[PICTURE_TAKE - 1] Successfully obtained geolocation query observer data 
        ${queryObserver.data?.coords.latitude} ${queryObserver.data?.coords.longitude}`,
    );

    if (hasImageData && props.onRetake) {
      props.onRetake();
    }

    const geolocationPosition = queryObserver.data;

    await uploadDocumentImage({
      data: {
        imageUrl: dataUri,
        timestamp: Date.now(),
        latitude: String(geolocationPosition?.coords.latitude ?? 0),
        longitude: String(geolocationPosition?.coords.longitude ?? 0),
      },
      documentId: props.formContext.documentId,
      templateId: props.formContext.templateId as DocumentTemplateId,
      imageName: props.imageName,
      rollbar,
    });
  }

  function openCameraModal() {
    openModal({
      modalProps: {
        isDisplayStartCameraError: false,
        onTakePhotoAnimationDone,
      },
      modalType: 'cameraModal',
    });
  }

  function onPictureRetake() {
    if (props.retakeWarningIntlId) {
      openModal({
        modalType: 'componentModal',
        modalProps: {
          children: (
            <ConfirmationDialog
              onProceed={() => {
                openCameraModal();
              }}
            >
              <Text
                as="p"
                sx={{ textAlign: 'center' }}
                intlId={props.retakeWarningIntlId}
              />
            </ConfirmationDialog>
          ),
        },
      });

      return;
    }

    openCameraModal();
  }

  const isLoading = geolocationStatus === 'loading' || isUploadImageLoading;

  if (hasImageData) {
    return (
      <React.Fragment>
        <PictureTakeWrapperCard>
          <PictureTakeWrapperHeader>
            <PictureTakeTitle>
              {props.title || props.uiSchema['ui:titleText']}
              <PictureTakeTimestamp timestamp={props.formData.timestamp} />
            </PictureTakeTitle>
          </PictureTakeWrapperHeader>
          <Box>
            <Image src={props.formData.imageUrl} />
          </Box>

          <LabelWithIcon>
            <Icons.Gps />

            <ThemeUI.Link
              target="_blank"
              href={getLocationMapLink({
                latitude: props.formData.latitude,
                longitude: props.formData.longitude,
              })}
              variant="default"
              sx={{
                alignSelf: 'center',
                ml: 3,
              }}
            >
              {formatLocation({
                latitude: props.formData.latitude,
                longitude: props.formData.longitude,
              })}
            </ThemeUI.Link>
          </LabelWithIcon>

          {!props.disabled && (
            <Button
              prependIcon={<Icons.RetakeCamera width={25} />}
              sx={{
                backgroundColor: '#3192F1',
              }}
              isLoading={isLoading}
              onClick={() => onPictureRetake()}
              intlId="pressureTest.retake"
            />
          )}
        </PictureTakeWrapperCard>

        {props.children}
      </React.Fragment>
    );
  }

  return React.isValidElement(props.ActionButton)
    ? React.cloneElement(props.ActionButton, {
      onClick: openCameraModal,
      isLoading,
      disabled: props.disabled,
    })
    : null;
}

type LabelWithIconProps = {
  label?: string;
  children: React.ReactNode | React.ReactNodeArray;
  position?: 'start' | 'end';
};

function LabelWithIcon({ position = 'start', ...props }: LabelWithIconProps) {
  return (
    <Flex
      sx={{
        display: 'inline-flex',
        alignItems: 'baseline',
        justifyContent: `flex-${position}`,
      }}
    >
      {props.children}
      {props.label && (
        <Text
          sx={{
            alignSelf: 'center',
            mt: '2px',
            ml: 3,
          }}
        >
          {props.label}
        </Text>
      )}
    </Flex>
  );
}

function PictureTakeTitle(props: Pick<Props, 'children'>) {
  return (
    <Flex
      sx={{
        gap: 4,
        alignItems: 'center',
      }}
    >
      <Text
        as="h1"
        sx={{
          color: 'darkgrey.800',
          fontSize: 'xl',
          fontWeight: 600,
        }}
      >
        {props.children}
      </Text>
    </Flex>
  );
}

function PictureTakeWrapperCard(props: { children: React.ReactNode }) {
  return (
    <Flex
      sx={{
        boxShadow: 1,
        flexDirection: 'column',
        p: 4,
        backgroundColor: 'white.50',
        gap: 4,
        color: 'darkgrey.800',
      }}
    >
      {props.children}
    </Flex>
  );
}

function PictureTakeWrapperHeader(props: { children: React.ReactNode }) {
  return (
    <Flex
      sx={{
        flexDirection: 'row',
        backgroundColor: 'white.50',
        gap: 4,
        color: 'darkgrey.800',
      }}
    >
      {props.children}
    </Flex>
  );
}

export function PictureTakeTimestamp({ timestamp }: { timestamp: number }) {
  const { localeTimeFormat } = useLocaleTimeFormat();

  const formattedDate = (
    <FormattedDateParts
      value={new Date(timestamp)}
      day="2-digit"
      year="numeric"
      month="short"
    >
      {(parts) => {
        const [day, month, year] = parts.filter(
          ({ type }) => type === 'day' || type === 'month' || type === 'year',
        );

        return (
          <Text>
            {day.value} {month.value} {year.value}
          </Text>
        );
      }}
    </FormattedDateParts>
  );

  const formattedTime = localeTimeFormat(new Date(timestamp));

  return (
    <Box>
      {formattedDate}, {formattedTime}
    </Box>
  );
}
