import * as React from 'react';
import * as Icons from 'components/icons';
import * as ReactIntl from 'lib/intl';
import { Box } from 'components/layout';
import { DocumentRequestBase, DocumentTemplateId } from 'services/documents';
import { ReliningSchemaFinal } from 'global/types/templates';
import { TextWithFallback } from 'components/typography';

import {
  DocumentCardAvatar,
  DocumentCardBody,
  DocumentCardDate,
  DocumentCardDivider,
  DocumentCardHeader,
  DocumentCardNew,
  DocumentCardNumber,
} from 'components/cards/DocumentCard/DocumentCard';
import { GetAllUsersResponseUsers } from 'trace-backend-sdk';

type ReliningDocumentCardProps = DocumentRequestBase & {
  documentData: ReliningSchemaFinal;
  dateCreated: string;
  isCompleted: boolean;
  completedBy?: GetAllUsersResponseUsers;
  assignee?: GetAllUsersResponseUsers;
  dateFinished: string | undefined;
};

export function ReliningDocumentCard({
  documentId,
  documentData,
  assignee,
  completedBy,
  isCompleted,
  templateId,
  dateCreated,
  dateFinished,
}: ReliningDocumentCardProps) {
  const intl = ReactIntl.useIntl();

  return (
    <DocumentCardNew
      documentId={documentId}
      templateId={templateId as DocumentTemplateId}
    >
      <DocumentCardHeader isCompleted={isCompleted}>
        <DocumentCardNumber
          Icon={(
            <Box sx={{ marginLeft: '-4px' }}>
              <Icons.ReliningSplash
                size={22}
                color="#FFF"
                colorBackground="transparent"
              />
            </Box>
          )}
        >
          {documentId}
        </DocumentCardNumber>

        <DocumentCardDate
          dateCreated={dateCreated}
          dateFinished={dateFinished}
        />
      </DocumentCardHeader>

      <DocumentCardBody>
        <TextWithFallback
          variant="singleLine"
          as="h2"
          sx={{
            color: 'secondary.900',
            lineHeight: 1.3,
            whiteSpace: 'pre-line',
          }}
          fallbackText={intl.formatMessage({
            id: 'relining.documentCard.projectNameAndNumber.placeholder',
          })}
        >
          {documentData?.metadata?.projectNameAndNumber}
        </TextWithFallback>

        <TextWithFallback
          variant="singleLine"
          as="h3"
          sx={{
            color: 'secondary.500',
            mb: 2,
            lineHeight: 2,
          }}
          fallbackText={intl.formatMessage({
            id: 'relining.documentCard.address.placeholder',
          })}
        >
          {documentData?.metadata?.address}
        </TextWithFallback>

        <TextWithFallback
          variant="singleLine"
          as="p"
          sx={{ fontSize: 'md', color: 'grey.400' }}
          fallbackText={intl.formatMessage({
            id: 'relining.documentCard.pipeForRenovationOrApartmentNumber.placeholder',
          })}
        >
          {documentData?.metadata?.pipeForRenovationOrApartmentNumber}
        </TextWithFallback>
        <DocumentCardDivider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <TextWithFallback
              sx={{
                color: 'tertiary1.300',
              }}
              fallbackText={intl.formatMessage({
                id: 'relining.documentCard.nameLine.placeholder',
              })}
            >
              {documentData?.metadata?.nameLine}
            </TextWithFallback>
          </Box>

          <DocumentCardAvatar
            isCompleted={isCompleted}
            completedBy={completedBy}
            assignee={assignee}
          />
        </Box>
      </DocumentCardBody>
    </DocumentCardNew>
  );
}
