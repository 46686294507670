import * as React from 'react';
import { Box, Flex } from 'components/layout';

export const Wrapper: React.FC = ({ children }) => (
  <Box sx={{ width: '100%' }}>
    <Flex
      flexDirection="row"
      sx={{
        width: '100%',
        backgroundColor: 'white.50',
        boxShadow: '2dp',
        position: 'relative',
        zIndex: 1,
      }}
    >
      {children}
    </Flex>
  </Box>
);
