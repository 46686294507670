import * as ReactQuery from 'react-query';
import { identify, makeRequest, Service } from '../../../utils/http';
import { UnwrapPromise } from '../../../utils/types';
import { PaymentsUtils } from '../index';

type Data = Parameters<typeof Service.createPaymentsSubscription>[0];
async function createPaymentsSubscriptionApi(data: Data) {
  return makeRequest(
    Service.createPaymentsSubscription(data, await identify()),
  );
}

function useCreatePaymentsSubscription(options?: {
  onSuccess: (
    data: UnwrapPromise<ReturnType<typeof createPaymentsSubscriptionApi>>
  ) => void;
}) {
  const stripeCreatePaymentMethod = PaymentsUtils.useCreatePaymentMethod();

  const { mutate: createPaymentsSubscription, ...rest } = ReactQuery.useMutation(
    'createPaymentsSubscription',
    async (data: Pick<Data, 'stripePrices'> & { cardholderName: string }) => {
      const response = await stripeCreatePaymentMethod.mutateAsync({
        cardholderName: data.cardholderName,
      });

      return createPaymentsSubscriptionApi({
        stripePrices: data.stripePrices,
        paymentMethodId: response.paymentMethod.id,
      });
    },
    options,
  );

  return {
    createPaymentsSubscription,
    ...rest,
  };
}

export { useCreatePaymentsSubscription };
