import * as ReactQuery from 'react-query';
import { InferMutationOptions } from 'utils/types';
import { uploadDocumentWidgetApi } from 'services/documents/upload-document-widget/api';

export function useUploadDocumentWidget(
  options?: InferMutationOptions<typeof uploadDocumentWidgetApi>,
) {
  const { mutate: uploadDocumentWidget, ...rest } = ReactQuery.useMutation(
    'uploadDocumentWidget',
    uploadDocumentWidgetApi,
    options,
  );

  return {
    uploadDocumentWidget,
    ...rest,
  };
}
