import * as ReactQuery from 'react-query';
import * as Utils from 'utils';
import { InferQueryOptions } from 'utils/types';
import { getWorkspaceAPI } from './api';

export const QUERY_KEY_WORKSPACE = 'workspace';

export function useGetWorkspace(
  options?: InferQueryOptions<typeof getWorkspaceAPI>,
) {
  const { isAuthenticated } = Utils.Auth.useIsAuthenticated();
  const { currentUser } = Utils.Auth.useCurrentAuthenticatedUser();

  /**
   * Check that the user is authenticated (has token),
   * and that the user has a workspace ID (has gone through the create organization flow).
   * */
  const enabled = isAuthenticated
    && Boolean(currentUser?.signInUserSession.idToken.payload.tenant_id);

  const { data, isLoading, isError, error } = ReactQuery.useQuery(
    QUERY_KEY_WORKSPACE,
    getWorkspaceAPI,
    {
      enabled,
      ...options,
    },
  );

  const isWorkspaceReady = (data?.ready && data.isStripeIntegrationSetup) ?? false;

  return {
    workspaceData: data,
    isLoading,
    isError,
    error,
    isWorkspaceReady,
  };
}
