import { useSignUp } from 'services/auth/signUp/hook';
import { useSignIn } from 'services/auth/signIn/hook';
import { useSignOut } from 'services/auth/signOut/hook';
import { useForgotPassword } from 'services/auth/forgot-password/hook';
import { useForgotPasswordConfirm } from 'services/auth/forgot-password-confirm/hook';

export const AuthService = {
  useSignUp,
  useSignIn,
  useSignOut,
  useForgotPassword,
  useForgotPasswordConfirm,
};
