import * as ReactQuery from 'react-query';
import * as Intl from 'lib/intl';
import * as R from 'remeda';

import { InferQueryOptions } from 'utils/types';
import { getLoggedInUserApi } from 'services/users/get-logged-in-user/api';

export const QUERY_KEY_LOGGED_IN_USER = 'loggedInUser';

export function useGetLoggedInUser(
  options?: InferQueryOptions<typeof getLoggedInUserApi>,
) {
  const intl = Intl.useIntl();
  const { data, ...rest } = ReactQuery.useQuery(
    QUERY_KEY_LOGGED_IN_USER,
    getLoggedInUserApi,
    {
      ...options,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        options?.onSuccess?.(
          R.set(
            data,
            'title',
            data?.title ? intl.formatMessage({ id: data.title }) : undefined,
          ),
        );
      },
    },
  );

  const title = data?.title
    ? intl.formatMessage({
      id: data.title,
    })
    : '';

  return {
    loggedInUser: data ? R.set(data, 'title', title) : undefined,
    ...rest,
  };
}
