import * as ReactRouter from 'react-router-dom';
import React from 'react';
import { DocumentStatusEnum } from 'trace-backend-sdk';
import {
  DocumentRequestBase,
  DocumentsService,
} from '../../../../services/documents';
import { useNotifications } from '../../../../components/notifications/notifications';
import { useModal } from '../../../../store/modals';
import { Routing } from '../../../../global/routing';
import { Submenu } from '../../../../components/submenu';
import { ConfirmationDialog } from '../../../../components/confirmation-dialog/ConfirmationDialog';
import { Text } from '../../../../components/typography';
import { Flex } from '../../../../components/layout';
import * as Icons from '../../../../components/icons';
import { Authorization } from '../../../../components/authorization/Authorization';

export function DeleteDocument({
  documentId,
  templateId,
}: Pick<DocumentRequestBase, 'documentId' | 'templateId'>) {
  const history = ReactRouter.useHistory();
  const notifications = useNotifications();
  const { openModal } = useModal();

  const { document, hasDocumentWritePermission } = DocumentsService.useGetDocument(documentId, templateId);
  const { deleteDocument } = DocumentsService.useDeleteDocument({
    onSuccess: () => {
      history.replace(Routing.DOCUMENTS.default);
      notifications.success({
        description: 'document.delete-success',
        tag: [Routing.DOCUMENTS.default],
      });
    },
  });

  const isCompleted = document?.status === DocumentStatusEnum.Completed;

  if (isCompleted || !hasDocumentWritePermission) {
    return null;
  }

  return (
    <Authorization roles={['admin']}>
      <Submenu>
        <Submenu.Item
          onClick={() => {
            openModal({
              modalType: 'componentModal',
              modalProps: {
                children: (
                  <ConfirmationDialog
                    onProceed={() => {
                      deleteDocument({ documentId, templateId });
                    }}
                  >
                    <Text
                      as="p"
                      sx={{ textAlign: 'center' }}
                      intlId="generic.confirm.areYouSure"
                    />
                  </ConfirmationDialog>
                ),
              },
            });
          }}
        >
          <Flex sx={{ alignItems: 'center' }}>
            <Icons.Delete />{' '}
            <Text sx={{ ml: 2 }} intlId="document.delete-button" />
          </Flex>
        </Submenu.Item>
      </Submenu>
    </Authorization>
  );
}
