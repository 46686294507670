import { cards } from 'theming/variants/cards';
import _ from 'lodash';
import { FieldProps } from '@rjsf/core';
import * as React from 'react';
import { StyleObject } from 'theming';
import { Label } from 'components/form-elements/labels';
import { composeTranslationKey } from 'utils/functions/compose-translation-key';
import { Flex } from 'components/layout';
import { CustomSelect } from 'components/form-elements/selects/CustomSelect/CustomSelect';
import * as Icons from 'components/icons';
import { Text } from 'components/typography';
import { ErrorMessage } from 'components/form-elements/JsonForms/components/ErrorMessage';
import { FormattedMessage } from 'lib/intl';
import { LocaleKey } from '../../../../../translations';
import { Spacer } from '../../../../Spacer/Spacer';

const mapOptions = (options: number[]) =>
  options.map((option) => ({
    label: String(option),
    option,
  }));

type AggregateVariant = keyof typeof cards.timer;
type BackgroundLightness = 'dark' | 'light';

type Props = FieldProps & {
  variant?: AggregateVariant;
  onAggregateValues?: (values: number[]) => number;
  fieldSeparator?: React.ReactNode;
  containerSx?: StyleObject;
  backgroundLightness?: BackgroundLightness;
};

const textColorMapping: Record<BackgroundLightness, string> = {
  dark: '#d9d9e1',
  light: 'white.50',
};

/**
 * Function to assign properties from 'src' into 'dest',
 * while *keeping the order of the property keys*.
 * */
function assignPropertiesOrdered<
  T extends Record<string, unknown>,
  K extends T
>(dest: T, src: K) {
  const destCopy = { ...dest };
  for (const srcKey in src) {
    if (srcKey in destCopy) {
      destCopy[srcKey] = src[srcKey];
    }
  }

  return destCopy;
}

export function MultiSelectAggregate({
  variant = 'running',
  onAggregateValues = () => 0,
  fieldSeparator,
  containerSx,
  backgroundLightness = 'dark',
  ...props
}: Props) {
  function mapAggregatorValue(values: Record<string, number>) {
    return onAggregateValues(Object.values(values));
  }

  const { aggregatorKey, otherKeys } = getNonAggregatorKeys(props);

  const FieldSeparator = React.isValidElement(fieldSeparator)
    && React.cloneElement(fieldSeparator, {
      className: 'field_separator_id',
    });

  const defaultValues = reduceInitialValues(otherKeys, props.formData);
  const hasErrors = !_.isEmpty(props.errorSchema);

  return (
    <React.Fragment>
      <Label
        isError={hasErrors}
        required={props.required}
        sx={{
          variant: 'forms.jsonInputLabel',
        }}
        labelIntlId={props.name as LocaleKey}
      />

      <Spacer y={3} />

      <Flex
        sx={{
          alignItems: 'stretch',
          '& > *:not(.field_separator_id)': {
            flex: 1,
          },
          variant: `cards.timer.${variant}`,
          px: 2,
          ...containerSx,
        }}
      >
        {otherKeys.map((key, index) => {
          // @ts-expect-error 'enum' exists
          const options: number[] = props.schema.properties?.[key]?.enum ?? [];

          return (
            <React.Fragment>
              <CustomSelect
                key={key}
                isDisabled={props.disabled}
                wrapperSx={{
                  color: textColorMapping[backgroundLightness],
                }}
                value={{
                  option: defaultValues[key],
                  label: String(defaultValues[key]),
                }}
                onChange={(newValue) => {
                  // Create a proxy object which is sorted by the schema properties
                  const schemaOrderedProxy = assignPropertiesOrdered(
                    Object.fromEntries(otherKeys.map((key) => [key, 0])),
                    props.formData,
                  );

                  /**
                   * Assign the new value through the proxy,
                   * to keep the order as it is in the schema
                   * */
                  const newValues = assignPropertiesOrdered(
                    schemaOrderedProxy,
                    {
                      [key]: newValue.option,
                    },
                  );
                  const newAggregatorValue = mapAggregatorValue(newValues);

                  props.onChange({
                    ...newValues,
                    ...(aggregatorKey && {
                      [aggregatorKey]: newAggregatorValue,
                    }),
                  });
                }}
                options={mapOptions(options)}
                prependIcon={(
                  <Icons.ChevronUpFilled
                    sx={{ color: 'rgba(255, 255, 255, 0.25)' }}
                    width={20}
                  />
                )}
                appendIcon={(
                  <Icons.ChevronDownFilled
                    sx={{ color: 'rgba(255, 255, 255, 0.25)' }}
                    width={20}
                  />
                )}
                labelIntlId={composeTranslationKey(props.formContext.name, key)}
              />

              {aggregatorKey
                ? FieldSeparator
                : index !== otherKeys.length - 1 && FieldSeparator}
            </React.Fragment>
          );
        })}

        {aggregatorKey && (
          <Flex
            sx={{
              flexDirection: 'column',
              flex: '1',
              fontSize: '40px',
              color: textColorMapping[backgroundLightness],
              textAlign: 'center',
              alignSelf: 'stretch',
              zIndex: 'base',
            }}
          >
            <Flex
              sx={{
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
                mb: 1,
              }}
            >
              <Text as="p">{props.formData[aggregatorKey] ?? 0}</Text>
            </Flex>

            <Text
              as="p"
              sx={{ fontSize: 'md', flexShrink: 9999 }}
              intlId={composeTranslationKey(
                props.formContext.name,
                aggregatorKey,
              )}
            />
          </Flex>
        )}
      </Flex>
      {!_.isEmpty(props.errorSchema) && (
        <Flex sx={{ mt: 2, flexDirection: 'column' }}>
          {/*
          Map the errors for all potential properties
          of this "type: object" definition.
        */}
          {Object.values(props.errorSchema).map((errorsPerProperty) =>
            Object.values(errorsPerProperty).map((error) => (
              <ErrorMessage>
                <FormattedMessage id={error as unknown as string} />
              </ErrorMessage>
            )))}
        </Flex>
      )}
    </React.Fragment>
  );
}

export const JSON_AGGREGATOR_KEY = 'ui:aggregator';

/**
 * There can be N non-aggregator keys, and one aggregator key.
 * We infer the aggregator to be the property in the object-type which
 * does not have an enum in it.
 * */
function getNonAggregatorKeys(props: FieldProps) {
  const allKeys = Object.keys(props.schema.properties ?? {});
  /**
   * If user-specified aggregator key does not exist, find the one
   * with no associated 'enum' value.
   * */
  const aggregatorKey = props.uiSchema[JSON_AGGREGATOR_KEY]
    // TODO: Decide whether to use filter to see how many aggregators are found,
    //  and throw error if there is more than one.
    // @ts-expect-error 'enum' exists
    ?? allKeys.find((key) => props.schema.properties?.[key]?.enum == null);

  const otherKeys = allKeys.filter((key) => key !== aggregatorKey);

  return {
    aggregatorKey,
    otherKeys,
  };
}

function reduceInitialValues(
  propertyKeys: string[],
  formData: Record<string, any>,
) {
  const result: Record<string, number> = {};

  for (const key of propertyKeys) {
    result[key] = _.get(formData, key) ?? 0;
  }

  return result;
}
