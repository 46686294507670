import Popup from 'reactjs-popup';
import React from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Box } from 'components/layout';
import { OnResizeFn, useResizeObserver } from 'utils/hooks/use-resize-observer';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode | React.ReactNodeArray;
  onResize?: OnResizeFn;
};

export function Drawer({
  isOpen,
  onClose,
  children,
  onResize = () => {},
}: Props) {
  const drawerContainerRef = React.useRef<HTMLDivElement | null>(null);

  useResizeObserver(drawerContainerRef.current, onResize);

  return (
    <Popup
      nested
      // @ts-expect-error Can use sx due to JSX pragma
      sx={{
        '&-content': {
          margin: '0 !important',

          width: '80% !important',
          maxWidth: 320,
          marginRight: 'auto !important',
          backgroundColor: 'bg.50',
        },

        '&-overlay': {
          backgroundColor: 'rgba(7, 7, 7, 0.6)',
        },
      }}
      open={isOpen}
      onClose={onClose}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
        ref={drawerContainerRef}
      >
        {children}
      </Box>
    </Popup>
  );
}
