import * as React from 'react';
import * as Lsm from 'little-state-machine';

import { Box, Flex } from 'components/layout';
import { Search } from 'components/form-elements/inputs/Search/Search';
import * as Icons from 'components/icons';
import { IconButton } from 'components/form-elements/buttons/IconButton';
import { SidebarActions } from 'store';
import { Routing } from 'global/routing';
import * as ReactRouter from 'react-router-dom';
import { breakpoints } from 'utils/breakpoints';
import { TopBarTitle } from './TopBarTitle';

const BACK_BUTTON_WHITELIST_ROUTES = [
  Routing.SETTINGS.getPath(),
  Routing.PROFILE.default,
  Routing.USERS.default,
  Routing.USERS.InviteUsers.path,
  Routing.PERMISSIONS.Default.path,
  Routing.DOCUMENT.default,
  Routing.VIEW_PDF.default,
  Routing.SEARCH.Default,
  Routing.PRESSURE.Running,
  Routing.MESSAGES.Default,
  Routing.PAYMENTS.UpcomingPayment.path,
  Routing.PAYMENTS.Billing.path,
  Routing.PAYMENTS.PaymentHistory.path,
  Routing.PAYMENTS.ManagePaymentInfo.path,
  Routing.PAYMENTS.ManageSubscription.path,
];

export function TopBar({ children }: { children: React.ReactNode }) {
  const history = ReactRouter.useHistory();

  const [isSearchActive, setIsSearchActive] = React.useState(false);

  const { actions } = Lsm.useStateMachine({
    toggleSideBar: SidebarActions.toggleSideBar,
  });

  const isSearchActiveOrSearchPageOpened = isSearchActive || history.location.pathname === Routing.SEARCH.Default;

  return (
    <Flex
      as="nav"
      flexDirection="row"
      alignItems="center"
      sx={{
        p: 3,
        px: 5,
        backgroundColor: 'primary.900',
        width: '100%',
        mt: '0px!important',
        zIndex: 'docked',
        // Separates top menu from the rest of the page
        borderBottom: '1px solid white',
      }}
    >
      <Box sx={{ minWidth: '48px', display: 'inline-flex', p: 0 }}>
        <ReactRouter.Switch>
          <ReactRouter.Route path={BACK_BUTTON_WHITELIST_ROUTES}>
            <IconButton
              sx={{
                display: breakpoints({
                  lg: 'none',
                }),
                color: 'white.50',
              }}
              onClick={() => history.goBack()}
            >
              <Icons.Back />
            </IconButton>
          </ReactRouter.Route>

          <ReactRouter.Route path="/">
            <IconButton
              sx={{
                display: breakpoints({
                  lg: 'none',
                }),
                color: 'white.50',
                fontSize: '4xl',
                flexShrink: 0,
              }}
              onClick={() => actions.toggleSideBar(undefined)}
            >
              <Icons.BurgerMenu width={30} />
            </IconButton>
          </ReactRouter.Route>
        </ReactRouter.Switch>
      </Box>

      {!isSearchActiveOrSearchPageOpened && <TopBarTitle />}

      <Search
        isSearchActive={isSearchActiveOrSearchPageOpened}
        setIsSearchActive={setIsSearchActive}
      />

      <Flex width="auto" sx={{ flexShrink: 0, ml: 2 }}>
        {children}
      </Flex>
    </Flex>
  );
}
