import { Checkbox } from 'components/form-elements/Forms/Checkbox';
import { CheckboxProps } from 'theme-ui';
import { Flex } from '../../../../components/layout';
import { Currency } from '../../../../components/Currency';
import { Text } from '../../../../components/typography';

type ModuleCheckboxProps = CheckboxProps & {
  label: string;
  moduleCostCents: number;
  currency: string;
};

export function ModuleCheckbox({
  moduleCostCents,
  label,
  currency,
  ...delegated
}: ModuleCheckboxProps) {
  return (
    <Checkbox {...delegated}>
      <Flex
        sx={{
          alignItems: 'center',
          fontSize: 'xl',
          ml: 2,
        }}
      >
        <Text sx={{ mr: 'auto', fontWeight: 400 }}>{label}</Text>
        <Text sx={{ fontWeight: 400 }}>
          <Currency valueCents={moduleCostCents} currency={currency} />
        </Text>
      </Flex>
    </Checkbox>
  );
}
