import * as ReactQuery from 'react-query';
import { InferMutationOptions } from 'utils/types';
import { adminUpdateWorkspaceApi } from 'services/admin/admin-update-workspace/api';

export function useAdminUpdateWorkspace(
  options?: InferMutationOptions<typeof adminUpdateWorkspaceApi>,
) {
  const { mutate, ...rest } = ReactQuery.useMutation(
    ['update-workspace-api'],
    adminUpdateWorkspaceApi,
    options,
  );

  return {
    adminUpdateWorkspace: mutate,
    ...rest,
  };
}
