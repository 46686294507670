import React from 'react';
import * as ReactQuery from 'react-query';
import { DocumentStatusEnum } from 'trace-backend-sdk';
import {
  DocumentRequestBase,
  DocumentsService,
} from '../../../services/documents';
import { Submenu } from '../../submenu';
import { Box } from '../../layout';
import * as Icons from '../../icons';
import { Text } from '../../typography';
import { QUERY_KEY_DOCUMENT } from '../../../services/documents/get-document';
import { GenericFn } from '../../../utils/types';
import { ConfirmationDialog } from '../../confirmation-dialog/ConfirmationDialog';
import { useModal } from '../../../store/modals';
import { useQuickTraceContext } from './QuickTraceForm';

type QuickTraceWidgetMenuProps = DocumentRequestBase & {
  widgetKey: string;
  hasWidgetData: boolean;
};

/*
 * The submenu with common actions for each widget
 * such as "Delete", "Clear", etc.
 * */
export function QuickTraceWidgetMenu({
  widgetKey,
  documentId,
  templateId,
  hasWidgetData,
}: QuickTraceWidgetMenuProps) {
  const queryClient = ReactQuery.useQueryClient();

  const { openModal } = useModal();

  const { document, hasDocumentWritePermission } = DocumentsService.useGetDocument(documentId, templateId);
  const { onDeleteWidget, onClearWidget } = useQuickTraceContext();

  const { clearDocumentWidget } = DocumentsService.useClearDocumentWidget({
    onSuccess: (data, variables) => {
      queryClient.setQueryData(
        QUERY_KEY_DOCUMENT(variables.documentId, variables.templateId),
        data,
      );
      onClearWidget(variables.widgetKey);
    },
  });
  const { deleteDocumentWidget } = DocumentsService.useDeleteDocumentWidget({
    onSuccess: (data, variables) => {
      queryClient.setQueryData(
        QUERY_KEY_DOCUMENT(variables.documentId, variables.templateId),
        data,
      );
      onDeleteWidget(variables.widgetKey);
    },
  });

  function onActionWithConfirm(proceedAction: GenericFn) {
    openModal({
      modalType: 'componentModal',
      modalProps: {
        children: (
          <ConfirmationDialog onProceed={proceedAction}>
            <Text
              as="p"
              sx={{ textAlign: 'center' }}
              intlId="generic.confirm.areYouSure"
            />
          </ConfirmationDialog>
        ),
      },
    });
  }

  if (
    !hasDocumentWritePermission
    || document?.status === DocumentStatusEnum.Completed
  ) {
    return null;
  }

  return (
    <Submenu>
      <Submenu.Item
        onClick={() =>
          onActionWithConfirm(() =>
            deleteDocumentWidget({
              templateId,
              documentId,
              widgetKey,
            }))}
      >
        <Box sx={{ display: 'flex' }}>
          <Icons.Delete />
          <Text sx={{ ml: 2 }} intlId="quickCapture.widget.delete" />
        </Box>
      </Submenu.Item>

      <Submenu.Item
        disabled={!hasWidgetData}
        onClick={() =>
          onActionWithConfirm(() =>
            clearDocumentWidget({
              widgetKey,
              templateId,
              documentId,
            }))}
      >
        <Box sx={{ display: 'flex' }}>
          <Icons.Clear fill="currentColor" />
          <Text sx={{ ml: 2 }} intlId="quickCapture.widget.clear" />
        </Box>
      </Submenu.Item>
    </Submenu>
  );
}
