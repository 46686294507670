import { AxiosError } from 'axios';
import { StatusCodes } from 'global/status-codes';
import { ErrorType } from './types';

export function mapHttpError(error: AxiosError): ErrorType {
  const genericErrorMessage = 'Something Failed. Try again?';

  if (error.response) {
    if (error.response.data) {
      return {
        authToken: error.response.config.headers.Authorization,
        statusCode: error.response.status,
        message:
          error.response.data.message
          || error.response.data.error
          || genericErrorMessage,
      };
    }
  }

  return {
    authToken: error.response?.config.headers.Authorization,
    statusCode: StatusCodes.INTERNAL_SERVER_ERROR,
    message: genericErrorMessage,
  };
}
