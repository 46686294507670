import * as ReactQuery from 'react-query';
import { InferQueryOptions } from 'utils/types';
import { getUsersTemplatesApi } from 'services/users/get-users-templates/api';

const QUERY_KEY_USERS_TEMPLATES = () => ['users', 'permissions'];

function useGetUsersTemplates(
  options?: InferQueryOptions<typeof getUsersTemplatesApi>,
) {
  return ReactQuery.useQuery(
    QUERY_KEY_USERS_TEMPLATES(),
    getUsersTemplatesApi,
    options,
  );
}

export { useGetUsersTemplates };
