import * as React from 'react';
import { Box } from 'components/layout';
import { StyleObject } from 'theming';

type ReliningTemperatureIndicatorProps = {
  value: number;
  children: React.ReactNode;
  maxValue: number;
};

const mapProgress = (prog: number) => (prog <= 100 ? prog * 0.8 : 80);

function getGradient(progress: number) {
  if (mapProgress(progress) < 10) {
    return `conic-gradient(from 215deg, #3192f1 ${mapProgress(
      progress,
    )}%, transparent ${mapProgress(progress)}%)`;
  }
  if (mapProgress(progress) <= 20) {
    return `conic-gradient(from 215deg, #3192f1 10%, #ffd65f ${mapProgress(
      progress,
    )}%, transparent ${mapProgress(progress)}%)`;
  }
  if (mapProgress(progress) <= 40) {
    return `conic-gradient(from 215deg, #3192f1 10%, #ffd65f 20%, #fc752a ${mapProgress(
      progress,
    )}%, transparent ${mapProgress(progress)}%)`;
  }
  return `conic-gradient(from 215deg, #3192f1 10%, #ffd65f 20%, #fc752a  ${mapProgress(
    progress,
  )}%, transparent ${mapProgress(progress)}%)`;
}

function getDefaultGradient() {
  return 'conic-gradient(from 215deg, #3e3e53 80%, transparent 80%)';
}

export function ReliningTemperatureIndicator({
  value,
  children,
  maxValue,
}: ReliningTemperatureIndicatorProps) {
  return (
    <ReliningTemperaturePercentageWrapper>
      <ReliningTemperaturePercentageWithNoValue
        label={startLabel}
        progressBar={getDefaultGradient()}
      />
      <ReliningTemperaturePercentageWithValue
        label={endLabel(maxValue)}
        progressBar={getGradient(value)}
      />

      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {children}
      </Box>
    </ReliningTemperaturePercentageWrapper>
  );
}

function ReliningTemperaturePercentageWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Box
      sx={{
        position: 'relative',
        borderRadius: '50%',
        background: 'linear-gradient(-45deg, #4d4d65, #8686a1)',
        width: '300px',
        height: '300px',
        mx: 'auto',
        boxShadow: '#3192F145 0px 20px 30px',
      }}
    >
      {children}
    </Box>
  );
}

const startLabel = {
  '::after': {
    content: "'Temp'",
    position: 'absolute',
    left: '25%',
    bottom: '5%',
    color: '#C2C2C2',
  },
} as const;

const endLabel = (maxValue: number) =>
  ({
    '::before': {
      content: `'${maxValue} \\00B0'`,
      position: 'absolute',
      right: '25%',
      bottom: '5%',
      color: '#E6E6E6',
    },
  } as const);

function ReliningTemperaturePercentageWithNoValue({
  label,
  progressBar,
}: {
  label: StyleObject;
  progressBar: string;
}) {
  return (
    <Box
      sx={{
        position: 'absolute',
        right: '5%',
        width: 270,
        top: '5%',
        fontSize: 'sm',
        fontWeight: 200,
        ...label,
      }}
    >
      <svg viewBox="0 0 100 100">
        <clipPath id="clip2">
          <path d="M 50 1 a 50 50 0 0 1 0 100 50 50 0 0 1 0 -100 v 1 a 5 5 0 0 0 0 97 5 5 0 0 0 0 -97" />
        </clipPath>

        <foreignObject
          x="0"
          y="0"
          width="100"
          height="100"
          clipPath="url(#clip2)"
        >
          <Box
            sx={{
              width: '100px',
              height: '100px',
              borderRadius: '50%',
              backgroundImage: progressBar,
            }}
          />
        </foreignObject>
      </svg>
    </Box>
  );
}

function ReliningTemperaturePercentageWithValue({
  label,
  progressBar,
}: {
  label: StyleObject;
  progressBar: string;
}) {
  return (
    <Box
      sx={{
        position: 'absolute',
        right: '4.5%',
        width: 273,
        top: '5%',
        fontSize: 'sm',
        fontWeight: 200,
        ...label,
      }}
    >
      <svg viewBox="0 0 100 100">
        <clipPath id="clip1">
          <path d="M 50 0 a 50 50 0 0 1 0 100 50 50 0 0 1 0 -100 v 3 a 8 8 0 0 0 0 94 8 8 0 0 0 0 -94" />
        </clipPath>

        <foreignObject
          x="0"
          y="0"
          width="100"
          height="100"
          clipPath="url(#clip1)"
        >
          <Box
            sx={{
              width: '100px',
              height: '100px',
              borderRadius: '50%',
              backgroundImage: progressBar,
            }}
          />
        </foreignObject>
      </svg>
    </Box>
  );
}
