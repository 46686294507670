import React from 'react';
import * as ThemeUI from 'theme-ui';
import * as Rhf from 'react-hook-form';

import { Field, InputProps } from 'components/form-elements';

import { Colors } from 'theming/foundations/colors';
import { IconButton } from 'components/form-elements/buttons/IconButton';
import * as Icons from 'components/icons';
import { LocaleKey } from 'translations';
import * as ReactIntl from 'lib/intl';
import { useIntl } from 'lib/intl';
import { Flex } from 'components/layout';
import { ShouldRender } from 'components/ShouldRender';
import { StyleObject } from '../../../../theming';

export interface FieldEditTextProps extends Omit<InputProps, 'size'> {
  labelIntlId?: LocaleKey;
  backgroundColor?: Colors;
  name: string;
  type?: string;
  isLabelFloated?: boolean;
  isAlwaysFloating?: boolean;
  onMapChange?: (value: string) => any;
  AppendIcon?: React.ReactNode;
  size?: 'medium' | 'large';
  wrapperSx?: StyleObject;
  isPlaceholderAlwaysVisible?: boolean;
  isHoverEffectVisible?: boolean;
}

export const FieldEditText: React.FC<FieldEditTextProps> = ({
  id,
  size,
  labelIntlId,
  placeholderIntlId,
  name,
  type = 'text',
  required,
  autoComplete,
  AppendIcon,
  /**
   * Workaround for problems with browser autofill.
   * We should figure out a way to make the label trigger
   * float even with browser autofill, not only user input.
   * */
  isAlwaysFloating = false,
  onMapChange = (value) => value,
  wrapperSx,
  isPlaceholderAlwaysVisible = false,
  isHoverEffectVisible = false,
}) => {
  const intl = useIntl();
  const formMethods = Rhf.useFormContext();

  const {
    field: { value, name: fieldName, ref, onChange, onBlur },
    fieldState: { error },
  } = Rhf.useController({
    control: formMethods.control,
    name,
    defaultValue: '',
  });

  const [showPassword, setShowPassword] = React.useState(false);

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        ...wrapperSx,
      }}
    >
      <TextField
        size={size}
        value={value}
        id={id}
        autoComplete={autoComplete}
        required={required}
        name={fieldName}
        ref={ref}
        onBlur={onBlur}
        onChange={(event) => {
          onChange(onMapChange((event.target as HTMLInputElement).value));
        }}
        label={labelIntlId ? intl.formatMessage({ id: labelIntlId }) : ''}
        isError={Boolean(error?.message)}
        placeholder={
          placeholderIntlId ? intl.formatMessage({ id: placeholderIntlId }) : ''
        }
        type={showPassword ? 'text' : type}
        isLabelAlwaysLifted={isAlwaysFloating}
        isPlaceholderAlwaysVisible={isPlaceholderAlwaysVisible}
        isHoverEffectVisible={isHoverEffectVisible}
        appendIcon={
          AppendIcon
          ?? (type === 'password' && (
            <IconButton
              sx={{
                fill: 'darkgrey.800',
              }}
              onClick={() => {
                setShowPassword((p) => !p);
              }}
            >
              {showPassword ? (
                <Icons.EyeOpen width={20} />
              ) : (
                <Icons.EyeClosed width={20} />
              )}
            </IconButton>
          ))
        }
      />

      <ShouldRender when={Boolean(error?.message)}>
        <HelperText isError>
          <ReactIntl.FormattedMessage id={error?.message} />
        </HelperText>
      </ShouldRender>
    </Flex>
  );
};

export function HelperText(props: {
  children: React.ReactNode;
  isError?: boolean;
}) {
  return (
    <ThemeUI.Text
      sx={{
        mt: 1,
        fontSize: 'sm',
        minHeight: '1.25em', // should match line-height value
        lineHeight: 2,
        color: props.isError ? 'error' : 'inherit',
      }}
    >
      {props.children}
    </ThemeUI.Text>
  );
}

export const TextField = React.forwardRef<
  HTMLInputElement,
  {
    label: string;
    isError: boolean;
    onBlur: React.FocusEventHandler;
    onChange: React.ChangeEventHandler;
    name: string;
    placeholder?: string;
    appendIcon?: React.ReactNode;
    prependIcon?: React.ReactNode;
    type?: string;
    required?: boolean;
    autoComplete?: string;
    isLabelAlwaysLifted?: boolean;
    id?: string;
    disabled?: boolean;
    value: string;
    size?: FieldEditTextProps['size'];
    onFocus?: FieldEditTextProps['onFocus'];
    backgroundColor?: FieldEditTextProps['backgroundColor'];
    autoFocus?: FieldEditTextProps['autoFocus'];
    as?: FieldEditTextProps['as'];
    isPlaceholderAlwaysVisible?: boolean;
    isHoverEffectVisible?: boolean;
  }
>(
  (
    {
      size = 'large',
      required = false,
      isLabelAlwaysLifted = false,
      label,
      onBlur,
      onChange,
      name,
      disabled,
      value,
      isError,
      autoComplete,
      type,
      appendIcon,
      placeholder,
      onFocus,
      backgroundColor,
      autoFocus,
      isPlaceholderAlwaysVisible,
      isHoverEffectVisible,
      as = ThemeUI.Input,
    },
    ref,
  ) => {
    const [isActive, setIsActive] = React.useState(false);

    const isLabelLifted = label && (isLabelAlwaysLifted || isActive || value);
    const isPlaceholderVisible = isPlaceholderAlwaysVisible || !label || isLabelLifted;

    return (
      <Field
        name={name}
        label={label}
        isError={isError}
        isLabelAlwaysLifted={isLabelAlwaysLifted}
        hasValue={Boolean(value)}
        required={required}
        isActive={isActive}
        backgroundColor={backgroundColor}
        isPlaceholderVisible={isPlaceholderAlwaysVisible}
        isHoverEffectVisible={isHoverEffectVisible}
      >
        <ThemeUI.Input
          as={as}
          autoFocus={autoFocus}
          value={value}
          disabled={disabled}
          name={name}
          id={name}
          autoComplete={autoComplete}
          type={type}
          ref={ref}
          placeholder={isPlaceholderVisible ? placeholder : ''}
          onFocus={(event) => {
            if (onFocus) {
              onFocus(event);
            }
            setIsActive(true);
          }}
          onBlur={(event) => {
            onBlur(event);
            setIsActive(false);
          }}
          onChange={onChange}
          sx={{
            border: 'none',
            width: '100%',
            height: '1.1876em',
            padding: size === 'large' ? '18.5px 14px' : '14px 10px',
            display: 'block',
            minWidth: 0,
            background: 'none',
            boxSizing: 'content-box',
            letterSpacing: 'inherit',
            outline: 'none',
            ...(isHoverEffectVisible && {
              '@media screen and (min-width: 1280px)': {
                '&:hover': {
                  '::placeholder': {
                    color: '#474646',
                    opacity: 1,
                  },

                  ':-ms-input-placeholder': {
                    color: '#474646',
                  },

                  '::-ms-input-placeholder': {
                    color: '#474646',
                  },
                },
              },
            }),
          }}
        />

        {appendIcon && (
          <ThemeUI.Box
            sx={{
              position: 'absolute',
              right: 3,
              top: '50%',
              transform: 'translateY(-50%)',
              display: 'inline-flex',
            }}
          >
            {appendIcon}
          </ThemeUI.Box>
        )}
      </Field>
    );
  },
);
