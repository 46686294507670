import React from 'react';
import * as ThemeUI from 'theme-ui';
import * as ReactIntl from 'lib/intl';

import { InputVariants } from 'theming/variants/forms';

export interface InputProps extends ThemeUI.InputProps {
  variant?: InputVariants;
  placeholderIntlId?: string;
}

export const Input: React.FC<InputProps> = React.forwardRef(
  (
    {
      children,
      sx,
      variant,
      placeholderIntlId,
      placeholder,
      autoComplete,
      ...props
    },
    ref,
  ) => {
    const intl = ReactIntl.useIntl();

    return (
      <ThemeUI.Input
        autoComplete={autoComplete}
        sx={{
          ...sx,
          '&::placeholder': {
            opacity: 0.5,
          },
        }}
        variant={`input.${variant}`}
        placeholder={
          placeholderIntlId
            ? intl.formatMessage({ id: placeholderIntlId })
            : placeholder
        }
        ref={ref}
        {...props}
      >
        {children}
      </ThemeUI.Input>
    );
  },
);
