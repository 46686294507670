import { PictureData } from 'components/form-elements/JsonForms/components/PictureTake';
import { addDocumentImageApi } from 'services/documents/add-document-image/api';
import axios from 'axios';
import { confirmDocumentImageApi } from 'services/documents/confirm-document-image/api';
import imageCompression from 'browser-image-compression';
import { DocumentRequestBase } from 'services/documents/types';

type Data = DocumentRequestBase & {
  data: PictureData;
  imageName: string;
  rollbar: any;
};

export async function uploadDocumentImageApi({
  documentId,
  templateId,
  imageName,
  data,
  rollbar,
}: Data) {
  const addedImage = await addDocumentImageApi({
    templateId,
    imageName,
    documentId,
  });

  rollbar.info(
    `[PICTURE_TAKE - 2] Successfully obtained upload image URL ${addedImage.addImageUrl}`,
  );

  const { data: blob } = await axios(data.imageUrl, {
    responseType: 'blob',
  });

  const compressedImage = await imageCompression(
    new File([blob], 'workspaceImage', {
      type: 'image/jpeg',
    }),
    {
      maxSizeMB: 0.1,
    },
  );

  rollbar.info('[PICTURE_TAKE - 3] Successfully compressed the image');

  await axios.put(addedImage.addImageUrl, compressedImage);

  rollbar.info('[PICTURE_TAKE - 4] Successfully uploaded the compressed image');

  return confirmDocumentImageApi({
    templateId,
    documentId,
    longitude: data.longitude,
    latitude: data.latitude,
    timestamp: data.timestamp,
    imageName,
  });
}
