import React from 'react';
import * as ThemeUI from 'theme-ui';
import { Flex } from 'components/layout';
import { AvatarText } from 'components/avatar/AvatarText';
import { colors } from 'theming/foundations/colors';
import { StyleObject } from 'theming';
import { UsersService } from 'services/users';

export type AvatarProps = {
  color?: string;
  firstName?: string;
  lastName?: string;
  sx?: StyleObject;
  active?: boolean;
  profileImageUrl: string;
  children?: React.ReactNode;
  size?: 'small' | 'large';
};

type ColorsProps = {
  [index: string]: string;
};

export function AvatarCircle({
  size,
  children,
  backgroundColor,
  disabled,
  sx,
}: {
  size: AvatarProps['size'];
  children: React.ReactNode;
  backgroundColor?: string;
  disabled?: boolean;
  sx?: StyleObject;
}) {
  const pxSize = size === 'small' ? '30px' : '60px';

  return (
    <Flex
      sx={{
        width: pxSize,
        height: pxSize,
        position: 'relative',
        backgroundColor: disabled ? 'grey.400' : backgroundColor,
        borderRadius: 'rounded',
        flexShrink: 0,
        lineHeight: 0,
        border: '1px solid #fff',
        justifyContent: 'center',
        opacity: disabled ? 0.25 : 1,

        ...sx,
      }}
    >
      {children}
    </Flex>
  );
}

export function Avatar({
  color = '0',
  firstName,
  lastName,
  sx,
  active = true,
  profileImageUrl,
  children,
  size = 'small',
}: AvatarProps) {
  const avatarColors: ColorsProps = colors.avatar;
  const initials = `${firstName?.charAt(0) || ''}${lastName?.charAt(0) || ''}`;

  const { image, shouldShowFallback } = UsersService.useFetchAvatarImage(profileImageUrl);

  return (
    <AvatarCircle
      size={size}
      backgroundColor={shouldShowFallback ? avatarColors[color] : 'white.50'}
      disabled={!active}
      sx={{
        ...sx,
        fontSize: size === 'small' ? 'md' : '4xl',
      }}
    >
      <ThemeUI.Image
        src={image}
        sx={{
          width: '100%',
          objectFit: 'cover',
          borderRadius: 'rounded',
          visibility: shouldShowFallback ? 'hidden' : 'visible',
        }}
      />

      {shouldShowFallback && <AvatarText initials={initials} />}

      {children}
    </AvatarCircle>
  );
}
