import { identify, makeRequest, Service } from 'utils/http';
import axios from 'axios';

export type Data = {
  image: File;
};

export async function updateWorkspaceImageApi(data: Data) {
  const { updateImageUrl } = await makeRequest(
    Service.updateWorkspaceImage(await identify()),
  );

  await axios.put(updateImageUrl, data.image);
}
