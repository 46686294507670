import * as React from 'react';
import { useReferrerRedirect } from 'utils/hooks';
import { Routing } from 'global/routing';
import { ConfirmationPageSuccess } from 'components/ConfirmationPageSuccess/ConfirmationPageSuccess';
import { useIntl } from 'lib/intl';
import { useSearchQuery } from '../../../utils/url/use-search-query';

export function SuccessConfirmation() {
  const query = useSearchQuery();
  const intl = useIntl();
  const { onReferrerRedirect } = useReferrerRedirect();

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      onReferrerRedirect(Routing.HOME.getPath(), 'replace');
    }, 4000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [onReferrerRedirect]);

  return (
    <ConfirmationPageSuccess
      description={intl.formatMessage({
        id: query.key ?? 'success.documentSent',
      })}
    />
  );
}
