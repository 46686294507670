import { useInviteUsers } from 'services/users/invite-users/hook';
import { useUpdateLoggedInUser } from 'services/users/update-logged-in-user/hook';
import { useGetLoggedInUser } from 'services/users/get-logged-in-user/hook';
import { useGetUsersTemplates } from 'services/users/get-users-templates/hook';
import { useCreateInvitedUser } from './create-invited-user/hook';
import { useGetUsers } from './get-users';
import { useUpdateUser } from './update-user';
import { useResendInvitation } from './resend-invitation/hook';
import { useGetExternalUsers } from './external-users/hook';
import { useFetchAvatarImage } from './get-avatar-image/hook';
import { useUpdateUserProfileImage } from './update-user-profile-image/hook';
import { useDeleteInvitedUser } from './delete-invited-user/hook';
import {
  useCurrentUserTemplatePermissions,
  useTemplatePermissions,
} from './template-permissions/hook';
import { useRemoveExternalUsers } from './remove-external-users';

export const UsersService = {
  useCreateInvitedUser,
  useFetchAvatarImage,
  useGetExternalUsers,
  useGetLoggedInUser,
  useGetUsers,
  useInviteUsers,
  useResendInvitation,
  useUpdateLoggedInUser,
  useUpdateUser,
  useUpdateUserProfileImage,
  useDeleteInvitedUser,
  useTemplatePermissions,
  useCurrentUserTemplatePermissions,
  useGetUsersTemplates,
  useRemoveExternalUsers,
};

export * from './types';
