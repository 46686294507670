import { Auth } from 'aws-amplify';
import { ForgotPassword } from 'services/auth/forgot-password/api';

type ForgotPasswordConfirm = ForgotPassword & {
  confirmationCode: string;
  newPassword: string;
};

export function forgotPasswordConfirmApi(data: ForgotPasswordConfirm) {
  return Auth.forgotPasswordSubmit(
    data.email,
    data.confirmationCode,
    data.newPassword,
  );
}
