import * as Illustrations from 'components/illustrations';
import * as React from 'react';
import { useModal } from 'store/modals';
import { Container } from '../../../components/layout';
import { LanguageSwitcher } from '../../../components/LanaguageSwitcher/LanguageSwitcher';
import { useIntlContext } from '../../../translations';
import { H1 } from '../../../components/typography/H1';
import { IconButton } from '../../../components/form-elements/buttons/IconButton';

import * as Icons from '../../../components/icons';

export function ChooseLanguage() {
  const { closeModal } = useModal();
  const language = useIntlContext();

  return (
    <Container
      variant="spacer"
      sx={{
        position: 'relative',
        backgroundColor: 'bg.50',
        padding: '20px 40px',
        borderRadius: '8px',
      }}
    >
      <Illustrations.Languages />

      <H1 intlId="chooseLanguage.title" sx={{ my: 8 }} />

      <LanguageSwitcher
        onLanguageChange={(newLanguage) => language.setLanguage(newLanguage)}
        defaultSelected={language.activeLanguage}
      />

      <IconButton
        sx={{
          position: 'absolute',
          top: 8,
          right: 6,
          color: '#000',
        }}
        onClick={() => closeModal()}
      >
        <Icons.Clear />
      </IconButton>
    </Container>
  );
}
