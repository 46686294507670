import { DeepObjectKeys } from 'utils/types';

export type Colors = DeepObjectKeys<typeof colors>;

export const colors = {
  primary: {
    '050': '#E6E9F3',
    100: '#C0C7E2',
    200: '#97A3CE',
    300: '#6E80BA',
    400: '#4F65AC',
    500: '#2C4B9F',
    600: '#264496',
    700: '#1C3A8B',
    800: '#12317F',
    900: '#012169',
  },

  secondary: {
    '050': '#E3F1FD',
    100: '#BCDCFB',
    200: '#91C7F9',
    300: '#67B1F5',
    400: '#49A1F4',
    500: '#3192F1',
    600: '#2F83E3',
    700: '#2B71D0',
    800: '#2760BD',
    900: '#21429E',
  },

  tertiary1: {
    '050': '#E2F9ED',
    100: '#B9F0D2',
    200: '#86E6B6',
    300: '#3DDC97',
    400: '#00D37F',
    500: '#00C869',
    600: '#00B85E',
    700: '#00A551',
    800: '#009345',
    900: '#00732F',
  },

  tertiary2: {
    '050': '#FFF8E3',
    100: '#FFECB8',
    200: '#FFE08B',
    300: '#FFD65F',
    400: '#FFCB42',
    500: '#FFC235',
    600: '#FFB531',
    700: '#FFA32F',
    800: '#FF922D',
    900: '#FC752A',
  },

  bg: {
    50: '#f1f3f6',
  },

  white: {
    50: '#FFF',
    100: '#F8F8F8',
  },

  grey: {
    100: '#F6F6F6',
    200: '#D2D2D2',
    300: '#C2C2C2',
    400: '#8686A1',
    800: '#4D4D65B3',
    900: '#00000099',
  },

  purple: {
    300: '#6E80BA',
  },

  darkgrey: {
    800: '#4D4D65',
  },

  textprimary: {
    900: '#070707',
  },

  error: '#B00020',
  subtitle2: '#1A1D21',
  disabledText: '#E6E6E6',
  overlay: {
    300: 'rgba(0, 0, 0, 0.3)',
    600: 'rgba(7, 7, 7, 0.6)',
  },

  outlinedTextWidget: {
    background: '#FFFFFF4D',
  },

  avatar: {
    0: '#FF922D',
    1: '#3DDC97',
    2: '#8686A1',
    3: '#BAA100',
    4: '#0069BA',
    5: '#00BAA7',
    6: '#FF49AA',
    7: '#4F65AC',
    8: '#3192F1',
    9: '#958477',
  },
};
