import { identify, makeRequest, sanitize, Service } from 'utils/http';
import { UpdateUserRequest } from 'trace-backend-sdk';

type Data = UpdateUserRequest;

export async function updateLoggedInUserApi(data: Data) {
  return makeRequest(
    Service.updateLoggedInUser(
      // @ts-expect-error Need to sanitize input, but it returns Dictionary<unknown>
      sanitize({
        firstName: data.firstName,
        lastName: data.lastName,
        title: data.title,
      }),
      await identify(),
    ),
  );
}
