import { identify, makeRequest, Service } from 'utils/http';
import { DocumentRequestBase } from 'services/documents/types';
import { DocumentWidgetEnum } from 'trace-backend-sdk';

type Data = DocumentRequestBase & {
  widget: DocumentWidgetEnum;
};

export async function addDocumentWidgetApi({
  widget,
  documentId,
  templateId,
}: Data) {
  return makeRequest(
    Service.addDocumentWidget(documentId, templateId, widget, await identify()),
  );
}
