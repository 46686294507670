/* eslint-disable camelcase */
import * as ReactQuery from 'react-query';
import { Auth } from 'aws-amplify';
import { CurrentAuthenticatedUserError } from 'utils/auth/errors';
import * as Utils from 'utils/index';
import { InferQueryOptions } from '../types';
import { LOCAL_STORAGE_KEYS, useLocalStorage } from '../local-storage';

export const QUERY_KEY_CURRENT_USER = 'currentUser';

export function useCurrentAuthenticatedUser(
  options?: InferQueryOptions<() => CurrentAuthenticatedUser>,
) {
  const [assumedEmails, setAssumedEmails] = useLocalStorage<string[]>(
    LOCAL_STORAGE_KEYS.ASSUMED_EMAILS,
    [],
  );

  const { setCurrentUserId } = Utils.Auth.useCurrentUserId();
  const { setIsWorkspaceRedirectInProgress } = Utils.Auth.useIsWorkspaceRedirectInProgress();

  const { data: currentUser, ...rest } = ReactQuery.useQuery(
    QUERY_KEY_CURRENT_USER,
    async (): Promise<CurrentAuthenticatedUser> => {
      try {
        return await Auth.currentAuthenticatedUser();
      } catch (error) {
        /**
         * Throw custom error because we want to check in
         * React Query default "onError" callback (services/index.ts)
         * that the error is *not* one of the errors that Amplify throws when
         * it tries to get the current authenticated user.
         * */
        throw new CurrentAuthenticatedUserError(error as any);
      }
    },
    {
      ...options,
      onSuccess: async (data) => {
        /**
         * "onSuccess" results from Amplify "currentAuthenticatedUser"
         * function (see the query function above).
         * When it resolves, it means that the current user's
         * session is still valid, and it provides us the current user's
         * id in the form of the "sub" attribute.
         *
         * The above means that whenever the "currentAuthenticatedUser" promise
         * resolves, we can be sure that there is a logged in user and we can set
         * the "currentUserId" in Little State Machine to be the ID of the Amplify user.
         *
         * Once the "currentUserId" is set, our app will transition into the "logged in"
         * state, because "useIsAuthenticated" hook listens for "currentUserId" to
         * decide the app's state (logged in / logged out).
         * */
        setCurrentUserId(data.attributes.sub);

        await Utils.Auth.redirectToWorkspaceDomain({
          amplifyStorage: localStorage,
          tenantId: data.signInUserSession.idToken.payload.tenant_id,
          currentUserUsername: data.attributes.sub,
          onStartRedirect: () => setIsWorkspaceRedirectInProgress(true),
          workspaceName:
            data.signInUserSession.idToken.payload.tenant_subdomain,
        });

        const currentUserEmail = data.signInUserSession.idToken.payload.email;
        if (!assumedEmails?.includes(currentUserEmail)) {
          setAssumedEmails((old) =>
            (old ? [...old, currentUserEmail] : [currentUserEmail]));
        }

        if (options?.onSuccess) {
          options.onSuccess(data);
        }
      },
    },
  );

  return {
    currentUser,
    userAttributesFromToken: currentUser?.signInUserSession.idToken.payload,
    ...rest,
  };
}

export type CurrentAuthenticatedUser = {
  storage: Record<string, string>;
  attributes: {
    sub: string;
    'custom:avatar_color': string;
    phone_number: string;
    given_name: string;
    family_name: string;
    email: string;
  };
  signInUserSession: {
    idToken: {
      jwtToken: string;
      payload: {
        super_admin: 'true' | 'false';
        tenant_subdomain: string;
        tenant_id: string;
        sub: string;
        'custom:avatar_color': string;
        role: string;
        iss: string;
        'cognito:username': string;
        given_name: string;
        aud: string;
        event_id: string;
        token_use: string;
        auth_time: number;
        phone_number: string;
        exp: number;
        iat: number;
        family_name: string;
        email: string;
      };
    };
    refreshToken: {
      token: string;
    };
  };
};
