/* eslint-disable max-classes-per-file */

// Base Amplify error
export class AmplifyAuthError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'AmplifyAuthError';
  }
}

export class CurrentAuthenticatedUserError extends AmplifyAuthError {
  constructor(message: string) {
    super(message);
    this.name = 'CurrentAuthenticatedUserError';
  }
}
