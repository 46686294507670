import React, { ReactNode } from 'react';
import * as R from 'remeda';
import * as ReactIntl from 'lib/intl';
import { LOCAL_STORAGE_KEYS, useLocalStorage } from '../utils/local-storage';
import { generateContext } from '../utils/context';
import { useSearchQuery } from '../utils/url/use-search-query';
import { getSupportedLanguages } from '../utils/intl/get-supported-countries';
import englishTranslations from './en-US.json';
import swedishTranslations from './sv-SE.json';
import norwegianTranslations from './no-NO.json';

import swedishCountries from './countries/sv.json';
import englishCountries from './countries/en.json';
import norwegianCountries from './countries/no.json';

console.log(englishTranslations);

export interface IntlProps {
  intlId?: string;
  intlValues?: Record<string, ReactNode>;
  intlDefaultMessage?: string;
}

type LocaleMessages = typeof swedishTranslations;
export type LocaleKey = keyof LocaleMessages;

const generateCountriesTranslation = (
  countriesArray: Record<string, string>,
) => {
  return R.fromPairs(
    R.toPairs(countriesArray).map(([alpha2, name]) => [
      `country.${alpha2.toLowerCase()}`,
      name,
    ]),
  );
};

export const LANGUAGE_ENGLISH = 'en';
export const LANGUAGE_SWEDISH = 'sv';
export const LANGUAGE_NORWEGIAN = 'no';

export type Language =
  | typeof LANGUAGE_ENGLISH
  | typeof LANGUAGE_SWEDISH
  | typeof LANGUAGE_NORWEGIAN;

const TRANSLATIONS = {
  [LANGUAGE_ENGLISH]: {
    ...englishTranslations,
    ...generateCountriesTranslation(englishCountries),
  },
  [LANGUAGE_SWEDISH]: {
    ...swedishTranslations,
    ...generateCountriesTranslation(swedishCountries),
  },
  [LANGUAGE_NORWEGIAN]: {
    ...norwegianTranslations,
    ...generateCountriesTranslation(norwegianCountries),
  },
};

type IntlContext = {
  setLanguage: (language: Language) => void;
  activeLanguage: Language;
};
const [useIntlContext, IntlContextProvider] = generateContext<IntlContext>();

const IntlProvider: React.FC = ({ children }) => {
  const searchQuery = useSearchQuery();
  const defaultLanguage: Language = searchQuery.lang
    && getSupportedLanguages().includes(searchQuery.lang as Language)
    ? (searchQuery.lang as Language)
    : 'sv';

  const isDebugging = useDebugTranslations();
  const [activeLanguage, setActiveLanguage] = useLocalStorage<Language>(
    LOCAL_STORAGE_KEYS.LANG,
    defaultLanguage,
  );

  const translations = React.useMemo(() => {
    return activeLanguage ? TRANSLATIONS[activeLanguage] : {};
  }, [activeLanguage]);

  const intlContext = React.useMemo<IntlContext>(
    () => ({
      setLanguage: (language) => setActiveLanguage(language),
      activeLanguage: activeLanguage!,
    }),
    [activeLanguage, setActiveLanguage],
  );

  return (
    <ReactIntl.IntlProvider
      locale={activeLanguage!}
      messages={isDebugging ? {} : translations}
      onError={isDebugging ? () => {} : undefined}
    >
      <IntlContextProvider value={intlContext}>{children}</IntlContextProvider>
    </ReactIntl.IntlProvider>
  );
};

function useDebugTranslations() {
  const [isDebugging, setIsDebugging] = React.useState(false);

  React.useEffect(() => {
    if (['stage', 'prod'].includes(process.env.REACT_APP_ENVIRONMENT)) {
      return () => {};
    }

    function onKeydown(ev: KeyboardEvent) {
      if (ev.key === '`') {
        setIsDebugging((d) => !d);
      }
    }

    document.addEventListener('keydown', onKeydown);

    return () => document.removeEventListener('keydown', onKeydown);
  }, []);

  return isDebugging;
}

export { IntlProvider, useIntlContext };
