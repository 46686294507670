import { Link } from 'components/links';
import { Routing } from 'global/routing';
import * as Icons from 'components/icons';
import * as ReactRouter from 'react-router-dom';
import React from 'react';
import { NotificationsService } from '../../../services';

export function NotificationMessagesButton() {
  const isOnActiveRoute = ReactRouter.useRouteMatch(Routing.MESSAGES.Default);
  const getAllNotifications = NotificationsService.useGetAllNotifications();

  const hasUnseenMessages = getAllNotifications.notificationMessages.some(
    (message) => message.dateSeen == null,
  );

  function getIcon() {
    if (isOnActiveRoute) {
      return <Icons.Bell />;
    }

    if (hasUnseenMessages) {
      return <Icons.BellActive />;
    }

    return <Icons.BellOutlined />;
  }

  return (
    <Link
      // TODO: Separate link from the component
      to={Routing.MESSAGES.Default}
      sx={{
        display: 'inline-flex',
      }}
    >
      {getIcon()}
    </Link>
  );
}
