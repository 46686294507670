import React from 'react';
import * as ThemeUI from 'theme-ui';
import * as ReactRouterDom from 'react-router-dom';
import { LinkVariants } from 'theming/variants/links';
import * as ReactIntl from 'lib/intl';
import { IntlProps } from 'translations';

// Cast the type of ThemeUI.LinkProps to also include ReactRouterDom.NavLinkProps
type ThemeUILinkProps = ThemeUI.LinkProps & ReactRouterDom.NavLinkProps;
const ThemeUILink: React.FC<ThemeUILinkProps> = ThemeUI.Link;

export interface LinkProps extends ThemeUILinkProps, IntlProps {
  variant?: LinkVariants;
}

export const Link: React.FC<LinkProps> = ({
  to,
  variant,
  intlId,
  intlValues,
  intlDefaultMessage,
  children,
  ...props
}) => (
  <ThemeUILink
    as={ReactRouterDom.NavLink} variant={variant}
    to={to} {...props}
  >
    {intlId ? (
      <ReactIntl.FormattedMessage
        id={intlId}
        defaultMessage={intlDefaultMessage}
        values={intlValues}
      />
    ) : (
      children
    )}
  </ThemeUILink>
);
