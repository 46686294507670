import * as _ from 'lodash-es';
import * as Utils from 'utils';
import { useDocumentParams } from '../../pages';
import { DocumentsService } from '../../services/documents';

/*
 * Sets the document's initial value either from
 * React Query cache (if it exists), or waits for a GET
 * request to the document to finish before populating
 * the state with the document data from the response.
 * */
export function useDocumentFormState() {
  const params = useDocumentParams();
  const { document } = DocumentsService.useGetDocument(
    params.documentId,
    params.templateId,
    {
      onSuccess: (data) => {
        /*
         * We only want the initial value from the request,
         * cause continuous updates would require implementing
         * a solution that syncs local and async server updates.
         * */
        if (_.isEmpty(formData)) {
          setFormData(data.documentData ?? {});
        }
      },
    },
  );
  const [formData, setFormData] = Utils.Document.useProxiedFormState(
    document?.documentData ?? {},
  );

  return [formData, setFormData] as const;
}
