import React from 'react';
import * as ReactRouter from 'react-router-dom';
import * as _ from 'lodash-es';
import { Notification } from 'components/Notification/Notification';
import { Flex } from 'components/layout';
import { generateContext } from 'utils/context';
import {
  NotificationTag,
  NotificationType,
} from 'components/notifications/notification-types';
import * as UUID from 'uuid';
import ReactDOM from 'react-dom';
import { FormattedMessage, useIntl } from 'lib/intl';

const [useNotificationsStateContext, NotificationsStateProvider] = generateContext<{
    notifications:(NotificationType & { id: string })[];
    addNotification: (notification: NotificationType) => void;
    removeNotification: (id: string) => void;
    invalidateNotifications: (tag: NotificationTag) => void;
  }>();

function normalizeTag(tag: NotificationTag) {
  return typeof tag === 'string' ? tag : tag.join('');
}

export function NotificationsProvider(props: { children: React.ReactNode }) {
  const [notifications, setNotifications] = React.useState<(NotificationType & { id: string })[]
    >([]);

  const removeNotification = React.useCallback((id: string) => {
    setNotifications((oldNotifications) =>
      oldNotifications.filter((notification) => notification.id !== id));
  }, []);

  const addNotification = React.useCallback(
    (notification: NotificationType) => {
      const id = UUID.v4();

      setNotifications((oldNotifications) => [
        ...oldNotifications,
        {
          ...notification,
          id,
        },
      ]);

      if (notification.durationMs && notification.durationMs !== 'Infinity') {
        setTimeout(() => {
          removeNotification(id);
        }, notification.durationMs);

        return;
      }

      if (notification.durationMs == null) {
        setTimeout(() => {
          removeNotification(id);
        }, 4000);
      }
    },
    [removeNotification],
  );

  /*
   * Use to invalidate notifications based on their tag.
   * */
  const invalidateNotifications = React.useCallback((tag: NotificationTag) => {
    setNotifications((oldNotifications) => {
      const normalizedTag = normalizeTag(tag);

      return oldNotifications.filter((notification) => {
        if (!notification.tag) return true;

        return normalizedTag !== normalizeTag(notification.tag);
      });
    });
  }, []);

  const value = React.useMemo(
    () => ({
      notifications,
      addNotification,
      removeNotification,
      invalidateNotifications,
    }),
    [
      addNotification,
      invalidateNotifications,
      notifications,
      removeNotification,
    ],
  );

  return (
    <NotificationsStateProvider value={value}>
      {props.children}
    </NotificationsStateProvider>
  );
}

export const Notifications = () => {
  const { notifications, removeNotification } = useNotificationsStateContext();
  const intl = useIntl();

  // Filter so we don't show the same notifications multiple times
  const uniqueNotifications = _.uniqBy(notifications, (notification) =>
    intl.formatMessage({
      id: notification.description,
    }));

  const container = document.body;
  const element = (
    <Flex
      sx={{
        right: 0,
        width: 'fit-content',
        position: 'absolute',
        top: '64px',
        flexDirection: 'column',
        gap: 2,
        alignItems: 'flex-end',
        pr: 2,
        zIndex: 'highest',
      }}
    >
      {uniqueNotifications.map((notification) => (
        <Notification
          key={notification.id}
          variant="toast"
          severity={notification.severity}
          titleIntlId={notification.title}
          onClose={() => removeNotification(notification.id)}
        >
          <Notification.Description>
            <FormattedMessage
              id={notification.description}
              values={notification.intlValues}
            />
          </Notification.Description>
        </Notification>
      ))}
    </Flex>
  );

  return ReactDOM.createPortal(element, container);
};

export const useNotifications = () => {
  const { addNotification, removeNotification, invalidateNotifications } = useNotificationsStateContext();
  const location = ReactRouter.useLocation();
  const history = ReactRouter.useHistory();

  const currentRouteTag = React.useMemo(
    () => [location.pathname, location.search],
    [location.pathname, location.search],
  );

  React.useEffect(
    () =>
      history.listen(() => {
        invalidateNotifications(currentRouteTag);
      }),
    [currentRouteTag, history, invalidateNotifications],
  );

  const success = React.useCallback(
    (options: Omit<NotificationType, 'severity'>) => {
      addNotification({
        ...options,
        severity: 'success',
        tag: options.tag ?? currentRouteTag,
      });
    },
    [addNotification, currentRouteTag],
  );

  const error = React.useCallback(
    (options: Omit<NotificationType, 'severity'>) => {
      addNotification({
        ...options,
        severity: 'error',
        tag: options.tag ?? currentRouteTag,
      });
    },
    [addNotification, currentRouteTag],
  );

  const info = React.useCallback(
    (options: Omit<NotificationType, 'severity'>) => {
      addNotification({
        ...options,
        severity: 'info',
        tag: options.tag ?? currentRouteTag,
      });
    },
    [addNotification, currentRouteTag],
  );

  return React.useMemo(
    () => ({
      create: addNotification,
      remove: removeNotification,

      success,
      error,
      info,
    }),
    [info, removeNotification, error, addNotification, success],
  );
};
