import { z } from 'zod';

export const ORGANIZATION_NAME = z
  .string()
  .min(1, { message: 'createWorkspace.form.input.name.error.required' })
  .max(100);

export const STREET = z
  .string()
  .min(1, { message: 'createWorkspace.form.input.street.error.required' })
  .max(100);

export const ZIP_CODE = z
  .string()
  .min(1, { message: 'createWorkspace.form.input.zip.error.required' })
  .max(100);

export const CITY = z
  .string()
  .min(1, { message: 'createWorkspace.form.input.city.error.required' })
  .max(100);

export const COUNTRY = z
  .string()
  .min(1, { message: 'createWorkspace.form.input.country.error.required' });

export const WEBSITE = z.string().optional();
