/** @jsxImportSource theme-ui */
import React from 'react';
import { LinkProps } from 'components/links';
import * as ReactRouter from 'react-router-dom';
import { NavLinkProps } from 'react-router-dom';

type Props = LinkProps & NavLinkProps;

export const NavLink: React.FC<Props> = ({ to, children, ...props }) => (
  <ReactRouter.NavLink
    to={to}
    {...props}
    sx={{
      variant: `links.${props.variant ?? 'nav'}`,
      ...props.sx,
    }}
  >
    {children}
  </ReactRouter.NavLink>
);
