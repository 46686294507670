import * as Intl from 'lib/intl';
import { sv, enUS } from 'date-fns/esm/locale';
import { LANGUAGE_ENGLISH, LANGUAGE_SWEDISH } from 'translations';
import { formatRelative } from 'date-fns';
import React from 'react';

export function useLocaleDateFormat() {
  const { formatDate } = Intl.useIntl();

  const localeDateFormat = React.useCallback(
    (date: Date | string) => formatDate(date),
    [formatDate],
  );

  return {
    localeDateFormat,
  };
}

export function useLocaleTimeFormat() {
  const { formatTime } = Intl.useIntl();

  const localeTimeFormat = React.useCallback(
    (date: Date | string) => formatTime(date),
    [formatTime],
  );

  return {
    localeTimeFormat,
  };
}

const LOCAL_LOCALES_TO_DATE_FNS = {
  [LANGUAGE_ENGLISH]: enUS,
  [LANGUAGE_SWEDISH]: sv,
};

export function useLocaleRelativeFormat() {
  const intl = Intl.useIntl();

  const localeRelativeFormat = React.useCallback(
    (date: number | Date, relativeToDate: number | Date) =>
      formatRelative(date, relativeToDate, {
        // @ts-expect-error Key error
        locale: LOCAL_LOCALES_TO_DATE_FNS[intl.locale],
      }),
    [intl.locale],
  );

  const localeRelativeToNowFormat = React.useCallback(
    (date: number | Date) => localeRelativeFormat(date, new Date()),
    [localeRelativeFormat],
  );

  return { localeRelativeFormat, localeRelativeToNowFormat };
}
