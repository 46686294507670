import { matchSorter } from 'match-sorter';
import { useForm } from 'react-hook-form';
import { useGetLatest } from 'utils/hooks';
import React from 'react';
import { Forms } from 'components/form-elements';
import { IconButton } from 'components/form-elements/buttons/IconButton';
import * as Icons from 'components/icons';
import {
  Column,
  useGlobalFilter,
  usePagination,
  useSortBy,
  UseSortByState,
  useTable,
} from 'react-table';
import { Box, Flex } from 'components/layout';
import { Text } from 'components/typography';

const fuzzyTextFilterFn = (rows: any[], id: any, filterValue: string) =>
  matchSorter(rows, filterValue, {
    keys: [(row) => row.values[id]],
  });

fuzzyTextFilterFn.autoRemove = (val: any) => !val;

function TableGlobalFilter({
  onGlobalFilterChange,
  onResetGlobalFilter,
}: {
  onResetGlobalFilter: () => void;
  onGlobalFilterChange: (value: string) => void;
}) {
  const formMethods = useForm<{ query: string }>({
    defaultValues: {
      query: '',
    },
  });
  const getOnResetGlobalFilter = useGetLatest(onResetGlobalFilter);
  const searchQueryValue = formMethods.watch('query');

  React.useEffect(() => {
    if (!searchQueryValue) {
      getOnResetGlobalFilter()();
    }
  }, [searchQueryValue, getOnResetGlobalFilter]);

  return (
    <Forms.Provider
      sx={{
        mb: 4,
      }}
      {...formMethods}
      name="search"
      onValid={(data) => {
        onGlobalFilterChange(data.query);
      }}
    >
      <Forms.FieldEditText
        size="medium"
        placeholderIntlId="search.placeholder.label"
        name="query"
        AppendIcon={(
          <IconButton type="submit">
            <Icons.Search width={20} stroke="#333" />
          </IconButton>
        )}
      />
    </Forms.Provider>
  );
}

function TablePaginationButton({
  children,
  onClick,
  enabled,
}: {
  children: React.ReactNode;
  onClick: React.MouseEventHandler;
  enabled?: boolean;
}) {
  return (
    <IconButton
      disabled={!enabled}
      onClick={onClick}
      sx={{
        color: 'primary.900',
        '&:disabled': {
          color: 'grey.800',
          pointerEvents: 'none',
        },
        '&:hover': {
          backgroundColor: '#E3F1FD',
        },
        transition: 'background-color 0.1s ease-in-out',
      }}
    >
      {children}
    </IconButton>
  );
}

// TODO: Fix later
type FixLater = any;

// eslint-disable-next-line @typescript-eslint/ban-types
export function Table<TData extends object>({
  columns,
  data,
  sortBy,
}: {
  columns: readonly Column<TData>[];
  data: TData[];
  sortBy?: UseSortByState<TData>['sortBy'];
}) {
  const filterTypes: FixLater = React.useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
    }),
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,

    setGlobalFilter,

    rows,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      filterTypes,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        sortBy,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const pageStartIndex = pageIndex * pageSize + 1;
  const pageEndIndex = Math.min(pageStartIndex + pageSize, rows.length);

  return (
    <Box
      sx={{
        width: '100%',
        overflow: 'auto',
        boxShadow: '4dp',
        borderRadius: 1,
        px: 4,
        py: 4,
      }}
    >
      <TableGlobalFilter
        onResetGlobalFilter={() => setGlobalFilter(undefined)}
        onGlobalFilterChange={(value) => setGlobalFilter(value)}
      />

      <Box
        as="table"
        sx={{
          borderSpacing: 0,
          width: '100%',
        }}
        {...getTableProps()}
      >
        <Box
          as="thead"
          sx={{
            backgroundColor: '#E6E9F3',
            borderTopLeftRadius: 1,
            borderTopRightRadius: 1,
          }}
        >
          {headerGroups.map((headerGroup) => (
            <Box
              as="tr"
              sx={{
                '& > *:first-child': {
                  borderTopLeftRadius: 1,
                },
                '& > *:last-child': {
                  borderTopRightRadius: 1,
                },
              }}
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => (
                <Box
                  as="th"
                  sx={{
                    py: 4,
                    pr: 10,
                    whiteSpace: 'nowrap',
                    fontWeight: 500,
                    color: 'primary.900',
                    fontSize: 'md',
                    textAlign: 'right',
                    textTransform: 'capitalize',
                  }}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  <Flex sx={{ justifyContent: 'flex-end' }}>
                    <Box
                      sx={{
                        visibility: column.isSorted ? 'visible' : 'hidden',
                        display: 'inline-flex',
                        mr: 1,
                      }}
                    >
                      {column.isSortedDesc ? (
                        <Icons.ArrowUpward size={20} fill="currentColor" />
                      ) : (
                        <Icons.ArrowDownward size={20} fill="currentColor" />
                      )}
                    </Box>

                    {column.render('Header')}
                  </Flex>
                </Box>
              ))}
            </Box>
          ))}
        </Box>

        <Box as="tbody" {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <Box
                sx={{
                  '&:hover': {
                    backgroundColor: 'white.100',
                  },
                  '&:hover *[data-table-action-buttons]': {
                    opacity: 1,
                  },

                  transition: 'background-color 0.3s',

                  '& > *': {
                    pr: 10,
                  },
                }}
                as="tr"
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => (
                  <Box
                    as="td"
                    sx={{
                      py: 2,
                      textAlign: 'right',
                      fontSize: 'md',
                      color: '#333',
                      minWidth: cell.column.minWidth,
                    }}
                    {...cell.getCellProps()}
                  >
                    {cell.render('Cell')}
                  </Box>
                ))}
              </Box>
            );
          })}
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          py: 4,
          backgroundColor: '#E6E9F3',
          borderBottomLeftRadius: 1,
          borderBottomRightRadius: 1,
          width: '100%',
          px: 8,
        }}
      >
        <Text
          as="p"
          sx={{
            color: 'primary.900',
            mr: 8,
            fontSize: 'md',
            minWidth: 100,
          }}
        >
          {pageStartIndex} - {pageEndIndex} of {rows.length}
        </Text>

        <Flex
          sx={{
            width: 'auto',
            '& > *:not(:last-child)': {
              mr: 2,
            },
          }}
        >
          <TablePaginationButton
            enabled={pageIndex > 0}
            onClick={() => gotoPage(0)}
          >
            <Icons.FirstPage fill="currentColor" />
          </TablePaginationButton>

          <TablePaginationButton
            enabled={canPreviousPage}
            onClick={() => previousPage()}
          >
            <Icons.PreviousPage size={16} fill="currentColor" />
          </TablePaginationButton>

          <TablePaginationButton
            enabled={canNextPage}
            onClick={() => nextPage()}
          >
            <Icons.NextPage size={16} fill="currentColor" />
          </TablePaginationButton>

          <TablePaginationButton
            enabled={pageIndex < pageCount - 1}
            onClick={() => gotoPage(pageCount - 1)}
          >
            <Icons.LastPage fill="currentColor" />
          </TablePaginationButton>
        </Flex>
      </Box>
    </Box>
  );
}
