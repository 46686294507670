import * as Lsm from 'little-state-machine';
import { AuthActions } from 'store';

export function useIsAuthenticated() {
  const { currentUserId } = useCurrentUserId();

  return {
    isAuthenticated: currentUserId != null,
  };
}

export function useCurrentUserId() {
  const { actions, state } = Lsm.useStateMachine({
    setCurrentUserId: AuthActions.setCurrentUserId,
  });

  return {
    currentUserId: state.currentUserId,
    setCurrentUserId: actions.setCurrentUserId,
  };
}
