import React from 'react';

export function useEventListener<
  TElement extends Element,
  TEventType extends keyof ElementEventMap
>(element: TElement, type: TEventType, listener: EventListener) {
  React.useEffect(() => {
    if (!element) {
      return undefined;
    }

    element.addEventListener(type, listener);

    return () => {
      element.removeEventListener(type, listener);
    };
  }, [element, type, listener]);
}
