/* eslint-disable max-len */
export const Constants = {
  EMAIL_REGEX:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  PASSWORD_REGEX:
    /(?=.*[0-9])(?=.*[A-Za-z])(?=.*[/\]@!;:?€'#.<|>$%^&"+=,*\\[)_({}~`-])/,
  PHONE_REGEX: /^\+?[1-9]\d{1,14}$/,
};

export const DEVICE_WIDTHS = {
  TABLET: 768,
};

export const DEFAULT_TEMPLATES: string[] = [];
