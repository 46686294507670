import * as ReactQuery from 'react-query';
import { useNotifications } from 'components/notifications/notifications';
import { identify, makeRequest, Service } from '../../../utils/http';
import { InferMutationOptions } from '../../../utils/types';

type Data = Parameters<typeof Service.grantTemplatePermission>[0];
async function grantTemplatePermissionApi(data: Data) {
  return makeRequest(Service.grantTemplatePermission(data, await identify()));
}

function useGrantTemplatePermission(
  options?: InferMutationOptions<typeof grantTemplatePermissionApi>,
) {
  const queryClient = ReactQuery.useQueryClient();
  const notifications = useNotifications();
  const { mutate: grantTemplatePermission, ...rest } = ReactQuery.useMutation(
    'grantTemplatePermission',
    grantTemplatePermissionApi,
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        notifications.success({
          description: 'user.actions.changeModuleSuccess',
          durationMs: 2000,
        });

        await queryClient.invalidateQueries(['workspace']);

        if (!variables?.items) return;

        if (options?.onSuccess) {
          await options.onSuccess(data, variables, context);
        }
      },
    },
  );

  return {
    grantTemplatePermission,
    ...rest,
  };
}

export { useGrantTemplatePermission };
