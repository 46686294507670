import { CardElement } from '@stripe/react-stripe-js';
import React from 'react';
import { Box } from '../../../components/layout';

type StripeCardElementProps = {
  postalCode?: string;
};

export function StripeCardElement({ postalCode }: StripeCardElementProps) {
  return (
    <Box
      sx={{
        backgroundColor: 'white.100',
        padding: '18.5px 14px',
        borderRadius: 0,
        border: '1px solid',
        borderColor: '#9E9EAF',
      }}
    >
      <CardElement
        options={{
          hidePostalCode: true,
          value: {
            postalCode,
          },
          style: {
            base: {
              iconColor: '#333',
              color: '#000',
              fontWeight: 500,

              fontFamily: 'Poppins, sans-serif',
              fontSize: '16px',
              fontSmoothing: 'antialiased',
              ':-webkit-autofill': {
                color: '#fce883',
              },
              '::placeholder': {
                color: '#C2C2C2',
              },
            },
          },
        }}
      />
    </Box>
  );
}
