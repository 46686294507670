import * as React from 'react';
import { ErrorListProps } from '@rjsf/core';
import * as ThemeUI from 'theme-ui';
import { Notification } from 'components/Notification/Notification';
import { ErrorMessage } from 'components/form-elements/JsonForms/components/ErrorMessage';
import { Text } from 'components/typography';
import { createPortal } from 'react-dom';
import { LocaleKey } from 'translations';
import { Box } from '../../../layout';

export const ERROR_LIST_CONTAINER_ID = 'ERROR_LIST_CONTAINER_ID';

export function ErrorList(props: ErrorListProps) {
  const container = document.getElementById(ERROR_LIST_CONTAINER_ID);

  const element = (
    <ThemeUI.Box>
      <Notification severity="error">
        <ThemeUI.Box sx={{ ml: -7, mt: 2 }}>
          {props.errors.map((error) => (
            <ThemeUI.Box
              key={error.message}
              as="li"
              sx={{
                '&::marker': {
                  mr: 1,
                },
              }}
            >
              <Box as="span" sx={{ pl: '6px' }}>
                <ErrorMessage>
                  <Text intlId={error.message as LocaleKey} />
                </ErrorMessage>
              </Box>
            </ThemeUI.Box>
          ))}
        </ThemeUI.Box>
      </Notification>
    </ThemeUI.Box>
  );

  return container ? createPortal(element, container) : element;
}
