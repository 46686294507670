import { FieldProps } from '@rjsf/core';
import React from 'react';
import { ReliningFieldWrapper } from './ReliningFieldWrapper';

export function ReliningBatch(
  props: FieldProps<{ batchImage?: Record<string, any> }>,
) {
  return (
    <ReliningFieldWrapper>
      <props.registry.fields.ObjectField {...props} />
    </ReliningFieldWrapper>
  );
}
