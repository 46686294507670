import * as ReactQuery from 'react-query';
import { identify, makeRequest, Service } from '../../../utils/http';
import { InferMutationOptions } from '../../../utils/types';
import { DocumentRequestBase } from '../types';

type Data = DocumentRequestBase &
  Parameters<typeof Service.clearDocumentWidget>[2];
async function clearDocumentWidgetApi({
  documentId,
  templateId,
  widgetKey,
}: Data) {
  return makeRequest(
    Service.clearDocumentWidget(
      documentId,
      templateId,
      { widgetKey },
      await identify(),
    ),
  );
}

function useClearDocumentWidget(
  options?: InferMutationOptions<typeof clearDocumentWidgetApi>,
) {
  const { mutate: clearDocumentWidget, ...rest } = ReactQuery.useMutation(
    'clearDocumentWidget',
    clearDocumentWidgetApi,
    options,
  );

  return {
    clearDocumentWidget,
    ...rest,
  };
}

export { useClearDocumentWidget };
