import { Box } from 'components/layout';
import * as ThemeUI from 'theme-ui';
import { SwitchVariants } from 'theming/variants/forms';

type SwitchProps = Omit<ThemeUI.SwitchProps, 'ref'> & {
  variant?: SwitchVariants;
  label?: string;
};

export function Switch({ label, variant, ...props }: SwitchProps) {
  return (
    <Box
      sx={{ height: 'fit-content', width: 'fit-content', marginLeft: 'auto' }}
    >
      <ThemeUI.Switch
        {...props} label={label}
        variant={`switch.${variant}`}
      />
    </Box>
  );
}
