import { merge } from 'lodash/fp';
import { createTypedVariant, StyleObject } from 'theming';

export type CardVariants = keyof typeof cards;

const cardContainer = {
  display: 'flex',
};

const timerBase = {
  justifyContent: 'space-around',
  alignItems: 'center',
  px: 8,
  py: 4,
  position: 'relative',

  borderRadius: 1,

  '&::before': {
    content: '""',
    position: 'absolute',
    borderRadius: 1,
    height: '40%',
    width: '100%',
    top: 0,
    left: 0,
    backgroundColor: 'secondary.400',
    opacity: 1,
  },
} as StyleObject;

export const cards = createTypedVariant({
  home: {
    ...cardContainer,
    borderRadius: 6,
    flexDirection: 'column',
    justifyContent: 'flex-end',
    boxShadow: 'homeCard.elevation',
    flex: 1,
    pt: 5,
    pb: 3,
    px: 6,
  },
  user: {
    ...cardContainer,
    backgroundColor: 'white.50',
    width: '100%',
    boxShadow: 'userCard.elevation',
    p: 5,
  },
  document: {
    width: '100%',
    backgroundColor: 'white.50',
    boxShadow: 'documentCard.elevation',
    py: 3,
    pl: 5,
    pr: 4,
    borderRadius: 6,
  },

  timer: {
    running: merge(timerBase, {
      backgroundColor: 'secondary.500',
      '&::before': {
        backgroundColor: 'secondary.400',
      },
    }),

    done: merge(timerBase, {
      backgroundColor: 'tertiary1.500',
      '&::before': {
        backgroundColor: 'tertiary1.300',
      },
    }),

    gray: merge(timerBase, {
      backgroundColor: 'darkgrey.800',
      '&::before': {
        backgroundColor: '#5e5e74',
      },
    }),

    deepBlue: merge(timerBase, {
      backgroundColor: 'primary.900',
      '&::before': {
        backgroundColor: 'primary.800',
      },
    }),
  },
});
