import * as ThemeUI from 'theme-ui';

export function ErrorMessage({ children }: any) {
  return (
    <ThemeUI.Text
      id="ERROR_MESSAGE"
      sx={{
        color: 'error',
        fontSize: '13px',
      }}
    >
      {children}
    </ThemeUI.Text>
  );
}
