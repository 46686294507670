import { identify, makeRequest, Service } from 'utils/http';
import { Paginated } from 'utils/types';

type Data = Paginated;

export async function getAllNotificationsApi({ limit, skip }: Data) {
  return makeRequest(
    Service.getAllNotifications(limit, skip, await identify()),
  );
}
