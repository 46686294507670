import { WorkspaceStatusEnum } from 'trace-backend-sdk';
import { WorkspaceService } from 'services/workspace';
import { Box, Container } from '../../../components/layout';
import { Authorization } from '../../../components/authorization/Authorization';
import { DoubleBorderWrapper } from '../../../components/DoubleBorderWrapper/DoubleBorderWrapper';
import { UpcomingPaymentTable } from '../components/Billing/UpcomingPaymentTable';
import { BillingPageTitle } from '../components/Billing/BillingPageTitle';
import { PaymentsService, PaymentsUtils } from '../index';
import { Routing } from '../../../global/routing';
import { PageLoadingIndicator } from '../../../components/PageLoadingIndicator/PageLoadingIndicator';
import { IntlCustom } from '../../../lib';
import { ManageSubscription } from './ManageSubscription';

type CalculatePercentageOff = {
  amountCents: number;
  percentage: number;
};

function calculatePercentageOffCents({
  percentage,
  amountCents,
}: CalculatePercentageOff) {
  return (percentage / 100) * amountCents;
}

export function UpcomingPaymentPage() {
  const intl = IntlCustom.useIntl();

  const { upcomingInvoice, hasUpcomingInvoice, isLoading, invoiceLines } = PaymentsService.useGetUpcomingInvoice();
  const { workspaceData } = WorkspaceService.useGetWorkspace();
  const applicableDiscount = PaymentsUtils.useGetApplicableDiscount();

  const upcomingPayments = invoiceLines
    .filter((line) => {
      // @ts-expect-error TODO: Fix the type to include base-price as well
      return line.templateId && line.templateId !== 'base-price';
    })
    .map((line) => {
      return {
        productName: intl.formatMessage({
          id: `billing.productName.${line.templateId}`,
          defaultMessage: 'Test Product',
        }),
        productLink:
          // @ts-expect-error TODO: Fix the type to include base-price as well
          line.templateId && line.templateId !== 'base-price'
            ? Routing.PERMISSIONS.PerTemplate.getLink({
              templateId: line.templateId,
            })
            : undefined,
        productPriceCents: line.amountCents ?? 0,
        quantity: line.quantity ?? 0,
      };
    });

  if (isLoading) {
    return <PageLoadingIndicator />;
  }

  return (
    <Container
      variant="spacer"
      sx={{
        '--spacing': (theme) => `${theme.space?.[6]}px`,
      }}
    >
      {hasUpcomingInvoice && (
        <Authorization
          workspaceStatuses={[
            {
              resource: WorkspaceStatusEnum.Cancelled,
              action: 'deny',
            },
          ]}
        >
          <DoubleBorderWrapper
            sx={{ pb: 'var(--spacing)', mb: 'var(--spacing)' }}
          >
            <UpcomingPaymentTable
              currency={workspaceData ? workspaceData.currency : 'sek'}
              discountAmountCents={calculatePercentageOffCents({
                amountCents: upcomingInvoice?.estimatedSubtotalCents ?? 0,
                percentage: applicableDiscount?.percentage ?? 0,
              })}
              discountPercentage={applicableDiscount?.percentage}
              estimatedTotalCents={upcomingInvoice?.estimatedSubtotalCents ?? 0}
              upcomingPaymentDate={
                upcomingInvoice?.paymentDate ?? new Date().toISOString()
              }
              rows={upcomingPayments}
            />
          </DoubleBorderWrapper>
        </Authorization>
      )}

      <Box>
        <BillingPageTitle intlId="billing.sideMenu.manageSubscription" />
        <ManageSubscription />
      </Box>
    </Container>
  );
}
