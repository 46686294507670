/** @jsxImportSource theme-ui */
import React from 'react';
import { useModal } from 'store/modals';
import { IconButton } from '../../../components/form-elements/buttons/IconButton';
import { CountryFlagCircle } from '../../../components/CountryFlag/CountryFlag';
import { getCountryFromLanguage } from '../../../utils/intl/get-country-from-language';
import { useIntlContext } from '../../../translations';
import { ChooseLanguage } from '../../../features/language/components/ChooseLanguage';

export function FloatingLanguageSwitcher() {

  const { openModal } = useModal();
  const intlContext = useIntlContext();

  return (
    <IconButton
      onClick={() => openModal({
        modalType: 'componentModal',
        modalProps: {
          children: (
            <ChooseLanguage />
          ),
        }, 
      })}
      sx={{
        padding: 0,
        position: 'fixed',
        bottom: 4,
        right: 6,
      }}
    >
      <CountryFlagCircle
        sx={{
          border: '2px solid #333',
        }}
        alpha2={getCountryFromLanguage(intlContext.activeLanguage).alpha2}
      />
    </IconButton>
  );
}
