import { Auth } from 'aws-amplify';
import { CurrentAuthenticatedUser } from 'utils/auth/use-current-authenticated-user';

export interface SignInObjectType {
  email: string;
  password: string;
}

export async function signIn(
  user: SignInObjectType,
): Promise<CurrentAuthenticatedUser> {
  return Auth.signIn({
    username: user.email,
    password: user.password,
  });
}
