import * as ReactQuery from 'react-query';
import { InferQueryOptions } from 'utils/types';
import { adminGetAllWorkspacesApi } from 'services/admin/admin-get-all-workspaces/api';

export const QUERY_KEY_ADMIN_GET_ALL_WORKSPACES = ['admin-get-all-workspaces'];

export function useAdminGetAllWorkspaces(
  options?: InferQueryOptions<typeof adminGetAllWorkspacesApi>,
) {
  const { data, ...rest } = ReactQuery.useQuery(
    QUERY_KEY_ADMIN_GET_ALL_WORKSPACES,
    adminGetAllWorkspacesApi,
    options,
  );

  return {
    allWorkspaces: data?.workspaces ?? [],
    totalWorkspaces: data?.total ?? 0,
    ...rest,
  };
}
