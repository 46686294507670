import { identify, makeRequest, Service } from 'utils/http';
import { DocumentRequestBase } from 'services/documents/types';

type Data = DocumentRequestBase & {
  assigneeId: string;
};

export async function updateDocumentAssigneeApi(data: Data) {
  return makeRequest(
    Service.updateDocumentAssignee(
      data.documentId,
      data.templateId,
      {
        ...(data.assigneeId && { assigneeId: data.assigneeId }),
      },
      await identify(),
    ),
  );
}
