import React from 'react';
import { IntlProps } from 'translations';
import { Flex } from 'components/layout';

export type FormWrapperProps = IntlProps;

export const FormWrapper: React.FC<FormWrapperProps> = ({ children }) => (
  <Flex
    onSubmit={() => '#'}
    sx={{
      alignItems: 'center',
      justifyItems: 'center',
      flexDirection: 'column',
      gap: 5,
      mt: 5,
    }}
  >
    {children}
  </Flex>
);
