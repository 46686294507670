import * as ReactIntl from 'lib/intl';
import * as Icons from 'components/icons';

import {
  DocumentCardAvatar,
  DocumentCardBody,
  DocumentCardDate,
  DocumentCardDivider,
  DocumentCardHeader,
  DocumentCardNew,
  DocumentCardNumber,
} from 'components/cards/DocumentCard/DocumentCard';
import React from 'react';
import {
  GetAllUsersResponseUsers,
  Document,
  DocumentStatusEnum,
  DocumentWithoutTemplate,
} from 'trace-backend-sdk';
import * as _ from 'lodash-es';
import { LocaleKey } from 'translations';
import { Flex } from 'components/layout';
import { TextWithFallback } from 'components/typography';
import { DocumentTemplateId } from 'services/documents';

export type DocumentCardDefaultProps = {
  address?: string;
  documentNumber: number;
  templateNameIntl: LocaleKey;
  documentId: number;
  assignee: GetAllUsersResponseUsers | undefined;
  completedBy: GetAllUsersResponseUsers | undefined;
  templateId: DocumentTemplateId;
  documentCompleted: boolean;
  documentDateCreated: string;
  documentDateFinished?: string;
  projectName: string;
  projectNumber: string;
  specifikationAvPlats: string;
  fastighetsbeteckning: string;
};

export function getPressureTestCardProps(
  document: Document | DocumentWithoutTemplate,
  allUsers?: GetAllUsersResponseUsers[],
) {
  const assignee = allUsers?.find(
    (user) => user.userId === document.assigneeId,
  );
  const completedBy = allUsers?.find(
    (user) => user.userId === document.completedById,
  );
  const documentNumber = document.id;
  const address = _.get(document, 'documentData.adress', '');
  const templateNameIntl = 'document.template.pressureTest' as const;
  const documentCompleted = document.status === DocumentStatusEnum.Completed;
  const fastighetsbeteckning: string = _.get(
    document,
    'documentData.fastighetsbeteckning',
    '',
  );
  const specifikationAvPlats: string = _.get(
    document,
    'documentData.specifikationAvPlats',
    '',
  );
  const projectName: string = _.get(document, 'documentData.projektnamn');
  const projectNumber: string = _.get(document, 'documentData.projektnummber');

  return {
    assignee,
    completedBy,
    documentNumber,
    address,
    templateNameIntl,
    documentCompleted,
    fastighetsbeteckning,
    specifikationAvPlats,
    projectName,
    projectNumber,
  };
}

export function DocumentCardDefault({
  address,
  documentNumber,
  assignee,
  templateId,
  documentCompleted,
  documentDateCreated,
  documentDateFinished,
  documentId,
  fastighetsbeteckning,
  specifikationAvPlats,
  projectName,
  projectNumber,
  completedBy,
}: DocumentCardDefaultProps) {
  const intl = ReactIntl.useIntl();

  return (
    <DocumentCardNew documentId={String(documentId)} templateId={templateId}>
      <DocumentCardHeader isCompleted={documentCompleted}>
        <DocumentCardNumber
          Icon={<Icons.Timer sx={{ fill: 'currentColor' }} width={16} />}
        >
          {documentNumber}
        </DocumentCardNumber>

        <DocumentCardDate
          dateCreated={documentDateCreated}
          dateFinished={documentDateFinished}
        />
      </DocumentCardHeader>

      <DocumentCardBody>
        <TextWithFallback
          variant="singleLine"
          as="h2"
          sx={{
            color: 'secondary.900',
            lineHeight: 1.3,
          }}
          fallbackText={intl.formatMessage({
            id: 'document.streetAddress.placeholder',
          })}
        >
          {address}
        </TextWithFallback>

        <TextWithFallback
          variant="singleLine"
          as="h3"
          sx={{
            color: 'secondary.500',
            mb: 2,
            lineHeight: 2,
          }}
          fallbackText={intl.formatMessage({
            id: 'document.fastighetsbeteckning.placeholder',
          })}
        >
          {fastighetsbeteckning}
        </TextWithFallback>

        <TextWithFallback
          variant="singleLine"
          as="p"
          sx={{ fontSize: 'md', color: 'grey.400' }}
          fallbackText={intl.formatMessage({
            id: 'document.specifikationAvPlats.placeholder',
          })}
        >
          {specifikationAvPlats}
        </TextWithFallback>

        <DocumentCardDivider />

        <Flex
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <TextWithFallback
            sx={{
              color: 'tertiary1.300',
            }}
            fallbackText={intl.formatMessage({
              id: 'document.projectName.placeholder',
            })}
          >
            {`${[projectName, projectNumber].join('-').substring(0, 60)}...`}
          </TextWithFallback>

          <DocumentCardAvatar
            isCompleted={documentCompleted}
            completedBy={completedBy}
            assignee={assignee}
          />
        </Flex>
      </DocumentCardBody>
    </DocumentCardNew>
  );
}
