import React from 'react';
import * as ReactRouter from 'react-router-dom';
import { AdminService } from 'services';
import { useModal } from 'store/modals';
import { TableOptions } from 'react-table';
import { Text } from 'components/typography';
import { Box, Flex } from 'components/layout';
import * as Icons from 'components/icons';
import { IconButton } from 'components/form-elements/buttons/IconButton';
import { AdminWorkspace } from 'trace-backend-sdk';
import { z } from 'zod';
import { useNotifications } from 'components/notifications/notifications';
import * as ReactQuery from 'react-query';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { QUERY_KEY_ADMIN_GET_ALL_WORKSPACES } from 'services/admin';
import { Button, Forms } from 'components/form-elements';
import { TableRowActionButtons } from 'components/Table/components/TableRowActionButtons/TableRowActionButtons';
import { Table } from 'components/Table/Table';
import { Routing } from 'global/routing';

const schema = z.object({
  apiKey: z.string().min(1, { message: 'Api key is required' }),
  serverId: z.string().min(1, { message: 'Server id is required' }),
});

function mapAdminWorkspacesForTable(workspaces: AdminWorkspace[]) {
  return workspaces.map((workspace, index) => ({
    organizationName: workspace.organizationName,
    dateCreated: workspace.dateCreated,
    numberOfDocuments: Number(workspace.numberOfDocuments),
    activeUsers: Number(workspace.numberOfActiveUsers),
    invitedUsers: Number(workspace.numberOfInvitedUsers),
    traceProviderId: workspace.traceProviderId,
    workspaceId: workspace.id,
    index,
    reliningDocuments: workspace.numberOfReliningDocuments,
    pressureTestDocuments: workspace.numberOfPressureTestDocuments,
    quickCaptureDocuments: workspace.numberOfQuickCaptureDocuments,
    workspaceStatus: workspace.status,
  }));
}

type UnwrapArray<T> = T extends Array<infer U> ? U : T;

export function AdminWorkspaces() {
  const { allWorkspaces } = AdminService.useAdminGetAllWorkspaces();
  const { openModal } = useModal();

  const data = React.useMemo(
    () => mapAdminWorkspacesForTable(allWorkspaces),
    [allWorkspaces],
  );

  const columns: TableOptions<
    UnwrapArray<ReturnType<typeof mapAdminWorkspacesForTable>>
  >['columns'] = React.useMemo(
    () => [
      {
        minWidth: 350,
        Header: 'Organization Name',
        accessor: 'organizationName',
      },
      {
        Header: 'Date Created',
        accessor: 'dateCreated',
        Cell: (props) => (
          <React.Fragment>
            <Text as="p">
              {new Date(props.row.original.dateCreated).toLocaleDateString(
                'sv-SE',
              )}
            </Text>
            <Text as="p">
              {new Date(props.row.original.dateCreated).toLocaleTimeString(
                'sv-SE',
              )}
            </Text>
          </React.Fragment>
        ),
        sortType: (rowA, rowB) =>
          new Date(rowA.original.dateCreated).getTime()
          - new Date(rowB.original.dateCreated).getTime(),
      },
      {
        Header: 'Workspace status',
        accessor: 'workspaceStatus',
      },
      {
        Header: 'Invited users',
        accessor: 'invitedUsers',
      },
      {
        Header: 'Active users',
        accessor: 'activeUsers',
      },
      {
        Header: 'All documents',
        accessor: 'numberOfDocuments',
      },
      {
        Header: 'PT documents',
        accessor: 'pressureTestDocuments',
      },
      {
        Header: 'Relining documents',
        accessor: 'reliningDocuments',
      },
      {
        Header: 'QC documents',
        accessor: 'quickCaptureDocuments',
      },
      {
        Header: '',
        id: 'action-buttons',
        // @ts-expect-error Errors even though it can correctly infer
        Cell(props) {
          const hasApiKey = props.row.original.traceProviderId != null;

          return (
            <Flex sx={{ alignItems: 'center' }}>
              {!hasApiKey && <Icons.CancelFilled width={20} fill="error" />}

              <TableRowActionButtons>
                <IconButton
                  // @ts-expect-error "as" cast
                  to={Routing.ADMIN.Links.Workspace({
                    workspaceId: props.row.original.workspaceId,
                  })}
                  as={ReactRouter.Link}
                  sx={{
                    '&:hover': {
                      backgroundColor: 'primary.050',
                    },
                  }}
                >
                  <Icons.Pencil fill="#333" />
                </IconButton>

                <IconButton
                  disabled={hasApiKey}
                  onClick={() =>
                    openModal({
                      modalType: 'componentModal',
                      modalProps: {
                        children: (
                          <Box
                            sx={{
                              p: 8,
                            }}
                          >
                            <EditOrganizationApiForm
                              workspaceId={props.row.original.workspaceId}
                            />
                          </Box>
                        ),
                      },
                    })}
                  sx={{
                    '&:hover': {
                      backgroundColor: 'primary.050',
                    },
                  }}
                >
                  <Icons.Gear fill="#333" />
                </IconButton>
              </TableRowActionButtons>
            </Flex>
          );
        },
      },
    ],
    [openModal],
  );

  const sortBy = React.useMemo(() => [{ id: 'dateCreated', desc: true }], []);

  return (
    <Box
      sx={{
        borderRadius: 1,
        width: '100%',
        px: 6,
        mt: 6,
      }}
    >
      <Table
        columns={columns} data={data}
        sortBy={sortBy}
      />
    </Box>
  );
}

function EditOrganizationApiForm({ workspaceId }: { workspaceId: string }) {
  const { closeModal } = useModal();
  const notifications = useNotifications();
  const queryClient = ReactQuery.useQueryClient();

  const formMethods = useForm<z.infer<typeof schema>>({
    defaultValues: {
      apiKey: '',
      serverId: '',
    },
    resolver: zodResolver(schema),
  });

  const { addWorkspaceToTraceProvider, isLoading } = AdminService.useAddWorkspaceToTraceProvider({
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_KEY_ADMIN_GET_ALL_WORKSPACES);

      closeModal();
      notifications.success({
        description: 'admin-panel.api-details.edit-success',
      });
    },
  });

  return (
    <Forms.Provider
      sx={{ p: 0 }}
      {...formMethods}
      name="editApi"
      onValid={({ apiKey, serverId }) => {
        addWorkspaceToTraceProvider({
          providerServerId: serverId,
          providerApiKey: apiKey,
          workspaceId,
        });
      }}
    >
      <Text as="h1" sx={{ fontSize: '2xl', color: 'primary.900', mb: 2 }}>
        Edit Api Details
      </Text>

      <Forms.FieldEditText
        name="apiKey"
        labelIntlId="admin-panel.api-details.api-key"
      />

      <Forms.FieldEditText
        name="serverId"
        labelIntlId="admin-panel.api-details.server-id"
      />

      <Forms.SubmitButton
        sx={{ mt: 2 }}
        intlId="admin-panel.edit-api-keys.save"
        isLoading={isLoading}
      />

      <Button
        sx={{ textTransform: 'uppercase' }}
        variant="text"
        onClick={() => closeModal()}
      >
        Cancel
      </Button>
    </Forms.Provider>
  );
}
