import * as ReactQuery from 'react-query';
import { identify, makeRequest, Service } from '../../../utils/http';
import { DocumentRequestBase } from '../../../services/documents';
import { InferMutationOptions } from '../../../utils/types';

type Data = DocumentRequestBase & {
  data: Parameters<typeof Service.removeReliningImage>[2];
};

async function removeReliningImageApi({ data, templateId, documentId }: Data) {
  return makeRequest(
    Service.removeReliningImage(
      parseInt(documentId, 10),
      templateId,
      data,
      await identify(),
    ),
  );
}

function useRemoveReliningImage(
  options?: InferMutationOptions<typeof removeReliningImageApi>,
) {
  const { mutate: removeReliningImage, ...rest } = ReactQuery.useMutation(
    removeReliningImageApi,
    options,
  );

  return {
    removeReliningImage,
    ...rest,
  };
}

export { useRemoveReliningImage };
