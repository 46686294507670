import { Document, DocumentWithoutTemplate } from 'trace-backend-sdk';
import { addHours, addMinutes, intervalToDuration } from 'date-fns';

type DocumentData = {
  start: {
    timestamp: number;
  };
  provtid: {
    hours: number;
    minutes: number;
  };
};

export function getTestStartTime(document: Document | DocumentWithoutTemplate) {
  return (document.documentData as DocumentData)?.start.timestamp;
}

export function getTestExpectedDuration(
  document: Document | DocumentWithoutTemplate,
) {
  const hours = (document.documentData as DocumentData)?.provtid.hours ?? 0;
  const minutes = (document.documentData as DocumentData)?.provtid.minutes ?? 0;

  return {
    hours,
    minutes,
  };
}

type GetTestRemainingDuration = {
  startTime: number;
  expectedDuration: DocumentData['provtid'];
  currentTimestamp: number;
};

export function getTestRemainingDuration({
  startTime,
  expectedDuration,
  currentTimestamp,
}: GetTestRemainingDuration) {
  const startDate = new Date(startTime);

  const expectedDate = addMinutes(
    addHours(startDate, expectedDuration.hours),
    expectedDuration.minutes,
  );

  const { days, hours, minutes, seconds } = intervalToDuration({
    start: new Date(currentTimestamp),
    end: expectedDate,
  });

  const expired = new Date(currentTimestamp) > expectedDate;

  return {
    hours: (hours ?? 0) + (days ?? 0) * 24,
    minutes,
    seconds,
    expired,
  };
}
