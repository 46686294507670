import * as ReactQuery from 'react-query';
import { identify, makeRequest, Service } from '../../../utils/http';
import { DocumentRequestBase } from '../../../services/documents';
import { InferMutationOptions } from '../../../utils/types';

type Data = DocumentRequestBase;

async function timerResetApi({ documentId, templateId }: Data) {
  return makeRequest(
    Service.reliningTimerReset(
      parseInt(documentId, 10),
      templateId,
      await identify(),
    ),
  );
}

export function useTimerResetApi(
  options?: InferMutationOptions<typeof timerResetApi>,
) {
  const { mutate: timerReset, ...rest } = ReactQuery.useMutation(
    timerResetApi,
    options,
  );

  return {
    timerReset,
    ...rest,
  };
}
