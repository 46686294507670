import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from 'App';
import { Amplify } from 'aws-amplify';
import { Environment } from 'global/environment';
import { GlobalProvider } from 'app/GlobalProvider';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import '@ungap/global-this';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

Amplify.configure({
  Auth: {
    region: Environment.REGION,
    userPoolId: Environment.USER_POOL_ID,
    userPoolWebClientId: Environment.USER_POOL_WEB_CLIENT_ID,
  },
});

function renderApp() {
  // eslint-disable-next-line react/no-render-return-value
  return ReactDOM.render(
    <GlobalProvider>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>

      <ReactQueryDevtools />
    </GlobalProvider>,
    document.getElementById('root'),
  );
}

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./App', renderApp);
}

renderApp();

serviceWorkerRegistration.register();
