import { identify, makeRequest, Service } from 'utils/http';
import * as Utils from 'utils';
import { Environment } from 'global/environment';

type Payload = {
  firstName: string;
  lastName: string;
  oldPassword: string;
  username: string;
  newPassword: string;
};

/**
 * Creating an invited user is a 3-step process.
 * It can also be thought of as "Update a generated user".
 * 1. Sign in with route params from the link (which user gets by email)
 * 2. Use the returned cognito user from step 1, and a new password to
 *  complete an auth challenge for changing password.
 * 3.
 * */
export async function createInvitedUserApi(payload: Payload) {
  const { idToken, refreshToken, accessToken } = await makeRequest(
    Service.confirmUser(
      {
        firstName: payload.firstName,
        lastName: payload.lastName,
        newPassword: payload.newPassword,
        password: payload.oldPassword,
        username: payload.username,
      },
      await identify(),
    ),
  );

  const { cognitoUserSession, cognitoUser } = Utils.Auth.buildCognitoUserSession({
    idToken,
    refreshToken,
    accessToken,
    userPoolId: Environment.USER_POOL_ID!,
    clientId: Environment.USER_POOL_WEB_CLIENT_ID!,
  });

  cognitoUser.setSignInUserSession(cognitoUserSession);
}
