import * as React from 'react';
import * as ReactRouter from 'react-router-dom';
import * as ReactQuery from 'react-query';

import { DocumentsService } from 'services/documents';
import { UsersService } from 'services/users';
import { Container, Flex } from 'components/layout';
import { PageTitle } from 'components/seo/PageTitle';
import { Button } from 'components/form-elements';
import { ComboMultiSelectValueType } from 'components/form-elements/Forms/ComboMultiSelect';
import { Text } from 'components/typography';
import { OptionsType } from 'react-select';
import { PageLoadingIndicator } from 'components/PageLoadingIndicator/PageLoadingIndicator';
import { DocumentCertificatePreviewButton } from 'pages/Document/components/DocumentCertificatePreviewButton';
import { isQueryLoading } from 'utils/functions/is-query-loading';
import { useDocumentParams } from 'pages/Document/Document';
import { Routing } from 'global/routing';
import { DocumentToggleOption } from 'pages/Document/components/DocumentToggleOption';
import { QUERY_KEY_EXTERNAL_USERS } from '../../../services/users/external-users/hook';
import { useModal } from '../../../store/modals';
import { ConfirmationDialog } from '../../../components/confirmation-dialog/ConfirmationDialog';

export function DocumentAddRecipients() {
  const params = useDocumentParams();
  const history = ReactRouter.useHistory();
  const queryClient = ReactQuery.useQueryClient();
  const { openModal } = useModal();

  const { externalUsers, isLoading } = UsersService.useGetExternalUsers();
  const removeExternalUsers = UsersService.useRemoveExternalUsers({
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_KEY_EXTERNAL_USERS);
    },
  });

  const {
    documentCertificateUrl,
    isLoading: isCertificateLoading,
    isFetching: isCertificateFetching,
  } = DocumentsService.useGetDocumentCertificate(
    params.documentId,
    params.templateId,
  );

  const { document, isLoading: isDocumentLoading } = DocumentsService.useGetDocument(params.documentId, params.templateId);

  const { finalizeDocument, isLoading: isFinalizing } = DocumentsService.useFinalizeDocument();

  const [selectedRecipients, setSelectedRecipients] = React.useState<
    OptionsType<ComboMultiSelectValueType>
  >([]);

  const recipientUserEmails = selectedRecipients.map(
    (option: ComboMultiSelectValueType) => option?.value,
  );

  if (
    isQueryLoading(isDocumentLoading, document)
    || isQueryLoading(isCertificateLoading, documentCertificateUrl)
  ) {
    return (
      <PageLoadingIndicator loadingTextIntlId="documents.certificateUrl.loadingText" />
    );
  }

  function onFinalizeButtonClick() {
    if (selectedRecipients.length > 0) {
      onFinalizeDocument();
    } else {
      openModal({
        modalType: 'componentModal',
        modalProps: {
          children: (
            <ConfirmationDialog onProceed={onFinalizeDocument}>
              <Text
                as="p"
                sx={{ textAlign: 'center' }}
                intlId="documents.addRecipients.noRecipientsConfirm"
              />
            </ConfirmationDialog>
          ),
        },
      });
    }
  }

  function onFinalizeDocument() {
    if (!document) return;

    finalizeDocument(
      {
        templateId: params.templateId,
        documentId: params.documentId,
        /**
         * The type of documentData is "object | undefined",
         * but if we get to this step of the process, there
         * is no way that "documentData" is undefined, because
         * it is required to fill in the document data in the
         * form, in the previous step.
         * */
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        documentData: document.documentData!,
        recipients: recipientUserEmails,
      },
      {
        onSuccess: () => {
          /**
           * Its important that it's replace here
           * rather than push, because we don't want
           * to allow routing back to the "add recipients"
           * flow from the "success" page.
           * */
          history.replace(Routing.TRANSITIONS.SuccessConfirmation);
        },
      },
    );
  }

  return (
    <Container variant="spacer">
      <PageTitle titleIntl="viewDocument.recipients" />
      <Flex
        flexDirection="column" alignItems="center"
        gap={6}
      >
        <Flex flexDirection="row">
          <Text
            as="h1"
            sx={{
              alignSelf: 'flex-start',
              mt: 4,
              ml: 4,
            }}
            variant="headline2"
            intlId="viewDocument.recipients.add.label"
          />
        </Flex>

        <DocumentToggleOption
          externalUsers={externalUsers}
          isLoading={isLoading}
          selectedRecipients={selectedRecipients}
          setSelectedRecipients={setSelectedRecipients}
          removeExternalUsers={removeExternalUsers}
        />

        <Button
          onClick={() => onFinalizeButtonClick()}
          isLoading={isFinalizing}
          intlId="viewDocument.recipients.button.send.label"
          sx={{ maxWidth: 400, marginTop: 100 }}
        />

        <DocumentCertificatePreviewButton
          isLoading={isCertificateFetching}
          certificateUrl={documentCertificateUrl}
        />
      </Flex>
    </Container>
  );
}
