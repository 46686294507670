import * as ReactQuery from 'react-query';
import { InferMutationOptions } from 'utils/types';
import { forgotPasswordApi } from 'services/auth/forgot-password/api';

/**
 * Service for obtaining a forgot password code
 * through a provided email.
 * */
export function useForgotPassword(
  options?: InferMutationOptions<typeof forgotPasswordApi>,
) {
  const { mutate: forgotPassword, ...rest } = ReactQuery.useMutation(
    ['forgotPassword'],
    forgotPasswordApi,
    options,
  );

  return {
    forgotPassword,
    ...rest,
  };
}
