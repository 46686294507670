import * as ReactQuery from 'react-query';
import { signOutAPI } from 'services/auth/signOut/api';
import { ErrorType } from 'utils/http';
import { InferMutationOptions } from 'utils/types';

export function useSignOut(options?: InferMutationOptions<typeof signOutAPI>) {
  const { mutate, isLoading, isError, error } = ReactQuery.useMutation(
    'signOut',
    signOutAPI,
    options,
  );
  return {
    signOut: mutate,
    isLoading,
    isError,
    error: error as ErrorType,
  };
}
