import { identify, makeRequest, Service } from 'utils/http';
import { DocumentRequestBase } from 'services/documents/types';

type Data = DocumentRequestBase & {
  documentData: any;
};

export async function updateDocumentApi(data: Data) {
  return makeRequest(
    Service.updateDocument(
      data.documentId,
      data.templateId,
      {
        documentData: data.documentData,
      },
      await identify(),
    ),
  );
}
