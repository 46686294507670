import * as ReactQuery from 'react-query';
import { InferMutationOptions } from '../../../utils/types';
import * as Utils from '../../../utils';
import { updateUserProfileImageApi } from './api';

export function useUpdateUserProfileImage(
  options?: InferMutationOptions<typeof updateUserProfileImageApi>,
) {
  const { userAttributesFromToken } = Utils.Auth.useCurrentAuthenticatedUser();

  const { mutate: updateUserProfileImage, ...rest } = ReactQuery.useMutation(
    ['updateUserProfileImage'],
    ({ profileImage }) =>
      updateUserProfileImageApi({
        profileImage,
        userId: userAttributesFromToken?.sub,
      }),
    options,
  );

  return {
    updateUserProfileImage,
    ...rest,
  };
}
