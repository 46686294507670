import * as ReactQuery from 'react-query';
import { InferQueryOptions } from 'utils/types';
import { getDocumentRecipientsApi } from 'services/documents/get-document-recipients/api';
import { DocumentTemplateId } from 'services/documents/types';

export const DOCUMENT_RECIPIENTS_QUERY_KEY = (
  documentId: string,
  templateId: DocumentTemplateId,
) => ['documentRecipients', documentId, templateId];

export function useGetDocumentRecipients(
  documentId: string,
  templateId: DocumentTemplateId,
  options?: InferQueryOptions<typeof getDocumentRecipientsApi>,
) {
  const { data, ...rest } = ReactQuery.useQuery(
    DOCUMENT_RECIPIENTS_QUERY_KEY(documentId, templateId),
    () => getDocumentRecipientsApi({ templateId, documentId }),
    options,
  );

  return {
    recipients: data?.recipients,
    ...rest,
  };
}
