import * as ReactRouterDom from 'react-router-dom';
import React from 'react';
import { useGetLatest } from './use-get-latest';

function useScrollToTopOnRouteChange(appContainer: HTMLElement | null) {
  const location = ReactRouterDom.useLocation();
  const getAppContainer = useGetLatest(appContainer);

  React.useEffect(() => {
    getAppContainer()?.scrollTo({ top: 0 });
  }, [getAppContainer, location.pathname]);
}

export { useScrollToTopOnRouteChange };
