/** @jsxImportSource theme-ui */

import * as React from 'react';
import { Text } from 'components/typography';
import { Box, Flex } from 'components/layout';
import { WorkspaceLayout } from 'components/workspace-layout';
import { InlineLoadingIndicator } from 'components/LoadingIndicator';
import { WorkspaceService } from 'services/workspace';
import { ModuleCheckbox } from '../components/select-modules/ModuleCheckbox';
import { PaymentsService } from '../index';
import {
  PaymentsContainer,
  PaymentsDescription,
  PaymentsTitle,
} from '../components/select-modules/Common';
import { IntlCustom } from '../../../lib';
import { usePaymentsFormContext } from './PaymentsPage';

export function SelectModulesPage({ children }: { children: React.ReactNode }) {
  const { paidTemplates } = PaymentsService.useGetTemplatePrices({
    onSuccess: (data) => {
      data?.freeTemplates?.forEach((template) => {
        pricesAggregator.addValue(template.id);
      });
    },
  });

  const { workspaceData } = WorkspaceService.useGetWorkspace();
  const { pricesAggregator } = usePaymentsFormContext();
  const intl = IntlCustom.useIntl();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box
        sx={{
          backgroundColor: 'bg.50',
          filter: 'drop-shadow(0px 2px 8px rgb(0 0 0 / 14%))',
        }}
      >
        <PaymentsContainer sx={{ py: 6, position: 'relative' }}>
          <WorkspaceLayout.Logo />
          <PaymentsTitle intlId="payments.selectModule.selectPlan.title" />
          <PaymentsDescription intlId="payments.selectModule.selectPlan.description" />
          <Triangle />
        </PaymentsContainer>
      </Box>

      <Flex sx={{ flexDirection: 'column', flexGrow: 1 }}>
        <PaymentsContainer
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            pb: 0,
            overflowY: 'auto',
          }}
        >
          <Box sx={{ mt: 2, flexGrow: 1, position: 'relative' }}>
            <Flex
              sx={{
                alignItems: 'center',
                mb: 8,
              }}
            >
              <Text
                intlId="payments.selectModule.products"
                sx={{
                  fontWeight: 500,
                  color: 'black',
                }}
              />
              <Box
                sx={{
                  ml: 2,
                  flexGrow: 1,
                  height: '1px',
                  borderBottom: '1px solid #C8C8C8',
                }}
              />
            </Flex>

            {paidTemplates?.map((price) => (
              <Box
                key={price.id}
                sx={{
                  py: 4,
                  borderBottom: '1px solid #C8C8C8',
                  position: 'relative',

                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    borderBottom: '1px solid white',
                    bottom: 0,
                    width: '100%',
                  },
                }}
              >
                <ModuleCheckbox
                  currency={workspaceData ? workspaceData.currency : 'sek'}
                  moduleCostCents={Number(price.unitAmountDecimal)}
                  label={intl.formatMessage({
                    id: `billing.productName.${price.templateId}`,
                  })}
                  checked={pricesAggregator.hasValue(price.id)}
                  value={price.id}
                  onChange={(event) => {
                    if (event.target.checked) {
                      pricesAggregator.addValue(price.id);
                    } else {
                      pricesAggregator.removeValue(price.id);
                    }
                  }}
                />
                <Text
                  sx={{
                    fontStyle: 'italic',
                    fontWeight: 300,
                    color: 'gray.5',
                    fontSize: '14px',
                    ml: 10,
                    mt: 5,
                  }}
                >
                  {intl.formatMessage({
                    id: 'billing.productPrice.description',
                  })}
                </Text>
              </Box>
            )) ?? <InlineLoadingIndicator />}
          </Box>

          <Box sx={{ mt: 15, mb: 15 }}>{children}</Box>
        </PaymentsContainer>
      </Flex>
    </Box>
  );
}

export function Triangle() {
  return (
    <Box
      sx={{
        width: 0,
        height: 0,
        borderLeft: '27px solid rgba(0,0,0,0)',
        borderRight: '27px solid rgba(0,0,0,0)',
        borderTop: '27px solid #F1F3F6',
        position: 'absolute',
        bottom: '-27px',
      }}
    />
  );
}
