import React from 'react';
import * as ThemeUI from 'theme-ui';
import { WidgetProps, utils } from '@rjsf/core';
import { useIntl } from 'lib/intl';
import { LocaleKey } from '../../../../translations';
import { TextField } from '../../Forms/FieldEditText';

const { getDisplayLabel } = utils;

export type TextWidgetProps = WidgetProps &
  Pick<
    ThemeUI.InputProps,
    Exclude<keyof ThemeUI.InputProps, 'onBlur' | 'onFocus' | 'name'>
  > & {
    multiline?: boolean;
  };

export function TextWidget(props: TextWidgetProps) {
  return <OutlinedTextWidget {...props} />;
}

export function OutlinedTextWidget(props: TextWidgetProps) {
  const intl = useIntl();
  const {
    onFocus,
    resolvedLabel,
    onBlur,
    inputType,
    onChange,
    resolvedValue,
    isError,
  } = getCommonProps(props);

  return (
    <TextField
      label={intl.formatMessage({
        id: resolvedLabel,
      })}
      isError={isError}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      type={inputType}
      name={props.id}
      value={resolvedValue}
      backgroundColor="outlinedTextWidget.background"
      autoFocus={props.autofocus}
      required={props.required}
      disabled={props.disabled || props.readonly}
      placeholder={props.placeholder}
      id={props.id}
      as={props.as}
    />
  );
}

function getCommonProps({
  options,
  onFocus,
  onChange,
  onBlur,
  uiSchema,
  schema,
  type,
  label,
  id,
  rawErrors,
  value,
}: TextWidgetProps) {
  const _onChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) =>
    onChange(value === '' ? options.emptyValue : value);
  const _onBlur = ({ target: { value } }: React.FocusEvent<HTMLInputElement>) =>
    onBlur(id, value);
  const _onFocus = ({
    target: { value },
  }: React.FocusEvent<HTMLInputElement>) => onFocus(id, value);

  const displayLabel = getDisplayLabel(schema, uiSchema);
  const inputType = (type || schema.type) === 'string' ? 'text' : `${type || schema.type}`;

  const resolvedLabel = uiSchema['ui:title'] ?? (label as LocaleKey);
  const isError = rawErrors?.length > 0;
  const resolvedValue = value || value === 0 ? value : '';

  return {
    onChange: _onChange,
    onBlur: _onBlur,
    onFocus: _onFocus,
    inputType,
    resolvedLabel: displayLabel ? resolvedLabel : schema.title,
    isError,
    resolvedValue,
  };
}
