import { useIntl } from 'lib/intl';
import * as Icons from 'components/icons';
import React from 'react';
import {
  QuickTraceWidgetBodyWrapper,
  QuickTraceWidgetCaptionInput,
  QuickTraceWidgetTimestamp,
  QuickTraceWidgetTitle,
  QuickTraceWidgetWrapper,
} from '../quick-trace/components/QuickTraceWidget';
import { Image } from '../Image';
import { Spacer } from '../Spacer/Spacer';
import { Box } from '../layout';

type ImageWithCaptionProps = {
  imageUrl: string;
  title: string;
  caption: string;
  onCaptionChange: React.ChangeEventHandler<HTMLTextAreaElement>;
  timestamp: number;
  children?: React.ReactNode;
  disabled?: boolean;
  hideCaptionInput?: boolean;
};

export function ImageWithCaption({
  title,
  imageUrl,
  caption,
  timestamp,
  onCaptionChange,
  children,
  disabled,
  hideCaptionInput = false,
}: ImageWithCaptionProps) {
  const intl = useIntl();

  return (
    <QuickTraceWidgetWrapper hasError={false}>
      <QuickTraceWidgetBodyWrapper
        sx={{
          lineHeight: 2,
          pt: 2,
          display: 'flex',
          alignItems: 'center',
          mx: 0,
          mb: 6,
        }}
      >
        <Box
          sx={{
            backgroundColor: 'grey.800',
            display: 'inline-flex',
            p: 2,
            borderRadius: 'rounded',
            mr: 2,
          }}
        >
          <Icons.CameraMui color="white.50" />
        </Box>

        <Box>
          <QuickTraceWidgetTitle>{title}</QuickTraceWidgetTitle>
          <QuickTraceWidgetTimestamp
            placeholder={intl.formatMessage({
              id: 'quickCapture.image.subTitle',
            })}
            timestamp={timestamp}
          />
        </Box>

        <Box sx={{ flexGrow: 1 }} />

        {children}
      </QuickTraceWidgetBodyWrapper>

      <Spacer y={6} />

      <React.Fragment>
        <Image src={imageUrl} sx={{ width: '100%', mb: 4, borderRadius: 1 }} />
        {!hideCaptionInput && (
          <QuickTraceWidgetCaptionInput
            disabled={disabled}
            value={caption}
            onChange={onCaptionChange}
          />
        )}
      </React.Fragment>
    </QuickTraceWidgetWrapper>
  );
}
