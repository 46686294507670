import * as _ from 'lodash-es';

// eslint-disable-next-line @typescript-eslint/ban-types
export function sanitize(payload: Record<string, unknown> | object) {
  return _.pickBy(payload, (value) => value != null && value !== '');
}

export function sanitizeDeep(payload: Record<string, unknown>) {
  const result = sanitize(payload);
  const keys = Object.keys(result);

  for (const key of keys) {
    // @ts-expect-error key error
    const value = result[key];

    // Sanitize arrays by filtering through them using "filter"
    if (Array.isArray(value)) {
      _.set(
        result,
        key,
        value.filter((val) => val != null && val !== ''),
      );
    }

    // Need triple check here, because "array" and "null" are also objects
    if (!Array.isArray(value) && typeof value === 'object' && value != null) {
      _.set(result, key, sanitizeDeep(value as Record<string, unknown>));
    }
  }

  return result;
}
