import * as React from 'react';
import { Button } from 'components/form-elements';
import { useNotifications } from 'components/notifications/notifications';
import { useModal } from 'store/modals';

export function DocumentCertificatePreviewButton(props: {
  certificateUrl?: string;
  isLoading?: boolean;
  isDocumentCompleted?: boolean;
}) {
  const notifications = useNotifications();
  const { openModal } = useModal();

  return (
    <Button
      disabled={props.isLoading || props.certificateUrl == null}
      variant="preview"
      sx={{ maxWidth: 400 }}
      onClick={() => {
        if (!props.certificateUrl) {
          notifications.error({
            description: 'errors.certificatePreview',
          });
          return;
        }

        openModal({
          modalType: 'pdfModal',
          modalProps: {
            src: props.certificateUrl,
          },
        });
      }}
      intlId={
        props.isDocumentCompleted
          ? 'viewDocument.recipients.button.view.label'
          : 'viewDocument.recipients.button.preview.label'
      }
    />
  );
}
