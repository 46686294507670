import React from 'react';
import * as ThemeUI from 'theme-ui';
import { ButtonVariants } from 'theming/variants/buttons';
import { IntlProps, LocaleKey } from 'translations';
import * as ReactIntl from 'lib/intl';
import { Box } from 'components/layout';
import { LoadingIndicator } from 'components/LoadingIndicator';

export interface ButtonProps
  extends ThemeUI.ButtonProps,
    Omit<IntlProps, 'intlId'> {
  as?: React.ElementType;
  variant?: ButtonVariants;
  prependIcon?: React.ReactNode;
  isLoading?: boolean;
  intlId?: LocaleKey;
}

export const Button: React.FC<ButtonProps> = ({
  type = 'button',
  as,
  sx,
  children,
  intlId,
  intlDefaultMessage,
  intlValues,
  onClick,
  isLoading,
  disabled,
  ...props
}) => (
  <ThemeUI.Button
    as={as || 'button'}
    sx={{
      cursor: isLoading || disabled ? 'not-allowed' : 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: 500,
      gap: 1,
      position: 'relative',
      ...sx,
    }}
    type={type}
    disabled={isLoading || disabled}
    {...props}
    onClick={onClick}
  >
    {props.prependIcon && !isLoading && (
      <Box
        as="span"
        sx={{
          display: 'inline-flex',
          mr: 2,
        }}
      >
        {props.prependIcon}
      </Box>
    )}

    {isLoading && (
      <Box
        as="span"
        sx={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          display: 'inline-flex',
          '& > *': {
            fill: 'currentColor',
            width: '16px',
          },
        }}
      >
        <LoadingIndicator color="white.50" />
      </Box>
    )}

    <Box
      as="span"
      sx={{
        visibility: isLoading ? 'hidden' : 'visible',
      }}
    >
      {intlId ? (
        <ReactIntl.FormattedMessage
          id={intlId}
          defaultMessage={intlDefaultMessage}
          values={intlValues}
        />
      ) : (
        children
      )}
    </Box>
  </ThemeUI.Button>
);
