import { Auth } from 'aws-amplify';
import { Http } from 'utils';

export interface SignUpObjectType {
  workEmail: string;
  password: string;
  firstName: string;
  lastName: string;
  phoneNumber?: string;
  countryCallingCode: string;
}

export async function signUp(user: SignUpObjectType) {
  await Auth.signUp({
    username: user.workEmail,
    password: user.password,
    // @ts-expect-error Fix sanitize return type
    clientMetadata: Http.sanitize({
      ...(user.phoneNumber && {
        phone_number_extension: user.countryCallingCode,
      }),
      phone_number: user.phoneNumber,
    }),
    attributes: Http.sanitize({
      given_name: user.firstName,
      family_name: user.lastName,
      email: user.workEmail,
    }),
  });
}
