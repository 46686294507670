import { Box } from 'components/layout';
import * as ReactRouter from 'react-router-dom';
import * as Icons from 'components/icons';
import * as React from 'react';

import { IconButton } from 'components/form-elements/buttons/IconButton';
import { useOnClickOutside } from 'utils/hooks/use-on-click-outside';
import { Input } from 'components/form-elements/inputs/Input';
import { composeQuery } from 'utils/url';
import { Routing } from 'global/routing';
import { useSearchQuery } from 'utils/url/use-search-query';

export const Search = (props: {
  isSearchActive: boolean;
  setIsSearchActive: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [searchValue, setSearchValue] = useUrlSyncedSearchValue();

  const history = ReactRouter.useHistory();
  const searchBoxRef = React.useRef<HTMLDivElement>(null);
  const searchInputRef = React.useRef<HTMLInputElement>(null);

  function handleSearch() {
    const searchQuery = composeQuery({
      search: searchValue,
    });

    history.push(`${Routing.SEARCH.Default}?${searchQuery}`);
  }

  useOnClickOutside(searchBoxRef, () => {
    props.setIsSearchActive(false);
  });

  React.useEffect(() => {
    if (!props.isSearchActive) {
      setSearchValue('');
    }
  }, [props.isSearchActive, setSearchValue]);

  return (
    <Box
      onSubmit={(e) => {
        e.preventDefault();
        handleSearch();
      }}
      as="form"
      ref={searchBoxRef}
      sx={{
        ml: 'auto',
        flexGrow: 1,
        width: 'auto',
        alignItems: 'center',
        height: '30px',
        overflow: 'hidden',
        position: 'relative',
        justifyContent: 'flex-end',
        flexShrink: 0,
      }}
    >
      <Box
        sx={{
          position: 'relative',
          height: '100%',
          width: 10,
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          opacity: 0,
          transition: 'all 300ms ease',
          ml: 'auto',

          ...(props.isSearchActive && {
            opacity: 1,
            width: '100%',
          }),
        }}
      >
        <Input
          ref={searchInputRef}
          type="search"
          sx={{
            '&::-webkit-search-decoration,&::-webkit-search-cancel-button': {
              display: 'none',
            },
            border: 0,
            borderRadius: '15px',
            px: 3,
            outline: 0,
            height: '100%',
            backgroundColor: 'primary.200',
            pr: 10,

            '&::placeholder, &::-webkit-input-placeholder': {
              fontSize: 'md',
              color: 'primary.900',
              opacity: '0.75 !important',
            },
            '&[value]': {
              fontSize: 'md',
              color: 'primary.900',
            },
          }}
          placeholderIntlId="search.placeholder.label"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        {searchValue && (
          <Box
            onClick={() => {
              setSearchValue('');
              searchInputRef.current?.focus();
            }}
            sx={{
              position: 'absolute',
              top: '0px',
              right: 9,
              fill: 'primary.900',
              cursor: 'pointer',
              p: '6px',
            }}
          >
            <Icons.Close width={18} sx={{}} />
          </Box>
        )}
      </Box>

      {!props.isSearchActive && (
        <IconButton
          type="button"
          sx={{
            position: 'absolute',
            top: '50%',
            right: 2,
            transform: 'translateY(-50%)',
          }}
          onClick={() => {
            props.setIsSearchActive(true);
            searchInputRef.current?.focus();
          }}
        >
          <Icons.Search width={20} />
        </IconButton>
      )}

      {props.isSearchActive && (
        <IconButton
          type="submit"
          sx={{
            position: 'absolute',
            top: '50%',
            right: 2,
            transform: 'translateY(-50%)',
          }}
        >
          <Icons.Search width={20} />
        </IconButton>
      )}
    </Box>
  );
};

function useUrlSyncedSearchValue() {
  const [searchValue, setSearchValue] = React.useState('');
  const searchQuery = useSearchQuery();

  React.useEffect(() => {
    if (searchQuery.search) {
      setSearchValue(searchQuery.search);
    }
  }, [searchQuery.search]);

  return [searchValue, setSearchValue] as const;
}
