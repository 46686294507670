import React from 'react';
import { keyframes } from '@emotion/react';
import { Box, Flex } from 'components/layout';
import { StyleObject } from 'theming';
import { Text } from 'components/typography';
import { LocaleKey } from 'translations';
import { Colors } from 'theming/foundations/colors';

export function InlineLoadingIndicator({
  sx,
  loadingTextIntlId,
  loadingTextIntlValues,
}: {
  sx?: StyleObject;
  loadingTextIntlId?: LocaleKey;
  loadingTextIntlValues?: Record<string, string | number>;
}) {
  return (
    <Flex
      sx={{
        isolation: 'isolate',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',

        width: 'fit-content',
        alignItems: 'center',
        flexDirection: 'column',
        gap: 2,
        mb: 6,
      }}
    >
      <LoadingIndicator sx={sx} />

      {loadingTextIntlId && (
        <Text intlId={loadingTextIntlId} intlValues={loadingTextIntlValues} />
      )}
    </Flex>
  );
}

const dotElastic = keyframes({
  '0%': { transform: 'scale(1, 1)' },
  '25%': { transform: 'scale(1, 1)' },
  '50%': { transform: 'scale(1, 1.5)' },
  '75%': { transform: 'scale(1, 1)' },
  '100%': { transform: 'scale(1, 1)' },
});

const dotElasticBefore = keyframes({
  '0%': { transform: 'scale(1, 1)' },
  '25%': { transform: 'scale(1, 1.5)' },
  '50%': { transform: 'scale(1, .67)' },
  '75%': { transform: 'scale(1, 1)' },
  '100%': { transform: 'scale(1, 1)' },
});

const dotElasticAfter = keyframes({
  '0%': { transform: 'scale(1, 1)' },
  '25%': { transform: 'scale(1, 1)' },
  '50%': { transform: 'scale(1, .67)' },
  '75%': { transform: 'scale(1, 1.5)' },
  '100%': { transform: 'scale(1, 1)' },
});

function getDotStyles({
  size = 10,
  color = '#9880ff',
}: {
  size?: number;
  color?: string;
}) {
  return {
    width: `${size}px`,
    height: `${size}px`,
    borderRadius: `${size / 2}px`,
    backgroundColor: color,
    color,
  };
}

export function LoadingIndicator({
  sx,
  color = 'primary.900',
}: {
  sx?: StyleObject;
  color?: Colors;
}) {
  return (
    <Box
      sx={{
        position: 'relative',
        ...getDotStyles({ color }),
        animation: `${dotElastic} 1s infinite linear`,

        '&::before, &::after': {
          content: '""',
          display: 'inline-block',
          position: 'absolute',
          top: 0,
        },

        '&::before': {
          left: '-15px',
          ...getDotStyles({ color }),
          animation: `${dotElasticBefore} 1s infinite linear`,
        },

        '&::after': {
          left: '15px',
          ...getDotStyles({ color }),
          animation: `${dotElasticAfter} 1s infinite linear`,
        },

        ...sx,
      }}
    />
  );
}
