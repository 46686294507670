import * as React from 'react';
import * as Rhf from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';

import { WorkspaceLayout } from 'components/workspace-layout';
import { Forms } from 'components/form-elements';
import { Text } from 'components/typography';
import { Container, Flex } from 'components/layout';
import * as ReactRouter from 'react-router-dom';
import { Routing } from 'global/routing';
import { useSearchQuery } from 'utils/url/use-search-query';
import { AuthService } from 'services/auth';
import WavesImg from 'assets/images/waves.svg';
import { Image } from 'components/Image';
import PhoneImg from 'assets/images/phone.svg';
import { ValidationSchemas } from '../../utils/validation';

const schema = Joi.object({
  password: ValidationSchemas.Register.PASSWORD.messages({
    'any.only': 'confirmPassword.form.input.confirmPassword.error.message',
  }).valid(Joi.ref('confirmPassword')),
  confirmPassword: ValidationSchemas.Register.PASSWORD,
});

export const ConfirmPassword = () => {
  const searchQuery = useSearchQuery();
  const history = ReactRouter.useHistory();
  const formMethods = Rhf.useForm({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
    resolver: joiResolver(schema),
  });

  const { forgotPasswordConfirm, isLoading } = AuthService.useForgotPasswordConfirm({
    onSuccess: () => {
      history.replace(Routing.LOGIN.getPath());
    },
  });

  const { isSubmitted } = Rhf.useFormState({
    control: formMethods.control,
  });

  const { trigger } = formMethods;
  const confirmPassword = formMethods.watch('confirmPassword');
  /**
   * (Theory below)
   *
   * We manually do the watch -> check -> trigger loop
   * here in the "useEffect" because the inputs are
   * uncontrolled and they cannot cause "mutual validation".
   *
   * When "password" is changed, "confirmPassword" won't
   * render & revalidate, and it will stay with an error until
   * some event, such as submitting, causes revalidation.
   *
   * TODO: Create a "FieldEditTextControlled" component as a helper
   * */
  React.useEffect(() => {
    /**
     * Only trigger if the form has been submitted once
     * to avoid spamming errors from the start.
     * */
    if (isSubmitted) {
      trigger('password');
    }
  }, [trigger, confirmPassword, isSubmitted]);

  return (
    <Container
      variant="page"
      sx={{
        height: '100%',
        justifyContent: 'flex-start',
        overflowX: 'hidden',
        px: '0px',
      }}
    >
      <WorkspaceLayout sx={{ px: 4 }}>
        <WorkspaceLayout.Headline intlId="confirmPassword.headline" />
        <WorkspaceLayout.Paragraph intlId="confirmPassword.paragraph" />
        <Forms.Provider
          sx={{ gap: 4 }}
          onValid={(data) => {
            forgotPasswordConfirm({
              email: searchQuery.email,
              newPassword: data.password,
              confirmationCode: searchQuery.code,
            });
          }}
          name="confirmPassword"
          {...formMethods}
        >
          <Forms.FieldEditText
            isAlwaysFloating
            labelIntlId="confirmPassword.form.input.password.label"
            name="password"
            required
            type="password"
          />
          <Forms.FieldEditText
            isAlwaysFloating
            labelIntlId="confirmPassword.form.input.confirmPassword.label"
            name="confirmPassword"
            required
            type="password"
          />
          <Forms.SubmitButton isLoading={isLoading}>
            <Text
              intlId="confirmPassword.form.button.label"
              variant="button.text"
            />
          </Forms.SubmitButton>
        </Forms.Provider>
      </WorkspaceLayout>
      <Flex
        alignItems="flex-start"
        justifyContent="center"
        sx={{
          backgroundImage: `url('${WavesImg}')`,
          mt: 4,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'top',
          flexShrink: 1,
          flexGrow: 1,
          width: '130%',
        }}
      >
        <Image src={PhoneImg} />
      </Flex>
    </Container>
  );
};
