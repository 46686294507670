import axios, { AxiosInstance, AxiosPromise } from 'axios';
import { Environment } from 'global/environment';

import { TraceApiFp } from 'trace-backend-sdk';

import { StatusCodes } from 'global/status-codes';
import { ErrorType } from 'utils/http/types';
import { mapHttpError } from 'utils/http/mapHttpError';
import { LOCAL_STORAGE_KEYS } from '../local-storage';

export const Service = TraceApiFp({
  basePath: Environment.BACKEND_API,
  isJsonMime: (mime: string) => {
    const jsonMime = new RegExp(
      '^(application/json|[^;/ \t]+/[^;/ \t]+[+]json)[ \t]*(;.*)?$',
      'i',
    );
    return (
      mime !== null
      && (jsonMime.test(mime)
        || mime.toLowerCase() === 'application/json-patch+json')
    );
  },
});

const axiosInstance = axios.create({
  baseURL: Environment.BACKEND_API,
});

// Set language query param on every request
axiosInstance.interceptors.request.use((config) => {
  try {
    const lang = localStorage.getItem(LOCAL_STORAGE_KEYS.LANG);
    if (!lang) {
      return config;
    }

    config.params = {
      ...config.params,
      lang: JSON.parse(lang),
    };
    return config;
  } catch (error) {
    console.error('Cannot get language from local storage');
  }

  return config;
});

export function makeRequest<T>(
  apiCallPromise: Promise<(axios?: AxiosInstance) => AxiosPromise<T>>
): Promise<T>;
export function makeRequest<T, Data>(
  apiCallPromise: Promise<(axios?: AxiosInstance) => AxiosPromise<T>>,
  mapData?: (data: T) => Data
): Promise<Data>;
export function makeRequest<T, Data>(
  apiCallPromise: Promise<(axios?: AxiosInstance) => AxiosPromise<T>>,
  mapData?: (data: T) => Data,
) {
  return new Promise(async (resolve, reject) => {
    apiCallPromise.then(async (apiCall) => {
      try {
        const { data } = await apiCall(axiosInstance);
        resolve(mapData ? mapData(data) : data);
      } catch (error) {
        onUnauthorizedWithNoToken(mapHttpError(error as any));
        reject(mapHttpError(error as any));
      }
    });
  });
}

/**
 * TODO: Look into making this function more robust.
 *  Now it will send the user to the login page on
 *  every unauthorized error, but it could be that
 *  the user *is* logged in, just doesn't have the
 *  permission to make the particular request.
 * */
function onUnauthorizedWithNoToken(error: ErrorType) {
  /**
   * Three things are important here:
   * 1. No auth token (no authenticated user)
   *
   * 2. Received an unauthorized error from backend
   *  (attempting to access a restricted resource)
   *
   * 3. Check if we are already at "/login",
   *  don't redirect then cause it will cause
   *  infinite redirect loop
   * */
  if (
    error.authToken == null
    && error.statusCode === StatusCodes.UNAUTHORIZED
    && window.location.pathname !== '/login'
  ) {
    // TODO: Implement this redirection potentially at the ReactQuery level
    // window.location.pathname = '/login';
  }
}
