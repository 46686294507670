import * as ReactQuery from 'react-query';
import { set } from 'lodash/fp';

import { InferMutationOptions } from 'utils/types';
import { updateDocumentApi } from 'services/documents/update-document/api';
import { QUERY_KEY_DOCUMENT } from 'services/documents/get-document';

export function useUpdateDocument(
  options?: InferMutationOptions<typeof updateDocumentApi>,
) {
  const queryClient = ReactQuery.useQueryClient();

  const {
    data: document,
    mutate: updateDocument,

    ...rest
  } = ReactQuery.useMutation('updateDocument', updateDocumentApi, {
    onMutate: async (variables) => {
      await queryClient.cancelQueries(
        QUERY_KEY_DOCUMENT(variables.documentId, variables.templateId),
      );

      if (options?.onMutate) {
        await options.onMutate(variables);
      }
    },
    onSuccess: async (data, variables) => {
      const queryData = queryClient.getQueryData<any>(
        QUERY_KEY_DOCUMENT(variables.documentId, variables.templateId),
      );

      queryClient.setQueryData(
        QUERY_KEY_DOCUMENT(variables.documentId, variables.templateId),
        set('documentData', variables.documentData, queryData),
      );
    },
  });

  return {
    document,
    updateDocument,
    ...rest,
  };
}
