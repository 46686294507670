import { FieldProps } from '@rjsf/core';
import * as ReactQuery from 'react-query';
import * as Icons from 'components/icons';
import { PictureTake } from 'components/form-elements/JsonForms/components/PictureTake';
import { Button } from 'components/form-elements';
import { Timer } from 'components/Timer/Timer';
import * as React from 'react';
import { QUERY_KEY_RUNNING_DOCUMENTS_FOR_USER } from '../../../../services/documents/get-running-documents-for-user/hook';
import { IntlCustom } from '../../../../lib';

export function PictureTakeEnd(props: FieldProps) {
  const queryClient = ReactQuery.useQueryClient();
  const intl = IntlCustom.useIntl();

  return (
    <PictureTake
      {...props}
      imageName="end"
      onPictureTake={async () => {
        /*
         * Backend relies on Lequinox events to decide
         * which tests are running. These events take time
         * to propagate, and we need to invalidate *after*
         * they have propagated, if we want to get the latest
         * values of the running documents.
         *
         * TODO: Fix with websockets
         * */
        setTimeout(() => {
          queryClient.invalidateQueries(QUERY_KEY_RUNNING_DOCUMENTS_FOR_USER);
        }, 2000);
      }}
      retakeWarningIntlId="confirmationModal.pressureTest.endReset"
      ActionButton={(
        <Button
          prependIcon={<Icons.CameraMui color="currentColor" />}
          sx={{
            backgroundColor: 'tertiary2.800',
          }}
          intlId="pressureTest.endTest"
        />
      )}
      TitleIcon={<Icons.PressureTest />}
      title={intl.formatMessage({ id: 'pressureTest.endTest' })}
    >
      <Timer
        wrapperSx={{ mt: 6 }}
        labelIntlId="pressureTest.totalTestDuration"
        variant="done"
        startTime={props.formContext.startDate}
        endTime={props.formContext.endDate}
      />
    </PictureTake>
  );
}
