import * as ReactQuery from 'react-query';
import { useNotifications } from 'components/notifications/notifications';
import { identify, makeRequest, Service } from '../../../utils/http';
import { InferMutationOptions } from '../../../utils/types';

type Data = Parameters<typeof Service.revokeTemplatePermission>[0];
async function revokeTemplatePermissionApi(data: Data) {
  return makeRequest(Service.revokeTemplatePermission(data, await identify()));
}

function useRevokeTemplatePermission(
  options?: InferMutationOptions<typeof revokeTemplatePermissionApi>,
) {
  const queryClient = ReactQuery.useQueryClient();
  const notifications = useNotifications();
  const { mutate: revokeTemplatePermission, ...rest } = ReactQuery.useMutation(
    'revokeTemplatePermission',
    revokeTemplatePermissionApi,
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        notifications.success({
          description: 'user.actions.changeModuleSuccess',
          durationMs: 2000,
        });

        await queryClient.invalidateQueries(['workspace']);

        if (!variables?.items) return;

        if (options?.onSuccess) {
          await options.onSuccess(data, variables, context);
        }
      },
    },
  );

  return {
    revokeTemplatePermission,
    ...rest,
  };
}

export { useRevokeTemplatePermission };
