import React from 'react';
import * as ReactRouterDom from 'react-router-dom';
import { ServiceProvider } from 'services';
import { LittleStateMachineProvider, StoreProvider } from 'store';
import { ThemeProvider } from 'theming';
import { IntlProvider } from 'translations';
import { NotificationsProvider } from 'components/notifications/notifications';
import { WebsocketsProvider } from 'components/WebsocketsProvider/WebsocketsProvider';
// @ts-expect-error No type definitions for rollbar
import { Provider as RollbarProvider } from '@rollbar/react';
import { ROLLBAR_CONFIG } from 'lib/rollbar';
import { StripeProvider } from '../../lib/stripe';
import { ModalContainer } from '../../components/modals';

export const GlobalProvider: React.FC = ({ children }) => (
  <RollbarProvider config={ROLLBAR_CONFIG}>
    <ReactRouterDom.BrowserRouter>
      <LittleStateMachineProvider>
        <StoreProvider>
          <ThemeProvider>
            <IntlProvider>
              <NotificationsProvider>
                <ServiceProvider>
                  <WebsocketsProvider>
                    <StripeProvider>
                      <React.Fragment>
                        {children}
                        <ModalContainer />
                      </React.Fragment>
                    </StripeProvider>
                  </WebsocketsProvider>
                </ServiceProvider>
              </NotificationsProvider>
            </IntlProvider>
          </ThemeProvider>
        </StoreProvider>
      </LittleStateMachineProvider>
    </ReactRouterDom.BrowserRouter>
  </RollbarProvider>
);
