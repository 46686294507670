import * as ReactQuery from 'react-query';
import { ErrorType } from 'utils/http';
import { InferMutationOptions } from '../../../utils/types';
import { createWorkspaceAPI } from './api';

export function useCreateWorkspace(
  options?: InferMutationOptions<typeof createWorkspaceAPI>,
) {
  const { mutate, isLoading, isError, error, isSuccess } = ReactQuery.useMutation('createWorkspace', createWorkspaceAPI, options);

  return {
    createWorkspace: mutate,
    isLoading,
    isError,
    error: error as ErrorType,
    isSuccess,
  };
}
