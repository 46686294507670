import React from 'react';
import * as ReactIntl from 'lib/intl';

import { Text } from 'components/typography';
import { Box, Flex } from 'components/layout';
import { breakpoints } from 'utils/breakpoints';
import { DocumentsService } from 'services/documents';
import * as Icons from 'components/icons';
import { colors } from 'theming/foundations/colors';
import { UsersService } from 'services/users';
import { StyleObject } from 'theming';
import { DocumentStatusEnum } from 'trace-backend-sdk';
import { Avatar } from 'components/avatar';
import { useLocaleDateFormat } from 'utils/hooks/use-locale-date-format';
import { FormattedMessage } from 'lib/intl';
import { LocaleKey } from 'translations';
import Select, { createFilter } from 'react-select';
import { useDocumentParams } from 'pages/Document/Document';

export const DocumentDetails: React.FC = () => {
  const intl = ReactIntl.useIntl();
  const params = useDocumentParams();
  const { localeDateFormat } = useLocaleDateFormat();

  const { document, hasDocumentWritePermission } = DocumentsService.useGetDocument(params.documentId, params.templateId, {
    onSuccess: (data) => setAssigneeId(data.assigneeId),
  });
  const [assigneeId, setAssigneeId] = React.useState(document?.assigneeId);
  const { updateDocumentAssignee } = DocumentsService.useUpdateDocumentAssignee(
    {
      onMutate(variables) {
        setAssigneeId(variables.assigneeId);
        return { prevAssigneeId: assigneeId };
      },
      onError(error, variables, context: any) {
        setAssigneeId(context.prevAssigneeId);
      },
    },
  );

  const { allUsers } = UsersService.useGetUsers();

  const userCreator = document?.creatorId
    && allUsers.find((user) => user.userId === document.creatorId);

  const userCompletedBy = document?.completedById
    && allUsers.find((user) => user.userId === document.completedById);

  const assigneeSelectOptions = allUsers
    .filter((item) => item.ready && item.active)
    .map(({ user, profileImageUrl, active }) => ({
      label: (
        <Flex
          sx={{
            alignItems: 'center',
          }}
        >
          <Avatar
            profileImageUrl={profileImageUrl}
            firstName={user.firstName}
            lastName={user.lastName}
            color={user.avatarColor}
            active={active}
          />

          <Text
            as="p"
            sx={{
              ml: 2,
              fontSize: 'sm',
              flexShrink: 9999,
            }}
          >
            {user.firstName} {user.lastName}
          </Text>
        </Flex>
      ),
      value: {
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
      },
    }));

  return (
    <React.Fragment>
      <Flex
        flexDirection="column" alignItems="center"
        justifyContent="center"
      >
        <DocumentDetail
          label="viewDocument.details.state"
          icon={<Icons.Status width="35px" />}
        >
          <FormattedMessage
            id={['document.status', document?.status.toLowerCase()].join('.')}
          />
        </DocumentDetail>

        {document?.status === DocumentStatusEnum.Completed ? (
          <DocumentDetail
            icon={<Icons.Assignee width="35px" />}
            label="viewDocument.details.completedBy"
          >
            {userCompletedBy && (
              <DocumentDetailUser
                active={userCompletedBy.active}
                profileImageUrl={userCompletedBy.profileImageUrl}
                firstName={userCompletedBy.user.firstName}
                lastName={userCompletedBy.user.lastName}
                avatarColor={userCompletedBy.user.avatarColor}
              />
            )}
          </DocumentDetail>
        ) : (
          <DocumentDetail
            label="viewDocument.details.assignee"
            icon={<Icons.Assignee width="35px" />}
          >
            <Select
              placeholder={intl.formatMessage({
                id: 'users.assignee.placeholder',
              })}
              value={assigneeSelectOptions.find(
                (option) => option.value.id === assigneeId,
              )}
              styles={{
                menu(base) {
                  return { ...base, backgroundColor: colors.bg['50'] };
                },
                container(base) {
                  return { ...base, flexGrow: 1, marginLeft: -8 };
                },
                control(base) {
                  return {
                    ...base,
                    backgroundColor: 'transparent',
                    border: 'none',
                  };
                },
                valueContainer(base) {
                  return {
                    ...base,
                    padding: '0 8px',
                  };
                },
                indicatorSeparator() {
                  return { display: 'none' };
                },
                dropdownIndicator(base) {
                  return {
                    ...base,
                    color: colors.primary['400'],
                  };
                },
              }}
              isDisabled={
                !hasDocumentWritePermission
                || (document?.status as DocumentStatusEnum)
                  === DocumentStatusEnum.Completed
              }
              name="user"
              filterOption={createFilter({
                stringify(option) {
                  return [option.value.firstName, option.value.lastName].join(
                    ' ',
                  );
                },
              })}
              options={[
                // @ts-expect-error Weird option error
                {
                  label: intl.formatMessage({
                    id: 'viewDocument.assignee.no-assignee',
                  }),
                  value: '',
                },
                ...assigneeSelectOptions,
              ]}
              onChange={(option) => {
                if (option == null) return;

                updateDocumentAssignee({
                  templateId: params.templateId,
                  documentId: params.documentId,
                  assigneeId: option.value.id,
                });
              }}
            />
          </DocumentDetail>
        )}

        <DocumentDetail
          label="viewDocument.details.createdBy"
          icon={<Icons.CreatedBy width="35px" />}
        >
          {userCreator && (
            <DocumentDetailUser
              active={userCreator.active}
              profileImageUrl={userCreator.profileImageUrl}
              firstName={userCreator.user.firstName}
              lastName={userCreator.user.lastName}
              avatarColor={userCreator.user.avatarColor}
            />
          )}
        </DocumentDetail>

        <DocumentDetail
          label="viewDocument.details.dateCreated"
          icon={<Icons.Created width="35px" />}
        >
          {document?.dateCreated
            && localeDateFormat(new Date(document.dateCreated))}
        </DocumentDetail>

        <DocumentDetail
          label="viewDocument.details.dateCompleted"
          icon={<Icons.Created width="35px" />}
        >
          {document?.dateFinished
            && localeDateFormat(new Date(document.dateFinished))}
        </DocumentDetail>
      </Flex>
    </React.Fragment>
  );
};

type DetailProp = {
  icon?: JSX.Element;
  label?: LocaleKey;
  sx?: StyleObject;
};

export const DocumentDetail: React.FC<DetailProp> = ({
  icon,
  label,
  children,
  sx,
}) => (
  <Flex
    alignItems="center"
    justifyContent="center"
    flexDirection="row"
    sx={{
      display: 'inline-flex',
      flexGrow: 1,
      color: 'darkgrey.800',
      fontSize: 'md',
      borderBottom: `1px solid ${colors.grey['300']}`,
      py: 7,
      px: breakpoints({
        _: 3,
        sm: 0,
      }),
      ...sx,
    }}
  >
    <Flex
      alignItems="center"
      justifyContent="center"
      flexDirection="row"
      sx={{
        justifyContent: 'flex-start',
        flexGrow: 1,
      }}
    >
      <Box
        sx={{
          display: 'block',
          '.a': {
            fill: 'grey.400',
            opacity: '0.2',
          },
          '.b': {
            fill: 'none',
            stroke: 'primary.900',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          },
        }}
      >
        {icon}
      </Box>
      <Text
        intlId={label}
        as="p"
        sx={{
          display: 'block',
          mx: 5,
        }}
      />
    </Flex>
    <Flex alignItems="center">{children}</Flex>
  </Flex>
);

function DocumentDetailUser({
  active,
  profileImageUrl,
  lastName,
  firstName,
  avatarColor,
}: {
  firstName?: string;
  lastName?: string;
  avatarColor: string;
  active: boolean;
  profileImageUrl: string;
}) {
  return (
    <React.Fragment>
      <Avatar
        firstName={firstName}
        lastName={lastName}
        color={avatarColor}
        active={active}
        profileImageUrl={profileImageUrl}
        sx={{
          mr: 2,
          ml: '0px',
          flexShrink: 0,
        }}
      />

      <Text as="p" sx={{ fontSize: 'sm' }}>
        {firstName} {lastName}
      </Text>
    </React.Fragment>
  );
}
