export const typography = {
  lineHeights: {
    0: 0,
    1: 1,
    2: 1.25,
    3: 1.5,
    4: 1.75,
    5: 2,
  },

  fonts: {},

  fontSizes: {
    xs: '10px',
    sm: '12px',
    md: '14px',
    lg: '16px',
    xl: '18px',
    '2xl': '20px',
    '3xl': '24px',
    '4xl': '28px',
    '5xl': '32px',
  },
};
