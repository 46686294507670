import * as ReactQuery from 'react-query';
import { ReliningTimerStopRequest } from 'trace-backend-sdk';
import { identify, makeRequest, Service } from '../../../utils/http';
import { DocumentRequestBase } from '../../../services/documents';
import { InferMutationOptions } from '../../../utils/types';

type Data = DocumentRequestBase & ReliningTimerStopRequest;
async function timerStopApi({ timestamp, templateId, documentId }: Data) {
  return makeRequest(
    Service.reliningTimerStop(
      parseInt(documentId, 10),
      templateId,
      { timestamp },
      await identify(),
    ),
  );
}

function useTimerStopApi(options?: InferMutationOptions<typeof timerStopApi>) {
  const { mutate: timerStop, ...rest } = ReactQuery.useMutation(
    'timerStop',
    timerStopApi,
    options,
  );

  return {
    timerStop,
    ...rest,
  };
}

export { useTimerStopApi };
