import React from 'react';
import * as ThemeUI from 'theme-ui';
import { isFlexGapSupported } from 'utils/browser-support';

export interface FlexProps extends ThemeUI.FlexProps {
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  alignItems?: 'center' | 'flex-start' | 'flex-end';
  alignContent?:
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  justifyItems?: 'center' | 'start' | 'end';
  justifyContent?:
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  width?: string;
  gap?: string | number;
}

export const Flex: React.FC<FlexProps> = React.forwardRef(
  (
    {
      sx,
      as,
      flexDirection,
      alignItems,
      alignContent,
      justifyContent,
      justifyItems,
      width = '100%',
      gap,
      children,
      ...props
    },
    ref,
  ) => {
    let flexSx: any = {
      gap,
      flexDirection,
      alignItems,
      alignContent,
      justifyItems,
      justifyContent,
      width,
      ...sx,
    };

    // Workaround for browsers that do not support flexbox gap yet
    if (!isFlexGapSupported) {
      if (flexSx.gap) {
        if (
          flexSx.flexDirection === 'column'
          || flexSx.flexDirection === 'column-reverse'
        ) {
          flexSx = {
            ...flexSx,
            '& > *:not(:first-child)': {
              marginTop: flexSx.gap,
            },
          };
        } else {
          flexSx = {
            ...flexSx,
            '& > *:not(:first-child)': {
              marginLeft: flexSx.gap,
            },
          };
        }
      } else if (flexSx.columnGap || flexSx.rowGap) {
        // used when flexDirection has different breakpoints
        flexSx = {
          ...flexSx,
          '& > *:not(:first-child)': {
            marginTop: flexSx.columnGap,
            marginLeft: flexSx.rowGap,
          },
        };
      }
    }

    return (
      <ThemeUI.Flex
        ref={ref} sx={flexSx}
        as={as} {...props}
      >
        {children}
      </ThemeUI.Flex>
    );
  },
);
