import { FieldProps } from '@rjsf/core';
import { PictureTake } from 'components/form-elements/JsonForms/components/PictureTake';
import { Button } from 'components/form-elements';
import { Timer } from 'components/Timer/Timer';
import * as React from 'react';
import * as Icons from 'components/icons';
import * as ReactQuery from 'react-query';
import { QUERY_KEY_RUNNING_DOCUMENTS_FOR_USER } from '../../../../services/documents/get-running-documents-for-user/hook';
import { IntlCustom } from '../../../../lib';

export function PictureTakeStart(props: FieldProps) {
  const queryClient = ReactQuery.useQueryClient();
  const intl = IntlCustom.useIntl();

  const hasEndImage = props.formContext.endDate;

  return (
    <React.Fragment>
      <PictureTake
        {...props}
        imageName="start"
        onRetake={props.formContext.onStartImageRetake}
        onPictureTake={async () => {
          /*
           * Backend relies on Lequinox events to decide
           * which tests are running. These events take time
           * to propagate, and we need to invalidate *after*
           * they have propagated, if we want to get the latest
           * values of the running documents.
           *
           * TODO: Fix with websockets
           * */
          setTimeout(() => {
            queryClient.invalidateQueries(QUERY_KEY_RUNNING_DOCUMENTS_FOR_USER);
          }, 2000);
        }}
        retakeWarningIntlId={
          hasEndImage
            ? 'confirmationModal.pressureTest.retakeInitialPhoto'
            : 'confirmationModal.pressureTest.initialReset'
        }
        ActionButton={(
          <Button
            prependIcon={<Icons.CameraMui color="currentColor" />}
            intlId="pressureTest.startTest"
          />
        )}
        TitleIcon={<Icons.PressureTest />}
        title={intl.formatMessage({ id: 'pressureTest.startTest' })}
      />

      {!hasEndImage && props.formContext.startDate && (
        <Timer
          wrapperSx={{ position: 'sticky', bottom: 0 }}
          variantSx={{ boxShadow: 1 }}
          startTime={props.formContext.startDate}
        />
      )}
    </React.Fragment>
  );
}
