import * as ReactQuery from 'react-query';
import { identify, makeRequest, Service } from '../../../utils/http';
import { UnwrapPromise } from '../../../utils/types';

type Data = Parameters<typeof Service.createPaymentsFreeSubscription>[0];
async function createPaymentsFreeSubscriptionApi(data: Data) {
  return makeRequest(
    Service.createPaymentsFreeSubscription(data, await identify()),
  );
}

function useCreatePaymentsFreeSubscription(options?: {
  onSuccess: (
    data: UnwrapPromise<ReturnType<typeof createPaymentsFreeSubscriptionApi>>
  ) => void;
}) {
  const { mutate: createPaymentsFreeSubscription, ...rest } = ReactQuery.useMutation(
    'createPaymentsSubscription',
    async (data: Pick<Data, 'stripePrices'>) => {
      return createPaymentsFreeSubscriptionApi({
        stripePrices: data.stripePrices,
      });
    },
    options,
  );

  return {
    createPaymentsFreeSubscription,
    ...rest,
  };
}

export { useCreatePaymentsFreeSubscription };
