import * as React from 'react';
import { useLocalStorage } from '../local-storage/use-local-storage';

type ValuesAggregator<T> = {
  addValue: (value: T) => void;
  removeValue: (value: T) => void;
  hasValue: (value: T) => boolean;
  values: T[];
};

/*
 * Hook to manage a collection of values.
 * Useful in a checkbox group scenario.
 * */
function useAggregateValues<T>(defaultValues?: T[]): ValuesAggregator<T> {
  const [values, setValues] = React.useState<T[]>(defaultValues ?? []);

  const hasValue = React.useCallback(
    (value: T) => values.includes(value),
    [values],
  );

  const addValue = React.useCallback((value: T) => {
    setValues((oldData) => {
      if (oldData.includes(value)) return oldData;

      return oldData.concat(value);
    });
  }, []);

  const removeValue = React.useCallback((value: T) => {
    setValues((oldData) => oldData.filter((v) => v !== value));
  }, []);

  return React.useMemo(
    () => ({
      addValue,
      removeValue,
      values,
      hasValue,
    }),
    [addValue, hasValue, removeValue, values],
  );
}

function useLocalStorageAggregateValues<T>(
  key: string,
  defaultValues?: T[],
): ValuesAggregator<T> {
  const [values, setValues] = useLocalStorage(key, defaultValues);

  const hasValue = React.useCallback(
    (value: T) => values?.includes(value) ?? false,
    [values],
  );

  const addValue = React.useCallback(
    (value: T) => {
      setValues((oldData) => {
        if (oldData?.includes(value)) return oldData;

        return oldData?.concat(value) ?? [value];
      });
    },
    [setValues],
  );

  const removeValue = React.useCallback(
    (value: T) => {
      setValues((oldData) => oldData?.filter((v) => v !== value) ?? []);
    },
    [setValues],
  );

  return React.useMemo(
    () => ({
      addValue,
      removeValue,
      values: values ?? [],
      hasValue,
    }),
    [addValue, hasValue, removeValue, values],
  );
}

export { useAggregateValues, useLocalStorageAggregateValues };
export type { ValuesAggregator };
