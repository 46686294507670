import * as React from 'react';
import * as Utils from 'utils';
import { set } from 'lodash/fp';
import { Button } from 'components/form-elements';
import { FieldProps, IChangeEvent, ISubmitEvent } from '@rjsf/core';
import { PictureTakeStart } from 'components/PressureTestForm/components/PictureTakeStart/PictureTakeStart';
import { PictureTakeEnd } from 'components/PressureTestForm/components/PictureTakeEnd/PictureTakeEnd';
import { Box } from 'components/layout';
import { TimerText } from 'components/Timer/Timer';
// eslint-disable-next-line max-len
import { MultiSelectAggregate } from 'components/form-elements/JsonForms/components/MultiSelectAggregate/MultiSelectAggregate';
import { JsonForm } from 'components/form-elements/JsonForms/components/JsonForm/JsonForm';
import { useDocumentParams } from '../../pages';
import { OtherField } from '../form-elements/JsonForms/components/OtherField';
import { uiSchema } from './ui-schema';

function mapDotSeparatedNumber(values: number[]) {
  return parseFloat(values.join('.'));
}

function KontrollTryck(props: FieldProps) {
  return (
    <MultiSelectAggregate
      {...props}
      onAggregateValues={mapDotSeparatedNumber}
      variant="gray"
      fieldSeparator={(
        <Box
          sx={{
            width: '1px',
            alignSelf: 'stretch',
            backgroundColor: '#3e3e53',
            isolation: 'isolate',
          }}
        />
      )}
    >
      {props.children}
    </MultiSelectAggregate>
  );
}

function BerakingStryck(props: FieldProps) {
  return (
    <MultiSelectAggregate
      {...props}
      onAggregateValues={mapDotSeparatedNumber}
      variant="deepBlue"
      fieldSeparator={(
        <Box
          sx={{
            width: '1px',
            alignSelf: 'stretch',
            backgroundColor: '#5168a1',
            isolation: 'isolate',
          }}
        />
      )}
    >
      {props.children}
    </MultiSelectAggregate>
  );
}

function Timer(props: FieldProps) {
  return (
    <MultiSelectAggregate
      {...props}
      backgroundLightness="light"
      variant="running"
      fieldSeparator={<TimerText sx={{ mb: 4 }}>:</TimerText>}
      containerSx={{ px: 10 }}
    >
      {props.children}
    </MultiSelectAggregate>
  );
}

const fields = {
  startPicture: PictureTakeStart,
  endPicture: PictureTakeEnd,
  kontrolltryckAggregate: KontrollTryck,
  berakningstryckAggregate: BerakingStryck,
  timerAggregate: Timer,
};

const widgets = {
  underlinedInput: OtherField,
};

type Props = {
  schema: Record<string, any>;
  isCompleted: boolean;
  hasDocumentWritePermission: boolean;
};

export function PressureTestForm(props: Props) {
  const params = useDocumentParams();
  const [formData, setFormData] = Utils.Document.useDocumentFormState();
  const onUpdateDocument = Utils.Document.useUpdateDocumentData();
  const onSubmitDocument = Utils.Document.useSubmitDocument();

  const onStartImageRetake = React.useCallback(() => {
    setFormData((formData: any) => set('end', {}, formData));
  }, [setFormData]);

  const formContext = React.useMemo(
    () => ({
      endDate: formData.end?.timestamp,
      startDate: formData.start?.timestamp,
      onStartImageRetake,
      documentId: params.documentId,
      templateId: params.templateId,
    }),
    [
      formData.end?.timestamp,
      formData.start?.timestamp,
      onStartImageRetake,
      params.documentId,
      params.templateId,
    ],
  );

  const onChange = React.useCallback(
    (e: IChangeEvent) => {
      setFormData(e.formData);
      onUpdateDocument(e.formData);
    },
    [onUpdateDocument, setFormData],
  );

  const onSubmit = React.useCallback(
    (e: ISubmitEvent<any>) => {
      onSubmitDocument(e.formData);
    },
    [onSubmitDocument],
  );

  const isDisabled = props.isCompleted || !props.hasDocumentWritePermission;

  return (
    <JsonForm
      showErrorList={Boolean(formData.end?.timestamp)}
      disabled={isDisabled}
      name="pressureTest"
      formData={formData}
      formContext={formContext}
      fields={fields}
      widgets={widgets}
      schema={props.schema}
      uiSchema={uiSchema}
      onChange={onChange}
      onSubmit={onSubmit}
    >
      {formData.end?.imageUrl && !props.isCompleted && (
        <Button
          disabled={isDisabled}
          sx={{
            mt: 8,
          }}
          type="submit"
          intlId="document.nextStep"
        />
      )}
    </JsonForm>
  );
}
