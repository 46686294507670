import * as ReactQuery from 'react-query';
import * as ThemeUi from 'theme-ui';
import React from 'react';
import { Box } from '../layout';

export function GlobalLoadingIndicator() {
  const mutationsCount = ReactQuery.useIsMutating();
  const isMutating = mutationsCount > 0;

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      {isMutating && (
        <ThemeUi.Spinner sx={{ width: 20, color: 'primary.900' }} />
      )}
    </Box>
  );
}
