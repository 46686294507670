import { useElements, useStripe } from '@stripe/react-stripe-js';
import * as ReactQuery from 'react-query';
import { PaymentMethod } from '@stripe/stripe-js';
import { InferMutationOptions } from '../../../utils/types';

type Data = {
  cardholderName: string;
};

type MutationFn = (data: Data) => {
  paymentMethod: PaymentMethod;
  error?: undefined;
};

/*
 * Uses stripe internals to obtain the card element and send
 * a request with the values currently in order to create
 * a new payment method.
 * */
export function useCreatePaymentMethod(
  options?: InferMutationOptions<MutationFn>,
) {
  const stripe = useStripe();
  const stripeElements = useElements();

  return ReactQuery.useMutation(
    'stripeCreatePaymentMethod',
    async ({ cardholderName }: Data) => {
      const cardElement = stripeElements?.getElement('card');
      if (!cardElement) {
        throw new Error('No payment method configured');
      }

      const response = await stripe?.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: cardholderName,
        },
      });

      if (response?.error) {
        throw new Error(response.error.message);
      }

      if (!response || !response.paymentMethod) {
        throw new Error('No payment method configured');
      }

      return response;
    },
    options,
  );
}
