import * as ReactQuery from 'react-query';
import { identify, makeRequest, Service } from '../../../utils/http';
import { InferQueryOptions } from '../../../utils/types';

async function getSubscriptionApi() {
  return makeRequest(Service.getSubscription(await identify()));
}

export const QUERY_KEY_SUBSCRIPTION = ['subscription'];

function useGetSubscription(
  options?: InferQueryOptions<typeof getSubscriptionApi>,
) {
  return ReactQuery.useQuery(
    QUERY_KEY_SUBSCRIPTION,
    getSubscriptionApi,
    options,
  );
}

export { useGetSubscription };
