import { QuickTraceForm } from 'components/quick-trace/components/QuickTraceForm';
import { Container } from 'components/layout';
import React from 'react';

export function QuickTracePage() {
  return (
    <Container sx={{ pt: 0 }} variant="spacer">
      <QuickTraceForm />
    </Container>
  );
}
