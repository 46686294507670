import { StyleObject } from 'theming/index';

export const root: StyleObject = {
  backgroundColor: 'bg.50',
  margin: '0px',
  boxSizing: 'border-box',
  height: '100%',

  '*, *::before, *::after': {
    boxSizing: 'inherit',
    margin: '0px',
    fontFamily: '"Poppins", sans-serif',
  },

  h2: {
    fontSize: '3xl',
    fontWeight: 500,
  },
  h3: {
    fontSize: '2xl',
    fontWeight: 500,
  },

  button: {
    cursor: 'pointer',
  },
};
