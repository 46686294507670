import * as React from 'react';
import * as ThemeUI from 'theme-ui';
import * as ReactIntl from 'lib/intl';
import { Flex } from 'components/layout';
import { Text } from 'components/typography';
import { ChangeEventHandler } from 'react';

export type Temperature = 'celsius' | 'fahrenheit';
type TemperatureToggleProps = {
  value: Temperature;
  onChange: (value: Temperature) => void;
  disabled?: boolean;
};

export function TemperatureToggle(props: TemperatureToggleProps) {
  return (
    <Flex
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      sx={{
        height: '40px',
        backgroundColor: 'white.50',
        borderRadius: '20px',
        boxShadow: '3dp',
      }}
    >
      <TemperatureScaleType
        disabled={props.disabled}
        isActive={props.value === 'celsius'}
        value="celsius"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          props.onChange((event.target.value as Temperature) ?? '');
        }}
      />

      <TemperatureScaleType
        disabled={props.disabled}
        isActive={props.value === 'fahrenheit'}
        value="fahrenheit"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          props.onChange((event.target.value as Temperature) ?? '');
        }}
      />
    </Flex>
  );
}

function TemperatureScaleType({
  value,
  isActive,
  onChange,
  disabled,
}: {
  value: string;
  isActive: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
}) {
  return (
    <Flex
      sx={{
        height: '40px',
        borderRadius: '20px',
        textAlign: 'center',
        color: isActive ? 'white.50' : '#4D4D65',
        backgroundColor: isActive ? '#3192F1' : 'white.50',
        fontWeight: 300,
        textTransform: 'capitalize',
      }}
    >
      <ThemeUI.Label
        sx={{
          '& input:checked ~ svg': {
            color: 'transparent',
          },
          '& input:focus ~ svg': {
            backgroundColor: 'transparent',
          },
          '& input:not(:checked) ~ svg': {
            color: 'transparent',
          },
          cursor: 'pointer',
        }}
      >
        <ThemeUI.Radio
          disabled={disabled}
          name="temperatureScaleType"
          value={value}
          onChange={onChange}
        />

        <Flex
          flexDirection="row" justifyContent="center"
          alignItems="center"
        >
          <ReactIntl.FormattedMessage
            id={`temperature.scaleType.${value}`}
            values={{
              bold: (message: string) => (
                <Text
                  sx={{
                    fontWeight: 500,
                    px: 1,
                  }}
                >
                  {message}
                </Text>
              ),
            }}
          />
          <Text
            sx={{
              px: 1,
              fontWeight: 600,
            }}
          >
            {value === 'fahrenheit' ? (
              <span>&#8457;</span>
            ) : (
              <span>&#8451;</span>
            )}
          </Text>
        </Flex>
      </ThemeUI.Label>
    </Flex>
  );
}
