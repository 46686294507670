import React from 'react';

import PWAPrompt from './components/PWAPrompt'

const deviceCheck = () => {
  const isiOS = /iphone|ipad|ipod/.test(
    window.navigator.userAgent.toLowerCase(),
  );
  const isiPadOS = navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1;
  const isStandalone = 'standalone' in window.navigator && window.navigator.standalone;

  return (isiOS || isiPadOS) && !isStandalone;
};

export default ({
  timesToShow = 1,
  promptOnVisit = 1,
  permanentlyHideOnDismiss = true,
  copyTitle = 'Add to Home Screen',
  copyBody = 'This website has app functionality. Add it to your home screen to use it in fullscreen and while offline.',
  copyClosePrompt = 'Cancel',
  copyIosChrome = 'You can add this app to your home screen through the Safari browser.',
  delay = 1000,
  debug = false,
  onClose = () => { },
}) => {
  let promptData = JSON.parse(localStorage.getItem('iosPwaPrompt'));

  if (promptData === null) {
    promptData = { isiOS: deviceCheck(), visited: false };
    localStorage.setItem('iosPwaPrompt', JSON.stringify(promptData));
  }

  if (!promptData.visited || debug) {
    return (
      <PWAPrompt
        delay={delay}
        copyTitle={copyTitle}
        copyBody={copyBody}
        copyClosePrompt={copyClosePrompt}
        copyIosChrome={copyIosChrome}
        permanentlyHideOnDismiss={permanentlyHideOnDismiss}
        promptData={promptData}
        maxVisits={timesToShow + promptOnVisit}
        onClose={onClose}
        isIOS={promptData.isiOS}
      />
    );
  }

  return null;
};
