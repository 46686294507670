import { Box, Flex, FlexProps } from 'components/layout';
import { LoadingIndicator } from 'components/LoadingIndicator';
import * as React from 'react';
import { Text } from 'components/typography';
import { LocaleKey } from 'translations';

type Props = FlexProps & {
  loadingTextIntlId?: LocaleKey;
  loadingTextIntlValues?: Record<string, string | number>;
  backgroundOpacity?: number;
};

export function PageLoadingIndicator({
  backgroundOpacity = 0.85,
  ...props
}: Props) {
  return (
    <Flex
      {...props}
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        isolation: 'isolate',
        backgroundColor: `rgba(255, 255, 255, ${backgroundOpacity})`,
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        color: 'primary.800',
        textAlign: 'center',
        ...props.sx,
      }}
    >
      <Flex
        sx={{
          width: 'fit-content',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 2,
          mb: 6,
        }}
      >
        <LoadingIndicator />

        {props.loadingTextIntlId && (
          <Text
            as="p"
            sx={{
              maxWidth: 250,
              fontSize: 'md',
              mt: 3,
            }}
            intlId={props.loadingTextIntlId}
            intlValues={props.loadingTextIntlValues}
          />
        )}
      </Flex>
    </Flex>
  );
}

export function RelativeLoadingIndicator() {
  return (
    <Box
      sx={{
        backgroundColor: 'rgba(255,255,255,0.85)',
        height: '100%',
        width: '100%',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <LoadingIndicator />
    </Box>
  );
}
