import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as ReactIntl from 'lib/intl';
import * as Rhf from 'react-hook-form';
import * as ThemeUI from 'theme-ui';
import Creatable from 'react-select/creatable';
import {
  components,
  MenuProps,
  MultiValueProps,
  OptionProps,
  OptionsType,
  PlaceholderProps,
  ValueContainerProps,
  ControlProps,
} from 'react-select';
import { MultiValueRemoveProps } from 'react-select/src/components/MultiValue';

import { Box, Flex } from 'components/layout';
import { Text } from 'components/typography';
import { StyleObject } from 'theming';
import { breakpoints } from 'utils/breakpoints';
import * as Icons from 'components/icons';
import { Constants } from 'global/constants';
import { LocaleKey } from 'translations';
import countryCallingCodes from 'translations/countries/country-calling-codes.json';
import { generateContext } from '../../../utils/context';
import { CountryCallingCodeSelect } from '../selects/CountryCallingCodeSelect/CountryCallingCodeSelect';

export type ComboMultiSelectValueType = {
  label: React.ReactNode;
  value: string;
};

type ComboMultiSelectProps = {
  labelIntlId?: LocaleKey;
  placeholderIntlId?: LocaleKey;
  onChange?: (value: OptionsType<ComboMultiSelectValueType>) => any;
  options?: OptionsType<ComboMultiSelectValueType>;
  wrapperSx?: StyleObject;
  value: OptionsType<ComboMultiSelectValueType>;
  isLoading?: boolean;
  isDisabled?: boolean;
  isClearable?: boolean;
  selectedButton?: string;
  countryCodeValue?: string;
};

const optionStyles = {
  backgroundColor: 'white.50',
  boxShadow: 'userCard.elevation',
  height: '50px',
  marginBottom: '5px',
  px: 6,
  '& > *': {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    fontSize: 'md',
    fontWeight: '300',
    '& > *:nth-last-child(2)': {
      flexGrow: 1,
    },
  },
} as StyleObject;

const MultiValue = React.forwardRef<HTMLDivElement, MultiValueProps<any>>(
  (props, ref) =>
    ReactDOM.createPortal(
      <Box sx={{ ...optionStyles, pointerEvents: 'none' }}>
        <components.MultiValue {...props}>
          {props.children}
        </components.MultiValue>
      </Box>,
      // @ts-expect-error type-mismatch
      ref?.current,
    ),
);

const MultiValueRemove = (props: MultiValueRemoveProps<any>) => (
  <components.MultiValueRemove {...props}>
    <Icons.CloseCircled width={32} sx={{ stroke: 'grey.400' }} />
  </components.MultiValueRemove>
);

const Menu = (props: MenuProps<any, any>) => (
  <Box
    sx={{
      minWidth: '97%',
      my: 2,
      mx: breakpoints({ _: 'containerXPaddingNegative', sm: 0 }),
    }}
  >
    <components.Menu {...props}>{props.children}</components.Menu>
  </Box>
);

const [useCreatableContext, CreatableContextProvider] = generateContext<{ placeholderIntlId: LocaleKey }>();

const ClearIndicator = (props: any) => (
  <components.ClearIndicator {...props}>
    <Icons.CloseCircled width={32} sx={{ stroke: 'grey.400' }} />
  </components.ClearIndicator>
);

const Input = (props: any) => {
  return (
    <React.Fragment>
      <components.Input {...props} />
    </React.Fragment>
  );
};

const DropdownIndicator = (props: any) => (
  <components.DropdownIndicator {...props}>
    <Icons.AddCircle color="tertiary1.400" />
  </components.DropdownIndicator>
);

const Option = (props: OptionProps<any, any>) => (
  <Box sx={{ ...optionStyles }}>
    <components.Option {...props}>{props.children}</components.Option>
  </Box>
);

const Control = (props: ControlProps<any, any>) => {
  const [selectedButton, setSelectedButton] = React.useState('sms');
  const [countryCodeValue, setCountryCodeValue] = React.useState('');

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.selectProps.setSelectedButton(e.target.value);
    setSelectedButton(e.target.value);
  };

  const onCountryCodeChange = (value: string) => {
    setCountryCodeValue(value);
    props.selectProps.setCountryCode(value);
  };

  const formMethods = Rhf.useForm({
    defaultValues: {
      countryCallingCode: countryCallingCodes.SE,
    },
  });
  const {
    field: { name, onBlur },
  } = Rhf.useController({
    name: 'countryCallingCode',
    control: formMethods.control,
  });

  return (
    <Box>
      <Flex sx={{ justifyContent: 'center', marginBottom: '20px' }}>
        <Box
          sx={{
            display: 'inline-flex',
            overflow: 'hidden',
            backgroundColor: 'white.50',
            borderRadius: '22px',
            border: '2px solid #21429E',
            width: '100%',
          }}
        >
          <ThemeUI.Input
            type="radio"
            value="sms"
            id="sms"
            name="sms"
            onChange={onChange}
            sx={{ display: 'none' }}
            defaultChecked
          />
          <ThemeUI.Label
            htmlFor="sms"
            sx={{
              transition: 'background-color 0.5s',
              cursor: 'pointer',
              color: '#4D4D65',
              padding: '8px 14px',
              'input:checked ~ &': {
                backgroundColor: '#21429E',
                color: 'white.50',
              },
            }}
          >
            <Flex
              justifyContent="center"
              alignItems="center"
              sx={{ marginRight: '5' }}
            >
              <Icons.Message
                color={selectedButton === 'sms' ? '#FFF' : '4D4D65'}
              />
              <Text intlId="viewDocument.recipients.add.sms" />
            </Flex>
          </ThemeUI.Label>

          <ThemeUI.Input
            type="radio"
            value="email"
            id="email"
            name="sms"
            onChange={onChange}
            sx={{ display: 'none' }}
          />

          <ThemeUI.Label
            htmlFor="email"
            sx={{
              transition: 'background-color 0.5s',
              cursor: 'pointer',
              color: '#4D4D65',
              padding: '8px 14px',
              textAlign: 'center',
              ...(selectedButton === 'email' && {
                backgroundColor: '#21429E',
                color: 'white.50',
              }),
            }}
          >
            <Flex
              justifyContent="center"
              alignItems="center"
              sx={{ marginRight: '5' }}
            >
              <Icons.Email
                color={selectedButton === 'email' ? '#FFF' : '4D4D65'}
              />
              <Text intlId="viewDocument.recipients.add.email" />
            </Flex>
          </ThemeUI.Label>
        </Box>
      </Flex>
      <Flex>
        {selectedButton === 'sms' && (
          <CountryCallingCodeSelect
            name={name}
            value={countryCodeValue}
            onChange={onCountryCodeChange}
            onBlur={onBlur}
            container={{ width: '50%', maxHeight: '52px', marginRight: '15px' }}
          />
        )}
        <components.Control {...props}>{props.children}</components.Control>
      </Flex>
    </Box>
  );
};

function Placeholder(props: PlaceholderProps<any, any>) {
  const { placeholderIntlId } = useCreatableContext();

  return (
    <components.Placeholder {...props}>
      <Text intlId={placeholderIntlId} />
    </components.Placeholder>
  );
}

function ValueContainer(props: ValueContainerProps<any, any>) {
  return (
    <components.ValueContainer {...props}>
      {/*
        We need to have a placeholder even when there is a value in the
        Creatable, so we add another placeholder here which only shows
        when the Creatable has a value.
      */}
      {props.hasValue && (
        <Placeholder {...(props as any)}>{props.children}</Placeholder>
      )}

      {props.children}
    </components.ValueContainer>
  );
}

export const ComboMultiSelect = ({
  labelIntlId,
  placeholderIntlId = 'generic.label.addMore',
  onChange,
  options = [],
  value,
  wrapperSx,
  isLoading = false,
  isDisabled = false,
  isClearable = false,
}: ComboMultiSelectProps) => {
  const intl = ReactIntl.useIntl();

  const listRef = React.useRef<HTMLDivElement>(null);

  const MultiValueComponent = React.useCallback(
    (props) => (
      <MultiValue
        {...props}
        ref={listRef}
        className={[ComboMultiSelectMultiValueClassName, props.className].join(
          ' ',
        )}
      >
        {props.children}
      </MultiValue>
    ),
    [],
  );

  const [selectedButton, setSelectedButton] = React.useState('sms');
  const [countryCodeValue, setCountryCodeValue] = React.useState('');

  const contextValue = React.useMemo(
    () => ({
      placeholderIntlId,
    }),
    [placeholderIntlId],
  );
  const [input, setInput] = React.useState('');
  return (
    <Box
      sx={{
        width: '97%',
        fontWeight: '200',
        color: 'darkgrey.800',
        ...wrapperSx,
      }}
    >
      {/* Detached options container */}
      <Box
        ref={listRef}
        sx={{
          mx: breakpoints({ _: 'containerXPaddingNegative', sm: 0 }),
          mb: 6,
          boxShadow: 'userCard.elevation',
        }}
      />
      <CreatableContextProvider value={contextValue}>
        <Creatable
          isMulti
          isLoading={isLoading}
          isDisabled={isDisabled}
          isClearable={isClearable}
          backspaceRemovesValue={false}
          formatCreateLabel={(string) =>
            `${intl.formatMessage({ id: 'generic.label.add' })} 
            "${selectedButton === 'sms' ? countryCodeValue + string : string}"`}
          isValidNewOption={(inputValue) =>
            (selectedButton === 'sms'
              ? Constants.PHONE_REGEX.test(countryCodeValue + inputValue)
              : Constants.EMAIL_REGEX.test(inputValue))}
          value={value}
          noOptionsMessage={() =>
            (selectedButton === 'sms'
              ? intl.formatMessage({
                id: 'documents.addRecipients.error.validPhone',
              })
              : intl.formatMessage({
                id: 'documents.addRecipients.error.validEmail',
              }))}
          components={{
            Menu,
            Option,
            ClearIndicator,
            MultiValueRemove,
            ValueContainer,
            DropdownIndicator,
            MultiValue: MultiValueComponent,
            Placeholder,
            Input,
            Control,
          }}
          setSelectedButton={(buttonSelected: string) => {
            setSelectedButton(buttonSelected);
          }}
          setCountryCode={(countryCode: string) => {
            setCountryCodeValue(countryCode);
          }}
          styles={{
            menu: () => ({ marginTop: '-8px' }), // prevent default styles
            option: () => ({}), // prevent default styles
            multiValue: () => ({}), // prevent default styles
            multiValueLabel: () => ({ fontSize: '100%' }),
            menuList: () => ({
              maxHeight: '1000px',
              paddingTop: '16px',
              overflowY: 'scroll',
              boxShadow: '0pt 3pt 18pt #00000014',
            }),
            container: () => ({
              width: '100%',
            }),
            multiValueRemove: () => ({
              cursor: 'pointer',
              pointerEvents: 'all',
            }),
            placeholder: (provided, state) => ({
              ...provided,
              pointerEvents: 'none',
              paddingLeft: '0.5em',
              display:
                state.isFocused || state.selectProps.inputValue
                  ? 'none'
                  : 'block',
            }),
            dropdownIndicator: (provided) => ({
              ...provided,
              display: 'none',
              padding: '14px',
            }),
            control: (provided) => ({
              ...provided,
              width: '100%',
              borderColor: '#9E9EAF',
              backgroundColor: 'transparent',
            }),
            input: (provided) => ({
              ...provided,
              width: '100%',
              color: 'inherit',
              fontWeight: 'inherit',

              /*
               * Input width needs to be full so that
               * on mobile it can be targeted using long
               * press for the paste menu.
               * */
              '& > div, & input': {
                width: '100% !important',
              },
            }),
            indicatorSeparator: (provided) => ({
              ...provided,
              display: 'none',
            }),
            indicatorsContainer: (provided) => ({
              ...provided,
              height: '50px',
              svg: { width: '30px' },
            }),
            noOptionsMessage: () => ({
              backgroundColor: 'white',
              boxShadow: '0pt 3pt 18pt #00000014',
              fontSize: '14px',
              'font-weight': '300',
              maxHeight: '55px',
              padding: '14px',
            }),
          }}
          options={options.filter((option) =>
            (selectedButton === 'sms'
              ? option.value.includes('+')
              : option.value.includes('@')))}
          onChange={(value, { action }) => {
            value.map((val) => {
              const conditions = ['+', '@'];
              if (typeof val.label !== 'object') {
                const isIncluded = conditions.some((el) =>
                  val.label.includes(el));
                if (val.__isNew__) {
                  val.label = isIncluded
                    ? val.label
                    : countryCodeValue + val.label;
                  val.value = isIncluded
                    ? val.value
                    : countryCodeValue + val.value;
                }
              }

              return val;
            });

            if (action === 'create-option' || action === 'select-option') {
              setInput('');
            }

            if (onChange && value) {
              onChange(value);
            }
          }}
          inputValue={input}
          onInputChange={(value, { action }) => {
            if (action === 'input-change') {
              setInput(value);
            }
          }}
        />
      </CreatableContextProvider>
      {labelIntlId && (
        <Text
          as="p"
          sx={{ mt: 2, fontSize: 'md', color: 'currentColor' }}
          intlId={labelIntlId}
        />
      )}
    </Box>
  );
};

export const ComboMultiSelectMultiValueClassName = 'multiSelectMultiValue';
