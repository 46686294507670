import * as Rhf from 'react-hook-form';
import * as _ from 'lodash-es';
import * as ThemeUI from 'theme-ui';
import * as ReactIntl from 'lib/intl';
import React from 'react';
import { ShouldRender } from '../../../ShouldRender';
import { Checkbox, CheckboxProps } from './Checkbox';

function composeFieldName(...names: string[]) {
  return names.join('.');
}

export function CheckboxField(
  props: CheckboxProps & {
    name: string;
  },
) {
  const formMethods = Rhf.useFormContext();
  const { errors } = Rhf.useFormState({
    control: formMethods.control,
  });

  const errorMessage: string | undefined = _.get(
    errors,
    composeFieldName(props.name, 'message'),
  );

  const fieldValues = formMethods.register(props.name);

  return (
    <ThemeUI.Box
      sx={{
        py: 1,
        width: '100%',
      }}
    >
      <Checkbox
        {...props}
        {...fieldValues}
        name={fieldValues.name}
        onChange={async (e) => {
          await fieldValues.onChange(e);
        }}
      >
        {props.children}
      </Checkbox>

      <ShouldRender when={Boolean(errorMessage)}>
        <ThemeUI.Text
          as="p"
          sx={{
            fontSize: 'sm',
            minHeight: '1.25em', // should match line-height value
            lineHeight: 2,
            color: 'error',
          }}
        >
          <ReactIntl.FormattedMessage id={errorMessage} />
        </ThemeUI.Text>
      </ShouldRender>
    </ThemeUI.Box>
  );
}
