import * as _ from 'lodash-es';
import React from 'react';
import { PaymentsService } from '../index';

function useGetApplicableDiscount() {
  const getPaymentDiscounts = PaymentsService.useGetPaymentDiscounts();
  const getUpcomingInvoice = PaymentsService.useGetUpcomingInvoice();

  const estimatedSubtotalCents = getUpcomingInvoice.upcomingInvoice?.estimatedSubtotalCents;
  return React.useMemo(
    () =>
      (getPaymentDiscounts.data && estimatedSubtotalCents != null
        ? getPaymentDiscounts.data.discounts.find((discount) =>
          _.inRange(estimatedSubtotalCents, discount.from, discount.to + 1))
        : undefined),
    [estimatedSubtotalCents, getPaymentDiscounts.data],
  );
}

export { useGetApplicableDiscount };
