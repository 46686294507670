import { WidgetProps } from '@rjsf/core';
import * as React from 'react';
import { UnderlinedInput } from './UnderlinedInput';

export function OtherField(props: WidgetProps) {
  return (
    <UnderlinedInput
      {...props}
      sx={{
        mt: -24,
      }}
      placeholderIntlId="pressureTest.ovrigt.placeholder"
    />
  );
}
