import * as ReactQuery from 'react-query';
import { identify, makeRequest, Service } from '../../../utils/http';
import { InferQueryOptions } from '../../../utils/types';

async function getBillingHistoryApi() {
  return makeRequest(Service.getBillingHistory(await identify()));
}

const QUERY_KEY_BILLING_HISTORY = 'billing-history';

function useGetBillingHistory(
  options?: InferQueryOptions<typeof getBillingHistoryApi>,
) {
  return ReactQuery.useQuery(
    QUERY_KEY_BILLING_HISTORY,
    getBillingHistoryApi,
    options,
  );
}

export { QUERY_KEY_BILLING_HISTORY, useGetBillingHistory };
