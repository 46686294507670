import { FieldProps } from '@rjsf/core';
import React from 'react';
import * as _ from 'lodash-es';
import { useIntl } from 'lib/intl';
import { z } from 'zod';
import { useDocumentParams } from '../../../pages';
import { useModal } from '../../../store/modals';
import { ReliningServices } from '../index';
import { getBlobFromBase64 } from '../../../utils/base64/get-blob-from-base64';
import { Box } from '../../../components/layout';
import { Button } from '../../../components/form-elements';
import * as Icons from '../../../components/icons';
import { ImageWithCaption } from '../../../components/ImageWithCaption/ImageWithCaption';
import { LocaleKey } from '../../../translations';
import { Colors } from '../../../theming/foundations/colors';
import { Submenu } from '../../../components/submenu';
import { ConfirmationDialog } from '../../../components/confirmation-dialog/ConfirmationDialog';
import { Text } from '../../../components/typography';
import { RelativeLoadingIndicator } from '../../../components/PageLoadingIndicator/PageLoadingIndicator';
import { Spacer } from '../../../components/Spacer/Spacer';
import { useReliningFormContext } from '../routes';
import { getObjectPathFromIdSchema } from '../../../components/form-elements/JsonForms/utils/get-object-path-from-id-schema';

type ReliningImageData = {
  caption: string;
  imageUrl: string;
  timestamp: number;
};

const ImageTypeParser = z
  .enum(['start', 'end', 'default'] as const)
  .default('default');
type ImageType = z.infer<typeof ImageTypeParser>;

type ImageTypeProperties = {
  backgroundColor: Colors;
  buttonLabel: LocaleKey;
  prependIcon?: React.ReactNode;
};

const imageType: Record<ImageType, ImageTypeProperties> = {
  start: {
    backgroundColor: 'tertiary1.300',
    buttonLabel: 'pressureTest.startTest',
    prependIcon: <Icons.CameraMui color="currentColor" />,
  },
  end: {
    backgroundColor: 'tertiary2.900',
    buttonLabel: 'pressureTest.endTest',
    prependIcon: <Icons.CameraMui color="currentColor" />,
  },
  default: {
    backgroundColor: 'tertiary1.300',
    buttonLabel: 'relining.batch.cameraButton',
    prependIcon: <Icons.CameraMui color="currentColor" />,
  },
};

export function ReliningImage(props: FieldProps<ReliningImageData>) {
  const { onImageRemove } = useReliningFormContext();
  const params = useDocumentParams();
  const intl = useIntl();
  const { openModal } = useModal();

  const { removeReliningImage, isLoading: isRemovingImage } = ReliningServices.useRemoveReliningImage({
    onSuccess: (data) => {
      if (data.documentData) {
        onImageRemove(data.documentData);
      }
    },
  });
  const { uploadReliningImage, isLoading: isUploadingImage } = ReliningServices.useUploadReliningImage({
    onSuccess: (data) => {
      props.onChange({
        ...props.formData,
        imageUrl: data.uploadUrl,
        timestamp: data.timestamp,
      });
    },
  });

  const hasData = !_.isEmpty(props.formData);
  const isRetaking = hasData && isUploadingImage;

  const name = props.uiSchema['ui:imageName'];
  const outerTitle = props.uiSchema['ui:outerTitle']
    ? intl.formatMessage({
      id: props.uiSchema['ui:outerTitle'],
    })
    : '';
  const title = props.uiSchema['ui:imageTitle']
    ? intl.formatMessage({
      id: props.uiSchema['ui:imageTitle'],
    })
    : '';

  function handleTakePicture() {
    openModal({
      modalType: 'cameraModal',
      modalProps: {
        onTakePhotoAnimationDone: async (imageUri) => {
          uploadReliningImage({
            documentId: params.documentId,
            templateId: params.templateId,
            image: await getBlobFromBase64(imageUri),
            fileType: 'image/jpeg',
            name,
          });
        },
      },
    });
  }

  function handleRetakePicture() {
    openModal({
      modalType: 'componentModal',
      modalProps: {
        children: (
          <ConfirmationDialog onProceed={() => handleTakePicture()}>
            <Text
              as="p"
              intlId="generic.confirm.areYouSure"
              sx={{ textAlign: 'center' }}
            />
          </ConfirmationDialog>
        ),
      },
    });
  }

  function handleRemoveImage() {
    openModal({
      modalType: 'componentModal',
      modalProps: {
        children: (
          <ConfirmationDialog
            onProceed={() =>
              removeReliningImage({
                documentId: params.documentId,
                templateId: params.templateId,
                data: {
                  imagePath: getObjectPathFromIdSchema(props.idSchema.$id),
                  timestamp: Date.now(),
                  imageName: name,
                },
              })}
          >
            <Text
              as="p"
              intlId="generic.confirm.areYouSure"
              sx={{ textAlign: 'center' }}
            />
          </ConfirmationDialog>
        ),
      },
    });
  }

  function handleCaptionChange(caption: string) {
    props.onChange({
      ...props.formData,
      caption,
    });
  }

  const type = ImageTypeParser.parse(props.uiSchema['ui:imageType']);
  const { buttonLabel, backgroundColor, prependIcon } = imageType[type];

  return (
    <Box sx={{ mt: -2 }}>
      {outerTitle && (
        <React.Fragment>
          <Text as="h2" variant="h2">
            {outerTitle}
          </Text>

          <Spacer y={3} />
        </React.Fragment>
      )}

      <Box sx={{ position: 'relative' }}>
        {!hasData && (
          <React.Fragment>
            <Button
              disabled={props.disabled}
              sx={{
                backgroundColor,
              }}
              isLoading={isUploadingImage}
              prependIcon={(
                <Box sx={{ display: 'inline-flex', mr: -1, color: 'white.50' }}>
                  {prependIcon}
                </Box>
              )}
              type="button"
              onClick={() => handleTakePicture()}
              intlId={buttonLabel ?? 'relining.batch.cameraButton'}
            />
          </React.Fragment>
        )}

        {(isRetaking || isRemovingImage) && <RelativeLoadingIndicator />}

        {hasData && (
          <ImageWithCaption
            hideCaptionInput={props.uiSchema['ui:hideCaption']}
            disabled={props.disabled}
            imageUrl={props.formData.imageUrl}
            title={title}
            caption={props.formData.caption}
            onCaptionChange={(event) => handleCaptionChange(event.target.value)}
            timestamp={props.formData.timestamp}
          >
            {!props.disabled && (
              <Submenu>
                <Submenu.Item
                  onClick={() => handleRetakePicture()}
                  prependIcon={<Icons.RetakeCamera color="currentColor" />}
                  intlId="relining.retakePicture"
                />

                <Submenu.Item
                  onClick={() => handleRemoveImage()}
                  prependIcon={<Icons.Delete fill="currentColor" />}
                  intlId="quickCapture.widget.delete"
                />
              </Submenu>
            )}
          </ImageWithCaption>
        )}
      </Box>
    </Box>
  );
}
