import {
  DocumentWithoutTemplate,
  DocumentWithoutTemplateStatusEnum,
} from 'trace-backend-sdk';
import { useIntl } from 'lib/intl';
import React from 'react';
import { UsersService } from '../../../services/users';
import { DocumentTemplateId } from '../../../services/documents';
import {
  DocumentCardAvatar,
  DocumentCardBody,
  DocumentCardDate,
  DocumentCardDivider,
  DocumentCardHeader,
  DocumentCardNew,
  DocumentCardNumber,
} from '../../../components/cards/DocumentCard/DocumentCard';
import { Box, Flex } from '../../../components/layout';
import * as Icons from '../../../components/icons';
import { Text, TextWithFallback } from '../../../components/typography';
import { QuickTraceWidgetIconWrapper } from '../../../components/quick-trace/components/QuickTraceWidget';
import { Spacer } from '../../../components/Spacer/Spacer';
import { ReliningDocumentCard } from '../../relining/components/ReliningDocumentCard';
import { ReliningSchemaFinal } from '../../../global/types/templates';
import {
  DocumentCardDefault,
  getPressureTestCardProps,
} from '../../../components/cards/DocumentCard/components/DocumentCardDefault/DocumentCardDefault';
import { Image } from '../../../components/Image';
import NoDocumentsListImage from '../../../assets/images/NoDocumentsList.svg';

type DocumentListProps = {
  documents: DocumentWithoutTemplate[];
};

export function DocumentList({ documents }: DocumentListProps) {
  const intl = useIntl();
  const { allUsers, isLoading: isUsersLoading } = UsersService.useGetUsers();

  const renderDocuments = (
    <React.Fragment>
      {documents.map((document) => {
        const isCompleted = document.status === DocumentWithoutTemplateStatusEnum.Completed;
        const completedBy = allUsers.find(
          (user) => user.userId === document.completedById,
        );
        const assignee = allUsers.find(
          (user) => user.userId === document.assigneeId,
        );

        if ((document.templateId as DocumentTemplateId) === 'quick-capture') {
          // eslint-disable-next-line operator-linebreak
          const { subject, description, ...widgets } =
            // @ts-expect-error TODO: Add type for documentdata
            document?.documentData?.main ?? {};
          const hasFileWidget = Object.keys(widgets).some((key) =>
            key.startsWith('FILE'));
          const hasImageWidget = Object.keys(widgets).some((key) =>
            key.startsWith('IMAGE'));
          const hasGpsWidget = Object.keys(widgets).some((key) =>
            key.startsWith('GPS'));

          return (
            <DocumentCardNew
              documentId={String(document.id)}
              templateId={document.templateId as DocumentTemplateId}
              key={document.id + document.templateId}
            >
              <DocumentCardHeader isCompleted={isCompleted}>
                <DocumentCardNumber
                  Icon={(
                    <Box sx={{ marginLeft: '-4px' }}>
                      <Icons.QuickCaptureOutlined size={22} />
                    </Box>
                  )}
                >
                  {document.id}
                </DocumentCardNumber>

                <DocumentCardDate
                  dateFinished={document.dateFinished}
                  dateCreated={document.dateCreated}
                />
              </DocumentCardHeader>

              <DocumentCardBody>
                <TextWithFallback
                  variant="singleLine"
                  as="h2"
                  sx={{
                    color: 'secondary.900',
                    fontSize: '2xl',
                  }}
                  fallbackText={intl.formatMessage({
                    id: 'quickCapture.card.subject.fallback',
                  })}
                >
                  {subject}
                </TextWithFallback>

                <TextWithFallback
                  variant="singleLine"
                  as="h3"
                  sx={{
                    fontWeight: 400,
                    color: 'grey.400',
                    fontSize: 'md',
                  }}
                  fallbackText={intl.formatMessage({
                    id: 'quickCapture.card.description.fallback',
                  })}
                >
                  {description}
                </TextWithFallback>

                <DocumentCardDivider />

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {hasImageWidget && (
                      <React.Fragment>
                        <QuickTraceWidgetIconWrapper
                          size={26}
                          color="tertiary2.700"
                        >
                          <Icons.ImageCapture size={13} />
                        </QuickTraceWidgetIconWrapper>

                        <Spacer x={2} />
                      </React.Fragment>
                    )}

                    {hasGpsWidget && (
                      <React.Fragment>
                        <QuickTraceWidgetIconWrapper
                          size={26}
                          color="secondary.300"
                        >
                          <Icons.Gps width={16} color="white.50" />
                        </QuickTraceWidgetIconWrapper>

                        <Spacer x={2} />
                      </React.Fragment>
                    )}

                    {hasFileWidget && (
                      <QuickTraceWidgetIconWrapper
                        size={26}
                        color="tertiary1.200"
                      >
                        <Icons.FileUpload size={15} />
                      </QuickTraceWidgetIconWrapper>
                    )}
                  </Box>

                  <DocumentCardAvatar
                    isCompleted={isCompleted}
                    completedBy={completedBy}
                    assignee={assignee}
                  />
                </Box>
              </DocumentCardBody>
            </DocumentCardNew>
          );
        }

        if (document.templateId === 'relining') {
          return (
            <ReliningDocumentCard
              key={document.id + document.templateId}
              documentId={String(document.id)}
              templateId={document.templateId}
              completedBy={completedBy}
              assignee={assignee}
              dateCreated={new Date(document.dateCreated).toISOString()}
              isCompleted={
                document.status === DocumentWithoutTemplateStatusEnum.Completed
              }
              documentData={document.documentData as ReliningSchemaFinal}
              dateFinished={document.dateFinished}
            />
          );
        }

        const {
          documentNumber,
          documentCompleted,
          templateNameIntl,
          address,
          specifikationAvPlats,
          projectName,
          fastighetsbeteckning,
          projectNumber,
        } = getPressureTestCardProps(document, allUsers);

        return (
          <DocumentCardDefault
            fastighetsbeteckning={fastighetsbeteckning}
            projectName={projectName}
            projectNumber={projectNumber}
            specifikationAvPlats={specifikationAvPlats}
            key={document.id + document.templateId}
            documentId={document.id}
            address={address}
            assignee={assignee}
            documentNumber={documentNumber}
            templateNameIntl={templateNameIntl}
            templateId={document.templateId as DocumentTemplateId}
            documentCompleted={documentCompleted}
            documentDateCreated={document.dateCreated}
            documentDateFinished={document.dateFinished}
            completedBy={completedBy}
          />
        );
      })}
    </React.Fragment>
  );

  const renderNoDocuments = (
    <Flex
      sx={{
        flexDirection: 'column',
        justifyContent: 'center',
        px: 2,
        flexGrow: 1,
        mb: -4,
        textAlign: 'center',
      }}
    >
      <Text
        as="h1"
        sx={{ fontSize: '4xl', color: 'primary.900', fontWeight: 500 }}
        intlId="documents.empty.title"
      />

      <Box sx={{ mt: 6 }}>
        <Image src={NoDocumentsListImage} sx={{ width: '100%' }} />
      </Box>
    </Flex>
  );

  if (isUsersLoading) {
    return null;
  }

  return (
    <Flex
      flexDirection="column"
      sx={{
        my: 4,
        '& > *:not(:last-child)': {
          mb: 8,
        },
      }}
    >
      {documents.length > 0 ? renderDocuments : renderNoDocuments}
    </Flex>
  );
}
