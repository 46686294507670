import * as Utils from 'utils';
import { DocumentStatusEnum } from 'trace-backend-sdk';
import { useDocumentParams } from '../../pages';
import { DocumentsService } from '../../services/documents';
import { useAsyncDebounce } from '../hooks';
import { UsersService } from '../../services/users';

/*
 * Abstracts the common behavior of updating a document's data
 * when the component is not in a completed state and the user
 * has write permissions for that template.
 * Also implements a 300ms debounce.
 * */
export function useUpdateDocumentData() {
  const params = useDocumentParams();

  const { hasCurrentUserPermission } = UsersService.useCurrentUserTemplatePermissions();
  const { updateDocument } = DocumentsService.useUpdateDocument();
  const { document } = DocumentsService.useGetDocument(
    params.documentId,
    params.templateId,
  );

  return useAsyncDebounce((data: Record<string, any>) => {
    /*
     * Important check to avoid data loss.
     * Check the file where "isDocumentStateValid" is defined for more information.
     * */
    if (!Utils.Document.isDocumentStateValid(data)) {
      return;
    }

    if (
      params.templateId
      && hasCurrentUserPermission({ templateId: params.templateId })
      && document?.status !== DocumentStatusEnum.Completed
    ) {
      updateDocument({
        documentData: data,
        documentId: params.documentId,
        templateId: params.templateId,
      });
    }
  }, 300);
}
