import React from 'react';

import { ObjectFieldTemplateProps } from '@rjsf/core';
import { FormattedMessage } from 'lib/intl';
import { Flex } from 'components/layout';
import { Text } from '../../../typography';

export const ObjectFieldTemplate = ({
  DescriptionField,
  description,
  title,
  properties,
  uiSchema,
  idSchema,
  formContext,
}: ObjectFieldTemplateProps) => (
  <React.Fragment>
    {uiSchema['ui:title'] && (
      <Text
        as="h2"
        sx={{
          '& > *': { p: 0 },
          fontWeight: 300,
          fontSize: '3xl',
          mb: 5,
        }}
      >
        <FormattedMessage
          id={title}
          values={{
            small: (parts: string[]) => (
              <Text
                sx={{ fontSize: '0.65em', bottom: '2px', position: 'relative' }}
              >
                {parts}
              </Text>
            ),
          }}
        />
      </Text>
    )}
    {/*TODO: Add translation*/}
    {description && (
      <DescriptionField
        id={`${idSchema.$id}-description`}
        description={description}
      />
    )}
    <Flex
      id={idSchema.$id}
      sx={{
        gap: 7,
        flexDirection: 'column',
      }}
    >
      {properties.map((element: any) =>
        React.cloneElement(element.content, {
          name: `${formContext.name}.${element.name}`,
        }))}
    </Flex>
  </React.Fragment>
);

export default ObjectFieldTemplate;
