import React from 'react';
import * as Utils from 'utils';
import { DocumentTemplateId } from '../../documents';
import { UsersService } from '../index';
import { DEFAULT_TEMPLATES } from '../../../global/constants';

type HasPermissionParams = {
  userId: string;
  templateId: DocumentTemplateId;
};

// TODO: Review this implementation to decide whether
//  it is viable for large amounts of users
function useTemplatePermissions() {
  const { allUsers } = UsersService.useGetUsers();

  const hasPermission = React.useCallback(
    ({ userId, templateId }: HasPermissionParams) => {
      if (DEFAULT_TEMPLATES.includes(templateId)) {
        return true;
      }

      const user = allUsers.find((user) => user.userId === userId);
      const permission = user?.templates?.find(
        (template) => template.templateId === templateId,
      );

      return permission != null;
    },
    [allUsers],
  );

  return React.useMemo(
    () => ({
      hasPermission,
    }),
    [hasPermission],
  );
}

function useCurrentUserTemplatePermissions() {
  const { currentUser } = Utils.Auth.useCurrentAuthenticatedUser();
  const { hasPermission } = useTemplatePermissions();

  const hasCurrentUserPermission = React.useCallback(
    ({ templateId }: Pick<HasPermissionParams, 'templateId'>) => {
      if (!currentUser) return false;

      return hasPermission({
        templateId,
        userId: currentUser.signInUserSession.idToken.payload.sub,
      });
    },
    [currentUser, hasPermission],
  );

  return React.useMemo(
    () => ({
      hasCurrentUserPermission,
    }),
    [hasCurrentUserPermission],
  );
}

export { useTemplatePermissions, useCurrentUserTemplatePermissions };
