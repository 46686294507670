import * as ReactQuery from 'react-query';
import { InferMutationOptions } from 'utils/types';
import { deleteDocumentApi } from 'services/documents/delete-document/api';
import { QUERY_KEY_DOCUMENTS } from 'services/documents/get-documents';

export function useDeleteDocument(
  options?: InferMutationOptions<typeof deleteDocumentApi>,
) {
  const queryClient = ReactQuery.useQueryClient();
  const { mutate: deleteDocument, ...rest } = ReactQuery.useMutation(
    ['deleteDocumentApi'],
    deleteDocumentApi,
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries(QUERY_KEY_DOCUMENTS);

        if (options?.onSuccess) {
          await options.onSuccess(data, variables, context);
        }
      },
    },
  );

  return { deleteDocument, ...rest };
}
