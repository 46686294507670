import React from 'react';
import * as ThemeUI from 'theme-ui';
import { ShouldRender } from 'components/ShouldRender';
import { LocaleKey } from 'translations';
import { IntlCustom } from '../../../../lib';

interface LabelProps extends ThemeUI.LabelProps {
  labelIntlId?: LocaleKey;
  required?: boolean;
  isError?: boolean;
}

export const Label: React.FC<LabelProps> = ({
  htmlFor,
  required,
  labelIntlId,
  isError = false,
  sx,
  ...delegated
}) => {
  const intl = IntlCustom.useIntl();

  return (
    <ShouldRender when={Boolean(labelIntlId)}>
      <ThemeUI.Label
        {...delegated}
        htmlFor={htmlFor}
        sx={{
          cursor: 'pointer',
          ...sx,
          // @ts-expect-error For some reason, it thinks 'sx' does not have 'color'
          color: isError ? 'error' : sx?.color,
        }}
      >
        {labelIntlId ? intl.formatMessage({ id: labelIntlId }) : ''}{' '}
        {required && '*'}
      </ThemeUI.Label>
    </ShouldRender>
  );
};
