import React from 'react';
import * as ThemeUI from 'theme-ui';
import { Flex } from '../../../layout';

export type CheckboxProps = ThemeUI.CheckboxProps & {
  checkedColor?: string;
  notCheckedColor?: string;
};

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      children,
      checkedColor = 'secondary.500',
      notCheckedColor = 'grey.900',
      ...delegated
    },
    ref,
  ) => (
    <ThemeUI.Label
      sx={{
        position: 'relative',

        '& input:checked ~ svg': {
          color: checkedColor,
        },
        '& input:focus ~ svg': {
          backgroundColor: '#B5D5FF',
        },
        '& input:not(:checked) ~ svg': {
          color: notCheckedColor,
        },

        '& input:disabled ~ svg': {
          color: 'grey.400',
        },
      }}
    >
      <Flex sx={{ flexDirection: 'column' }}>
        <Flex sx={{ flexDirection: 'row', alignItems: 'center' }}>
          <ThemeUI.Checkbox {...delegated} ref={ref} />

          {children}
        </Flex>
      </Flex>
    </ThemeUI.Label>
  ),
);
