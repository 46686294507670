import React from 'react';
import { generateContext } from 'utils/context';
import {
  useLocalStorageAggregateValues,
  ValuesAggregator,
} from 'utils/hooks/use-aggregate-values';
import { Button } from 'components/form-elements';
import {
  LOCAL_STORAGE_KEYS,
  useLocalStorage,
} from 'utils/local-storage/use-local-storage';
import { PaymentsRoutes, PaymentsService } from '../index';
import { FloatingLanguageSwitcher } from '../../language/components/FloatingLanguageSwitcher';

type PaymentStep =
  | 'select-modules'
  | 'select-payment-plan'
  | 'payment-information';

const [usePaymentsFormContext, PaymentsFormProvider] = generateContext<{
  pricesAggregator: ValuesAggregator<string>;
  totalPrice: number;
}>();

export function PaymentsPage() {
  const pricesAggregator = useLocalStorageAggregateValues<string>(
    LOCAL_STORAGE_KEYS.PAYMENTS_SELECTED_PRICES,
  );
  const [currentStep, setCurrentStep] = useLocalStorage<PaymentStep>(
    LOCAL_STORAGE_KEYS.PAYMENTS_CURRENT_STEP,
    'select-modules',
  );

  const { templateIdToTemplate, baseTemplate } = PaymentsService.useGetTemplatePrices();
  const baseModulePrice = baseTemplate?.unitAmountDecimal
    ? parseInt(baseTemplate?.unitAmountDecimal, 10)
    : 0;
  const totalPrice = React.useMemo(
    () =>
      pricesAggregator.values.reduce(
        (total, priceId) =>
          Number(templateIdToTemplate[priceId]?.unitAmountDecimal ?? 0) + total,
        baseModulePrice,
      ),
    [templateIdToTemplate, pricesAggregator.values, baseModulePrice],
  );

  const contextValue = React.useMemo(
    () => ({
      pricesAggregator,
      totalPrice: Number(totalPrice),
    }),
    [pricesAggregator, totalPrice],
  );

  return (
    <PaymentsFormProvider value={contextValue}>
      <React.Fragment>
        {(() => {
          switch (currentStep) {
            case 'select-modules':
              return (
                <PaymentsRoutes.SelectModulesPage>
                  <Button
                    onClick={() => setCurrentStep('select-payment-plan')}
                    intlId="payments.selectModule.next"
                  />
                </PaymentsRoutes.SelectModulesPage>
              );
            case 'select-payment-plan':
              return (
                <PaymentsRoutes.SelectPaymentPlanPage
                  setCurrentStep={() => setCurrentStep('payment-information')}
                />
              );
            case 'payment-information':
              return (
                <PaymentsRoutes.PaymentInformationPage
                  onPreviousStep={() => setCurrentStep('select-modules')}
                />
              );
            default:
              return null;
          }
        })()}

        <FloatingLanguageSwitcher />
      </React.Fragment>
    </PaymentsFormProvider>
  );
}

export { usePaymentsFormContext };
