import * as _ from 'lodash-es';
import React from 'react';

export function isDocumentStateValid(
  state: Record<string, any> | null | undefined,
) {
  return state != null && !_.isEmpty(state);
}

/*
 * **IMPORTANT**
 *
 * This hook defines a proxy setter for form state. Its purpose is to
 * disable any attempt at sending an empty value into the form state,
 * thus causing the entire form state to reset to its defaults causing
 * major data loss.
 *
 * You may wonder: "Why isn't the logic for this in the backend?", and
 * there is an explanation in https://linear.app/metergram/issue/TR-763/
 * */
export function useProxiedFormState(defaultValue: Record<string, any>) {
  const [formData, setFormData] = React.useState<Record<string, any>>(defaultValue);

  const safeSetFormData = React.useCallback<
    React.Dispatch<React.SetStateAction<Record<string, any>>>
  >((value) => {
    if (typeof value === 'function') {
      setFormData((oldState) => {
        const newState = value(oldState);

        if (isDocumentStateValid(newState)) {
          return newState;
        }

        return oldState;
      });
    }

    setFormData((oldState) => (isDocumentStateValid(value) ? value : oldState));
  }, []);

  return React.useMemo(
    () => [formData, safeSetFormData] as const,
    [formData, safeSetFormData],
  );
}
