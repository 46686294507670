import { BillingHistoryTable } from '../components/Billing/BillingHistoryTable';
import { Box, Container } from '../../../components/layout';
import { Text } from '../../../components/typography';
import { Link } from '../../../components/links';
import { Routing } from '../../../global/routing';
import { PaymentsService } from '../index';

export function PaymentHistoryPage() {
  const getBillingHistory = PaymentsService.useGetBillingHistory();
  const billingHistory = getBillingHistory.data?.billingHistory
    .slice(0, -1)
    .filter((entry) => entry.invoicePdfUrl != null)
    .map((entry) => ({
      invoiceUrl: entry.invoicePdfUrl,
      billedTotalCents: entry.amountCents,
      billedDate: entry.createdAt,
      currency: entry.currency,
      paidAt: entry.paidAt,
    })) ?? [];

  return (
    <Container variant="spacer">
      <BillingHistoryTable rows={billingHistory} />

      <Box
        sx={{
          color: 'darkgrey.800',
          fontWeight: 300,
          fontSize: 'md',
          mt: 6,
          px: 5,
        }}
      >
        <Text intlId="payments.billingHistory.managePaymentPrompt" />{' '}
        <Link variant="default" to={Routing.PAYMENTS.ManagePaymentInfo.path}>
          <Text
            sx={{ textTransform: 'capitalize' }}
            intlId="payments.billingHistory.managePayment"
          />
        </Link>
      </Box>
    </Container>
  );
}
