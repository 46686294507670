import React from 'react';
import { Box, Flex } from '../layout';
import { AnimatedCheckmark } from '../icons';
import { Text } from '../typography';

type ConfirmationPageSuccessProps = {
  description?: string;
};

export function ConfirmationPageSuccess({
  description,
}: ConfirmationPageSuccessProps) {
  return (
    <Flex
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        p: 10,
        backgroundColor: 'tertiary1.300',
      }}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box sx={{ width: 128 }}>
          <AnimatedCheckmark />
        </Box>

        <Flex
          sx={{
            flexDirection: 'column',
            textAlign: 'center',
            mt: 4,
            color: 'white.50',
            animation: 'fade-in-animation 800ms linear',
            '@keyframes fade-in-animation': {
              '0%': {
                opacity: 0,
              },

              '50%': {
                opacity: 0,
              },

              '100%': {
                opacity: 1,
              },
            },
          }}
        >
          <Text
            sx={{ fontWeight: 500, fontSize: '5xl' }}
            intlId="success.confirmation"
          />

          {description && <Text sx={{ fontSize: 'xl' }}>{description}</Text>}
        </Flex>
      </Flex>
    </Flex>
  );
}
