import { createTypedVariant } from '../index';

export type LinkVariants = keyof typeof links;

export const links = createTypedVariant({
  nav: {
    textDecoration: 'none',
    color: 'currentColor',
  },
  'action-link': {
    color: 'darkgrey.800',
    fontSize: '14px',
    textDecoration: 'none',
  },
  default: {
    color: 'primary.900',
    textDecoration: 'underline',
  },
  basic: {
    textDecoration: 'none',
  },
  mainMenuItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
    color: 'currentColor',

    '& > *:first-child': {
      mb: 1,
    },

    '&.active': {
      color: 'secondary.500',
    },
  },
});
