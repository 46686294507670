import React from 'react';
import { StyleObject } from '../../../theming';
import { DoubleBorderWrapper } from '../../../components/DoubleBorderWrapper/DoubleBorderWrapper';

export function ReliningFieldWrapper({
  children,
  sx,
}: {
  children: React.ReactNode;
  sx?: StyleObject;
}) {
  return (
    <DoubleBorderWrapper sx={{ ...sx, pb: 6 }}>{children}</DoubleBorderWrapper>
  );
}
