import * as React from 'react';
import Select from 'react-select';
import { Flex } from 'components/layout';
import { Text } from 'components/typography';
import { StyleObject } from 'theming';
import { LocaleKey } from 'translations';

type ValueType = {
  label: string;
  option: number;
};

type CustomSelectProps = {
  labelIntlId?: LocaleKey;
  prependIcon?: React.ReactNode;
  appendIcon?: React.ReactNode;
  onChange?: (value: ValueType) => any;
  options?: ValueType[];
  wrapperSx?: StyleObject;
  value: ValueType;
  isDisabled: boolean;
};

const SingleValue = (customProps: any) => (props: any) => (
  <React.Fragment>
    {customProps.prependIcon}

    <Text
      {...props}
      as="p"
      sx={{
        fontSize: '40px',
        margin: '0 auto',
        color: 'currentcolor',
        lineHeight: 1.15,
      }}
    />

    {customProps.appendIcon}
  </React.Fragment>
);

function ValueContainer(props: any) {
  return (
    <Flex
      {...props}
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'stretch',

        '& > *:last-child': {
          position: 'absolute',
        },
      }}
    >
      {props.children}
    </Flex>
  );
}

export const CustomSelect = ({
  labelIntlId,
  appendIcon,
  prependIcon,
  onChange,
  options = [],
  value,
  wrapperSx,
  isDisabled,
}: CustomSelectProps) => (
  <Flex
    sx={{
      flexDirection: 'column',
      alignItems: 'center',
      ...wrapperSx,
    }}
  >
    <Select
      isDisabled={isDisabled}
      value={value}
      components={{
        ValueContainer,
        SingleValue: SingleValue({
          appendIcon,
          prependIcon,
        }),
      }}
      styles={{
        indicatorsContainer: () => ({
          display: 'none',
        }),
        control: (provided) => ({
          ...provided,
          position: 'relative',
          border: 'none',
          width: '100%',
          backgroundColor: 'transparent',
        }),
        container: (provided) => ({
          ...provided,
          display: 'flex',
          justifyContent: 'center',
          alignSelf: 'stretch',
        }),
        option: (provided) => ({
          ...provided,
          color: '#333',
          backgroundColor: 'white',
        }),
        menu: (provided) => ({
          ...provided,
          height: '240px',
        }),
      }}
      options={options}
      isSearchable={false}
      onChange={(value) => {
        if (onChange && value) {
          onChange(value);
        }
      }}
    />

    {labelIntlId && (
      <Text
        as="p"
        sx={{ mt: 2, fontSize: 'md', color: 'currentColor' }}
        intlId={labelIntlId}
      />
    )}
  </Flex>
);
