import * as ReactQuery from 'react-query';
import { InferMutationOptions } from 'utils/types';
import { ErrorType } from 'utils/http';
import { signUpAPI } from './api';

export function useSignUp(options?: InferMutationOptions<typeof signUpAPI>) {
  const {
    mutate: signUp,
    error,
    ...rest
  } = ReactQuery.useMutation('signUp', signUpAPI, options);

  return {
    signUp,
    error: error as ErrorType,
    ...rest,
  };
}
