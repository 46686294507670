import * as ReactRouter from 'react-router-dom';
import React from 'react';
import { useSearchQuery } from 'utils/url/use-search-query';
import { getSearchQuery } from '../functions/get-search-query';

/**
 * Use this redirect when you want to redirect to a "referrer"
 * if present, or to the requested "path" if not present.
 *
 * The referrer lives in the state of React Router
 * or in the URL search params.
 * */
export function useReferrerRedirect() {
  const history = ReactRouter.useHistory();
  const searchQuery = useSearchQuery();

  const onReferrerRedirect = React.useCallback(
    (path?: string, method: 'push' | 'replace' = 'push') => {
      const { referrer } = (history.location.state ?? {}) as Record<
        string,
        any
      >;
      return history[method]({
        pathname: referrer ?? searchQuery.referrer ?? path,
        search: getSearchQuery((oldParams) => ({ ...oldParams, referrer: '' })),
      });
    },
    [history, searchQuery.referrer],
  );

  return {
    onReferrerRedirect,
  };
}
