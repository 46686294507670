import { Text, TextProps } from '../../../../components/typography';

type BillingPageTitleProps = TextProps;

export function BillingPageTitle({ sx, ...props }: BillingPageTitleProps) {
  return (
    <Text
      as="h2" sx={{ mb: 4, fontSize: '26px', ...sx }}
      {...props}
    >
      {props.children}
    </Text>
  );
}
