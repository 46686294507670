import React from 'react';
import { isBefore } from 'date-fns';
import { useIntl } from 'lib/intl';
import { WorkspaceStatusEnum } from 'trace-backend-sdk';
import { Button } from '../../../components/form-elements';
import { Text } from '../../../components/typography';
import { useModal } from '../../../store/modals';
import { ConfirmationDialog } from '../../../components/confirmation-dialog/ConfirmationDialog';
import { PaymentsService } from '../index';
import { PageLoadingIndicator } from '../../../components/PageLoadingIndicator/PageLoadingIndicator';
import { WorkspaceService } from '../../../services/workspace';
import { isQueryLoading } from '../../../utils/functions/is-query-loading';
import { useLocaleDateFormat } from '../../../utils/hooks/use-locale-date-format';
import { Link } from '../../../components/links';
import { Routing } from '../../../global/routing';

type SubscriptionStatus = 'cancelled' | 'will-cancel' | 'active';
type GetSubscriptionStatus = {
  workspaceStatus: WorkspaceStatusEnum;
  cancelAtPeriodEnd: boolean;
};

function getSubscriptionStatus({
  workspaceStatus,
  cancelAtPeriodEnd,
}: GetSubscriptionStatus): SubscriptionStatus {
  if (workspaceStatus === WorkspaceStatusEnum.Cancelled) {
    return 'cancelled';
  }

  if (cancelAtPeriodEnd) {
    return 'will-cancel';
  }

  return 'active';
}

export function ManageSubscription() {
  const cancelSubscription = PaymentsService.useCancelSubscription();
  const reactivateSubscription = PaymentsService.useReactivateSubscription();
  const getSubscription = PaymentsService.useGetSubscription();
  const getWorkspace = WorkspaceService.useGetWorkspace();

  const intl = useIntl();
  const { openModal, closeModal } = useModal();
  const { localeDateFormat } = useLocaleDateFormat();

  function onModifySubscription() {
    openModal({
      modalType: 'componentModal',
      modalProps: {
        children: (
          <ConfirmationDialog
            onProceed={() => {
              if (!getWorkspace.workspaceData || !getSubscription.data) {
                return;
              }

              const subscriptionStatus = getSubscriptionStatus({
                workspaceStatus: getWorkspace.workspaceData.status,
                cancelAtPeriodEnd: getSubscription.data.cancelAtPeriodEnd,
              });

              if (
                subscriptionStatus === 'cancelled'
                || subscriptionStatus === 'will-cancel'
              ) {
                reactivateSubscription.mutate(undefined);
              } else {
                cancelSubscription.mutate(undefined);
              }
            }}
          >
            {subscriptionStatus === 'cancelled' ? (
              <React.Fragment>
                <Text
                  variant="body2"
                  as="p"
                  sx={{ textAlign: 'center' }}
                  intlId="manageSubscription.reactivate.warn1"
                />

                <Text
                  variant="body2"
                  as="p"
                  sx={{ textAlign: 'center', mt: 2 }}
                >
                  <Link
                    onClick={() => closeModal()}
                    variant="default"
                    to={Routing.PERMISSIONS.Default.path}
                  >
                    <Text intlId="manageSubscription.reactivate.warn2" />
                  </Link>
                </Text>
              </React.Fragment>
            ) : (
              <Text
                as="p"
                sx={{ textAlign: 'center' }}
                intlId="generic.confirm.areYouSure"
              />
            )}
          </ConfirmationDialog>
        ),
      },
    });
  }

  if (
    isQueryLoading(getWorkspace.isLoading, getWorkspace.workspaceData)
    || isQueryLoading(getSubscription.isLoading, getSubscription.data)
  ) {
    return <PageLoadingIndicator />;
  }

  const subscriptionStatus = getSubscriptionStatus({
    workspaceStatus: getWorkspace.workspaceData.status,
    cancelAtPeriodEnd: getSubscription.data.cancelAtPeriodEnd,
  });

  return (
    <React.Fragment>
      {(() => {
        if (
          subscriptionStatus === 'active'
          && getSubscription.data?.trialEnd
          && isBefore(new Date(), new Date(getSubscription.data.trialEnd))
        ) {
          return (
            <Text as="p">
              {intl.formatMessage(
                {
                  id: 'payments.manageSubscription.freeTrial',
                },
                {
                  trialEnd: localeDateFormat(getSubscription.data.trialEnd),
                },
              )}
            </Text>
          );
        }

        switch (subscriptionStatus) {
          case 'active': {
            return (
              <Text as="p">
                {intl.formatMessage(
                  {
                    id: 'payments.manageSubscription.currentlyActive',
                  },
                  {
                    renewalDate: localeDateFormat(
                      getSubscription.data?.currentPeriodEnd,
                    ),
                  },
                )}
              </Text>
            );
          }
          case 'cancelled': {
            return (
              <Text as="p">
                {intl.formatMessage({
                  id: 'payments.manageSubscription.currentlyInactive',
                })}
              </Text>
            );
          }
          case 'will-cancel': {
            return (
              <Text as="p">
                {intl.formatMessage(
                  {
                    id: 'payments.manageSubscription.willCancel',
                  },
                  {
                    cancelDate: localeDateFormat(
                      getSubscription.data?.currentPeriodEnd,
                    ),
                  },
                )}
              </Text>
            );
          }
          default:
            return null;
        }
      })()}

      <Text as="p" sx={{ mt: 2 }}>
        <Button
          onClick={() => onModifySubscription()}
          variant="text"
          sx={{ color: 'secondary.700', display: 'inline', p: 0 }}
          intlId="payments.manageSubscription.clickHere"
        />{' '}
        {intl.formatMessage({
          id: `payments.manageSubscription.action.${subscriptionStatus}`,
        })}
      </Text>

      {(cancelSubscription.isLoading || reactivateSubscription.isLoading) && (
        <PageLoadingIndicator backgroundOpacity={1} />
      )}
    </React.Fragment>
  );
}
