import axios from 'axios';
import imageCompression from 'browser-image-compression';
import * as ReactQuery from 'react-query';
import { ReliningImageNameEnum } from '@trace/types';
import { DocumentRequestBase } from '../../../services/documents';
import { identify, makeRequest, Service } from '../../../utils/http';
import { InferMutationOptions } from '../../../utils/types';

type UploadData = DocumentRequestBase & {
  fileType: string;
  image: Blob;
  name: ReliningImageNameEnum;
};

async function uploadReliningImageApi({
  image,
  templateId,
  documentId,
  name,
  fileType,
}: UploadData) {
  const { signedUrl, uploadUrl } = await makeRequest(
    Service.getReliningUploadImageUrl(
      documentId,
      templateId,
      name,
      await identify(),
    ),
  );

  await axios.put(
    signedUrl,
    await imageCompression(new File([image], name, { type: fileType }), {
      fileType,
    }),
    {
      headers: {
        'Content-Type': fileType,
      },
    },
  );

  const { timestamp } = await makeRequest(
    Service.confirmReliningImageUpload(
      documentId,
      templateId,
      {
        imageUrl: uploadUrl,
        imageName: name,
      },
      await identify(),
    ),
  );

  return {
    uploadUrl,
    timestamp,
  };
}

function useUploadReliningImage(
  options?: InferMutationOptions<typeof uploadReliningImageApi>,
) {
  const { mutate: uploadReliningImage, ...rest } = ReactQuery.useMutation(
    'uploadReliningImage',
    uploadReliningImageApi,
    options,
  );

  return {
    uploadReliningImage,
    ...rest,
  };
}
export { useUploadReliningImage };
