import React from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { useGetLatest } from 'utils/hooks/use-get-latest';

export type OnResizeFn = (contentRect: DOMRectReadOnly) => void;

export function useResizeObserver(
  element: Element | null,
  onResize: OnResizeFn,
) {
  const resizeObserverRef = React.useRef<ResizeObserver>();
  const getOnResize = useGetLatest(onResize);

  React.useLayoutEffect(() => {
    if (!element) {
      return () => {};
    }

    resizeObserverRef.current = new ResizeObserver((entries) => {
      const drawer = entries[0];
      getOnResize()(drawer.contentRect);
    });

    resizeObserverRef.current.observe(element);

    return () => {
      resizeObserverRef.current?.unobserve(element);
    };
  }, [element, getOnResize]);
}
