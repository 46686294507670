import React from 'react';
import { createStoreProvider } from 'utils/context';
import { ModalState, ModalTypes } from './types';

const initialState = {
  modalType: null,
  modalProps: undefined,
};

function useModalState() {
  const [modalState, setModalState] = React.useState<ModalState<ModalTypes>>(initialState);

  const openModal = React.useCallback(
    <T extends ModalTypes>(modalOptions: ModalState<T>) =>
      setModalState(modalOptions),
    [],
  );

  const closeModal = React.useCallback(() => setModalState(initialState), []);

  return {
    modalType: modalState.modalType,
    modalProps: modalState.modalProps,
    openModal,
    closeModal,
  };
}

export const [useModal, ModalProvider] = createStoreProvider(useModalState);
