import * as ReactQuery from 'react-query';
import { ReliningTimerStartRequest } from 'trace-backend-sdk';
import { identify, makeRequest, Service } from '../../../utils/http';
import { DocumentRequestBase } from '../../../services/documents';
import { InferMutationOptions } from '../../../utils/types';

type Data = DocumentRequestBase & ReliningTimerStartRequest;
async function timerStartApi({ timestamp, templateId, documentId }: Data) {
  return makeRequest(
    Service.reliningTimerStart(
      parseInt(documentId, 10),
      templateId,
      { timestamp },
      await identify(),
    ),
  );
}

function useTimerStartApi(
  options?: InferMutationOptions<typeof timerStartApi>,
) {
  const { mutate: timerStart, ...rest } = ReactQuery.useMutation(
    'timerStart',
    timerStartApi,
    options,
  );

  return {
    timerStart,
    ...rest,
  };
}

export { useTimerStartApi };
