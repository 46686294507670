import { getAccessToken, getIdToken } from 'utils/auth/token';

export async function identify() {
  const [idToken, accessToken] = await Promise.all([
    getIdToken(),
    getAccessToken(),
  ]);

  return {
    headers: {
      authorization: idToken,
      'X-ACCESS-TOKEN': accessToken,
    },
  };
}
