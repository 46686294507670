/** @jsxImportSource theme-ui */
import * as React from 'react';
import * as ReactjsPopup from 'reactjs-popup';
import * as ReactjsPopupTypes from 'reactjs-popup/dist/types';
import { Box } from 'components/layout';
import { GenericFn } from 'utils/types';

export interface PopupProps extends ReactjsPopupTypes.PopupProps {
  closeOnOutsideClick?: boolean;
  closeOnContentClick?: boolean;
  width?: '100%' | 'auto';
  height?: '100%' | 'auto';
  backDropStyle?: 'blur' | 'shadow';
}

/*
 * Wrapper around "reactjs-popup" providing some base
 * styles and the functionality of closing the popup
 * whenever its inside content is clicked.
 * */
export const Popup: React.FC<PopupProps> = ({
  modal,
  closeOnOutsideClick,
  width = 'auto',
  height = 'auto',
  backDropStyle = 'blur',
  children,
  contentStyle,
  closeOnContentClick = false,
  ...props
}) => {
  React.useEffect(
    () => () => {
      document.body.style.overflow = 'auto';
    },
    [],
  );

  return (
    <ReactjsPopup.Popup
      sx={{
        '&-overlay': modal
          ? {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            overflowY: 'auto',
            outline: 'none',
            backdropFilter: backDropStyle === 'blur' ? 'blur(10px)' : 'none',
          }
          : { outline: 'none' },
        '&-content': modal
          ? {
            display: 'flex',
            justifyContent: 'center',
            width,
            height,
            ...contentStyle,
          }
          : {},
      }}
      lockScroll
      closeOnDocumentClick={closeOnOutsideClick}
      closeOnEscape={closeOnOutsideClick}
      {...props}
    >
      {(closePopup: GenericFn) => (
        <Box
          sx={{ width: '100%', height: '100%' }}
          onClick={() => {
            if (closeOnContentClick) {
              closePopup();
            }
          }}
        >
          {children}
        </Box>
      )}
    </ReactjsPopup.Popup>
  );
};
