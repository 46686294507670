import React from 'react';
import * as ThemeUI from 'theme-ui';
import * as ReactIntl from 'lib/intl';

import { IntlProps, LocaleKey } from 'translations';
import { TextVariants } from 'theming/variants/text';
import { StyleObject } from 'theming';

export interface TextProps
  extends ThemeUI.TextProps,
    Omit<IntlProps, 'intlId'> {
  fontWeight?: string | number;
  fontSize?: string | number;
  textAlign?: 'left' | 'center' | 'right';
  textTransform?: 'uppercase' | 'lowercase' | 'capitalize';
  variant?: TextVariants;
  scrollable?: boolean;
  intlId?: LocaleKey;
}

export const Text: React.FC<TextProps> = ({
  as = 'span',
  fontWeight,
  fontSize,
  textAlign,
  textTransform,
  sx,
  intlId,
  intlValues,
  intlDefaultMessage,
  children,
  variant,
  scrollable = false,
  ...props
}) => {
  const scrollableStyles: StyleObject = {
    overflowX: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none', // Hide scrollbar for Chrome, Safari and Opera
    },
    msOverflowStyle: 'none', // ... IE and Edge
    scrollbarWidth: 'none', // ... Firefox
    '-webkit-overflow-scrolling': 'touch', // ... Touch devices including iOS
  };

  return (
    <ThemeUI.Text
      as={as}
      sx={{
        fontWeight,
        fontSize,
        textAlign,
        textTransform,
        color: !variant && 'currentColor',
        ...(scrollable && scrollableStyles),
        ...sx,
      }}
      {...props}
      variant={variant}
    >
      {intlId ? (
        <ReactIntl.FormattedMessage
          id={intlId}
          defaultMessage={intlDefaultMessage}
          values={{
            bold: (...parts: any[]) => <strong>{parts}</strong>,
            ...intlValues,
          }}
        />
      ) : (
        children
      )}
    </ThemeUI.Text>
  );
};

export function TextWithFallback(
  props: TextProps & {
    fallbackText: string;
  },
) {
  if (props.children) {
    return <Text {...props}>{props.children}</Text>;
  }

  return (
    <Text
      {...props}
      sx={{
        ...props.sx,
        color: '#E6E6E6 !important',
      }}
    >
      {props.fallbackText}
    </Text>
  );
}
