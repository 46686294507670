import * as ThemeUI from 'theme-ui';
import * as Icons from 'components/icons';
import { Popup } from 'components/Popup/Popup';
import React from 'react';
import { Flex } from 'components/layout';
import { Button, ButtonProps } from 'components/form-elements';
import { StyleObject } from 'theming';
import { FocusThief } from 'components/FocusThief/FocusThief';

type Props = {
  children: React.ReactNode | React.ReactNodeArray;
  icon?: React.ReactNode | React.ReactNodeArray;
  sx?: StyleObject;
};

export const Submenu = ({ children, icon, sx }: Props) => (
  <Popup
    closeOnContentClick
    position={['bottom right', 'top right']}
    keepTooltipInside
    trigger={(
      <ThemeUI.Box
        sx={{
          display: 'inline-flex',
        }}
      >
        {icon}
        {!icon && <Icons.ThreeDots width={48} />}
      </ThemeUI.Box>
    )}
    arrow={false}
  >
    <FocusThief />

    <Flex
      flexDirection="column"
      alignItems="flex-start"
      sx={{
        borderRadius: '7px',
        backgroundColor: 'grey.100',
        color: 'grey.400',
        fontWeight: '200',
        fontSize: 'sm',
        position: 'relative',
        boxShadow: '4dp',

        '& > *:not(:last-child)': {
          borderBottom: '1px solid',
          borderColor: 'grey.300',
          borderBottomLeftRadius: '0px',
          borderBottomRightRadius: '0px',
        },

        ...sx,
      }}
    >
      {children}
    </Flex>
  </Popup>
);

type SubmenuItemProps = ButtonProps;

Submenu.Item = function SubmenuItem({
  sx,
  children,
  ...props
}: SubmenuItemProps) {
  return (
    <Button
      {...props}
      sx={{
        p: 3,
        width: '100%',
        color: 'secondary.400',
        fontWeight: 400,
        fontSize: 'md',
        justifyContent: 'flex-start',
        ...sx,
      }}
      variant="text"
    >
      {children}
    </Button>
  );
};
