import React from 'react';
import * as Icons from 'components/icons';
import { Container, Flex } from 'components/layout';
import { PressureTestForm } from 'components/PressureTestForm/PressureTestForm';
import { DocumentsService } from 'services/documents';
import { DocumentStatusEnum } from 'trace-backend-sdk';
import * as ReactIntl from 'lib/intl';
import { useLocaleDateFormat } from 'utils/hooks/use-locale-date-format';
import { isQueryLoading } from 'utils/functions/is-query-loading';
import { useDocumentParams } from 'pages/Document/Document';
import { InlineLoadingIndicator } from 'components/LoadingIndicator';
import {
  DocumentTitleContent,
  DocumentTitleDate,
  DocumentTitleIconWrapper,
  DocumentTitleTemplateName,
  DocumentTitleWrapper,
} from 'components/DocumentTitleWrapper/DocumentTitleWrapper';
import { DocumentCertificateAccess } from '../../features/document/components/DocumentCertificateAccess/DocumentCertificateAccess';
import { Spacer } from '../../components/Spacer/Spacer';
import { DeleteDocument } from '../../features/document/components/DeleteDocument/DeleteDocument';

export const Pressure: React.FC = () => {
  const params = useDocumentParams();
  const { localeDateFormat } = useLocaleDateFormat();

  const {
    document: pressureDocument,
    isLoading: isPressureTestLoading,
    hasDocumentWritePermission,
  } = DocumentsService.useGetDocument(params.documentId, params.templateId);
  const isDocumentCompleted = pressureDocument?.status === DocumentStatusEnum.Completed;

  if (isQueryLoading(isPressureTestLoading, pressureDocument)) {
    return <InlineLoadingIndicator />;
  }

  return (
    <Container variant="spacer">
      <DocumentTitleWrapper>
        <DocumentTitleIconWrapper>
          <Icons.PressureTestSplash
            size={43}
            outlineColor="secondary.500"
            splashColor="secondary.050"
          />
        </DocumentTitleIconWrapper>

        <DocumentTitleContent>
          <DocumentTitleDate isDocumentCompleted={isDocumentCompleted}>
            {localeDateFormat(new Date(pressureDocument.dateCreated))}
          </DocumentTitleDate>

          <DocumentTitleTemplateName>
            <ReactIntl.FormattedMessage id="menu.main.pressureTest" />
          </DocumentTitleTemplateName>
        </DocumentTitleContent>

        <Flex
          sx={{
            position: 'relative',
            alignItems: 'center',
            justifyContent: 'flex-end',
            flex: 1,
            bottom: -1,
          }}
        >
          <DeleteDocument
            templateId={params.templateId}
            documentId={params.documentId}
          />
        </Flex>
      </DocumentTitleWrapper>

      {isDocumentCompleted && (
        <React.Fragment>
          <DocumentCertificateAccess />
          <Spacer y={8} />
        </React.Fragment>
      )}

      <PressureTestForm
        hasDocumentWritePermission={hasDocumentWritePermission}
        isCompleted={isDocumentCompleted}
        schema={pressureDocument.template.templateSchemaFinal}
      />
    </Container>
  );
};
