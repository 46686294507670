import * as ReactQuery from 'react-query';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { identify, makeRequest, Service } from '../../../utils/http';
import { UnwrapPromise } from '../../../utils/types';

type Data = Parameters<typeof Service.updateSetupIntent>[0];
async function updateSetupIntentApi(data: Data) {
  return makeRequest(Service.updateSetupIntent(data, await identify()));
}

function useUpdateSetupIntent(options?: {
  onSuccess: (
    data: UnwrapPromise<ReturnType<typeof updateSetupIntentApi>>
  ) => void;
}) {
  const stripe = useStripe();
  const stripeElements = useElements();

  return ReactQuery.useMutation(
    'updateSetupIntent',
    async (
      data: Pick<Data, 'setupIntentId'> & {
        cardholderName: string;
      },
    ) => {
      const cardElement = stripeElements?.getElement(CardElement);

      if (cardElement) {
        const response = await stripe?.createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: {
            name: data.cardholderName,
          },
        });

        if (!response || !response.paymentMethod) {
          throw new Error('No payment method configured.');
        }

        return updateSetupIntentApi({
          setupIntentId: data.setupIntentId,
          paymentMethodId: response.paymentMethod.id,
        });
      }

      throw new Error('No payment method configured.');
    },
    options,
  );
}

export { useUpdateSetupIntent };
