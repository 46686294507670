import React from 'react';
import * as ThemeUI from 'theme-ui';

export type BoxProps = ThemeUI.BoxProps;

export const Box = React.forwardRef<HTMLDivElement, BoxProps>(
  ({ sx, as, children, ...props }, ref) => (
    <ThemeUI.Box
      ref={ref} as={as}
      sx={sx} {...props}
    >
      {children}
    </ThemeUI.Box>
  ),
);
