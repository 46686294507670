import { identify, makeRequest, Service } from 'utils/http';
import { DocumentStatusEnum } from 'trace-backend-sdk';
import { Paginated } from 'utils/types';

export type GetDocumentsRequestData = Paginated & {
  status?: DocumentStatusEnum;
  /** Return only the documents assigned to the current user */
  userOnly?: 'true' | 'false';
  sortBy?: Parameters<typeof Service.getAllDocuments>[5];
  order?: Parameters<typeof Service.getAllDocuments>[6];
  query?: string;
};

/** Describes the business logic for filtering documents based on status. */
const STATUS_FILTERING: Record<DocumentStatusEnum, DocumentStatusEnum[]> = {
  [DocumentStatusEnum.Assigned]: [DocumentStatusEnum.Assigned],
  [DocumentStatusEnum.Completed]: [DocumentStatusEnum.Completed],
  [DocumentStatusEnum.Draft]: [
    DocumentStatusEnum.Draft,
    DocumentStatusEnum.Assigned,
  ],
};

export async function getDocumentsAPI({
  userOnly = 'false',
  status,
  skip,
  limit,
  order = 'DESC',
  sortBy = 'dateCreated',
  query,
}: GetDocumentsRequestData) {
  const FORCE_UPPERCASE_STATUS = status?.toUpperCase() as
    | DocumentStatusEnum
    | undefined;

  if (query) {
    return getDocumentsSearchApi({
      query,
      limit,
      skip,
    });
  }

  return makeRequest(
    Service.getAllDocuments(
      limit,
      query,
      skip,
      userOnly,
      FORCE_UPPERCASE_STATUS && STATUS_FILTERING[FORCE_UPPERCASE_STATUS],
      sortBy,
      order,
      await identify(),
    ),
  );
}

async function getDocumentsSearchApi({
  query,
  limit,
  skip,
}: Pick<GetDocumentsRequestData, 'limit' | 'query' | 'skip'>) {
  return makeRequest(
    Service.getAllDocuments(
      limit,
      query,
      skip,
      'false',
      undefined,
      'id',
      'ASC',
      await identify(),
    ),
  );
}
