import * as Lsm from 'little-state-machine';
import { omit } from 'lodash/fp';
import React from 'react';

export const DEFAULT_LSM_STATE = {
  isStateMachineActive: false,
  isSideBarOpen: false,
  isAuthenticated: false,
  currentUserId: null,
  isWorkspaceRedirectInProgress: false,
};

export function useResetLsmStore() {
  const { actions } = Lsm.useStateMachine({
    reset: (state) => ({
      ...state,
      // Don't reset these user ID, because they are used for
      // refreshing session, and resetting it will lead to
      // mismatch between LSM and Amplify local storage.
      ...omit(['currentUserId'], DEFAULT_LSM_STATE),
    }),
  });

  React.useLayoutEffect(() => {
    actions.reset(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
