import * as ReactQuery from 'react-query';
import { ErrorType } from 'utils/http';
import { DocumentStatusEnum } from 'trace-backend-sdk';
import { InferQueryOptions } from '../../../utils/types';
import { getDocumentsAPI, GetDocumentsRequestData } from './api';

export const QUERY_KEY_DOCUMENTS = ['documents'];

// TODO: Don't fetch all documents in this hook
export function useGetDocuments(
  options?: InferQueryOptions<typeof getDocumentsAPI>,
) {
  const { data, error, ...rest } = ReactQuery.useQuery(
    QUERY_KEY_DOCUMENTS,
    () =>
      getDocumentsAPI({
        skip: 0,
        limit: 1,
      }),
    options,
  );

  return {
    documents: data?.documents ?? [],
    totalAssignedDocuments: data?.totalAssigned ?? 0,
    totalCompletedDocuments: data?.totalCompleted ?? 0,
    totalDraftsDocuments: data?.totalDrafts ?? 0,

    error: error as ErrorType,
    ...rest,
  };
}

const INFINITE_QUERY_PAGE_LIMIT = 25;

export function usePaginatedDocuments(
  status?: DocumentStatusEnum,
  userOnly?: boolean,
  sortBy?: GetDocumentsRequestData['sortBy'],
  order?: GetDocumentsRequestData['order'],
  query?: string,
) {
  function fetchDocuments({ pageParam = 0 }) {
    return getDocumentsAPI({
      skip: pageParam,
      limit: INFINITE_QUERY_PAGE_LIMIT,
      status,
      userOnly: userOnly ? 'true' : 'false',
      sortBy,
      order,
      query,
    });
  }

  return ReactQuery.useInfiniteQuery(
    ['documentsInfinite', status, userOnly, sortBy, order, query],
    fetchDocuments,
    {
      getNextPageParam({ total: totalDocuments }, allPages) {
        const nextCursor = allPages.length * INFINITE_QUERY_PAGE_LIMIT;

        return totalDocuments > nextCursor ? nextCursor : undefined;
      },
    },
  );
}
