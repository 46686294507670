/* eslint-disable max-len */
import { FormProps, withTheme, ISubmitEvent } from '@rjsf/core';
import { set as fpSet } from 'lodash/fp';
import { UiSchemaCustom } from 'components/form-elements/JsonForms/types';
import { Theme } from 'components/form-elements/JsonForms/theme';
import * as React from 'react';
import { reduceDependentsToBooleanMap } from 'components/form-elements/JsonForms/utils';
import { Button } from 'components/form-elements/buttons';
import { Box } from 'components/layout';
import { ERROR_LIST_CONTAINER_ID } from 'components/form-elements/JsonForms/components/ErrorList';
import { getDependentProcessedFormData } from 'components/form-elements/JsonForms/utils/get-dependent-processed-form-data';
import { useValidateDependents } from 'components/form-elements/JsonForms/utils/use-validate-dependents';
import { scrollIntoView } from 'seamless-scroll-polyfill/lib/scrollIntoView';
import { DependentsContextProvider } from '../../utils/depdenents-context';

const _JsonForm = withTheme(Theme);
/**
 * Be sure that when passing `formContext`, it is always
 * wrapped inside a `useMemo`.
 * */
type JsonFormProps = Omit<FormProps<any>, 'uiSchema' | 'onSubmit'> & {
  uiSchema?: UiSchemaCustom;
  name: string;
  onSubmit?: (e: ISubmitEvent<any>) => any;
};

export function JsonForm(props: JsonFormProps) {
  useValidateDependents(props.schema, props.uiSchema);

  const formContext = React.useMemo(
    () => ({
      name: props.name,
      ...props.formContext,
    }),
    [props.formContext, props.name],
  );

  const dependentsContextValue = React.useMemo(
    () => ({
      dependentsMap: props.uiSchema?.__DEPENDENTS__
        ? reduceDependentsToBooleanMap(
          props.uiSchema.__DEPENDENTS__,
          props.formData,
        )
        : {},
    }),
    [props.formData, props.uiSchema?.__DEPENDENTS__],
  );

  /**
   * We use this to ensure that we "live validate" only once the user
   * has attempted to submit the form once, and that action has
   * resulted in an error.
   * */
  const [hasErrored, setHasErrored] = React.useState(false);

  return (
    <DependentsContextProvider value={dependentsContextValue}>
      {/*eslint-disable-next-line react/jsx-pascal-case*/}
      <_JsonForm
        liveValidate={hasErrored}
        noHtml5Validate
        {...props}
        formContext={formContext}
        onChange={(formState) => {
          if (!props.onChange) return;
          props.onChange(fpSet('formData', formState.formData, formState));
        }}
        onError={(error) => {
          const element = document.getElementById('ERROR_MESSAGE');
          if (element) {
            scrollIntoView(element, { block: 'center', behavior: 'smooth' });
          }
          setHasErrored(true);
          if (props.onError) {
            props.onError(error);
          }
        }}
        onSubmit={(formState) => {
          if (!props.onSubmit) return;

          const newFormState = fpSet(
            'formData',
            getDependentProcessedFormData(
              formState.formData,
              props.uiSchema?.__DEPENDENTS__ ?? {},
            ),
            formState,
          );

          props.onSubmit(newFormState);
        }}
      >
        <PreventEnterSubmitButton />

        <Box
          id={ERROR_LIST_CONTAINER_ID}
          sx={{ mt: 8, display: 'inline-block', width: '100%' }}
        />

        {props.children && <React.Fragment>{props.children}</React.Fragment>}
      </_JsonForm>
    </DependentsContextProvider>
  );
}

/*
 * This button does two things:
 * 1. It prevents the JSON form from rendering its default <button> element if no button is provided.
 *  This is useful as we don't always want to have a submit button on the form.
 *
 * 2. It prevents the browser's default behavior of "submit the form on enter" by rendering
 * a disabled submit button (spec reference - https://www.w3.org/TR/2018/SPSD-html5-20180327/forms.html#implicit-submission)
 * */
function PreventEnterSubmitButton() {
  return (
    <Button
      type="submit"
      disabled
      sx={{
        display: 'none',
      }}
    >
      Hack
    </Button>
  );
}
