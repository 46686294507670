import axios from 'axios';

type Data = {
  imageUrl: string;
};

export async function fetchAvatarImageApi(data: Data) {
  return axios.get(data.imageUrl, {
    responseType: 'blob',
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  });
}
