import * as React from 'react';
import * as Rhf from 'react-hook-form';
import { generateContext } from 'utils/context';
import { Flex } from 'components/layout';
import { FlexProps } from 'theme-ui';

const [useNameContext, NameContextProvider] = generateContext<{
  name: string;
}>();

type FormProps = Omit<React.FormHTMLAttributes<HTMLFormElement>, 'name'>;

type Props<TFieldValues extends Rhf.FieldValues> =
  Rhf.UseFormReturn<TFieldValues> &
    FormProps &
    FlexProps & {
      children: React.ReactNode | React.ReactNodeArray;
      name: string;

      onValid?: Rhf.SubmitHandler<TFieldValues>;
      onInvalid?: Rhf.SubmitErrorHandler<TFieldValues>;
    };

/**
 * Provider component which enables usage of auto-connected form fields.
 *
 * You **must pass all of React Hook Form's methods to `Provider`**.
 *
 * ```
 * import * as Forms from 'components/forms';
 * import * as Rhf from 'react-hook-form'
 *
 * // ...
 * const formMethods = Rhf.useForm()
 * <Forms.Provider {...formMethods}>
 *   // ...
 * </Forms.Provider>
 * // ...
 * ```
 * */
export function Provider<TFieldValues extends Rhf.FieldValues>({
  children,
  name,

  onValid = () => {},
  onInvalid = () => {},

  control,
  handleSubmit,
  unregister,
  setFocus,
  setError,
  clearErrors,
  register,
  trigger,
  getValues,
  setValue,
  reset,
  formState,
  watch,
  sx,

  ...formProps
}: Props<TFieldValues>) {
  const nameContextValue = React.useMemo(() => ({ name }), [name]);

  return (
    <NameContextProvider value={nameContextValue}>
      <Rhf.FormProvider
        control={control}
        handleSubmit={handleSubmit}
        unregister={unregister}
        setFocus={setFocus}
        setError={setError}
        clearErrors={clearErrors}
        register={register}
        trigger={trigger}
        getValues={getValues}
        setValue={setValue}
        reset={reset}
        formState={formState}
        watch={watch}
      >
        <Flex
          as="form"
          flexDirection="column"
          // @ts-expect-error 'name' exists on 'form'
          name={name}
          // @ts-expect-error 'gap' exists on 'sx'
          gap={sx?.gap || 4}
          onSubmit={handleSubmit(onValid, onInvalid)}
          sx={{
            pt: 5,
            alignItems: 'center',
            '& > *': {
              width: '100%',
            },
            ...sx,
          }}
          {...formProps}
        >
          {children}
        </Flex>
      </Rhf.FormProvider>
    </NameContextProvider>
  );
}

export function useFormProvider() {
  const formMethods = Rhf.useFormContext();
  const { name } = useNameContext();

  return {
    formMethods,
    formName: name,
  };
}
