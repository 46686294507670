import * as ReactQuery from 'react-query';
import { InferMutationOptions } from 'utils/types';
import { forgotPasswordConfirmApi } from 'services/auth/forgot-password-confirm/api';

export function useForgotPasswordConfirm(
  options?: InferMutationOptions<typeof forgotPasswordConfirmApi>,
) {
  const { mutate: forgotPasswordConfirm, ...rest } = ReactQuery.useMutation(
    ['forgotPasswordConfirm'],
    forgotPasswordConfirmApi,
    options,
  );

  return {
    forgotPasswordConfirm,
    ...rest,
  };
}
