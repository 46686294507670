import { identify, makeRequest, Service } from 'utils/http';
import { DocumentRequestBase } from 'services/documents/types';

type Data = DocumentRequestBase;

export async function getDocumentApi(data: Data) {
  return makeRequest(
    Service.getDocument(data.documentId, data.templateId, await identify()),
  );
}
