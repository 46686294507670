import * as ReactQuery from 'react-query';
import * as ReactRouter from 'react-router-dom';
import { getDocumentApi } from 'services/documents/get-document/api';
import { InferQueryOptions } from 'utils/types';
import { StatusCodes } from 'global/status-codes';
import { DocumentTemplateId } from 'services/documents/types';
import { UsersService } from '../../users';

export const QUERY_KEY_DOCUMENT = (
  documentId: string,
  templateId: DocumentTemplateId,
) => ['document', documentId, templateId];

export function useGetDocument(
  documentId: string,
  templateId: DocumentTemplateId,
  options?: InferQueryOptions<typeof getDocumentApi>,
) {
  const history = ReactRouter.useHistory();
  const { hasCurrentUserPermission } = UsersService.useCurrentUserTemplatePermissions();

  const { data, ...rest } = ReactQuery.useQuery(
    QUERY_KEY_DOCUMENT(documentId, templateId),
    () => getDocumentApi({ documentId, templateId }),
    {
      refetchOnWindowFocus: false,
      retry: 0,
      ...options,
      onError: (error) => {
        /**
         * Currently we use the "400" status code as a "404",
         * because backend can't return "404" due to an
         * issue with CloudFront.
         * */
        const TEMPORARY_404_MAPPING = StatusCodes.BAD_REQUEST;
        if (error?.statusCode === TEMPORARY_404_MAPPING) {
          history.replace('/404');
        }

        if (options?.onError) {
          options.onError(error);
        }
      },
    },
  );

  return {
    document: data,
    ...rest,
    hasDocumentWritePermission: hasCurrentUserPermission({ templateId }),
  };
}
