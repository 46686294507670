import * as ReactQuery from 'react-query';
import { identify, makeRequest, Service } from '../../../utils/http';
import { DocumentTemplateId } from '../../../services/documents';
import { InferQueryOptions } from '../../../utils/types';

type Data = {
  templateId: DocumentTemplateId;
};
async function getAllUsersWithPermission({ templateId }: Data) {
  return makeRequest(
    Service.getAllUsersWithPermission(templateId, await identify()),
  );
}

const QUERY_KEY_GET_ALL_USERS_WITH_PERMISSION = ({ templateId }: Data) => [
  'users',
  'permissions',
  templateId,
  'with',
];

function useGetAllUsersWithPermission(
  { templateId }: Data,
  options?: InferQueryOptions<typeof getAllUsersWithPermission>,
) {
  const { data, ...rest } = ReactQuery.useQuery(
    QUERY_KEY_GET_ALL_USERS_WITH_PERMISSION({ templateId }),
    () => getAllUsersWithPermission({ templateId }),
    options,
  );

  return {
    usersWithPermission: data?.users,
    ...rest,
  };
}

export {
  useGetAllUsersWithPermission,
  QUERY_KEY_GET_ALL_USERS_WITH_PERMISSION,
};
