import React from 'react';
import { Box } from '../../../../components/layout';

type DocumentSectionWrapperProps = {
  children: React.ReactNode;
};

/*
 * Wrapper for a section in a document form.
 * A section is usually an object field in JSON schema.
 * */
export function DocumentSectionWrapper({
  children,
}: DocumentSectionWrapperProps) {
  return (
    <Box
      sx={{
        boxShadow: '1dp',
        borderBottomLeftRadius: 1,
        borderBottomRightRadius: 1,
        position: 'relative',
        pb: 64,
        pt: 8,
        mx: -4,
        px: 4,
        backgroundColor: 'white.50',
      }}
    >
      {children}
    </Box>
  );
}
