import * as React from 'react';
import * as Utils from 'utils';
import * as Icons from 'components/icons';
import * as ReactRouter from 'react-router-dom';
import * as _ from 'lodash-es';
import { Container, Flex } from 'components/layout';
import { DocumentsService, DocumentTemplateId } from 'services/documents';
import { Text } from 'components/typography';
import { Link } from 'components/links';
import { Routing } from 'global/routing';
import { PageLoadingIndicator } from 'components/PageLoadingIndicator/PageLoadingIndicator';
// eslint-disable-next-line max-len
import { getPressureTestCardProps } from 'components/cards/DocumentCard/components/DocumentCardDefault/DocumentCardDefault';
import { UsersService } from 'services/users';
import { RunningPressureTest } from 'components/RunningTimersBanner/components/RunningPressureTest/RunningPressureTest';

const NO_SHOW_PATHS = [
  Routing.DOCUMENT.default,
  Routing.PRESSURE.Running,
  Routing.VIEW_PDF.default,
];

export function RunningTimersBanner() {
  const history = ReactRouter.useHistory();
  const { runningDocuments } = DocumentsService.useGetRunningDocumentsForUser();

  if (
    runningDocuments.length === 0
    || NO_SHOW_PATHS.some((path) => history.location.pathname.includes(path))
  ) {
    return null;
  }

  return (
    <Flex sx={{ justifyContent: 'center', backgroundColor: 'secondary.300' }}>
      <Flex
        sx={{
          maxWidth: 450,
          color: 'white.50',
          py: 4,
          px: 5,
        }}
      >
        <Icons.TimerRunning />

        <Text
          sx={{ ml: 3, mr: 'auto', fontSize: 'md', flexShrink: 9999, pr: 2 }}
          intlId="pressure.running.bannerText"
          intlValues={{ running: runningDocuments.length }}
        />

        <Link
          sx={{
            color: 'inherit',
            textTransform: 'uppercase',
            fontSize: 'md',
            fontWeight: 400,
          }}
          to={Routing.PRESSURE.Running}
          intlId="pressure.running.viewHere"
        />
      </Flex>
    </Flex>
  );
}

export function RunningTimers() {
  const { runningDocuments, isLoading } = DocumentsService.useGetRunningDocumentsForUser();
  const { allUsers } = UsersService.useGetUsers();

  if (isLoading) {
    return <PageLoadingIndicator />;
  }

  const documentsByLeastTimeRemaining = _.orderBy(
    runningDocuments,
    (document) => {
      const { hours, minutes } = Utils.PressureTest.getTestExpectedDuration(document);
      return hours * 60 + minutes;
    },
    ['asc'],
  );

  return (
    <Container variant="spacer">
      <Text
        as="p"
        sx={{ my: 4, color: 'grey.400', textAlign: 'center' }}
        intlId="pressure.running.bannerText"
        intlValues={{ running: runningDocuments.length }}
      />

      <Flex
        flexDirection="column" gap={4}
        sx={{ mb: 4 }}
      >
        {documentsByLeastTimeRemaining.map((document) => {
          const { documentNumber, templateNameIntl, address, assignee } = getPressureTestCardProps(document, allUsers);

          const testStartTime = Utils.PressureTest.getTestStartTime(document);
          const expectedDuration = Utils.PressureTest.getTestExpectedDuration(document);

          return (
            <RunningPressureTest
              testStartTime={testStartTime}
              expectedMinutes={expectedDuration.minutes}
              expectedHours={expectedDuration.hours}
              documentId={document.id}
              key={document.id}
              documentNumber={documentNumber}
              templateNameIntl={templateNameIntl}
              templateId={document.templateId as DocumentTemplateId}
              assignee={assignee}
              address={address}
            />
          );
        })}
      </Flex>
    </Container>
  );
}
