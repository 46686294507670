/** @jsxImportSource theme-ui */
import { FormattedMessage } from 'lib/intl';
import * as React from 'react';
import { Text } from '../typography';

export function TermsAndConditionsText() {
  return (
    <Text sx={{ fontSize: 'md', color: 'darkgrey.800', mb: 1 }}>
      <FormattedMessage id="createAccount.form.input.privacyAndTermsAndConditions.message" />
      <PrivacyPolicyLink>
        <Text
          intlId="createAccount.form.input.privacy.message"
          sx={{ color: 'secondary.500' }}
        />
      </PrivacyPolicyLink>

      <FormattedMessage id="createAccount.form.input.and.message" />
      <TermsAndConditionsLink>
        <Text
          intlId="createAccount.form.input.termsAndConditions.message"
          sx={{ color: 'secondary.500' }}
        />
      </TermsAndConditionsLink>
    </Text>
  );
}

export function TermsAndConditionsLink({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <a
      target="_blank"
      sx={{ textDecoration: 'none' }}
      href="https://digitaltrace.se/terms"
      rel="noreferrer"
    >
      {children}
    </a>
  );
}

export function PrivacyPolicyLink({ children }: { children: React.ReactNode }) {
  return (
    <a
      target="_blank"
      sx={{ textDecoration: 'none' }}
      href="https://digitaltrace.se/privacy"
      rel="noreferrer"
    >
      {children}
    </a>
  );
}
