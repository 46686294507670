import * as ReactQuery from 'react-query';
import { InferMutationOptions } from 'utils/types';
import { deleteInvitedUserApi } from 'services/users/delete-invited-user/api';

export function useDeleteInvitedUser(
  options?: InferMutationOptions<typeof deleteInvitedUserApi>,
) {
  const { mutate: deleteInvitedUser, ...rest } = ReactQuery.useMutation(
    'deleteInvitedUser',
    deleteInvitedUserApi,
    options,
  );

  return {
    deleteInvitedUser,
    ...rest,
  };
}
