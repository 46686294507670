import * as ReactQuery from 'react-query';
import * as _ from 'lodash';
import { getAllTemplatesApi } from 'services/documents/get-all-templates/api';
import { InferQueryOptions } from 'utils/types';
import { UsersService } from 'services/users';
import { TemplateIdEnum } from 'trace-backend-sdk';

export const QUERY_KEY_ALL_TEMPLATES = ['all-templates'];

export function useGetAllTemplates(
  options?: InferQueryOptions<typeof getAllTemplatesApi>,
) {
  const { data, ...rest } = ReactQuery.useQuery(
    QUERY_KEY_ALL_TEMPLATES,
    getAllTemplatesApi,
    options,
  );

  const { hasCurrentUserPermission } = UsersService.useCurrentUserTemplatePermissions();
  const templatesWithPermission = _.sortBy(data?.templates, ({ id }) =>
    (id === TemplateIdEnum.QuickCapture ? 0 : 1)).filter((template) =>
    hasCurrentUserPermission({ templateId: template.id })) ?? [];

  return {
    templatesWithPermission,
    ...rest,
  };
}
