import React from 'react';
import { Box } from 'components/layout';

export function TableRowActionButtons({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Box
      data-table-action-buttons="true"
      sx={{
        minWidth: 100,
        opacity: 0,
        transition: 'opacity 0.3s',
        '& > *:not(:last-child)': {
          mr: 2,
        },
      }}
    >
      {children}
    </Box>
  );
}
