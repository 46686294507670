import React from 'react';
import { Flex, FlexProps } from 'components/layout';
import { ImageProps } from 'components/Image';
import { breakpoints } from 'utils/breakpoints';
import { ButtonProps } from 'components/form-elements';
import {
  Link,
  LinkProps,
  Button,
  Headline,
  ParagraphProps,
  Image,
  Logo,
  Paragraph,
  FormWrapper,
  FormWrapperProps,
  HeadlineProps,
} from './components';

type WorkspaceLayoutType = React.FC<FlexProps> & {
  Logo: React.ElementType<ImageProps>;
  Headline: React.ElementType<HeadlineProps>;
  Paragraph: React.ElementType<ParagraphProps>;
  FormWrapper: React.ElementType<FormWrapperProps>;
  Button: React.ElementType<ButtonProps>;
  Image: React.ElementType<ImageProps>;
  Link: React.ElementType<LinkProps>;
};

export const WorkspaceLayout: WorkspaceLayoutType = ({ children, sx }) => (
  <Flex
    alignItems="flex-start"
    justifyContent="center"
    flexDirection="column"
    sx={{
      backgroundColor: 'bg.50',
      py: 6,
      px: breakpoints({
        _: 2,
        sm: 6,
      }),
      ...sx,
    }}
  >
    {children}
  </Flex>
);

WorkspaceLayout.Logo = Logo;
WorkspaceLayout.Headline = Headline;
WorkspaceLayout.Paragraph = Paragraph;
WorkspaceLayout.FormWrapper = FormWrapper;
WorkspaceLayout.Button = Button;
WorkspaceLayout.Image = Image;
WorkspaceLayout.Link = Link;
