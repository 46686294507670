import * as ReactQuery from 'react-query';
import { ErrorType } from 'utils/http';
import { getUsersAPI } from './api';

export const USERS_QUERY_KEY = 'users';

export function useGetUsers() {
  const { isLoading, isError, data, error } = ReactQuery.useQuery(
    USERS_QUERY_KEY,
    getUsersAPI,
  );

  return {
    active: data?.users.filter((user) => user.active && user.ready),
    inactive: data?.users.filter((user) => !user.active && user.ready),
    pending: data?.users.filter((user) => !user.ready),
    allUsers: data?.users ?? [],
    isLoading,
    isError,
    error: error as ErrorType,
  };
}
