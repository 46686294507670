import { WidgetProps } from '@rjsf/core';
import React from 'react';
import { Switch } from 'components/Switch/Switch';
import { Box } from '../../../layout';

export function SwitchWidget(props: WidgetProps) {
  function onFocus(event: React.FocusEvent<HTMLInputElement>) {
    props.onFocus(props.id, event.target.checked);
  }

  function onBlur(event: React.FocusEvent<HTMLInputElement>) {
    props.onBlur(props.id, event.target.checked);
  }

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    props.onChange(event.target.checked);
  }

  return (
    <Box
      sx={{
        width: 'fit-content',
        display: 'flex',
        alignItems: 'center',
        ml: 'auto',

        '& > label': {
          alignItems: 'center',
          color: 'textprimary.900',
          fontWeight: 400,
          fontSize: 'xl',
        },
        '& > label > div': {
          mr: 2,
        },
      }}
    >
      <Switch
        disabled={props.disabled}
        id={props.label}
        name={props.label}
        checked={props.value}
        value={props.value}
        label={props.label}
        onChange={(event) => onChange(event)}
        onFocus={(event) => onFocus(event)}
        onBlur={(event) => onBlur(event)}
        sx={{
          backgroundColor: 'grey.400',
          '& > div': {
            backgroundColor: 'white.50',
          },
          'input:checked ~ &': {
            backgroundColor: 'secondary.500',
          },
        }}
      />
    </Box>
  );
}
