import * as ReactQuery from 'react-query';

import { InferMutationOptions } from 'utils/types';
import { addDocumentWidgetApi } from 'services/documents/add-document-widget/api';

export function useAddDocumentWidget(
  options?: InferMutationOptions<typeof addDocumentWidgetApi>,
) {
  const { mutate: addDocumentWidget, ...rest } = ReactQuery.useMutation(
    'addDocumentWidget',
    addDocumentWidgetApi,
    options,
  );

  return {
    addDocumentWidget,
    ...rest,
  };
}
