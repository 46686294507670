import { identify, makeRequest, Service } from 'utils/http';
import { DocumentRequestBase } from 'services/documents/types';
import {
  ConfirmDocumentWidgetRequest,
  DocumentWidgetEnum,
  FILETypeEnum,
  GetWidgetUploadUrlRequestWidgetTypeEnum,
  GPSTypeEnum,
  IMAGETypeEnum,
} from 'trace-backend-sdk';
import axios from 'axios';
import imageCompression from 'browser-image-compression';

type Data = DocumentRequestBase & {
  widgetType: DocumentWidgetEnum;
  /*
   * The name of the widget inside JSON schema.
   *
   * *Note:* This refers to the name itself, not the full path.
   * So it would be "something" instead of "parent.something" if nested.
   * */
  widgetKey: string;
  widgetData: GpsData | FileData | ImageData;
};

type GpsData = {
  type: 'gps';
  latitude: string;
  longitude: string;
  caption?: string;
};

type FileData = {
  type: 'file';
  // The extension type of the file
  fileType: string;
  filename: string;
  fileSizeBytes: number;
  file: Blob;
  caption?: string;
};

type ImageData = {
  type: 'image';
  image: Blob;
  caption?: string;
};

const WIDGET_MAP: Record<
  DocumentWidgetEnum,
  GetWidgetUploadUrlRequestWidgetTypeEnum
> = {
  [DocumentWidgetEnum.File]: GetWidgetUploadUrlRequestWidgetTypeEnum.File,
  [DocumentWidgetEnum.Gps]: GetWidgetUploadUrlRequestWidgetTypeEnum.Gps,
  [DocumentWidgetEnum.Image]: GetWidgetUploadUrlRequestWidgetTypeEnum.Image,
};

/*
 * Upload a file to S3 and link the S3 url to the
 * document data of the appropriate document.
 * */
export async function uploadDocumentWidgetApi({
  documentId,
  templateId,
  widgetType,
  widgetKey,
  widgetData,
}: Data) {
  const makeBaseRequest = async (requestObject: ConfirmDocumentWidgetRequest) =>
    makeRequest(
      Service.confirmDocumentWidget(
        documentId,
        templateId,
        requestObject,
        await identify(),
      ),
    );

  if (widgetData.type === 'gps') {
    return makeBaseRequest({
      type: GPSTypeEnum.Gps,
      latitude: widgetData.latitude,
      longitude: widgetData.longitude,
      caption: widgetData.caption,
      timestamp: Date.now(),
      widgetKey,
    });
  }

  const { uploadUrl } = await makeRequest(
    Service.getWidgetUploadUrl(
      documentId,
      templateId,
      {
        widgetType: WIDGET_MAP[widgetType],
        widgetKey,
      },
      await identify(),
    ),
  );

  if (widgetData.type === 'image') {
    await axios.put(
      uploadUrl,
      await imageCompression(
        new File([widgetData.image], 'workspaceImage', {
          type: 'image/jpeg',
        }),
        {
          maxSizeMB: 0.1,
        },
      ),
    );

    return makeBaseRequest({
      widgetKey,
      caption: widgetData.caption,
      type: IMAGETypeEnum.Image,
      timestamp: Date.now(),
    });
  }

  await axios.put(uploadUrl, widgetData.file, {
    headers: {
      'Content-Type': widgetData.fileType,
    },
  });

  return makeBaseRequest({
    type: FILETypeEnum.File,
    caption: widgetData.caption,
    widgetKey,
    filename: widgetData.filename,
    timestamp: Date.now(),
    fileSizeBytes: widgetData.fileSizeBytes,
  });
}
