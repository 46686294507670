import * as React from 'react';

import { NavLink } from 'components/links';
import { Image } from 'components/Image';
import { Routing } from 'global/routing';
import { StyleObject } from 'theming';
import TraceLogoImg from 'assets/images/TraceLogo.svg';

export const TraceLogo: React.FC<{ sx?: StyleObject }> = ({ sx }) => (
  <NavLink to={Routing.HOME.getPath()}>
    <Image
      src={TraceLogoImg} alt="Trace Logotype"
      sx={sx}
    />
  </NavLink>
);
