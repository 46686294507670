import { useStripe } from '@stripe/react-stripe-js';
import React from 'react';
import { useMutation } from 'react-query';
import { useNotifications } from '../../../components/notifications/notifications';
import { LocaleKey } from '../../../translations';
import {
  LOCAL_STORAGE_KEYS,
  useLocalStorage,
} from '../../../utils/local-storage';

export function useConfirmCardSetup(options?: { onSuccess: () => void }) {
  const stripe = useStripe();
  const [failedSetupIntentId, setFailedSetupIntentId] = useLocalStorage<string>(
    LOCAL_STORAGE_KEYS.FAILED_SETUP_INTENT_ID,
  );
  const notifications = useNotifications();

  const { mutate, isLoading } = useMutation(
    'stripeConfirmCard',
    async (clientSecret: string) => {
      const result = await stripe?.confirmCardSetup(clientSecret);

      if (!result) {
        throw new Error('Something went wrong while confirming the card.');
      }

      if (result.error) {
        setFailedSetupIntentId(result.error.setup_intent?.id);
        throw new Error(result.error.message);
      }
    },
    {
      onSuccess: options?.onSuccess,
      onError: (error) => {
        if (error instanceof Error) {
          notifications.error({ description: error.message as LocaleKey });
        }
      },
    },
  );

  const resetFailedSetupIntentId = React.useCallback(() => {
    setFailedSetupIntentId(undefined);
  }, [setFailedSetupIntentId]);

  return {
    failedSetupIntentId,
    resetFailedSetupIntentId,
    stripeConfirmCard: mutate,
    isConfirmingCard: isLoading,
  };
}
