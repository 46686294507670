import * as ReactQuery from 'react-query';
import { InferMutationOptions } from 'utils/types';
import { ErrorType, identify, makeRequest, Service } from 'utils/http';

type Data = Parameters<typeof Service.inviteUser>[0];
async function inviteUserApi(data: Data) {
  return makeRequest(Service.inviteUser(data, await identify()));
}

export function useInviteUsers(
  options?: InferMutationOptions<typeof inviteUserApi>,
) {
  const { mutate: inviteUsers, ...rest } = ReactQuery.useMutation(
    'invite-users',
    inviteUserApi,
    options,
  );

  return {
    inviteUsers,
    ...rest,
    error: rest.error as ErrorType,
  };
}
