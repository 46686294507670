import { buttons } from './buttons';
import { forms } from './forms';
import { links } from './links';
import { layout } from './layout';
import { text } from './text';
import { cards } from './cards';

export const variants = {
  buttons,
  forms,
  links,
  layout,
  text,
  cards,
};
