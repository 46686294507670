import * as React from 'react';
import { Container, Flex } from 'components/layout';
import { Button } from 'components/form-elements';
import { Language, LANGUAGE_ENGLISH, useIntlContext } from 'translations';

export function Certificate() {
  const searchParams = new URLSearchParams(window.location.search);
  const { setLanguage } = useIntlContext();
  const url = searchParams.get('url');
  // User is not logged in on this page, but he access it through sms message link
  // Get the workspace language from query params of the url, to display the content in that language.
  const lang = searchParams.get('lang') as Language;
  const urlValue = url !== null ? url : '';

  React.useEffect(() => {
    setLanguage(lang || LANGUAGE_ENGLISH);
  }, [lang, setLanguage]);

  return (
    <Container
      variant="page"
      sx={{
        height: '100%',
        backgroundColor: 'white.50',
      }}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          maxWidth: 300,
          height: '100%',
        }}
      >
        <Button
          intlId="s.email.certificate.download"
          onClick={() => {
            window.open(decodeURIComponent(urlValue), '_blank');
          }}
        />
      </Flex>
    </Container>
  );
}
