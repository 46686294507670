import {
  useGetDocuments,
  usePaginatedDocuments,
} from 'services/documents/get-documents';
import { useCreateDocument } from 'services/documents/create-document/hook';
import { useGetDocument } from 'services/documents/get-document/hook';
import { useUpdateDocument } from 'services/documents/update-document/hook';
import { useUpdateDocumentAssignee } from 'services/documents/update-document-assignee/hook';
import { useFinalizeDocument } from 'services/documents/finalize-document/hook';
import { useUploadDocumentImage } from 'services/documents/upload-document-image/hook';
import { useGetDocumentRecipients } from 'services/documents/get-document-recipients';
import { useGetDocumentCertificate } from 'services/documents/get-document-certificate/hook';
import { useDeleteDocument } from 'services/documents/delete-document/hook';
import { useGetAllTemplates } from 'services/documents/get-all-templates/hook';
import { useAddDocumentWidget } from 'services/documents/add-document-widget/hook';
import { useGetRunningDocumentsForUser } from './get-running-documents-for-user/hook';
import { useUploadDocumentWidget } from './upload-document-widget/hook';
import { useDeleteDocumentWidget } from './delete-document-widget/hook';
import { useClearDocumentWidget } from './clear-document-widget/hook';

export const DocumentsService = {
  useAddDocumentWidget,
  useCreateDocument,
  useDeleteDocument,
  useFinalizeDocument,
  useGetAllTemplates,
  useGetDocument,
  useGetDocumentCertificate,
  useGetDocumentRecipients,
  useGetDocuments,
  useGetRunningDocumentsForUser,
  usePaginatedDocuments,
  useUpdateDocument,
  useUpdateDocumentAssignee,
  useUploadDocumentImage,
  useUploadDocumentWidget,
  useDeleteDocumentWidget,
  useClearDocumentWidget,
};

export * from './types';
