import React from 'react';

import { Flex } from 'components/layout';
import { breakpoints } from 'utils/breakpoints';
import * as Icons from 'components/icons';
import { Text } from 'components/typography';
import { DocumentsService } from 'services/documents';
import {
  useLocaleDateFormat,
  useLocaleTimeFormat,
} from 'utils/hooks/use-locale-date-format';
import { isQueryLoading } from 'utils/functions/is-query-loading';
import { InlineLoadingIndicator } from 'components/LoadingIndicator';
import { useDocumentParams } from 'pages/Document/Document';

export const DocumentRecipients: React.FC = () => {
  const params = useDocumentParams();
  const { recipients, isLoading } = DocumentsService.useGetDocumentRecipients(
    params.documentId,
    params.templateId,
  );

  if (isQueryLoading(isLoading, recipients)) {
    return <InlineLoadingIndicator />;
  }

  return (
    <Flex
      flexDirection="column"
      sx={{
        mt: 6,
        px: breakpoints({
          _: 3,
          sm: 0,
        }),

        '& > *:not(:first-child)': {
          mt: 2,
        },
      }}
    >
      {recipients.length === 0 && (
        <Text
          intlId="viewDocument.recipients.noRecipients.message"
          sx={{ textAlign: 'center' }}
        />
      )}

      {recipients.map((recipient) => (
        <DocumentRecipient>
          <DocumentRecipientEmail email={recipient.externalUserRecipientId} />

          <DocumentRecipientSeenMetadata dateOpened={recipient.dateOpened}>
            <Flex
              sx={{
                width: 'auto',
                flex: 1,
              }}
            >
              {recipient.opened ? (
                <React.Fragment>
                  <Icons.EyeOpenNotFilled width={22} />
                  <Text
                    intlId="viewDocument.recipients.opened"
                    sx={{ ml: 3 }}
                  />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Icons.EyeClosedNotFilled width={22} />
                  <Text
                    intlId="viewDocument.recipients.notOpened"
                    sx={{ ml: 3 }}
                  />
                </React.Fragment>
              )}
            </Flex>
          </DocumentRecipientSeenMetadata>
        </DocumentRecipient>
      ))}
    </Flex>
  );
};

function DocumentRecipientEmail(props: { email: string }) {
  return (
    <Flex
      sx={{
        color: 'secondary.500',
      }}
    >
      <Icons.Profile width={16} />

      <Text
        scrollable
        sx={{
          fontSize: 'md',
          color: 'currentColor',
          ml: 4,
        }}
      >
        {props.email}
      </Text>
    </Flex>
  );
}

function DocumentRecipientSeenMetadata(props: {
  dateOpened?: string;
  children: React.ReactNode;
}) {
  const { localeDateFormat } = useLocaleDateFormat();
  const { localeTimeFormat } = useLocaleTimeFormat();

  return (
    <Flex
      sx={{
        fontSize: 'sm',
        color: 'subtitle2',
      }}
    >
      {props.children}

      <Text
        scrollable
        sx={{
          textAlign: 'center',
          flex: 1,
          borderLeft: '1px solid',
          borderRight: '1px solid',
          borderColor: 'grey.300',
          color: 'currentColor',
        }}
      >
        {props.dateOpened ? localeDateFormat(new Date(props.dateOpened)) : '-'}
      </Text>

      <Text
        scrollable
        sx={{
          textAlign: 'center',
          color: 'currentColor',
          flex: 1,
        }}
      >
        {props.dateOpened ? localeTimeFormat(new Date(props.dateOpened)) : '-'}
      </Text>
    </Flex>
  );
}

function DocumentRecipient(props: { children: React.ReactNode }) {
  return (
    <Flex
      sx={{
        '& > *:first-child': {
          mb: 4,
        },

        flexDirection: 'column',
        borderBottom: '1px solid',
        borderBottomColor: 'grey.300',
        backgroundColor: 'white.50',

        px: breakpoints({
          _: 2,
          sm: 4,
        }),
        py: 3,
      }}
    >
      {props.children}
    </Flex>
  );
}
