import React from 'react';
import * as ThemeUI from 'theme-ui';

import { FieldTemplateProps } from '@rjsf/core';
import { Label } from 'components/form-elements';
import { DependentGuard } from 'components/form-elements/JsonForms/utils/DependentGuard';
import { FormattedMessage } from 'lib/intl';
import { LocaleKey } from '../../../../translations';
import { Spacer } from '../../../Spacer/Spacer';
import { ErrorMessage } from './ErrorMessage';

export const FieldTemplate = ({
  id,
  children,
  displayLabel,
  rawErrors = [],
  rawHelp,
  rawDescription,
  ...props
}: FieldTemplateProps) => {
  const isError = rawErrors.length > 0;

  return (
    <DependentGuard fieldId={id}>
      {props.uiSchema['ui:detached'] ? (
        children
      ) : (
        <ThemeUI.Flex
          sx={{
            flexDirection: 'column',
          }}
        >
          {/*
          Need this check because the default Array renderer loses the label on the way down,
          and it never gets propagated to the lower components (Checkboxes, or MultiSelect).
        */}
          {props.schema.type === 'array' && (
            <React.Fragment>
              <Label
                isError={isError}
                required={props.required}
                sx={{
                  variant: 'forms.jsonInputLabel',
                }}
                labelIntlId={props.label as LocaleKey}
              />

              <Spacer y={3} />
            </React.Fragment>
          )}

          {children}

          {displayLabel && rawDescription ? (
            <ThemeUI.Text
              sx={{
                fontSize: '12px',
                color: '#333',
              }}
            >
              {/*TODO: Add translation*/}
              {rawDescription}
            </ThemeUI.Text>
          ) : null}
          {rawErrors.length > 0 && (
            <ThemeUI.Box mt={1}>
              {rawErrors.map((error: any, i: number) => (
                <ErrorMessage key={i}>
                  <FormattedMessage id={error} />
                </ErrorMessage>
              ))}
            </ThemeUI.Box>
          )}
          {/*TODO: Add translation*/}
          {rawHelp && <ErrorMessage>{rawHelp}</ErrorMessage>}
        </ThemeUI.Flex>
      )}
    </DependentGuard>
  );
};
