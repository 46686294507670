import React from 'react';
import { Text, TextProps } from 'components/typography';
import { IntlProps } from 'translations';

export type ParagraphProps = IntlProps & TextProps;

export const Paragraph: React.FC<ParagraphProps> = ({
  intlId,
  intlValues,
  variant = 'body1',
  sx,
}) => (
  <Text
    variant={variant} intlId={intlId}
    intlValues={intlValues} sx={sx}
  />
);
