import { identify, makeRequest, Service } from 'utils/http';
import { DocumentRequestBase } from 'services/documents/types';

type Data = DocumentRequestBase;

export async function deleteDocumentApi({ documentId, templateId }: Data) {
  return makeRequest(
    Service.deleteDocument(documentId, templateId, await identify()),
  );
}
