import * as React from 'react';
import { useRedirect } from 'utils/redirect';
import { Routing } from 'global/routing';
import { AuthService } from 'services/auth';

export function SignOut() {
  const { onRedirect } = useRedirect(Routing.LOGIN.getPath());
  const { signOut } = AuthService.useSignOut({
    onSuccess: () => {
      onRedirect();
      window.location.reload();
    },
  });

  // Use layout effect to start the request *before* browser paint
  // https://raw.githubusercontent.com/donavon/hook-flow/master/hook-flow.png
  React.useLayoutEffect(() => {
    signOut(undefined);
  }, [signOut]);

  return null;
}
