// eslint-disable-next-line max-len
import { DocumentCardDefaultProps } from 'components/cards/DocumentCard/components/DocumentCardDefault/DocumentCardDefault';
import * as React from 'react';
import { padTimerEntry, TimerState } from 'components/Timer/Timer';
import * as ReactRouter from 'react-router-dom';
import * as Utils from 'utils';
import {
  DocumentCard,
  DocumentCardAddress,
  DocumentCardAvatarWrapper,
  DocumentCardTemplateName,
  DocumentCardTitle,
} from 'components/cards/DocumentCard/DocumentCard';
import { Routing } from 'global/routing';
import { Avatar } from 'components/avatar';
import { Flex } from 'components/layout';
import { Text } from 'components/typography';

type RunningPressureTestProps = {
  expectedMinutes: number;
  expectedHours: number;
  testStartTime: number;
};

export function RunningPressureTest({
  documentNumber,
  templateNameIntl,
  address,
  assignee,
  templateId,
  documentId,
  testStartTime,
  expectedHours,
  expectedMinutes,
}: RunningPressureTestProps &
  Pick<
    DocumentCardDefaultProps,
    | 'templateNameIntl'
    | 'documentNumber'
    | 'assignee'
    | 'address'
    | 'templateId'
    | 'documentId'
  >) {
  const history = ReactRouter.useHistory();

  const [remainingTime, setRemainingTime] = React.useState<
    TimerState & { expired: boolean }
  >(() => getRemainingDuration(expectedHours, expectedMinutes, testStartTime));

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setRemainingTime(
        getRemainingDuration(expectedHours, expectedMinutes, testStartTime),
      );
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [expectedHours, expectedMinutes, testStartTime]);

  return (
    <DocumentCard
      onClick={() =>
        history.push(
          Routing.DOCUMENT.Links.DocumentTab({
            documentId,
            templateId,
          }),
        )}
    >
      <DocumentCardTitle
        templateNameIntl={templateNameIntl}
        documentNumber={documentNumber}
      />

      {assignee && (
        <DocumentCardAvatarWrapper>
          <Avatar
            color={assignee.user.avatarColor}
            firstName={assignee.user.firstName}
            lastName={assignee.user.lastName}
            active={assignee.active}
            profileImageUrl={assignee.profileImageUrl}
          />
        </DocumentCardAvatarWrapper>
      )}

      <DocumentCardAddress>{address}</DocumentCardAddress>

      <DocumentCardTemplateName
        templateNameIntl={templateNameIntl}
        templateId={templateId}
      />

      <Text
        sx={{
          gridArea: 'date',
          justifySelf: 'end',
          color: 'grey.800',
          fontSize: 'sm',
        }}
      >
        <Flex
          sx={{
            flexDirection: 'column',
            gap: '2px',
          }}
        >
          <Text
            sx={{
              fontSize: 'sm',
              color: 'grey.800',
            }}
            intlId="pressure.running.remainingTime"
          />

          <Flex
            sx={{
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
              color: 'secondary.500',
            }}
          >
            <Time>{remainingTime.expired ? '00' : remainingTime.hours}</Time>
            <Text sx={{ px: '2px' }}>{' : '}</Text>
            <Time>{remainingTime.expired ? '00' : remainingTime.minutes}</Time>
          </Flex>
        </Flex>
      </Text>
    </DocumentCard>
  );
}

function getRemainingDuration(
  expectedHours: number,
  expectedMinutes: number,
  testStartTime: number,
) {
  const { hours, minutes, seconds, expired } = Utils.PressureTest.getTestRemainingDuration({
    expectedDuration: {
      hours: expectedHours,
      minutes: expectedMinutes,
    },
    currentTimestamp: Date.now(),
    startTime: testStartTime,
  });

  return {
    hours: padTimerEntry(hours),
    minutes: padTimerEntry(minutes),
    seconds: padTimerEntry(seconds),
    expired,
  };
}

export function Time(props: { children: React.ReactNode }) {
  return (
    <Flex
      variant="cards.timer.running"
      sx={{
        borderRadius: '2px',
        padding: '0',
        width: '28px',
        height: '36px',
        textAlign: 'center',
        color: 'white.50',
        fontSize: 'md',
        '&::before': {
          height: '42%',
          borderRadius: '2px',
        },
      }}
    >
      <Text
        as="p"
        sx={{
          position: 'absolute',
          width: '100%',
          fontSize: 'sm',
          p: 1,
        }}
      >
        {props.children}
      </Text>
    </Flex>
  );
}
