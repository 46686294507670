import React from 'react';
import * as R from 'remeda';
import * as ReactRouterDom from 'react-router-dom';

import { Text } from 'components/typography';
import { Container, Flex } from 'components/layout';
import { DocumentsService } from 'services/documents';
import * as Tabs from 'components/Tabs';

import { breakpoints } from 'utils/breakpoints';
import { DocumentStatusEnum } from 'trace-backend-sdk';
import { Button } from 'components/form-elements';
import { composeTranslationKey } from 'utils/functions/compose-translation-key';
import { isIgnoreCaseEqual } from 'utils/functions/is-ignore-case-equal';
import { GetDocumentsRequestData } from 'services/documents/get-documents/api';
import { InlineLoadingIndicator } from 'components/LoadingIndicator';
import { isQueryLoading } from '../../utils/functions/is-query-loading';
import { DocumentList } from '../../features/document/components/DocumentList';

const DOCUMENT_STATUS_ENUM_ROUTES = Object.values(DocumentStatusEnum).map(
  (value) => value.toLowerCase(),
);

export const Documents: React.FC = () => {
  const match = ReactRouterDom.useRouteMatch();

  const getMatchRoute = (route: string) => [match.url, route].join('/');

  return (
    <Flex flexDirection="column">
      <Container
        variant="page"
        sx={{
          px: breakpoints({ _: 0, sm: 4 }),
          py: 0,
        }}
      >
        <Tabs.Wrapper>
          {DOCUMENT_STATUS_ENUM_ROUTES.map((enumRoute) => (
            <Tabs.Tab key={enumRoute} to={getMatchRoute(enumRoute)}>
              <Text
                intlId={composeTranslationKey('menu.side.documents', enumRoute)}
              />
            </Tabs.Tab>
          ))}
        </Tabs.Wrapper>
      </Container>

      <Container variant="spacer" sx={{ py: 4, flexGrow: 1, display: 'flex' }}>
        <ReactRouterDom.Switch>
          <ReactRouterDom.Route path={getMatchRoute(':status')}>
            <DocumentsPerStatus />
          </ReactRouterDom.Route>

          <ReactRouterDom.Redirect
            to={getMatchRoute(DOCUMENT_STATUS_ENUM_ROUTES[0])}
          />
        </ReactRouterDom.Switch>
      </Container>
    </Flex>
  );
};

function useDocumentStatusParam() {
  return ReactRouterDom.useParams<{ status: DocumentStatusEnum }>();
}

const DOCUMENT_STATUS_SORT_BY: Record<
  DocumentStatusEnum,
  GetDocumentsRequestData['sortBy']
> = {
  [DocumentStatusEnum.Assigned]: 'dateCreated',
  [DocumentStatusEnum.Draft]: 'dateCreated',
  [DocumentStatusEnum.Completed]: 'dateFinished',
};

function DocumentsPerStatus() {
  const params = useDocumentStatusParam();
  const isGetDocumentsForCurrentUserOnly = isIgnoreCaseEqual(
    params.status,
    DocumentStatusEnum.Assigned,
  );

  const {
    data,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
  } = DocumentsService.usePaginatedDocuments(
    params.status,
    isGetDocumentsForCurrentUserOnly,
    DOCUMENT_STATUS_SORT_BY[params.status.toUpperCase() as DocumentStatusEnum],
  );

  const hasDocuments = (data?.pages?.[0].documents.length ?? 0) > 0;
  const documents = React.useMemo(
    () => R.flatMap(data?.pages ?? [], (page) => page.documents),
    [data?.pages],
  );

  if (isQueryLoading(isLoading, data)) {
    return <InlineLoadingIndicator />;
  }

  return (
    <Flex
      flexDirection="column"
      sx={{
        my: 4,
        '& > *:not(:last-child)': {
          mb: 8,
        },
      }}
    >
      {isFetching && !hasDocuments ? null : (
        <DocumentList documents={documents} />
      )}

      {hasNextPage && (
        <Button
          isLoading={isFetchingNextPage}
          onClick={() => {
            fetchNextPage();
          }}
          intlId="documents.loadMoreButton"
        />
      )}
    </Flex>
  );
}
