import countryCallingCodes from 'translations/countries/country-calling-codes.json';
import React from 'react';
import { Flex } from 'components/layout';
import { CountryFlag } from 'components/CountryFlag/CountryFlag';
import { Text } from 'components/typography';
import { useIntl } from 'lib/intl';
import Select from 'react-select';
import { CSSObject } from 'theme-ui';

const COUNTRY_CODES_MOST_USED = ['se', 'dk', 'fi', 'no', 'us'];
const COUNTRY_CALLING_CODES_ALPHA2 = Object.keys(countryCallingCodes);

export function CountryCallingCodeSelect(props: {
  name: string;
  value: string;
  container?: CSSObject;
  onChange: (value: string) => void;
  onBlur: React.FocusEventHandler;
}) {
  const intl = useIntl();

  const mostUsedOptions = COUNTRY_CALLING_CODES_ALPHA2.filter((alpha2) =>
    COUNTRY_CODES_MOST_USED.includes(alpha2.toLowerCase())).map((alpha2) => getOption(alpha2));

  const restOptions = COUNTRY_CALLING_CODES_ALPHA2.filter(
    (alpha2) => !COUNTRY_CODES_MOST_USED.includes(alpha2.toLowerCase()),
  ).map((alpha2) => getOption(alpha2));

  const options = [...mostUsedOptions, ...restOptions];

  // TODO: Separate styling the select from the functional props
  return (
    <Select
      styles={{
        container: (base) => ({
          ...base,
          ...props.container,
          display: 'flex',
        }),
        control: (base) => ({
          ...base,
          flex: 1,
          backgroundColor: '#F8F8F8',
          borderColor: '#9E9EAF',
        }),
      }}
      name={props.name}
      value={options.find((opt) => opt.value === props.value)}
      onBlur={props.onBlur}
      onChange={(event) => props.onChange(event?.value ?? '')}
      options={[
        {
          label: intl.formatMessage({
            id: 'countryCodeSelect.mostUsed',
          }),
          options: mostUsedOptions,
        },
        {
          label: intl.formatMessage({
            id: 'countryCodeSelect.others',
          }),
          options: restOptions,
        },
      ]}
    />
  );
}

function getOption(alpha2: string) {
  return {
    label: (
      <Flex
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <CountryFlag
          width={24} height={24}
          alpha2={alpha2}
        />

        <Text sx={{ ml: 3, fontSize: 'sm' }}>
          {/*@ts-expect-error Key error*/}
          {countryCallingCodes[alpha2.toUpperCase()]}
        </Text>
      </Flex>
    ),
    // @ts-expect-error Key error
    value: countryCallingCodes[alpha2.toUpperCase()],
  };
}
