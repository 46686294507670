import * as _ from 'lodash-es';

export function getSearchQuery(
  fn: (oldParams: Record<string, string>) => Record<string, string>,
): string {
  const searchParams = new URLSearchParams(window.location.search);
  const searchParamsMap: Record<string, string> = {};
  for (const [key, value] of searchParams.entries()) searchParamsMap[key] = value;

  const newParams = fn(searchParamsMap);
  for (const [key, value] of _.toPairs(newParams)) {
    if (!value) {
      delete newParams[key];
    }
  }

  return new URLSearchParams(newParams).toString();
}
