import * as ReactQuery from 'react-query';
import React from 'react';
import { QUERY_KEY_WORKSPACE } from '../../../services/workspace/get-workspace';

/*
 * This is a hack until we implement support for multiple
 * websocket connections. We can use a websocket connection
 * to inform the client that Stripe's webhook has updated
 * the workspace status to TRIAL.
 *
 * Until we have the websocket connection, we assume that
 * 2000ms is enough for Stripe's webhook to propagate and
 * we invalidate after that time.
 *
 * TODO: Implement multiple websocket connection support
 *  on frontend and backend
 *  - https://linear.app/metergram/issue/TR-783/[be]-implement-multiple-websocket-connections
 * */
function useInvalidateWorkspaceOnTimeout() {
  const queryClient = ReactQuery.useQueryClient();
  const invalidateWorkspaceOnTimeout = React.useCallback(async () => {
    await new Promise((resolve) =>
      setTimeout(() => {
        queryClient
          .invalidateQueries(QUERY_KEY_WORKSPACE)
          .then(() => resolve(undefined));
      }, 2000));
  }, [queryClient]);

  return { invalidateWorkspaceOnTimeout };
}

export { useInvalidateWorkspaceOnTimeout };
