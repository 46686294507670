import React, { ReactNode, ReactNodeArray } from 'react';
import { Box, Flex } from 'components/layout';
import { colors } from 'theming/foundations/colors';
import { Button } from 'components/form-elements';
import { useModal } from 'store/modals';
import { GenericFn } from 'utils/types';
import { StyleObject } from 'theming';
import { LocaleKey } from 'translations';

type Props = {
  onProceed: GenericFn;
  children: ReactNode | ReactNodeArray;
  sx?: StyleObject;
  proceedText?: LocaleKey;
  disabled?: boolean;
};

export const ConfirmationDialog = ({
  onProceed,
  children,
  sx,
  proceedText,
  disabled,
}: Props) => {
  const { closeModal } = useModal();

  return (
    <Box
      sx={{
        p: 6,
        pb: 0,
        minWidth: 280,
        maxWidth: 500,
        ...sx,
      }}
    >
      {children}

      <Flex
        flexDirection="row"
        alignItems="center"
        sx={{
          borderTop: `2px solid ${colors.grey['300']}`,
          '& > *': {
            flexGrow: 1,
          },
          mt: 5,
        }}
      >
        <Button
          onClick={closeModal}
          intlId="confirmationModal.cancel.button.label"
          variant="text"
          sx={{ color: 'error', outline: 'none', py: 4 }}
        />
        <Button
          disabled={disabled}
          onClick={() => {
            closeModal();
            onProceed();
          }}
          intlId={proceedText ?? 'confirmationModal.proceed.button.label'}
          variant="text"
          sx={{
            py: 4,
            color: 'tertiary1.600',
            outline: 'none',
          }}
        />
      </Flex>
    </Box>
  );
};
