import Joi from 'joi';
import { z } from 'zod';
import { WorkspaceStatusEnum } from 'trace-backend-sdk';

export const ORGANIZATION_NUMBER = z
  .string()
  .min(5, { message: 'createWorkspace.form.input.orgNumber.error.length' })
  .max(15, { message: 'createWorkspace.form.input.orgNumber.error.length' });

export const TERMS_AND_CONDITIONS = Joi.boolean()
  .required()
  .valid(true)
  .messages({
    'any.only':
      'createAccount.form.input.privacyAndTermsAndConditions.error.message',
  });

export const WORKSPACE_STATUS = z.enum([
  WorkspaceStatusEnum.Trial,
  WorkspaceStatusEnum.ForeverFree,
  WorkspaceStatusEnum.Unpaid,
  WorkspaceStatusEnum.PastDue,
  WorkspaceStatusEnum.Paid,
] as const);
