import React from 'react';
import { Text } from 'components/typography';

type AvatarTextProps = {
  initials?: string;
};

export const AvatarText: React.FC<AvatarTextProps> = ({ initials }) => (
  <Text
    sx={{
      position: 'absolute',
      bottom: '50%',
      width: '100%',
      height: 'auto',
      textAlign: 'center',
      fontSize: '1em',
      color: 'white.50',
      textShadow: '0px 0px 1px #000000cc',
    }}
  >
    {initials}
  </Text>
);
