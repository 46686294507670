import * as ReactQuery from 'react-query';
import { InferQueryOptions } from 'utils/types';
import { getDocumentCertificateApi } from 'services/documents/get-document-certificate/api';
import { DocumentTemplateId } from 'services/documents/types';

const QUERY_KEY_DOCUMENT_CERTIFICATE = (
  documentId: string,
  templateId: DocumentTemplateId,
) => ['documentCertificate', documentId, templateId];

export function useGetDocumentCertificate(
  documentId: string,
  templateId: DocumentTemplateId,
  options?: InferQueryOptions<typeof getDocumentCertificateApi>,
) {
  const { data: documentCertificateUrl, ...rest } = ReactQuery.useQuery(
    QUERY_KEY_DOCUMENT_CERTIFICATE(documentId, templateId),
    () => getDocumentCertificateApi({ documentId, templateId }),
    options,
  );

  return {
    documentCertificateUrl,
    ...rest,
  };
}
