import { Box } from 'components/layout';
import { Text } from 'components/typography';
import * as Icons from 'components/icons';
import {
  ComboMultiSelect,
  ComboMultiSelectMultiValueClassName,
} from 'components/form-elements/Forms/ComboMultiSelect';
import { IconButton } from 'components/form-elements/buttons/IconButton';

export function DocumentToggleOption(props: any) {
  return (
    <ComboMultiSelect
      wrapperSx={{ mb: 10 }}
      value={props.selectedRecipients}
      isLoading={props.isLoading}
      placeholderIntlId="viewDocument.recipients.add.input.placeholder"
      options={props.externalUsers?.map((userEmail: any) => ({
        label: (
          <Box
            sx={{
              flexGrow: 1,
              justifyContent: 'space-between',
              display: 'flex',
              alignItems: 'center',

              /*
               * If we expand this selector, it will only ever apply when this element `&` is
               * rendered inside the multi select value container `.valueContainer &` and will
               * target the button inside `.valueContainer & button`.
               * */
              [`.${ComboMultiSelectMultiValueClassName} & button`]: {
                display: 'none',
              },
            }}
          >
            <Text as="p">{userEmail}</Text>
            <IconButton
              disabled={props.removeExternalUsers.isLoading}
              onClick={(event) => {
                event.stopPropagation();
                props.removeExternalUsers.mutate({ emails: [userEmail] });
              }}
            >
              <Icons.CloseCircled width={32} sx={{ stroke: 'grey.400' }} />
            </IconButton>
          </Box>
        ),
        value: userEmail,
      }))}
      onChange={(value) => props.setSelectedRecipients(value)}
    />
  );
}
