import * as React from 'react';
import * as ThemeUI from 'theme-ui';
import * as Intl from 'lib/intl';
import { Box } from 'components/layout';
import CheckmarkWhite from 'assets/images/Checkmark-white.svg';
import Close from 'assets/images/Close.svg';
import { useIntl } from 'lib/intl';
import { Text } from '../typography';

type Option = 'on' | 'off';
type SwitchProps = Omit<ThemeUI.SwitchProps, 'ref'>;

export function Switch({ label, ...props }: SwitchProps) {
  const intl = useIntl();
  const switchStatus = props.value ? 'on' : 'off';
  return (
    <ThemeUI.Label>
      <Text sx={{ mr: 3, variant: 'forms.jsonInputLabel' }}>
        {intl.formatMessage({
          id: label,
        })}
      </Text>

      <SwitchWrapper status={switchStatus}>
        {switchStatus === 'on' && <SwitchLabel status={switchStatus} disabled={props.disabled} />}
        <ThemeUI.Switch
          {...props}
          sx={{
            backgroundColor: 'transparent',
            display: 'flex',
            alignItems: 'center',
            justifyContent: switchStatus === 'on' ? 'flex-end' : 'flex-start',
            height: '40px',
            ...(switchStatus === 'on' && {
              'input:checked ~ & > div': {
                width: '24px',
                height: '24px',
                backgroundColor: 'tertiary1.600',
                transform: 'translateX(0px)',
                ':after': {
                  content: `url(${CheckmarkWhite})`,
                  pl: 1,
                  position: 'relative',
                  bottom: '1px',
                  left: '1px',
                },
              },
            }),
            ...(switchStatus === 'off' && {
              'input ~ & > div': {
                flexShrink: 0,
                width: '24px',
                height: '24px',
                backgroundColor: '#8686A1',
                transform: 'translateX(0px)',
                ':after': {
                  content: `url(${Close})`,
                  pl: '6px',
                  position: 'relative',
                  bottom: '1px',
                  left: '1px',
                },
              },
            }),
          }}
        />
        {switchStatus === 'off' && <SwitchLabel status={switchStatus} disabled={props.disabled} />}
      </SwitchWrapper>
    </ThemeUI.Label>
  );
}

function SwitchWrapper({
  status,
  children,
}: {
  status: Option;
  children: React.ReactNode;
}) {
  return (
    <ThemeUI.Flex
      sx={{
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '104px',
        height: '40px',
        borderRadius: '20px',
        p: 2,
        backgroundColor: status === 'on' ? 'white.50' : 'grey.300',
        boxShadow: status === 'on' ? '4dp' : 'none',
        '& > label': {
          flex: '1 1 0px',
          '& > div': {
            mr: '0px',
          },
        },
      }}
    >
      {children}
    </ThemeUI.Flex>
  );
}

function SwitchLabel({ status, disabled }: { status: Option, disabled?: boolean }) {
  return (
    <Box
      as="span"
      sx={{
        flex: '1 1 0px',
        textTransform: 'uppercase',
        fontWeight: 300,
        color: '#4D4D65',
        cursor: disabled ? 'not-allowed' : 'pointer',
      }}
    >
      <Intl.FormattedMessage id={`switch.${status}.label`} />
    </Box>
  );
}
