import * as ReactQuery from 'react-query';
import { identify, makeRequest, Service } from '../../utils/http';
import { InferMutationOptions } from '../../utils/types';
import { QUERY_KEY_NOTIFICATIONS } from './get-all-notifications/hook';

type Data = {
  userId: string;
  data: Parameters<typeof Service.notificationsUserMarkSeen>[1];
};

async function notificationsUserMarkSeenApi({ userId, data }: Data) {
  return makeRequest(
    Service.notificationsUserMarkSeen(userId, data, await identify()),
  );
}

function useNotificationsUserMarkSeen(
  options?: InferMutationOptions<typeof notificationsUserMarkSeenApi>,
) {
  const queryClient = ReactQuery.useQueryClient();
  return ReactQuery.useMutation(notificationsUserMarkSeenApi, {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.refetchQueries(QUERY_KEY_NOTIFICATIONS);
      await options?.onSuccess?.(data, variables, context);
    },
  });
}

export { useNotificationsUserMarkSeen };
