import * as ReactQuery from 'react-query';
import React from 'react';
import { GetTemplatePricesTemplate } from 'trace-backend-sdk';
import { InferQueryOptions } from '../../../utils/types';
import { identify, makeRequest, Service } from '../../../utils/http';

async function getTemplatePricesApi() {
  return makeRequest(Service.getModulePrices(await identify()));
}

const QUERY_KEY_MODULE_PRICES = ['module-prices'];
function useGetTemplatePrices(
  options?: InferQueryOptions<typeof getTemplatePricesApi>,
) {
  const { data, ...rest } = ReactQuery.useQuery(
    QUERY_KEY_MODULE_PRICES,
    getTemplatePricesApi,
    {
      // 5 minutes stale time
      staleTime: 5 * 60 * 1000,
      ...options,
    },
  );

  const paidTemplates = React.useMemo(
    () =>
      data?.templates.reduce((acc, val) => {
        acc[val.id] = val;
        return acc;
      }, {} as Record<string, GetTemplatePricesTemplate>) ?? {},
    [data],
  );

  const freeTemplates = React.useMemo(
    () =>
      data?.freeTemplates?.reduce((acc, val) => {
        acc[val.id] = val;
        return acc;
      }, {} as Record<string, GetTemplatePricesTemplate>) ?? {},
    [data],
  );

  const sortedPaidTemplates = React.useMemo(() => {
    const quickCapture = data?.templates.find(
      (template) => template.templateId === 'quick-capture',
    );
    const pressureTest = data?.templates.find(
      (template) => template.templateId === 'pressure-test',
    );
    const relining = data?.templates.find(
      (template) => template.templateId === 'relining',
    );
    const otherTemplates = data?.templates.filter(
      (template) =>
        template.templateId !== 'quick-capture'
        && template.templateId !== 'pressure-test'
        && template.templateId !== 'relining',
    );
    const sortedTemplates = [] as GetTemplatePricesTemplate[];
    if (quickCapture) sortedTemplates.push(quickCapture);
    if (pressureTest) sortedTemplates.push(pressureTest);
    if (relining) sortedTemplates.push(relining);
    if (otherTemplates) sortedTemplates.push(...otherTemplates);
    return sortedTemplates;
  }, [data]);

  const templateIdToTemplate = { ...paidTemplates, ...freeTemplates };

  return {
    baseTemplate: data?.baseTemplate,
    templatePrices: data,
    templateIdToTemplate,
    paidTemplates: sortedPaidTemplates ?? [],
    freeTemplates: data?.freeTemplates ?? [],
    ...rest,
  };
}

export { useGetTemplatePrices };
