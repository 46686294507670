import React from 'react';
import * as ThemeUI from 'theme-ui';

/*
 * Use whenever you want to avoid focusing the first
 * element in an accessible popup, while keeping the
 * accessibility for focusing the other elements.
 * */
export function FocusThief() {
  return (
    <ThemeUI.Input
      type="radio"
      sx={{
        width: 0,
        height: 0,
        p: 0,
        m: 0,
        border: 'none',
      }}
    />
  );
}
