import { sanitize, makeRequest, Service, identify } from 'utils/http';
import { UpdateUserWorkspaceRequest } from 'trace-backend-sdk';

type GetUpdateUserApiType = {
  userID: string;
  userData: UpdateUserWorkspaceRequest;
};

export async function updateUserAPI(data: GetUpdateUserApiType) {
  return makeRequest(
    Service.updateWorkspaceUser(
      data.userID,
      // @ts-expect-error Need to sanitize input, but it returns Dictionary<unknown>
      sanitize(data.userData),
      await identify(),
    ),
  );
}
