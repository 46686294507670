import React from 'react';
import { Box, BoxProps, Flex } from 'components/layout';
import { Text, TextProps } from 'components/typography';

export function NewDocumentButton({ children }: { children: React.ReactNode }) {
  return (
    <Flex
      sx={{
        alignItems: 'center',
        mt: 6,
        pl: 1,
        borderRadius: '42px',
        border: '0.5px solid',
        borderColor: 'grey.300',
        px: 3,
        py: 2,
        width: 300,
      }}
    >
      {children}
    </Flex>
  );
}

export function NewDocumentButtonContent({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Box
      sx={{
        flex: '1 1 auto',
        lineHeight: 2,
        width: 'fit-content',
        ml: 5,
        mr: 5,
      }}
    >
      {children}
    </Box>
  );
}

export const NewDocumentButtonTitle = (props: TextProps) => (
  <Text
    {...props}
    sx={{
      fontWeight: 500,
      color: 'darkgrey.800',
    }}
    as="dt"
  />
);
export const NewDocumentIconWrapper = (props: BoxProps) => (
  <Box
    {...props}
    sx={{
      height: 62,
      minWidth: 42,
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  />
);
