import React from 'react';
import * as ReactRouter from 'react-router-dom';
import * as Icons from 'components/icons';
import * as Intl from 'lib/intl';
import { DocumentTemplateId } from 'services/documents';
import { TemplateIdEnum } from 'trace-backend-sdk';
import { Box, Container } from 'components/layout';
import { Text } from 'components/typography';
import { Routing } from 'global/routing';
import { InlineLoadingIndicator } from 'components/LoadingIndicator';
import { Spacer } from 'components/Spacer/Spacer';
import { Authorization } from 'components/authorization/Authorization';
import { WorkspaceService } from 'services/workspace';
import { Switch } from 'components/form-elements/buttons/Switch';
import { Button } from 'components/form-elements';
import { ManageModulesCard } from '../../payments/components/ManageModules/ManageModulesCard';
import { PaymentsService } from '../../payments';

import { useUpdateModulePermissions } from '../utils/use-update-module-permissions';

export const PERMISSIONS_DOCUMENT_ICON: Record<
  DocumentTemplateId,
  React.ReactNode
> = {
  'pressure-test': (
    <Icons.PressureTestSplash
      size={47}
      outlineColor="secondary.500"
      splashColor="secondary.050"
    />
  ),
  relining: <Icons.ReliningSplash size={47} />,
  'quick-capture': <Icons.QuickCaptureSplash size={47} />,
};

export function UserPermissionsPage() {
  const intl = Intl.useIntl();
  const { paidTemplates, isLoading } = PaymentsService.useGetTemplatePrices();
  const { workspaceData } = WorkspaceService.useGetWorkspace();
  const {
    modulePermissions,
    onChangeHandler,
    onSaveHandler,
    isUpdatingPermissions,
  } = useUpdateModulePermissions();

  // if (grantingPermissions) return <PageLoadingIndicator/>

  return (
    <Authorization
      roles={['admin']}
      renderOnAuthFail={<ReactRouter.Redirect to={Routing.HOME.getPath()} />}
    >
      <Container
        variant="spacer"
        sx={{
          px: 6,
          flexGrow: 1,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Text
          as="h1"
          variant="headline2"
          intlId="permissions.products.manage.title"
        />

        <Text
          as="p"
          variant="body2"
          intlId="permissions.products.manage.description"
        />

        <Box sx={{ my: 7, mx: -2 }}>
          {isLoading && <InlineLoadingIndicator />}

          {paidTemplates.map((price, index) => {
            return (
              <React.Fragment key={price.id}>
                <ManageModulesCard
                  currency={workspaceData ? workspaceData.currency : 'sek'}
                  key={price.id}
                  ModuleIcon={
                    PERMISSIONS_DOCUMENT_ICON[
                      price.templateId as DocumentTemplateId
                    ]
                  }
                  pricePerCertificate={
                    price.unitAmountDecimal
                      ? parseInt(price.unitAmountDecimal, 10)
                      : 0
                  }
                  moduleName={intl.formatMessage({
                    id: ['template', price.templateId].join('.'),
                  })}
                  moduleModalText={intl.formatMessage({
                    id: ['module', 'modal', price.templateId].join('.'),
                  })}
                >
                  <Switch
                    variant="defaultSwitch"
                    checked={modulePermissions.includes(
                      price.templateId as TemplateIdEnum,
                    )}
                    value={price.templateId}
                    onChange={onChangeHandler}
                  />
                </ManageModulesCard>

                {index !== paidTemplates.length - 1 && <Spacer y={4} />}
              </React.Fragment>
            );
          })}
        </Box>
        <Button
          intlId="profile.submitButton"
          sx={{ mt: 'auto' }}
          onClick={onSaveHandler}
          isLoading={isUpdatingPermissions}
        />
      </Container>
    </Authorization>
  );
}
