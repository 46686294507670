/* eslint-disable max-len */
import React from 'react';
import * as psl from 'psl';
import { Helmet } from 'react-helmet';
import { Box, Container } from '../../components/layout';
import * as Icons from '../../components/icons';
import { Text } from '../../components/typography';
import { Button } from '../../components/form-elements';
import { useRollbar } from '../../lib/rollbar';
import { LOCAL_STORAGE_KEYS, useLocalStorage } from '../local-storage';
import { GOOGLE_ANALYTICS_ID } from './use-google-analytics-routing';

/*
 * We need to check for subdomain to display the cookie consent banner
 * only when the user reaches the subdomain for their organization,
 * rather than showing it twice: once for the application without a subdomain, for example "trace-develop.com"
 * and once for the application with a subdomain, for example "org.trace-develop.com"
 * */
function useIsValidSubdomain() {
  const rollbar = useRollbar();

  const getIsValidSubdomain = React.useCallback<() => boolean>(() => {
    const parseResult = psl.parse(window.location.hostname);

    if (parseResult.error) {
      rollbar.error({
        message: parseResult.error.message,
        code: parseResult.error.code,
        description:
          'An error occurred while parsing the URL in order to obtain the subdomain',
      });

      return false;
    }

    return parseResult.tld === 'localhost'
      ? false
      : parseResult.subdomain != null;
  }, [rollbar]);

  return React.useMemo(
    () => ({ isValidSubdomain: getIsValidSubdomain() }),
    [getIsValidSubdomain],
  );
}

function clearCookies() {
  try {
    document.cookie.split(';').forEach((cookie) => {
      document.cookie = cookie
        .replace(/^ +/, '')
        .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
    });
  } catch {
    // try/catch in case something fails in different browsers
  }
}

function useTrackingConsent(localStorageKey: string) {
  const [hasConsented, setHasConsented] = useLocalStorage<boolean>(localStorageKey);

  React.useEffect(() => {
    if (!hasConsented) {
      clearCookies();
    }
  }, [hasConsented]);

  return React.useMemo(
    () => ({
      hasConsented,
      denyConsent: () => setHasConsented(false),
      allowConsent: () => setHasConsented(true),
    }),
    [setHasConsented, hasConsented],
  );
}

export type ScriptProps = Partial<
  Pick<HTMLScriptElement, 'type' | 'async' | 'src' | 'innerHTML' | 'defer'>
>;

function RenderTrackingScripts({
  shouldRender,
  scripts,
}: {
  shouldRender: boolean;
  scripts: ScriptProps[];
}) {
  return <Helmet script={shouldRender ? scripts : []} />;
}

const ANALYTICS_SCRIPTS = [
  {
    type: 'text/javascript',
    async: true,
    src: `https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_ID}`,
  },
  {
    type: 'text/javascript',
    innerHTML: `
              window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${GOOGLE_ANALYTICS_ID}');
            `,
  },
  {
    type: 'text/javascript',
    innerHTML: `
              window._mfq = window._mfq || [];
              (function() {
                var mf = document.createElement("script");
                mf.type = "text/javascript";
                mf.defer = true;
                mf.src = "//cdn.mouseflow.com/projects/d50c14dc-e39e-42fc-92fc-6deef0a32bf4.js";
                document.getElementsByTagName("head")[0].appendChild(mf);
              })();
            `,
  },
];

export function CookieConsentBanner() {
  const { hasConsented, allowConsent, denyConsent } = useTrackingConsent(
    LOCAL_STORAGE_KEYS.COOKIE_CONSENT,
  );
  const { isValidSubdomain } = useIsValidSubdomain();

  return (
    <React.Fragment>
      {hasConsented == null && isValidSubdomain && (
        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            width: ['auto', 'auto', '80%'],
            m: 'auto',
            backgroundColor: 'primary.900',
            py: 8,
            px: 10,
            borderTopLeftRadius: 1,
            borderTopRightRadius: 1,
          }}
        >
          <Container variant="maxWidthWrapper">
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <Box
                sx={{
                  alignSelf: 'flex-start',
                  minWidth: 'fit-content',
                  mr: 5,
                  position: 'relative',
                  left: -1,
                }}
              >
                <Icons.Cookie width={36} />
              </Box>

              <Text
                as="p"
                sx={{ color: 'white.50', fontSize: 'md', fontWeight: 300 }}
                intlId="cookieConsent.description"
              />
            </Box>

            <Button
              onClick={() => allowConsent()}
              sx={{ backgroundColor: 'secondary.500', my: 5 }}
              intlId="cookieConsent.allowCookies"
            />

            <Button
              onClick={() => denyConsent()}
              sx={{
                border: '1px solid',
                borderColor: 'secondary.500',
                backgroundColor: 'transparent',
              }}
              intlId="cookieConsent.denyCookies"
            />
          </Container>
        </Box>
      )}

      <RenderTrackingScripts
        shouldRender={hasConsented ?? false}
        scripts={ANALYTICS_SCRIPTS}
      />
    </React.Fragment>
  );
}
