import { Box, BoxProps, Flex, FlexProps } from 'components/layout';
import React from 'react';
import { Text, TextProps } from 'components/typography';
import * as Icons from 'components/icons';

export const DocumentTitleWrapper = (props: FlexProps) => (
  <Flex
    {...props}
    sx={{
      mb: 52,
      alignItems: 'center',
    }}
  />
);

export const DocumentTitleIconWrapper = (props: BoxProps) => (
  <Box
    {...props}
    sx={{
      display: 'inline-flex',
      position: 'relative',
      bottom: -1,
      mr: 3,
    }}
  />
);

export const DocumentTitleDate = (
  props: TextProps & { isDocumentCompleted: boolean },
) => (
  <Flex sx={{ alignItems: 'center' }}>
    <Text
      {...props}
      sx={{
        color: '#9E9EAF',
        fontSize: 'sm',
        fontWeight: 400,
        letterSpacing: 1.25,
        ml: '2px',
        mr: 'auto',
      }}
    >
      {props.children}
    </Text>

    {props.isDocumentCompleted && (
      <Icons.CheckCircleFilled width={16} fill="tertiary1.600" />
    )}
  </Flex>
);

export const DocumentTitleContent = (props: FlexProps) => (
  <Flex
    {...props}
    sx={{
      flexDirection: 'column',
      width: 'auto',
    }}
  />
);

export const DocumentTitleTemplateName = (props: TextProps) => (
  <Text
    as="h1"
    {...props}
    sx={{
      color: '#0079D6',
      fontWeight: 600,
      fontSize: '3xl',
      textTransform: 'capitalize',
    }}
  />
);
