import { FormattedNumber } from 'lib/intl';
import { ComponentProps } from 'react';

type FormattedNumberProps = Omit<
  ComponentProps<typeof FormattedNumber>,
  'value'
>;
type CurrencyProps = FormattedNumberProps & {
  valueCents: number;
};

export function Currency({ valueCents, ...delegated }: CurrencyProps) {
  return (
    <FormattedNumber
      maximumFractionDigits={2}
      style="currency"
      currency="SEK"
      value={valueCents / 100}
      currencyDisplay="code"
      {...delegated}
    />
  );
}
