import { Box, Flex } from 'components/layout';
import * as ReactRouter from 'react-router-dom';
import * as Icons from 'components/icons';
import { Text } from 'components/typography';
import React from 'react';
import { useLocaleDateFormat } from 'utils/hooks/use-locale-date-format';
import { Currency } from 'components/Currency';
import { Spacer } from 'components/Spacer/Spacer';
import { Link } from 'components/links';
import type { Property } from 'csstype';
import { Button } from '../../../../components/form-elements';
import { Routing } from '../../../../global/routing';
import { IntlCustom } from '../../../../lib';
import { BillingPageTitle } from './BillingPageTitle';

type Row = {
  productName: string;
  quantity: number;
  productPriceCents: number;
  productLink?: string;
};

type UpcomingPaymentTableProps = {
  upcomingPaymentDate: string;
  estimatedTotalCents: number;
  currency: string;
  rows: Row[];
  discountPercentage?: number;
  discountAmountCents?: number;
};

export function UpcomingPaymentTable({
  rows,
  currency,
  upcomingPaymentDate,
  estimatedTotalCents,
  discountPercentage = 0,
  discountAmountCents = 0,
}: UpcomingPaymentTableProps) {
  const intl = IntlCustom.useIntl();
  const { localeDateFormat } = useLocaleDateFormat();

  const rowsDescendingByQuantity = rows.sort((a, b) => b.quantity - a.quantity);

  return (
    <TableWrapper>
      <BillingPageTitle>
        {intl.formatMessage({
          id: 'payments.billing.upcomingPayment.title',
        })}
      </BillingPageTitle>

      <Table>
        <TableHead>
          <TableRow>
            <TableColumn>
              {intl.formatMessage({
                id: 'payments.billing.upcomingPayment.productName',
              })}
            </TableColumn>
            <TableColumn textAlign="right">
              {intl.formatMessage({
                id: 'payments.billing.upcomingPayment.productQuantity',
              })}
            </TableColumn>
            <TableColumn textAlign="right">
              {intl.formatMessage({
                id: 'payments.billing.upcomingPayment.productPrice',
              })}
            </TableColumn>
          </TableRow>
        </TableHead>

        <TableBody>
          {rowsDescendingByQuantity.map((row, index) => (
            <TableRow
              backgroundColor={isEvenRow(index) ? 'secondary.050' : '#EEF7FF'}
              color="primary.400"
            >
              <TableColumn>
                {row.productLink ? (
                  <Link to={row.productLink}>{row.productName}</Link>
                ) : (
                  row.productName
                )}
              </TableColumn>
              <TableColumn textAlign="right">{row.quantity}</TableColumn>
              <TableColumn textAlign="right">
                <Currency
                  valueCents={row.productPriceCents}
                  currency={currency}
                />
              </TableColumn>
            </TableRow>
          ))}
        </TableBody>

        <TableFooter>
          <TableRow backgroundColor="#3192F1" color="white.50">
            <TableColumn colSpan="2">
              {intl.formatMessage({
                id: 'payments.billing.upcomingPayment.productTotalPrice',
              })}
            </TableColumn>

            <TableColumn textAlign="right">
              <Currency
                maximumFractionDigits={0}
                valueCents={estimatedTotalCents}
                currency={currency}
              />
            </TableColumn>
          </TableRow>

          <React.Fragment>
            <TableRow backgroundColor="secondary.050" color="primary.400">
              <TableColumn>
                {intl.formatMessage({
                  id: 'payments.billing.upcomingPayment.discountPercentage',
                })}
              </TableColumn>
              <TableColumn textAlign="right">{discountPercentage}%</TableColumn>
              <TableColumn textAlign="right">
                <Currency
                  maximumFractionDigits={2}
                  valueCents={discountAmountCents}
                  currency={currency}
                />
              </TableColumn>
            </TableRow>

            <TableRow backgroundColor="primary.900" color="white.50">
              <TableColumn colSpan="2">
                <TableRow backgroundColor="primary.900" color="white.50">
                  {intl.formatMessage({
                    id: 'payments.billing.upcomingPayment.discountTotalPrice',
                  })}
                </TableRow>
                <TableRow backgroundColor="primary.900" color="white.50">
                  {intl.formatMessage({
                    id: 'payments.excludingVat',
                  })}
                </TableRow>
              </TableColumn>

              <TableColumn textAlign="right">
                <Currency
                  maximumFractionDigits={2}
                  valueCents={estimatedTotalCents - discountAmountCents}
                  currency={currency}
                />
              </TableColumn>
            </TableRow>
          </React.Fragment>
        </TableFooter>
      </Table>

      <ButtonWrapper>
        <Button
          as={ReactRouter.Link}
          // @ts-expect-error Cast error
          to={Routing.PAYMENTS.Billing.DiscountSchedule.path}
          intlId="payments.billing.upcomingPayment.buttonLabel"
          prependIcon={<Icons.Discount />}
        />
      </ButtonWrapper>

      <Note>
        {intl.formatMessage({
          id: 'payments.billing.upcomingPayment.note',
        })}

        <Spacer x={2} />

        {localeDateFormat(upcomingPaymentDate)}
      </Note>
    </TableWrapper>
  );
}

function TableWrapper({ children }: { children: React.ReactNode }) {
  return <Flex flexDirection="column">{children}</Flex>;
}

function Table({ children }: { children: React.ReactNode }) {
  return (
    <Box
      as="table"
      sx={{
        borderCollapse: 'collapse',
        boxShadow: '4dp',
      }}
    >
      {children}
    </Box>
  );
}

function TableHead({ children }: { children: React.ReactNode }) {
  return (
    <Box
      as="thead"
      sx={{
        color: 'black',
        fontWeight: 500,
        fontSize: 'lg',
      }}
    >
      {children}
    </Box>
  );
}

function TableBody({ children }: { children: React.ReactNode }) {
  return (
    <Box as="tbody" sx={{ fontSize: 'md' }}>
      {children}
    </Box>
  );
}

function TableRow({
  children,
  backgroundColor = 'white.50',
  color = 'black',
}: {
  children: React.ReactNode;
  backgroundColor?: string;
  color?: string;
}) {
  return (
    <Box
      as="tr"
      sx={{
        backgroundColor,
        color,
      }}
    >
      {children}
    </Box>
  );
}

function TableColumn({
  children,
  textAlign = 'left',
  colSpan,
}: {
  children?: React.ReactNode;
  textAlign?: Property.TextAlign;
  colSpan?: string;
}) {
  return (
    <Box
      //@ts-expect-error cast error
      colSpan={colSpan}
      as="td"
      sx={{
        py: '13px',
        px: '14px',
        textAlign,
      }}
    >
      {children}
    </Box>
  );
}

function TableFooter({ children }: { children?: React.ReactNode }) {
  return (
    <Box as="tfoot" sx={{ fontSize: 'lg' }}>
      {children}
    </Box>
  );
}

function Note({ children }: { children?: React.ReactNode }) {
  return (
    <Text
      as="p"
      sx={{
        mt: 4,
        pr: 3,
        textAlign: 'right',
        fontSize: 'md',
        color: 'darkgrey.800',
      }}
    >
      {children}
    </Text>
  );
}

function ButtonWrapper({ children }: { children: React.ReactNode }) {
  return (
    <Flex
      flexDirection="column"
      sx={{ mt: 5, fontWeight: 400, letterSpacing: '1px' }}
    >
      {children}
    </Flex>
  );
}

function isEvenRow(index: number) {
  return index % 2 === 0;
}
