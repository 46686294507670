import * as React from 'react';
import * as ReactRouter from 'react-router-dom';
import * as Rhf from 'react-hook-form';
import { Forms } from 'components/form-elements';
import { Box, Container, Flex } from 'components/layout';
import { Text } from 'components/typography';
import * as Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { UsersService } from 'services/users';
import { Routing } from 'global/routing';
import AvatarEditor from 'react-avatar-editor';
import { IconButton } from 'components/form-elements/buttons/IconButton';
import * as Icons from 'components/icons';
import { Input } from 'theme-ui';
import { Notification } from 'components/Notification/Notification';
import { ZoomFileInput } from 'pages/Settings/ZoomFileInput';
import { useNotifications } from 'components/notifications/notifications';

const ALLOWED_IMAGE_TYPES = ['image/png', 'image/jpeg'];

const schema = Joi.object({
  firstName: Joi.string().required().max(20).messages({
    'string.empty': 'profile.firstName.required',
    'string.max':
      'createAccount.form.input.firstName.error.maxCharacters.message',
  }),
  lastName: Joi.string().required().max(20).messages({
    'string.empty': 'profile.lastName.required',
    'string.max':
      'createAccount.form.input.lastName.error.maxCharacters.message',
  }),
  title: Joi.string().allow(null, '').optional(),
});

const ZOOM_MIN = 0.5;
const ZOOM_MAX = 10;
const ZOOM_STEP = 0.1;

export function isImageSameAsCurrent(
  image: File | string | undefined,
): image is string {
  return typeof image === 'string';
}

export function ProfileEdit() {
  const notifications = useNotifications();
  const history = ReactRouter.useHistory();

  const { loggedInUser } = UsersService.useGetLoggedInUser({
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      if (image == null) {
        setImage(`${data.userImageUrl}?v=${Date.now()}`);
      }
      formMethods.reset({
        firstName: data.user.firstName,
        lastName: data.user.lastName,
        title: data.title,
      });
      setImage(data.userImageUrl);
    },
  });

  const formMethods = Rhf.useForm({
    defaultValues: {
      firstName: loggedInUser?.user.firstName ?? '',
      lastName: loggedInUser?.user.lastName ?? '',
      title: loggedInUser?.title ?? '',
    },
    resolver: joiResolver(schema),
  });

  const { isDirty } = Rhf.useFormState({
    control: formMethods.control,
  });

  const avatarEditorRef = React.useRef<AvatarEditor | null>();
  const [image, setImage] = React.useState<File | string>();
  const [currentZoom, setCurrentZoom] = React.useState(1);

  const isInvalidFileTypeError = image
    && typeof image !== 'string'
    && !ALLOWED_IMAGE_TYPES.includes(image.type);

  const showZoomFileInput = image && !isImageSameAsCurrent(image) && !isInvalidFileTypeError;

  const { updateLoggedInUser, isLoading: isUpdateUserLoading } = UsersService.useUpdateLoggedInUser();

  const { updateUserProfileImage, isLoading: isUpdateUserProfileImageLoading } = UsersService.useUpdateUserProfileImage({
    onSuccess: () => {
      notifications.success({
        description: 'profile.edit.success',
        durationMs: 'Infinity',
        tag: Routing.PROFILE.View,
      });
    },
  });

  function onUpdateImage() {
    try {
      avatarEditorRef.current?.getImageScaledToCanvas().toBlob((blob) => {
        if (!blob) {
          return;
        }

        const croppedImage = new File([blob as BlobPart], 'profileImage', {
          type: typeof image !== 'string' ? image?.type : blob.type,
        });

        if (!isImageSameAsCurrent(image)) {
          updateUserProfileImage({
            profileImage: croppedImage,
          });
        }
      });
      // Hack to prevent "getImage" from crashing
      // the app when there is no image
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  return (
    <Box
      sx={{
        height: '100%',
        backgroundColor: 'white.50',
        pt: 4,
      }}
    >
      <Container variant="spacer">
        <Forms.Provider
          variant="layout.avatarForm"
          onValid={(data) => {
            updateLoggedInUser(
              {
                firstName: data.firstName,
                lastName: data.lastName,
                title: data.title,
              },
              {
                onSuccess: () => {
                  onUpdateImage();
                  history.push(Routing.PROFILE.View);
                },
              },
            );
          }}
          name="profile"
          {...formMethods}
        >
          <Flex
            sx={{
              position: 'relative',
              maxWidth: '116px',
              flexDirection: 'column',
              mb: 4,
            }}
          >
            <AvatarEditor
              crossOrigin="anonymous"
              image={image ?? ''}
              width={100}
              height={100}
              color={[219, 219, 224]} // RGBA
              scale={currentZoom}
              rotate={0}
              border={8}
              style={{
                borderRadius: '23px',
                pointerEvents: typeof image === 'string' ? 'none' : 'auto',
              }}
              borderRadius={17}
              ref={(value) => {
                avatarEditorRef.current = value;
              }}
            />
            <IconButton
              sx={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                outline: 'none',
                '.a': {
                  fill: 'tertiary2.800',
                  opacity: '0.8',
                },
                '.b': { fill: 'white.50' },
              }}
            >
              <Icons.Camera width="60px" />
            </IconButton>

            <Input
              onChange={(event) => {
                const image = event.target.files?.item(0);

                if (!image) {
                  return;
                }

                setImage(image);
              }}
              accept="image/png, image/jpeg"
              name="image"
              type="file"
              sx={{
                position: 'absolute',
                opacity: 0,
                p: 0,
                m: 0,
                border: 'none',
                top: '70px',
                left: '70px',
                width: '60px',
                height: '50%',
                cursor: 'pointer',
                '&::-webkit-file-upload-button': {
                  width: '60px',
                  height: '60px',
                  cursor: 'pointer',
                },
              }}
            />
          </Flex>

          {showZoomFileInput && (
            <ZoomFileInput
              min={ZOOM_MIN}
              max={ZOOM_MAX}
              step={ZOOM_STEP}
              disabled={isInvalidFileTypeError || isImageSameAsCurrent(image)}
              zoomValue={currentZoom}
              onChange={(e) => {
                setCurrentZoom(Number(e.target.value));
              }}
            >
              <IconButton
                disabled={isInvalidFileTypeError || isImageSameAsCurrent(image)}
                variant="zoom"
                onClick={() => {
                  setCurrentZoom((currentValue) =>
                    Math.max(currentValue - ZOOM_STEP, ZOOM_MIN));
                }}
                sx={{
                  color:
                    isInvalidFileTypeError || isImageSameAsCurrent(image)
                      ? 'grey.400'
                      : 'secondary.500',
                }}
              >
                -
              </IconButton>

              <IconButton
                disabled={isInvalidFileTypeError || isImageSameAsCurrent(image)}
                variant="zoom"
                onClick={() => {
                  setCurrentZoom((currentValue) =>
                    Math.min(currentValue + ZOOM_STEP, ZOOM_MIN));
                }}
                sx={{
                  color:
                    isInvalidFileTypeError || isImageSameAsCurrent(image)
                      ? 'grey.400'
                      : 'secondary.500',
                }}
              >
                +
              </IconButton>
            </ZoomFileInput>
          )}

          <Forms.FieldEditText
            required
            isAlwaysFloating
            name="firstName"
            labelIntlId="profile.firstName.label"
          />

          <Forms.FieldEditText
            required
            isAlwaysFloating
            name="lastName"
            labelIntlId="profile.lastName.label"
          />

          <Forms.FieldEditText
            isAlwaysFloating
            name="title"
            labelIntlId="profile.occupation.label"
          />

          {isInvalidFileTypeError && (
            <Notification sx={{ mt: 2 }} severity="error">
              <Notification.Description>
                <Text intlId="settings.fileUpload.invalid" />
              </Notification.Description>
            </Notification>
          )}

          <Forms.SubmitButton
            isLoading={isUpdateUserLoading || isUpdateUserProfileImageLoading}
            sx={{ mt: isInvalidFileTypeError ? 2 : 3 }}
            disabled={
              isInvalidFileTypeError
              || (!isDirty && isImageSameAsCurrent(image))
            }
          >
            <Text intlId="profile.submitButton" />
          </Forms.SubmitButton>
        </Forms.Provider>
      </Container>
    </Box>
  );
}
