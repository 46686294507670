import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';

/*
 * Manually create a cognito user and cognito
 * user session object from given session parameters.
 * */
export function buildCognitoUserSession(payload: {
  userPoolId: string;
  clientId: string;
  idToken: string;
  accessToken: string;
  refreshToken: string;
}) {
  const idToken = new CognitoIdToken({
    IdToken: payload.idToken,
  });

  return {
    cognitoUserSession: new CognitoUserSession({
      IdToken: idToken,
      AccessToken: new CognitoAccessToken({
        AccessToken: payload.accessToken,
      }),
      RefreshToken: new CognitoRefreshToken({
        RefreshToken: payload.refreshToken,
      }),
    }),

    cognitoUser: new CognitoUser({
      Username: idToken.payload.sub,
      Pool: new CognitoUserPool({
        UserPoolId: payload.userPoolId,
        ClientId: payload.clientId,
      }),
    }),
  };
}
