import { z } from 'zod';
import { ValidationSchemas } from 'utils/validation';
import * as Rhf from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Forms } from 'components/form-elements';
import React from 'react';
import { Text } from 'components/typography';
import * as ReactIntl from 'lib/intl';
import { AdminService } from 'services';
import { Routing } from 'global/routing';
import { Box, Flex } from 'components/layout';
import { Divider } from 'theme-ui';
import * as Icons from 'components/icons';
import { IconButton } from 'components/form-elements/buttons/IconButton';
import { useHistory } from 'react-router-dom';
import { useNotifications } from 'components/notifications/notifications';
import {
  AdminUpdateWorkspaceRequestStatusEnum,
  WorkspaceStatusEnum,
} from 'trace-backend-sdk';
import { RelativeLoadingIndicator } from '../../../../components/PageLoadingIndicator/PageLoadingIndicator';
import { getSupportedCountries } from '../../../../utils/intl/get-supported-countries';

const schema = z.object({
  street: ValidationSchemas.Company.STREET,
  city: ValidationSchemas.Company.CITY,
  country: ValidationSchemas.Company.COUNTRY,
  zip: ValidationSchemas.Company.ZIP_CODE,
  website: ValidationSchemas.Company.WEBSITE,
  status: ValidationSchemas.Common.WORKSPACE_STATUS,
});

export function AdminWorkspace() {
  const { workspaceId } = Routing.ADMIN.useWorkspaceParams();
  const intl = ReactIntl.useIntl();
  const history = useHistory();
  const notifications = useNotifications();

  const { workspace, isLoading } = AdminService.useAdminGetWorkspace(
    workspaceId,
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        formMethods.reset({
          city: data.city,
          country: data.country,
          street: data.street,
          zip: data.zip,
          website: data.website,
          // @ts-expect-error Enum types are not reusable on BE for now
          status: data.status,
        });
      },
    },
  );

  const formMethods = Rhf.useForm<z.infer<typeof schema>>({
    defaultValues: {
      website: workspace?.website ?? '',
      country: workspace?.country ?? '',
      city: workspace?.city ?? '',
      street: workspace?.street ?? '',
      zip: workspace?.zip ?? '',
      // @ts-expect-error Enum types are not reusable on BE for now
      status: workspace?.status,
    },
    resolver: zodResolver(schema),
  });

  const { isDirty } = formMethods.formState;

  const { adminUpdateWorkspace, isLoading: isUpdatingWorkspace } = AdminService.useAdminUpdateWorkspace({
    onSuccess: (data, variables) => {
      formMethods.reset({
        website: variables.website,
        country: variables.country,
        city: variables.city,
        street: variables.street,
        zip: variables.zip,
        // @ts-expect-error Enum types are not reusable on BE for now
        status: variables.status as unknown as WorkspaceStatusEnum,
      });

      notifications.success({
        description: 'admin-panel.edit-workspace.success',
        intlValues: {
          workspaceName: workspace?.name,
        },
      });
    },
  });

  return (
    <Box
      sx={{
        p: 4,
        px: 8,
        minWidth: 500,
        backgroundColor: 'white.50',
        boxShadow: '2dp',
        ml: 'auto',
        position: 'relative',
      }}
    >
      {isLoading ? (
        <RelativeLoadingIndicator />
      ) : (
        <React.Fragment>
          {workspace?.organizationName && (
            <React.Fragment>
              <Flex sx={{ alignItems: 'center' }}>
                <IconButton
                  sx={{
                    color: 'primary.900',
                    mr: 'auto',
                  }}
                  onClick={() => history.goBack()}
                >
                  <Icons.Back />
                </IconButton>

                <Text
                  as="h1"
                  sx={{
                    color: 'primary.900',
                    fontWeight: 500,
                    ml: 4,
                    fontSize: '2xl',
                  }}
                >
                  {workspace.organizationName}
                </Text>
              </Flex>

              <Divider sx={{ borderColor: 'grey.200' }} />
            </React.Fragment>
          )}

          <Forms.Provider
            {...formMethods}
            name="admin-edit-workspace"
            onValid={(data) => {
              adminUpdateWorkspace({
                street: data.street,
                zip: data.zip,
                website: data.website,
                workspaceId,
                city: data.city,
                country: data.country,
                status:
                  data.status as unknown as AdminUpdateWorkspaceRequestStatusEnum,
              });
            }}
          >
            <Text variant="h2">Workspace details</Text>

            <Forms.FieldEditText
              required
              isAlwaysFloating
              name="street"
              labelIntlId="createWorkspace.form.input.street.label"
            />

            <Forms.FieldEditText
              isAlwaysFloating
              name="zip"
              labelIntlId="createWorkspace.form.input.zip.label"
              required
            />

            <Forms.FieldEditText
              required
              isAlwaysFloating
              name="city"
              labelIntlId="createWorkspace.form.input.city.label"
            />

            <Forms.SelectField
              name="country"
              required
              labelIntlId="createWorkspace.form.input.country.label"
            >
              {getSupportedCountries().map((alpha2) => (
                <option
                  value={alpha2}
                  key={`createWorkspace-country-select.option__${alpha2}`}
                >
                  {intl.formatMessage({ id: `country.${alpha2}` })}
                </option>
              ))}
            </Forms.SelectField>

            <Forms.FieldEditText
              isAlwaysFloating
              name="website"
              labelIntlId="createWorkspace.form.input.website.label"
            />

            <Text sx={{ mt: 4 }} variant="h2">
              Workspace status
            </Text>

            <Forms.SelectField
              name="status"
              required
              labelIntlId="workspace.status.formLabel"
            >
              {Object.values(
                ValidationSchemas.Common.WORKSPACE_STATUS.Values,
              ).map((status) => (
                <option value={status} key={status}>
                  {intl.formatMessage({
                    id: `workspace.status.${status}`,
                  })}
                </option>
              ))}
            </Forms.SelectField>

            <Forms.SubmitButton
              sx={{ mt: 10 }}
              disabled={!isDirty}
              isLoading={isUpdatingWorkspace}
            >
              <Text intlId="settings.button.label" />
            </Forms.SubmitButton>
          </Forms.Provider>
        </React.Fragment>
      )}
    </Box>
  );
}
