/** @jsxImportSource theme-ui */

import React from 'react';
import * as Icons from 'components/icons';
import * as ThemeUI from 'theme-ui';
import { Language } from 'translations';
import { Box, Container } from '../layout';
import { CountryFlagCircle } from '../CountryFlag/CountryFlag';
import { IntlCustom } from '../../lib';
import { Text } from '../typography';
import { getSupportedLanguages } from '../../utils/intl/get-supported-countries';
import { getCountryFromLanguage } from '../../utils/intl/get-country-from-language';

type LanguageSwitcherProps = {
  defaultSelected: Language;
  onLanguageChange?: (language: Language) => void;
};

export function LanguageSwitcher({
  defaultSelected,
  onLanguageChange,
}: LanguageSwitcherProps) {
  const [selectedLanguage, setSelectedLanguage] = React.useState(defaultSelected);
  const intl = IntlCustom.useIntl();

  function onLanguageSelect(newLanguage: Language) {
    onLanguageChange?.(newLanguage);
    setSelectedLanguage(newLanguage);
  }

  return (
    <Container variant="maxWidthWrapper">
      {getSupportedLanguages().map((language) => (
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
          }}
        >
          <ThemeUI.Button
            variant="default"
            onClick={() => onLanguageSelect(language)}
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexGrow: 1,
              py: 6,
              px: 0,
              backgroundColor: 'transparent',
              borderBottom: '1px solid',
              borderColor: 'grey.300',
              borderRadius: '0px',
              position: 'relative',
            }}
          >
            <CountryFlagCircle
              sx={{
                mr: 8,
              }}
              alpha2={getCountryFromLanguage(language).alpha2}
            />

            <Text>{intl.formatMessage({ id: `language.${language}` })}</Text>

            {selectedLanguage === language && (
              <Box
                sx={{
                  position: 'absolute',
                  backgroundColor: 'white.50',
                  width: 36,
                  height: 36,
                  borderRadius: 'rounded',
                  color: 'tertiary1.300',
                  right: 2,

                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Icons.Checkmark width={24} />
              </Box>
            )}
          </ThemeUI.Button>
        </Box>
      ))}
    </Container>
  );
}
