/** @jsxImportSource theme-ui */
import { PopupProps } from 'reactjs-popup/dist/types';
import { StyleObject } from 'theming';
import * as React from 'react';
import { Popup } from 'reactjs-popup';

type BaseModalProps = Omit<PopupProps, 'modal' | 'nested' | 'lockScroll'> & {
  contentSx?: StyleObject;
  overlaySx?: StyleObject;
};

export function BaseModal({
  children,
  overlaySx,
  contentSx,
  ...delegated
}: BaseModalProps) {
  React.useEffect(
    () => () => {
      document.body.style.overflow = 'auto';
    },
    [],
  );

  return (
    <Popup
      {...delegated}
      lockScroll
      sx={{
        '&-overlay': {
          isolation: 'isolate',
          backgroundColor: 'hsl(240, 24%, 40%, 85%)',
          px: [5, 10],
          ...overlaySx,
        },
        '&-content': contentSx,
      }}
      modal
      nested
    >
      {children}
    </Popup>
  );
}
