import React from 'react';
import { Text } from 'components/typography';
import { IntlProps, LocaleKey } from 'translations';
import { StyleObject } from 'theming';

export type HeadlineProps = Omit<IntlProps, 'intlId'> & {
  intlId?: LocaleKey;
  sx?: StyleObject;
};

export const Headline = ({ intlId, intlValues, sx }: HeadlineProps) => (
  <Text
    variant="headline1"
    intlId={intlId}
    intlValues={{
      bold: (message: string) => (
        <Text variant="headline2" sx={{ fontSize: '22px' }}>
          {message}
        </Text>
      ),
      ...intlValues,
    }}
    sx={{
      lineHeight: 2,
      fontSize: '22px',
      ...sx,
    }}
  />
);
