import * as ReactQuery from 'react-query';
import { InferMutationOptions } from '../../../utils/types';
import { deleteDocumentWidgetApi } from './api';

export function useDeleteDocumentWidget(
  options?: InferMutationOptions<typeof deleteDocumentWidgetApi>,
) {
  const { mutate: deleteDocumentWidget, ...rest } = ReactQuery.useMutation(
    'deleteDocumentWidget',
    deleteDocumentWidgetApi,
    options,
  );

  return {
    deleteDocumentWidget,
    ...rest,
  };
}
