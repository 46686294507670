import * as ReactQuery from 'react-query';
import { InferMutationOptions } from 'utils/types';
import { uploadDocumentImageApi } from 'services/documents/upload-document-image/api';

export function useUploadDocumentImage(
  options?: InferMutationOptions<typeof uploadDocumentImageApi>,
) {
  const { mutate, ...rest } = ReactQuery.useMutation(
    'uploadDocumentImage',
    uploadDocumentImageApi,
    options,
  );

  return {
    uploadDocumentImage: mutate,
    ...rest,
  };
}
