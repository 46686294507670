/** @jsxImportSource theme-ui */
import React from 'react';
// @ts-expect-error No type declarations for library
import Flags from 'country-flag-icons/react/3x2';
import { Box } from '../layout';

type CountryFlagProps = {
  alpha2: string;
  width?: number;
  height?: number;
  className?: string;
};

export function CountryFlag(props: CountryFlagProps) {
  const Component = Flags[props.alpha2.toUpperCase()];

  return Component ? (
    <Component
      width={props.width}
      height={props.height}
      className={props.className}
    />
  ) : null;
}

export function CountryFlagCircle(
  props: Pick<CountryFlagProps, 'alpha2' | 'className'>,
) {
  return (
    <Box
      className={props.className}
      sx={{
        width: 32,
        height: 32,
        backgroundColor: 'inherit',
        overflow: 'hidden',
        borderRadius: 'rounded',
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'center',
      }}
    >
      <CountryFlag
        alpha2={props.alpha2}
        sx={{
          minWidth: 48,
        }}
      />
    </Box>
  );
}
