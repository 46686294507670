import * as React from 'react';
import * as ReactRouter from 'react-router-dom';
import * as Rhf from 'react-hook-form';
import { Forms } from 'components/form-elements';
import { WorkspaceService } from 'services/workspace';
import { Container, Flex } from 'components/layout';
import { PageTitle } from 'components/seo/PageTitle';
import { Text } from 'components/typography';
import AvatarEditor from 'react-avatar-editor';
import { IconButton } from 'components/form-elements/buttons/IconButton';
import * as Icons from 'components/icons';
import { Input } from 'theme-ui';
import { Notification } from 'components/Notification/Notification';
import { ZoomFileInput } from 'pages/Settings/ZoomFileInput';
import { Routing } from 'global/routing';
import { ValidationSchemas } from 'utils/validation';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { isImageSameAsCurrent } from '../Profile/components/ProfileEdit/ProfileEdit';
import {
  getSupportedCountries,
  getSupportedLanguages,
} from '../../utils/intl/get-supported-countries';
import { useIntlContext } from '../../translations';
import { IntlCustom } from '../../lib';

const ALLOWED_IMAGE_TYPES = ['image/png', 'image/jpeg'];

const schema = z.object({
  street: ValidationSchemas.Company.STREET,
  city: ValidationSchemas.Company.CITY,
  country: ValidationSchemas.Company.COUNTRY,
  zip: ValidationSchemas.Company.ZIP_CODE,
  website: ValidationSchemas.Company.WEBSITE,
  language: z.string(),
});

const ZOOM_MIN = 0.5;
const ZOOM_MAX = 10;
const ZOOM_STEP = 0.1;

export const Settings = () => {
  const history = ReactRouter.useHistory();
  const intlContext = useIntlContext();
  const intl = IntlCustom.useIntl();

  const { workspaceData } = WorkspaceService.useGetWorkspace({
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      if (image == null) {
        setImage(`${data.imageUrl}?v=${Date.now()}`);
      }
      formMethods.reset({
        city: data.city,
        country: data.country,
        street: data.street,
        website: data.website,
        zip: data.zip,
        language: data.language,
      });
    },
  });

  const formMethods = Rhf.useForm<z.infer<typeof schema>>({
    defaultValues: {
      website: workspaceData?.website ?? '',
      country: workspaceData?.country ?? 'se',
      city: workspaceData?.city ?? '',
      street: workspaceData?.street ?? '',
      zip: workspaceData?.zip ?? '',
      language: workspaceData?.language ?? intlContext.activeLanguage,
    },
    resolver: zodResolver(schema),
  });

  const { isDirty } = Rhf.useFormState({ control: formMethods.control });

  const avatarEditorRef = React.useRef<AvatarEditor | null>();
  /**
   * The image can be:
   * 1. A "string", when it points to the URL of
   *  the currently uploaded workspace image
   * 2. A "File", when a new image is being uploaded from the user's computer
   * */
  const [image, setImage] = React.useState<File | string>();
  const [currentZoom, setCurrentZoom] = React.useState(1);

  const { isLoading: isUpdateWorkspaceImageLoading, updateWorkspaceImage } = WorkspaceService.useUpdateWorkspaceImage();
  const { isLoading: isUpdateWorkspaceLoading, updateWorkspace } = WorkspaceService.useUpdateWorkspace();

  async function onUpdateImage() {
    try {
      avatarEditorRef.current?.getImageScaledToCanvas().toBlob(async (blob) => {
        if (!blob) {
          return;
        }

        if (blob) {
          updateWorkspaceImage({
            image: new File([blob], 'workspaceImage', {
              type: typeof image !== 'string' ? image?.type : blob.type,
            }),
          });
        }
      });
      // Hack to prevent `getImage` from causing an error out when
      // there is no image at the provided URL.
      // https://github.com/mosch/react-avatar-editor/issues/334
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  if (!image) {
    return null;
  }

  /**
   * If the image is a string, then it must be a URL,
   * meaning that its not different than the current uploaded image
   * */
  const isInvalidFileTypeError = typeof image !== 'string' && !ALLOWED_IMAGE_TYPES.includes(image.type);
  const showZoomFileInput = !isImageSameAsCurrent(image) && !isInvalidFileTypeError;

  return (
    <Container variant="spacer" sx={{ px: 2, py: 0 }}>
      <Forms.Provider
        sx={{
          px: 4,
          py: 8,
          width: 'auto',
          gap: 5,
        }}
        {...formMethods}
        name="editWorkspace"
        onValid={(data) => {
          updateWorkspace(
            {
              city: data.city,
              country: data.country,
              street: data.street,
              zip: data.zip,
              website: data.website,
              language: data.language,
            },
            {
              async onSuccess() {
                await onUpdateImage();
                history.push(Routing.HOME.getPath());
              },
            },
          );
        }}
      >
        <PageTitle titleIntl="page.menu.main.settings" />

        <Flex
          sx={{
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            mb: 7,
            gap: 5,
          }}
        >
          <Flex
            sx={{
              position: 'relative',
              maxWidth: '116px',
              flexShrink: 0,
              borderRadius: '30px',
              backgroundColor: 'white.50',
              marginBottom: 2,
            }}
          >
            <AvatarEditor
              crossOrigin="anonymous"
              image={image}
              width={100}
              height={100}
              color={[219, 219, 224]} // RGBA
              scale={currentZoom}
              rotate={0}
              border={8}
              style={{
                borderRadius: '30px',
                pointerEvents: typeof image === 'string' ? 'none' : 'auto',
              }}
              borderRadius={25}
              ref={(value) => {
                avatarEditorRef.current = value;
              }}
            />
            <IconButton
              sx={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                outline: 'none',
                '.a': {
                  fill: 'tertiary2.800',
                  opacity: '0.8',
                },
                '.b': { fill: 'white.50' },
              }}
            >
              <Icons.Camera width="60px" />
            </IconButton>

            <Input
              onChange={(event) => {
                const image = event.target.files?.item(0);

                if (!image) {
                  return;
                }

                setImage(image);
              }}
              accept="image/png, image/jpeg"
              name="image"
              type="file"
              sx={{
                position: 'absolute',
                opacity: 0,
                p: 0,
                m: 0,
                border: 'none',
                top: '70px',
                left: '70px',
                width: '60px',
                height: '50%',
                cursor: 'pointer',
                '&::-webkit-file-upload-button': {
                  width: '60px',
                  height: '60px',
                  cursor: 'pointer',
                },
              }}
            />
          </Flex>

          <Text
            intlId="settings.permissions.media.denied"
            sx={{
              opacity: 0.5,
              fontSize: 'md',
              paddingX: 4,
              lineHeight: 1.4,
            }}
          />

          {workspaceData && (
            <Text
              sx={{
                fontWeight: 500,
                fontSize: '3xl',
                textAlign: 'center',
              }}
            >
              {workspaceData.organizationName}
            </Text>
          )}
        </Flex>

        {showZoomFileInput && (
          <ZoomFileInput
            min={ZOOM_MIN}
            max={ZOOM_MAX}
            step={ZOOM_STEP}
            disabled={isInvalidFileTypeError || isImageSameAsCurrent(image)}
            zoomValue={currentZoom}
            onChange={(e) => {
              setCurrentZoom(Number(e.target.value));
            }}
          >
            <IconButton
              disabled={isInvalidFileTypeError || isImageSameAsCurrent(image)}
              variant="zoom"
              onClick={() => {
                setCurrentZoom((currentValue) =>
                  Math.max(currentValue - ZOOM_STEP, ZOOM_MIN));
              }}
              sx={{
                color:
                  isInvalidFileTypeError || isImageSameAsCurrent(image)
                    ? 'grey.400'
                    : 'secondary.500',
              }}
            >
              -
            </IconButton>

            <IconButton
              disabled={isInvalidFileTypeError || isImageSameAsCurrent(image)}
              variant="zoom"
              onClick={() => {
                setCurrentZoom((currentValue) =>
                  Math.min(currentValue + ZOOM_STEP, ZOOM_MAX));
              }}
              sx={{
                color:
                  isInvalidFileTypeError || isImageSameAsCurrent(image)
                    ? 'grey.400'
                    : 'secondary.500',
              }}
            >
              +
            </IconButton>
          </ZoomFileInput>
        )}

        <Forms.FieldEditText
          required
          isAlwaysFloating
          name="street"
          labelIntlId="createWorkspace.form.input.street.label"
        />

        <Forms.FieldEditText
          isAlwaysFloating
          name="zip"
          labelIntlId="createWorkspace.form.input.zip.label"
          required
        />

        <Forms.FieldEditText
          required
          isAlwaysFloating
          name="city"
          labelIntlId="createWorkspace.form.input.city.label"
        />

        <Forms.SelectField
          name="country"
          required
          labelIntlId="createWorkspace.form.input.country.label"
        >
          {getSupportedCountries().map((alpha2) => (
            <option
              value={alpha2}
              key={`createWorkspace-country-select.option__${alpha2}`}
            >
              {intl.formatMessage({ id: `country.${alpha2}` })}
            </option>
          ))}
        </Forms.SelectField>

        <Forms.SelectField
          name="language"
          required
          labelIntlId="createWorkspace.form.input.language"
          helperText={intl.formatMessage({
            id: 'editWorkspace.form.input.language.helperText',
          })}
        >
          {getSupportedLanguages().map((language) => (
            <option value={language} key={language}>
              {intl.formatMessage({ id: `language.${language}` })}
            </option>
          ))}
        </Forms.SelectField>

        <Forms.FieldEditText
          isAlwaysFloating
          name="website"
          labelIntlId="createWorkspace.form.input.website.label"
        />

        <Flex
          sx={{
            width: '100%',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {isInvalidFileTypeError && (
            <Notification sx={{ mb: 2, maxWidth: '338px' }} severity="error">
              <Notification.Description>
                <Text intlId="settings.fileUpload.invalid" />
              </Notification.Description>
            </Notification>
          )}

          <Forms.SubmitButton
            sx={{
              py: 3,
              mt: 4,
            }}
            isLoading={
              isUpdateWorkspaceLoading || isUpdateWorkspaceImageLoading
            }
            disabled={
              isInvalidFileTypeError
              || (!isDirty && isImageSameAsCurrent(image))
            }
          >
            <Text intlId="settings.button.label" />
          </Forms.SubmitButton>
        </Flex>
      </Forms.Provider>
    </Container>
  );
};
