import * as ReactQuery from 'react-query';
import { InferMutationOptions } from 'utils/types';
import { ErrorType } from 'utils/http';
import { resendInvitationAPI } from './api';

export function useResendInvitation(
  options?: InferMutationOptions<typeof resendInvitationAPI>,
) {
  const { mutate: resendInvitation, ...rest } = ReactQuery.useMutation(
    'resend-invitation',
    resendInvitationAPI,
    options,
  );

  return {
    resendInvitation,
    ...rest,
    error: rest.error as ErrorType,
  };
}
