import * as React from 'react';
import { utils, WidgetProps } from '@rjsf/core';
import { useIntl } from 'lib/intl';
import { Textarea } from '../../../Textarea/Textarea';
import { LocaleKey } from '../../../../translations';

const { getDisplayLabel } = utils;

type Props = Omit<WidgetProps, 'style'>;

export function TextareaWidget({
  onChange,
  onBlur,
  onFocus,
  options,
  id,
  schema,
  uiSchema,
  label,
  value,
  rawErrors,
  ...delegated
}: Props) {
  const intl = useIntl();

  const _onChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLTextAreaElement>) =>
    onChange(value === '' ? options.emptyValue : value);
  const _onBlur = ({
    target: { value },
  }: React.FocusEvent<HTMLTextAreaElement>) => onBlur(id, value);
  const _onFocus = ({
    target: { value },
  }: React.FocusEvent<HTMLTextAreaElement>) => onFocus(id, value);

  const displayLabel = getDisplayLabel(schema, uiSchema);

  const resolvedLabel = uiSchema['ui:title'] ?? (label as LocaleKey);
  const isError = rawErrors?.length > 0;
  const resolvedValue = value || value === 0 ? value : '';

  return (
    <Textarea
      {...delegated}
      name={id}
      backgroundColor="outlinedTextWidget.background"
      onChange={_onChange}
      onBlur={_onBlur}
      onFocus={_onFocus}
      isLabelAlwaysLifted={false}
      isError={isError}
      value={resolvedValue}
      label={
        displayLabel
          ? intl.formatMessage({
            id: resolvedLabel,
          })
          : schema.title ?? ''
      }
    />
  );
}
