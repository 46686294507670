import { DocumentsService, DocumentTemplateId } from 'services/documents';
import React from 'react';
import {
  NewDocumentButton,
  NewDocumentButtonContent,
  NewDocumentButtonTitle,
  NewDocumentIconWrapper,
} from 'components/CreateNewDocument/components/NewDocumentButton/NewDocumentButton';
import * as Icons from 'components/icons';
import { useModal } from 'store/modals';
import { Flex } from 'components/layout';
import { Text } from 'components/typography';
import { Link } from 'components/links';
import { Routing } from 'global/routing';
import { useIntl } from 'lib/intl';
import { Notification } from '../Notification/Notification';

const NEW_DOCUMENT_BUTTON: Record<DocumentTemplateId, React.ReactNode> = {
  'pressure-test': (
    <NewDocumentButton>
      <NewDocumentIconWrapper>
        <Icons.PressureTestSplash
          outlineColor="secondary.500"
          splashColor="secondary.050"
        />
      </NewDocumentIconWrapper>

      <NewDocumentButtonContent>
        <NewDocumentButtonTitle intlId="documents.create.pressureTestTitle" />
      </NewDocumentButtonContent>
      <NewDocumentIconWrapper>
        <Icons.AddCircle size={30} color="tertiary1.400" />
      </NewDocumentIconWrapper>
    </NewDocumentButton>
  ),
  'quick-capture': (
    <NewDocumentButton>
      <NewDocumentIconWrapper>
        <Icons.QuickCaptureSplash size={42} />
      </NewDocumentIconWrapper>

      <NewDocumentButtonContent>
        <NewDocumentButtonTitle intlId="documents.create.quickCaptureTitle" />
      </NewDocumentButtonContent>
      <NewDocumentIconWrapper>
        <Icons.AddCircle size={30} color="tertiary1.400" />
      </NewDocumentIconWrapper>
    </NewDocumentButton>
  ),
  relining: (
    <NewDocumentButton>
      <NewDocumentIconWrapper>
        <Icons.ReliningSplash size={42} />
      </NewDocumentIconWrapper>

      <NewDocumentButtonContent>
        <NewDocumentButtonTitle intlId="documents.create.reliningTitle" />
      </NewDocumentButtonContent>
      <NewDocumentIconWrapper>
        <Icons.AddCircle size={30} color="tertiary1.400" />
      </NewDocumentIconWrapper>
    </NewDocumentButton>
  ),
};

export function CreateNewDocument() {
  const { closeModal } = useModal();
  const { templatesWithPermission, isLoading } = DocumentsService.useGetAllTemplates();
  const intl = useIntl();

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        px: 4,
      }}
    >
      {templatesWithPermission.length > 0 && (
        <Text
          intlId="documents.createNewDocument"
          sx={{
            color: 'darkgrey.800',
            textTransform: 'uppercase',
          }}
        />
      )}

      {templatesWithPermission.map((template) => (
        <Link
          key={template.id}
          onClick={() => closeModal()}
          sx={{ textDecoration: 'none' }}
          to={Routing.DOCUMENT.Links.DocumentCreate({
            templateId: template.id,
          })}
        >
          {NEW_DOCUMENT_BUTTON[template.id]}
        </Link>
      ))}

      {templatesWithPermission.length === 0 && !isLoading && (
        <Notification
          sx={{ maxWidth: 320, mx: 'auto' }}
          variant="alert"
          severity="info"
        >
          <Notification.Description>
            {intl.formatMessage({
              id: 'documents.create.noTemplatesPermission',
            })}
          </Notification.Description>
        </Notification>
      )}
    </Flex>
  );
}
