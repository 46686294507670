import * as Utils from 'utils';
import { NotificationsService } from 'services';
import * as Intl from 'lib/intl';
import { UsersService } from 'services/users';
import React from 'react';
import { Box } from 'components/layout';
import {
  DocumentRecipientNotificationTypeEnum,
  DocumentUserNotificationTypeEnum,
  UserNotificationTypeEnum,
} from 'trace-backend-sdk';
import { Message, MessageDescription } from 'components/messages/Message';
import { Avatar } from 'components/avatar';
import { Button } from 'components/form-elements';
import { Link } from 'components/links';
import { Routing } from 'global/routing';
import { Text } from 'components/typography';
import { useLocaleRelativeFormat } from 'utils/hooks/use-locale-date-format';
import { DocumentTemplateId } from 'services/documents';
import { useGetLatest } from '../utils/hooks';

// TODO: Add translations
const DOCUMENT_LINKS: Record<DocumentTemplateId, React.ReactNode> = {
  'pressure-test': (documentId: string) => (
    <Link
      variant="default"
      to={Routing.DOCUMENT.Links.DocumentTab({
        documentId: Number(documentId),
        templateId: 'pressure-test',
      })}
    >
      <Text intlId="documents.create.pressureTestTitle" /> {documentId}
    </Link>
  ),
  'quick-capture': (documentId: string) => (
    <Link
      variant="default"
      to={Routing.DOCUMENT.Links.DocumentTab({
        documentId: Number(documentId),
        templateId: 'quick-capture',
      })}
    >
      Quick Capture {documentId}
    </Link>
  ),
  relining: (documentId: string) => (
    <Link
      variant="default"
      to={Routing.DOCUMENT.Links.DocumentTab({
        documentId: Number(documentId),
        templateId: 'relining',
      })}
    >
      Relining {documentId}
    </Link>
  ),
};

export function Messages() {
  const { notificationMessages, fetchNextPage, hasNextPage } = NotificationsService.useGetAllNotifications();
  const { mutate: notificationsUserMarkSeen } = NotificationsService.useNotificationsUserMarkSeen();
  const { currentUser } = Utils.Auth.useCurrentAuthenticatedUser();

  const { active: activeUsers } = UsersService.useGetUsers();
  const { localeRelativeToNowFormat } = useLocaleRelativeFormat();
  const intl = Intl.useIntl();

  const userId = currentUser?.signInUserSession.idToken.payload.sub;
  const getNotificationMessages = useGetLatest(notificationMessages);
  const getNotificationsUserMarkSeen = useGetLatest(notificationsUserMarkSeen);

  React.useEffect(() => {
    if (!userId) {
      return () => {};
    }

    return () => {
      getNotificationsUserMarkSeen()({
        userId,
        data: {
          notificationIds: getNotificationMessages().map(
            (message) => message.id,
          ),
        },
      });
    };
  }, [getNotificationMessages, getNotificationsUserMarkSeen, userId]);

  return (
    <Box
      sx={{
        my: 8,
        maxWidth: 768,
        width: '100%',
        mx: 'auto',
        '& > *:not(:last-child)': { mb: '14px !important' },
      }}
    >
      {notificationMessages.map((message) => {
        switch (message.type) {
          case DocumentRecipientNotificationTypeEnum.RecipientViewDocumentNotification: {
            return (
              <Message
                title={message.recipientId}
                isExternalUserMessage
                footerText={localeRelativeToNowFormat(
                  new Date(message.dateCreated),
                )}
                Description={(
                  <MessageDescription>
                    {intl.formatMessage(
                      { id: 'document.message.viewed-document' },
                      {
                        // @ts-expect-error Key error
                        document: DOCUMENT_LINKS[message.document.templateId](
                          message.documentId,
                        ),
                      },
                    )}
                  </MessageDescription>
                )}
              />
            );
          }

          case DocumentUserNotificationTypeEnum.AssignDocumentNotification:
          case DocumentUserNotificationTypeEnum.FinalizeDocumentNotification: {
            const actorUser = activeUsers?.find(
              (user) => user.userId === message.actorId,
            );

            if (!actorUser) return null;

            const assignedDescription = (
              <React.Fragment>
                {intl.formatMessage(
                  { id: 'document.message.assigned-document-to-you' },
                  {
                    // @ts-expect-error Key error
                    document: DOCUMENT_LINKS[message.document.templateId](
                      message.documentId,
                    ),
                  },
                )}
              </React.Fragment>
            );

            const completedDescription = (
              <React.Fragment>
                {intl.formatMessage(
                  { id: 'document.message.completed-document' },
                  {
                    // @ts-expect-error Key error
                    document: DOCUMENT_LINKS[message.document.templateId](
                      message.documentId,
                    ),
                  },
                )}
              </React.Fragment>
            );

            return (
              <Message
                key={message.id}
                footerText={localeRelativeToNowFormat(
                  new Date(message.dateCreated),
                )}
                title={`${actorUser.user.firstName} ${actorUser.user.lastName}`}
                Description={(
                  <MessageDescription>
                    {message.type
                    === DocumentUserNotificationTypeEnum.AssignDocumentNotification
                      ? assignedDescription
                      : completedDescription}
                  </MessageDescription>
                )}
                isExternalUserMessage={false}
              >
                <Avatar
                  profileImageUrl={actorUser.profileImageUrl}
                  firstName={actorUser.user.firstName}
                  lastName={actorUser.user.lastName}
                  active={actorUser.active}
                  color={actorUser.user.avatarColor}
                  size="large"
                />
              </Message>
            );
          }

          case UserNotificationTypeEnum.NewUserNotification:
          case UserNotificationTypeEnum.UserGainAdminRole: {
            const actorUser = activeUsers?.find(
              (user) => user.userId === message.actorId,
            );

            if (!actorUser) return null;

            const title = `${actorUser.user.firstName} ${actorUser.user.lastName}`;

            return (
              <Message
                title={title}
                isExternalUserMessage={false}
                footerText={localeRelativeToNowFormat(
                  new Date(message.dateCreated),
                )}
                Description={(
                  <MessageDescription>
                    {message.type
                    === UserNotificationTypeEnum.NewUserNotification
                      ? intl.formatMessage({
                        id: 'document.message.accepted-invitation',
                      })
                      : intl.formatMessage({
                        id: 'document.message.gained-admin',
                      })}
                  </MessageDescription>
                )}
              >
                <Avatar
                  profileImageUrl={actorUser.profileImageUrl}
                  firstName={actorUser.user.firstName}
                  lastName={actorUser.user.lastName}
                  active={actorUser.active}
                  color={actorUser.user.avatarColor}
                  size="large"
                />
              </Message>
            );
          }

          case UserNotificationTypeEnum.UserGainPermissionToTemplate:
          case UserNotificationTypeEnum.UserLosePermissionToTemplate: {
            const actorUser = activeUsers?.find(
              (user) => user.userId === message.actorId,
            );

            if (!actorUser) return null;

            const title = `${actorUser.user.firstName} ${actorUser.user.lastName}`;
            const templateName = intl.formatMessage({
              id: `template.${message.templateId}`,
            });

            const gainAccessDescription = [
              intl.formatMessage({
                id: 'message.permissions.template.gainAccess',
              }),
              templateName,
            ].join(' ');

            const loseAccessDescription = [
              intl.formatMessage({
                id: 'message.permissions.template.loseAccess',
              }),
              templateName,
            ].join(' ');

            return (
              <Message
                title={title}
                isExternalUserMessage={false}
                footerText={localeRelativeToNowFormat(
                  new Date(message.dateCreated),
                )}
                Description={(
                  <MessageDescription>
                    <React.Fragment>
                      {message.type
                      === UserNotificationTypeEnum.UserGainPermissionToTemplate
                        ? gainAccessDescription
                        : loseAccessDescription}
                    </React.Fragment>
                  </MessageDescription>
                )}
              >
                <Avatar
                  profileImageUrl={actorUser.profileImageUrl}
                  firstName={actorUser.user.firstName}
                  lastName={actorUser.user.lastName}
                  active={actorUser.active}
                  color={actorUser.user.avatarColor}
                  size="large"
                />
              </Message>
            );
          }
          default:
            return null;
        }
      })}

      {hasNextPage && (
        <Button
          sx={{ my: 6, width: '80%', maxWidth: 320, mx: 'auto' }}
          intlId="documents.loadMoreButton"
          type="button"
          onClick={() => fetchNextPage()}
        />
      )}
    </Box>
  );
}
