import * as ReactQuery from 'react-query';
import { InferMutationOptions } from 'utils/types';
import { createDocumentApi } from './api';

export function useCreateDocument(
  options?: InferMutationOptions<typeof createDocumentApi>,
) {
  const { mutate: createDocument, ...rest } = ReactQuery.useMutation(
    'createDocument',
    createDocumentApi,
    options,
  );

  return {
    createDocument,
    ...rest,
  };
}
