import React from 'react';
import { useEventListener } from 'utils/hooks/use-event-listener';

export function useWindowWidth() {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  const getWindowWidth = React.useCallback(
    () => setWindowWidth(window.innerWidth),
    [],
  );

  // @ts-expect-error Window element mismatch type
  useEventListener(window, 'resize', getWindowWidth);

  return windowWidth;
}
