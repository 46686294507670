import { Language } from '../../translations';

/*
 * Returns a list of supported alpha2 countries
 * */
export function getSupportedCountries() {
  return ['se', 'no', 'au', 'gb', 'us'];
}

export function getSupportedLanguages(): Language[] {
  return ['sv', 'en', 'no'];
}
