import * as ReactQuery from 'react-query';
import * as _ from 'lodash-es';
import { identify, makeRequest, Service } from 'utils/http';
import { InferQueryOptions } from 'utils/types';

async function getUpcomingInvoiceApi() {
  return makeRequest(Service.getUpcomingInvoice(await identify()));
}

export const QUERY_KEY_UPCOMING_INVOICE = ['upcomingInvoice'];
function useGetUpcomingInvoice(
  options?: InferQueryOptions<typeof getUpcomingInvoiceApi>,
) {
  const { data: upcomingInvoice, ...rest } = ReactQuery.useQuery(
    QUERY_KEY_UPCOMING_INVOICE,
    getUpcomingInvoiceApi,
    options,
  );

  const invoiceLinesNotSorted = upcomingInvoice?.invoiceLines?.filter((line) => line.templateId) ?? [];

  const invoiceLines = _.sortBy(invoiceLinesNotSorted, ({ templateId }) =>
    // @ts-expect-error TODO: Fix the type to include base-price as well
    (templateId === 'base-price' ? 0 : 1));

  return {
    upcomingInvoice,
    invoiceLines,
    hasUpcomingInvoice:
      upcomingInvoice?.invoiceLines != null
      && upcomingInvoice.estimatedSubtotalCents != null
      && upcomingInvoice.paymentDate != null,
    ...rest,
  };
}

export { useGetUpcomingInvoice };
