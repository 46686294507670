/* eslint-disable camelcase */
import { PageLoadingIndicator } from 'components/PageLoadingIndicator/PageLoadingIndicator';
import { Box, Container, Flex } from 'components/layout';
import { Text } from 'components/typography';
import { Link } from 'components/links';
import { Routing } from 'global/routing';
import * as Icons from 'components/icons';
import * as React from 'react';
import { breakpoints } from 'utils/breakpoints';
import { UsersService } from 'services/users';
import { isQueryLoading } from 'utils/functions/is-query-loading';
import { Image } from 'components/Image';

export function ProfileView() {
  const { loggedInUser, isLoading: isLoggedInUserLoading } = UsersService.useGetLoggedInUser();
  const { shouldShowFallback, image: avatarImage } = UsersService.useFetchAvatarImage(loggedInUser?.userImageUrl);

  if (isQueryLoading(isLoggedInUserLoading, loggedInUser)) {
    return <PageLoadingIndicator />;
  }

  return (
    <Container
      variant="page"
      sx={{
        px: breakpoints({
          _: 0,
          sm: 4,
        }),
        minHeight: '525px',
        borderBottomLeftRadius: '25%',
        borderBottomRightRadius: '25%',
        overflowX: 'hidden',
        position: 'relative',

        '&:after': {
          height: '90%',
          content: '""',
          position: 'absolute',
          top: 0,
          bottom: 1,
          width: breakpoints({
            _: '150%',
            lg: '50%',
          }),
          backgroundColor: '#fff',
          borderBottomLeftRadius: '100%',
          borderBottomRightRadius: '100%',
          borderTop: 0,
          'z-index': -1,
        },
      }}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
          flexWrap: 'wrap',
          width: breakpoints({
            lg: '50%',
          }),
        }}
      >
        {loggedInUser && (
          <Box
            sx={{
              borderRadius: '23px',
              borderColor: '#4D4D6533',
              borderWidth: '8px',
              borderStyle: 'solid',
              objectFit: 'cover',
              width: '116px',
              height: '116px',
              mb: 10,
            }}
          >
            {!shouldShowFallback && (
              <Image
                src={avatarImage}
                sx={{ width: '100%', height: '100%', borderRadius: '15px' }}
              />
            )}
          </Box>
        )}

        <Text
          as="h1"
          sx={{
            fontSize: breakpoints({
              _: '24px',
              md: '28px',
            }),
            fontWeight: 500,
            textAlign: 'center',
          }}
        >
          {loggedInUser.user.firstName} {loggedInUser.user.lastName}
        </Text>

        <Text
          as="h2"
          sx={{
            fontSize: '2xl',
            fontWeight: 300,
            textAlign: 'center',
            my: 2,
          }}
        >
          {loggedInUser.title}
        </Text>

        <Box
          sx={{ width: 8, height: '3px', backgroundColor: 'tertiary2.800' }}
        />

        <Text
          as="p"
          sx={{
            color: 'secondary.500',
            textTransform: 'lowercase',
            fontSize: 'md',
            mt: 4,
          }}
        >
          {loggedInUser.user.email}
        </Text>

        <Box
          sx={{
            mt: 7,
            mb: 70,
          }}
        >
          <Link to={Routing.PROFILE.Edit} sx={{ textDecoration: 'none' }}>
            <Flex
              sx={{
                flexDirection: 'column',
                alignItems: 'center',
                color: 'darkgrey.800',
                gap: 2,
              }}
            >
              <Box as="span">
                <Icons.EditProfile />
              </Box>
              <Text sx={{ fontWeight: 300 }} intlId="profile.editProfile" />
            </Flex>
          </Link>
        </Box>
      </Flex>
    </Container>
  );
}
