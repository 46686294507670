import {
  DependentExtended,
  UiSchemaCustom,
} from 'components/form-elements/JsonForms/types';
import * as _ from 'lodash-es';
import * as React from 'react';
import { isExtendedDependent } from 'components/form-elements/JsonForms/utils/index';

/**
 * Runtime checkers for UI schema validity.
 * */
const DEPENDENT_CHECKERS = {
  enum: (
    key: string,
    schema: Record<string, any>,
    dependent: DependentExtended,
  ) => {
    const schemaField: any = _.get(schema.properties, dependent.field);
    const possibleValues = _.get(schemaField, 'items.enum');

    if (possibleValues == null) return;

    /**
     * Check the case where the dependendee is dependent on an enum value
     * which is not in the universe of the dependent's valid values.
     *
     * Ex. Field "state" depends on the field "country" having the value "USA",
     * but the field "country" has possible values ["Austria", "Hungary"], meaning
     * it can never have the value "USA".
     * */
    if (dependent.enum) {
      for (const enumValue of dependent.enum) {
        if (!possibleValues.includes(enumValue)) {
          throw new Error(
            `Invalid value "${enumValue}" specified for dependent "${key}", possible values:\n [${possibleValues}]`,
          );
        }
      }
    }
  },
};

export function useValidateDependents(
  schema: Record<string, any>,
  uiSchema?: UiSchemaCustom,
) {
  React.useEffect(() => {
    if (uiSchema?.__DEPENDENTS__) {
      for (const [dependeeKey, value] of Object.entries(
        uiSchema.__DEPENDENTS__,
      )) {
        const extendedDependents = value.filter(isExtendedDependent);

        for (const dependent of extendedDependents) {
          for (const dependentKey in dependent) {
            // @ts-expect-error Key error
            const checkFn = DEPENDENT_CHECKERS[dependentKey];
            if (checkFn) {
              checkFn(dependeeKey, schema, dependent);
            }
          }
        }
      }
    }
  }, [schema, uiSchema]);
}
