import React from 'react';
import * as _ from 'lodash';
import { Temperature } from '../../../components/TemperatureToggle/TemperatureToggle';
import { fahrenheitToCelsius } from '../utils/temperature';
import { ReliningTemperatureIndicator } from './ReliningTemperatureIndicator';
import { ReliningTemperatureSelectField } from './ReliningTemperatureSelectField';

type ReliningTemperatureSelectProps = {
  value: number;
  onChange: (value: number) => void;
  temperatureScale: Temperature;
  disabled?: boolean;
};

const TEMPERATURE_MAX_VALUE: Record<Temperature, number> = {
  celsius: 100,
  fahrenheit: 212,
};

const TEMPERATURE_MIN_VALUE: Record<Temperature, number> = {
  celsius: 40,
  fahrenheit: 104,
};

const TEMPERATURE_OPTIONS: Record<Temperature, number[]> = {
  celsius: _.range(40, TEMPERATURE_MAX_VALUE.celsius + 1),
  fahrenheit: _.range(104, TEMPERATURE_MAX_VALUE.fahrenheit + 1),
};

export function ReliningTemperatureSelect({
  value,
  onChange,
  temperatureScale,
  disabled,
}: ReliningTemperatureSelectProps) {
  const resolvedValue = value ?? _.first(TEMPERATURE_OPTIONS[temperatureScale]);
  /*
   * The indicator was built with Celsius scale in mind, so if the
   * value is in Fahrenheit, convert it to Celsius before sending
   * it to the indicator component.
   * */
  const indicatorValue = temperatureScale === 'fahrenheit'
    ? fahrenheitToCelsius(resolvedValue)
    : resolvedValue;

  return (
    <ReliningTemperatureIndicator
      maxValue={TEMPERATURE_MAX_VALUE[temperatureScale]}
      value={indicatorValue}
    >
      <ReliningTemperatureSelectField
        disabled={disabled}
        value={resolvedValue}
        onChange={(value) => {
          const maxValue = TEMPERATURE_MAX_VALUE[temperatureScale];
          const minValue = TEMPERATURE_MIN_VALUE[temperatureScale];

          if (value < minValue || value > maxValue) {
            return;
          }

          onChange(value);
        }}
        options={TEMPERATURE_OPTIONS[temperatureScale]}
      />
    </ReliningTemperatureIndicator>
  );
}
