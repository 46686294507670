import React from 'react';
import * as Lsm from 'little-state-machine';
import { DEFAULT_LSM_STATE } from 'store/utils';
import { ModalProvider } from './modals';
import * as SidebarActions from './sidebar';
import * as AuthActions from './auth';

export const StoreProvider: React.FC = ({ children }) => (
  <ModalProvider>{children}</ModalProvider>
);

Lsm.createStore(DEFAULT_LSM_STATE);

export const LittleStateMachineProvider = Lsm.StateMachineProvider;
export { SidebarActions, AuthActions };
