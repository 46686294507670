import React from 'react';
import WavesImg from 'assets/images/waves.svg';
import PhoneImg from 'assets/images/phone.svg';
import { WorkspaceLayout } from 'components/workspace-layout';
import { Container, Flex } from 'components/layout';
import { Image } from 'components/Image';

export function PasswordInstructions() {
  return (
    <Container
      variant="page"
      sx={{
        height: '100%',
        justifyContent: 'flex-start',
        overflowX: 'hidden',
        px: '0px',
      }}
    >
      <WorkspaceLayout sx={{ px: 4 }}>
        <WorkspaceLayout.Headline intlId="passwordInstructions.headline" />
        <WorkspaceLayout.Paragraph
          variant="body1"
          intlId="passwordInstructions.paragraph"
          sx={{ pt: 4 }}
        />
      </WorkspaceLayout>

      <Flex
        alignItems="flex-start"
        justifyContent="center"
        sx={{
          backgroundImage: `url('${WavesImg}')`,
          // mt: 4,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'top',
          flexShrink: 1,
          flexGrow: 1,
          width: '130%',
        }}
      >
        <Image src={PhoneImg} />
      </Flex>
    </Container>
  );
}
