import * as React from 'react';

import { Image } from 'components/Image';
import { StyleObject } from 'theming';
import { WorkspaceService } from 'services/workspace';
import { Box } from 'components/layout';
import { DefaultLogo } from 'components/icons';
import { UsersService } from 'services/users';

export const WorkspaceLogo: React.FC<{
  sx?: StyleObject;
  size?: number;
}> = ({ sx, size }) => {
  const { workspaceData } = WorkspaceService.useGetWorkspace();
  const { image, shouldShowFallback } = UsersService.useFetchAvatarImage(
    workspaceData?.imageUrl,
  );

  if (shouldShowFallback) {
    return (
      <Box
        sx={{
          borderRadius: 1,
          borderColor: '#4D4D6533',
          borderWidth: '2px',
          borderStyle: 'solid',
          objectFit: 'cover',
          flexShrink: 0,
          width: size,
          height: size,
          ...sx,
        }}
      >
        <DefaultLogo />
      </Box>
    );
  }

  return (
    <Image
      src={image}
      alt="Workspace Logo"
      sx={{
        borderRadius: 1,
        borderColor: '#4D4D6533',
        borderWidth: '2px',
        borderStyle: 'solid',
        objectFit: 'cover',
        backgroundColor: 'white.50',
        flexShrink: 0,
        width: size,
        height: size,
        ...sx,
      }}
    />
  );
};
