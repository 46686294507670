import { WorkspaceService } from 'services/workspace';
import { DiscountTable } from '../components/DiscountTable/DiscountTable';
import { PaymentsService } from '../index';
import { InlineLoadingIndicator } from '../../../components/LoadingIndicator';
import { Container } from '../../../components/layout';
import { isQueryLoading } from '../../../utils/functions/is-query-loading';

export function DiscountSchedulePage() {
  const getUpcomingInvoice = PaymentsService.useGetUpcomingInvoice();
  const getPaymentDiscounts = PaymentsService.useGetPaymentDiscounts();
  const { workspaceData } = WorkspaceService.useGetWorkspace();
  if (
    isQueryLoading(
      getUpcomingInvoice.isLoading,
      getUpcomingInvoice.upcomingInvoice,
    )
    || isQueryLoading(getPaymentDiscounts.isLoading, getPaymentDiscounts.data)
  ) {
    return <InlineLoadingIndicator />;
  }

  return (
    <Container variant="spacer">
      <DiscountTable
        currency={workspaceData ? workspaceData.currency : 'sek'}
        brackets={getPaymentDiscounts.data.discounts.map((discount) => ({
          discountPercentage: discount.percentage,
          toCents: discount.to,
          fromCents: discount.from,
        }))}
        currentTotalCents={
          getUpcomingInvoice.upcomingInvoice?.estimatedSubtotalCents ?? 0
        }
      />
    </Container>
  );
}
