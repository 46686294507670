import React from 'react';
import * as ThemeUI from 'theme-ui';
import { foundations } from './foundations';
import { variants } from './variants';

export type StyleObject = ThemeUI.ThemeUIStyleObject;

export const theme = {
  ...foundations,
  ...variants,
};

export function createTypedVariant<T extends { [name: string]: StyleObject }>(
  typedVariant: T,
) {
  return typedVariant;
}

export const ThemeProvider: React.FC = ({ children }) => (
  <ThemeUI.ThemeProvider theme={theme}>{children}</ThemeUI.ThemeProvider>
);
