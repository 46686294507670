import * as ReactQuery from 'react-query';
import { useNotifications } from 'components/notifications/notifications';
import React from 'react';

async function getPosition() {
  return new Promise<GeolocationPosition>((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve(position);
        },
        (positionError) => {
          reject(positionError);
        },
        {
          enableHighAccuracy: true,
        },
      );
    } else {
      reject(new Error('This browser does not support geolocation'));
    }
  });
}

export function useGetGeolocationPosition() {
  const notifications = useNotifications();

  const { data, refetch, ...rest } = ReactQuery.useQuery(
    ['geolocation'],
    getPosition,
    {
      enabled: false,
      retry: 0,
      refetchOnWindowFocus: false,
      onError: (error) => {
        if (error instanceof GeolocationPositionError) {
          switch (error.code) {
            case GeolocationPositionError.PERMISSION_DENIED:
              notifications.error({
                description: 'errors.geolocation.permissionDenied',
              });
              break;
            default:
              notifications.error({
                description: 'errors.geolocation.generic',
              });
          }
        } else {
          notifications.error({
            description: 'errors.geolocation.generic',
          });
        }
      },
    },
  );

  const getGeolocationPosition = React.useCallback(
    () =>
      refetch({
        throwOnError: true,
      }),
    [refetch],
  );

  return {
    ...rest,
    geolocationStatus: rest.status,
    geolocationPosition: data,
    getGeolocationPosition,
  };
}
