import * as ThemeUI from 'theme-ui';
import * as Intl from 'lib/intl';
import { FieldProps } from '@rjsf/core';
import * as Icons from 'components/icons';
import * as _ from 'lodash-es';
import {
  BaseWidgetData,
  QuickTraceWidgetBodyWrapper,
  QuickTraceWidgetCaptionInput,
  QuickTraceWidgetContent,
  QuickTraceWidgetIconWrapper,
  QuickTraceWidgetTimestamp,
  QuickTraceWidgetTitle,
  QuickTraceWidgetWrapper,
} from 'components/quick-trace/components/QuickTraceWidget';
import { Box, Flex } from 'components/layout';
import React from 'react';
import { DocumentsService } from 'services/documents';
import { DocumentWidgetEnum } from 'trace-backend-sdk';
import { Button } from 'components/form-elements';
import { Text } from '../../typography';
import { getOutOfContextFieldName } from '../../form-elements/JsonForms/utils/get-out-of-context-field-name';
import { QuickTraceWidgetMenu } from './QuickTraceWidgetMenu';

type FileCaptureData = BaseWidgetData & {
  url: string;
  filename: string;
  fileSizeBytes: number;
};

export function QuickTraceFileField(props: FieldProps<FileCaptureData>) {
  const intl = Intl.useIntl();
  const { uploadDocumentWidget, isLoading: isUploading } = DocumentsService.useUploadDocumentWidget({
    onSuccess: (data) => {
      if ('filename' in data) {
        onPreserveChange({
          url: data.url,
          timestamp: data.timestamp,
          filename: data.filename,
          fileSizeBytes: data.fileSizeBytes,
        });
      }
    },
  });

  const hasData = !_.isEmpty(props.formData);
  const hasError = !_.isEmpty(props.errorSchema);

  function onPreserveChange(data: Partial<FileCaptureData>) {
    props.onChange({
      ...props.formData,
      ...data,
    });
  }

  function getFileSize(fileSize: number) {
    if (fileSize < 1000) return `${fileSize} bytes`;
    return `${Math.round(fileSize / 1000)}kb`;
  }

  return (
    <QuickTraceWidgetWrapper hasError={hasError}>
      <Flex sx={{ mb: 6, alignItems: 'center' }}>
        <QuickTraceWidgetIconWrapper color="tertiary1.200">
          <Icons.FileUpload />
        </QuickTraceWidgetIconWrapper>

        <QuickTraceWidgetBodyWrapper>
          <QuickTraceWidgetTitle>
            {intl.formatMessage({
              id: 'quickCapture.file.title',
            })}
          </QuickTraceWidgetTitle>
          <QuickTraceWidgetTimestamp
            placeholder={intl.formatMessage({
              id: 'quickCapture.file.subTitle',
            })}
            timestamp={props.formData.timestamp}
          />
        </QuickTraceWidgetBodyWrapper>

        <QuickTraceWidgetMenu
          hasWidgetData={hasData}
          templateId={props.formContext.templateId}
          documentId={props.formContext.documentId}
          widgetKey={getOutOfContextFieldName(props.name)}
        />
      </Flex>

      {hasData && (
        <QuickTraceWidgetContent>
          <ThemeUI.Link
            variant="default"
            target="_blank"
            href={props.formData.url}
          >
            {props.formData.filename}
          </ThemeUI.Link>

          <Text as="p">{getFileSize(props.formData.fileSizeBytes)}</Text>
        </QuickTraceWidgetContent>
      )}

      {hasData && (
        <QuickTraceWidgetCaptionInput
          disabled={props.disabled}
          onChange={(e) => onPreserveChange({ caption: e.target.value })}
          value={props.formData.caption}
        />
      )}

      {!hasData && (
        <Box
          sx={{
            position: 'relative',
          }}
        >
          <Button
            disabled={props.disabled}
            isLoading={isUploading}
            sx={{
              backgroundColor: 'tertiary1.300',
            }}
            variant="secondary"
          >
            {intl.formatMessage({
              id: 'quickCapture.file.action',
            })}
          </Button>

          <ThemeUI.Input
            onChange={(event) => {
              const file = event.target.files?.item(0);
              if (!file) return;

              uploadDocumentWidget({
                templateId: props.formContext.templateId,
                documentId: props.formContext.documentId,
                widgetType: DocumentWidgetEnum.File,
                widgetKey: getOutOfContextFieldName(props.name),
                widgetData: {
                  type: 'file',
                  fileType: file.type,
                  file,
                  filename: file.name,
                  fileSizeBytes: file.size,
                  caption: props.formData.caption,
                },
              });
            }}
            type="file"
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              opacity: 0,
            }}
          />
        </Box>
      )}
    </QuickTraceWidgetWrapper>
  );
}
