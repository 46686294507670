import { identify, makeRequest, sanitize, Service } from 'utils/http';

type Data = Parameters<typeof Service.adminUpdateWorkspace>[1];

export async function adminUpdateWorkspaceApi({
  workspaceId,
  ...data
}: Data & {
  workspaceId: string;
}) {
  return makeRequest(
    Service.adminUpdateWorkspace(
      workspaceId,
      sanitize(data) as Data,
      await identify(),
    ),
  );
}
