/*
 * IdSchema has a definition for every component that rendered in
 * the JSON form. The definition has the following form:
 * "root_calculator_lengthOfPipe" for a "data" object of form:
 * ```
 * const data = { calculator: { lengthOfPipe: 3 } }
 * ```
 *
 * We can extract the full object path of the component
 * by using this function, which will remove the "root"
 * and join the rest of the path into a dotted object notation.
 * */
function getObjectPathFromIdSchema(idSchema: string) {
  // We want to omit the root part
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [root, ...parts] = idSchema.split('_');
  return parts.join('.');
}

export { getObjectPathFromIdSchema };
