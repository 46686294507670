import * as ReactQuery from 'react-query';
import * as Utils from 'utils';
import { InferMutationOptions } from 'utils/types';
import { updateLoggedInUserApi } from 'services/users/update-logged-in-user/api';

export function useUpdateLoggedInUser(
  options?: InferMutationOptions<typeof updateLoggedInUserApi>,
) {
  const { refreshSession } = Utils.Auth.useRefreshSession();

  const { mutate: updateLoggedInUser, ...rest } = ReactQuery.useMutation(
    'updateLoggedInUser',
    updateLoggedInUserApi,
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await refreshSession();

        if (options?.onSuccess) {
          await options.onSuccess(data, variables, context);
        }
      },
    },
  );

  return {
    updateLoggedInUser,
    ...rest,
  };
}
