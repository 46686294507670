import { FieldProps } from '@rjsf/core';
import * as ThemeUI from 'theme-ui';
import * as Intl from 'lib/intl';
import * as Icons from 'components/icons';
import { useGetGeolocationPosition } from 'utils/hooks';
import * as _ from 'lodash-es';
import {
  BaseWidgetData,
  QuickTraceWidgetBodyWrapper,
  QuickTraceWidgetCaptionInput,
  QuickTraceWidgetContent,
  QuickTraceWidgetIconWrapper,
  QuickTraceWidgetTimestamp,
  QuickTraceWidgetTitle,
  QuickTraceWidgetWrapper,
} from 'components/quick-trace/components/QuickTraceWidget';
import { Flex } from 'components/layout';
import { Button } from 'components/form-elements';
import React from 'react';
import { formatLocation } from 'utils/geolocation/format-location';
import { getLocationMapLink } from 'services/location/get-location-map-link';
import { DocumentWidgetEnum } from 'trace-backend-sdk';
import { DocumentsService } from '../../../services/documents';
import { getOutOfContextFieldName } from '../../form-elements/JsonForms/utils/get-out-of-context-field-name';
import { QuickTraceWidgetMenu } from './QuickTraceWidgetMenu';

type GpsCaptureData = BaseWidgetData & { longitude: string; latitude: string };

export function QuickTraceGpsField(props: FieldProps<GpsCaptureData>) {
  const { getGeolocationPosition } = useGetGeolocationPosition();
  const { uploadDocumentWidget } = DocumentsService.useUploadDocumentWidget({
    onSuccess: (data) => {
      if ('latitude' in data) {
        onPreserveChange({
          latitude: data.latitude,
          longitude: data.longitude,
          caption: data.caption,
          timestamp: data.timestamp,
        });
      }
    },
  });
  const intl = Intl.useIntl();

  const hasData = !_.isEmpty(props.formData);
  const hasError = !_.isEmpty(props.errorSchema);

  function onPreserveChange(data: Partial<GpsCaptureData>) {
    props.onChange({
      ...props.formData,
      ...data,
    });
  }

  return (
    <QuickTraceWidgetWrapper hasError={hasError}>
      <Flex sx={{ mb: 6, alignItems: 'center' }}>
        <QuickTraceWidgetIconWrapper color="secondary.300">
          <Icons.Gps color="white.50" />
        </QuickTraceWidgetIconWrapper>

        <QuickTraceWidgetBodyWrapper>
          <QuickTraceWidgetTitle>
            {intl.formatMessage({ id: 'quickCapture.gps.title' })}
          </QuickTraceWidgetTitle>
          <QuickTraceWidgetTimestamp
            placeholder={intl.formatMessage({
              id: 'quickCapture.gps.subTitle',
            })}
            timestamp={props.formData.timestamp}
          />
        </QuickTraceWidgetBodyWrapper>

        <QuickTraceWidgetMenu
          hasWidgetData={hasData}
          templateId={props.formContext.templateId}
          documentId={props.formContext.documentId}
          widgetKey={getOutOfContextFieldName(props.name)}
        />
      </Flex>

      {hasData && (
        <QuickTraceWidgetContent>
          <ThemeUI.Link
            target="_blank"
            href={getLocationMapLink({
              longitude: props.formData.longitude,
              latitude: props.formData.latitude,
            })}
            variant="default"
          >
            {formatLocation({
              latitude: props.formData.latitude,
              longitude: props.formData.longitude,
            })}
          </ThemeUI.Link>
        </QuickTraceWidgetContent>
      )}

      {hasData && (
        <QuickTraceWidgetCaptionInput
          disabled={props.disabled}
          onChange={(e) =>
            onPreserveChange({
              caption: e.target.value,
            })}
          value={props.formData.caption}
        />
      )}

      {!hasData && (
        <Button
          disabled={props.disabled}
          sx={{ backgroundColor: 'secondary.500' }}
          variant="secondary"
          onClick={() => {
            getGeolocationPosition().then(({ data }) => {
              if (!data) return;

              uploadDocumentWidget({
                documentId: props.formContext.documentId,
                templateId: props.formContext.templateId,
                widgetType: DocumentWidgetEnum.Gps,
                widgetKey: getOutOfContextFieldName(props.name),
                widgetData: {
                  latitude: String(data.coords.latitude),
                  longitude: String(data.coords.longitude),
                  type: 'gps',
                  caption: props.formData.caption,
                },
              });
            });
          }}
        >
          {intl.formatMessage({ id: 'quickCapture.gps.action' })}
        </Button>
      )}
    </QuickTraceWidgetWrapper>
  );
}
