// @ts-expect-error No type definitions for rollbar
import { useRollbar as originalUseRollbar } from '@rollbar/react';

export const ROLLBAR_CONFIG = {
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  environment: process.env.REACT_APP_ENVIRONMENT,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled:
    process.env.REACT_APP_ENVIRONMENT === 'stage'
    || process.env.REACT_APP_ENVIRONMENT === 'prod'
    || process.env.REACT_APP_ENVIRONMENT === 'test',
  payload: {
    environment: process.env.NODE_ENV,
  },
};

export function useRollbar() {
  return originalUseRollbar();
}
