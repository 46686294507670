import { createTypedVariant } from 'theming/index';

export type InputVariants = keyof typeof forms.input;
export type SwitchVariants = keyof typeof forms.switch;

export const forms = createTypedVariant({
  input: {
    fieldEditText: {
      color: 'darkgrey.800',
      height: '50px',
      outline: 'none',
    },
    primary: {
      backgroundColor: 'red',
    },
    underlined: {
      border: 'none',
      borderBottomStyle: 'solid',
      borderBottomWidth: '2px',
      borderBottomColor: 'grey.300',
      color: 'darkgrey.800',
      fontSize: 'sm',
      borderRadius: '0%',
      px: '0px',
      '&:focus': { outline: 'none' },
    },
  },

  jsonInput: {
    border: '1px solid',
    borderColor: '#9E9EAF82',
    backgroundColor: 'white.50',
    boxShadow: 2,
  },
  jsonInputLabel: {
    color: 'textprimary.900',
    fontWeight: 300,
    fontSize: 'xl',
  } as any,

  defaultFileUpload: {
    input: {
      position: 'relative',
      color: 'grey.300',
      border: 'none',
      backgroundColor: 'transparent',
      px: '0px',
      '&::-webkit-file-upload-button, &::file-selector-button': {
        visibility: 'hidden',
      },
      '&::before': {
        content: "'choose file'",
        display: 'inline-block',
        position: 'absolute',
        border: 'none',
        py: 1,
        px: 4,
        backgroundColor: '#fff',
        color: 'grey.300',
        fontSize: '1em',
        cursor: 'pointer',
        borderRadius: '20px',
        borderColor: '#9E9EAF',
        borderWidth: '1px',
        borderStyle: 'solid',
        top: '0',
        right: '0',
      },
    },
  },

  switch: {
    defaultSwitch: {
      div: {
        '&:first-of-type': {
          background: '#fff',
        },
      },
      'input:checked ~ &': {
        backgroundColor: 'secondary.500',
      },
    },
  },
});
