import * as React from 'react';
import { WidgetProps } from '@rjsf/core';
import * as ThemeUI from 'theme-ui';
import { Text } from 'components/typography';
import { IntlCustom } from '../../../../lib';

function selectValue(value: any, selected: any, all: any) {
  const at = all.indexOf(value);
  const updated = selected.slice(0, at).concat(value, selected.slice(at));

  return updated.sort((a: any, b: any) => all.indexOf(a) > all.indexOf(b));
}

function deselectValue(value: any, selected: any) {
  return selected.filter((v: any) => v !== value);
}

export function CheckboxesWidget({
  id,
  disabled,
  options,
  value,
  autofocus,
  readonly,
  onChange,
  onBlur,
  onFocus,

  ...props
}: WidgetProps) {
  const { enumOptions, enumDisabled, inline } = options;
  const intl = IntlCustom.useIntl();

  const _onChange = (option: any) =>
    ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
      const all = (enumOptions as any).map(({ value }: any) => value);

      if (checked) {
        onChange(selectValue(option.value, value, all));
      } else {
        onChange(deselectValue(option.value, value));
      }
    };

  const _onBlur = ({
    target: { value },
  }: React.FocusEvent<HTMLButtonElement>) => onBlur(id, value);

  const _onFocus = ({
    target: { value },
  }: React.FocusEvent<HTMLButtonElement>) => onFocus(id, value);

  const fieldName = id.replace(/root_/, '');
  const checkboxLabelTranslationKey = (label: string) =>
    [props.formContext.name, fieldName, label].join('.');

  return (
    <React.Fragment>
      {/*TODO: See if we can add the label here, instead of in 'FieldTemplate'*/}
      {/*<Label htmlFor={id}>{label || schema.title}</Label>*/}

      <ThemeUI.Flex
        sx={{
          flexDirection: inline ? 'row' : 'column',
          gap: '8px',
        }}
      >
        {(enumOptions as any).map((option: any, index: number) => {
          const checked = value.indexOf(option.value) !== -1;
          // eslint-disable-next-line eqeqeq
          const itemDisabled = enumDisabled && (enumDisabled as any).indexOf(option.value) != -1;

          return (
            <ThemeUI.Label
              key={index}
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: 'fit-content',
                py: 1,
                pr: 8,
                /**
                 * ThemeUI adds 'position: absolute' to the inner checkbox element,
                 * but it doesn't add 'position: relative' to any of its parents.
                 * This causes the 'position: absolute' to bubble up the DOM tree
                 * until it starts causing problems in the layout components.
                 *
                 * So, we add 'position: relative' here to restrict the absolutely
                 * positioned inner element of ThemeUI.
                 * */
                position: 'relative',

                '& input:checked ~ svg': {
                  color: 'secondary.500',
                },
                '& input:focus ~ svg': {
                  backgroundColor: '#B5D5FF',
                },
                '& input:not(:checked) ~ svg': {
                  color: 'grey.900',
                },
              }}
            >
              <ThemeUI.Checkbox
                sx={{
                  mr: 8,
                }}
                id={`${id}_${index}`}
                checked={checked}
                disabled={disabled || itemDisabled || readonly}
                autoFocus={autofocus && index === 0}
                onChange={_onChange(option)}
                // @ts-expect-error Expected
                onBlur={_onBlur}
                // @ts-expect-error Expected
                onFocus={_onFocus}
              />

              <Text sx={{ textTransform: 'capitalize', fontSize: 'md' }}>
                {intl.formatMessage({
                  id: checkboxLabelTranslationKey(option.label),
                })}
              </Text>
            </ThemeUI.Label>
          );
        })}
      </ThemeUI.Flex>
    </React.Fragment>
  );
}
