import { identify, makeRequest, Service } from '../../../utils/http';
import { DocumentRequestBase } from '../types';

type Data = DocumentRequestBase & {
  widgetKey: string;
};

export async function deleteDocumentWidgetApi({
  documentId,
  templateId,
  widgetKey,
}: Data) {
  return makeRequest(
    Service.deleteDocumentWidget(
      documentId,
      templateId,
      widgetKey,
      await identify(),
    ),
  );
}
