import { createTypedVariant, StyleObject } from 'theming';

export type LayoutVariants = keyof typeof layout;

const pageContainer: Partial<StyleObject> = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  py: 2,
};

export const layout = createTypedVariant({
  avatarForm: {
    px: 11,
    pb: 32,
  },
  page: {
    ...pageContainer,
    py: 0,
    px: 'containerXPadding',
  },
  narrow: {
    ...pageContainer,
    py: 0,
    maxWidth: 'containerNarrow',
    px: 'containerXPadding',
  },
  container: {
    px: 'containerXPadding',
    py: 2,
  },
  spacer: {
    maxWidth: 768,
    px: 4,
    py: 8,
    width: '100%',
    mx: 'auto',
  },
  maxWidthWrapper: {
    maxWidth: 768,
    width: '100%',
    mx: 'auto',
  },
});
