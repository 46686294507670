/* eslint-disable max-len */

export function Languages() {
  return (
    <svg
      id="translate"
      xmlns="http://www.w3.org/2000/svg"
      width="118.637"
      height="118.637"
      viewBox="0 0 118.637 118.637"
    >
      <path
        id="Path_11837"
        data-name="Path 11837"
        d="M292.793,85.333h-46.96a2.533,2.533,0,0,0-.781.124l-.593.2a2.471,2.471,0,0,0-1.565,3.125l.008.023,24.844,72.6-16.362,18.7a2.471,2.471,0,0,0,1.863,4.1h39.546a12.358,12.358,0,0,0,12.358-12.358V97.691A12.358,12.358,0,0,0,292.793,85.333Z"
        transform="translate(-186.515 -65.56)"
        fill="#3ddc97"
      />
      <path
        id="Path_11838"
        data-name="Path 11838"
        d="M274.77,406.781a2.472,2.472,0,0,0-2.249-1.448H247.8a2.472,2.472,0,0,0-2.313,3.342l7.415,19.773a2.472,2.472,0,0,0,1.873,1.562,2.746,2.746,0,0,0,.44.04,2.471,2.471,0,0,0,1.859-.845l17.3-19.773A2.472,2.472,0,0,0,274.77,406.781Z"
        transform="translate(-188.486 -311.412)"
        fill="#2b71d0"
      />
      <g
        id="Group_11732"
        data-name="Group 11732"
        transform="translate(69.205 44.489)"
      >
        <path
          id="Path_11839"
          data-name="Path 11839"
          d="M335.74,218.276h-34.6a2.472,2.472,0,0,1,0-4.943h34.6a2.472,2.472,0,0,1,0,4.943Z"
          transform="translate(-298.666 -208.39)"
          fill="#fff"
        />
        <path
          id="Path_11840"
          data-name="Path 11840"
          d="M365.138,201.886a2.472,2.472,0,0,1-2.472-2.472v-4.943a2.472,2.472,0,0,1,4.943,0v4.943A2.472,2.472,0,0,1,365.138,201.886Z"
          transform="translate(-347.836 -192)"
          fill="#fff"
        />
        <path
          id="Path_11841"
          data-name="Path 11841"
          d="M333.145,247.936a2.472,2.472,0,0,1-1.433-4.483c10.826-7.692,18.735-23.372,18.735-27.647a2.472,2.472,0,1,1,4.943,0c0,6.515-9.525,23.658-20.811,31.671A2.474,2.474,0,0,1,333.145,247.936Z"
          transform="translate(-323.259 -208.39)"
          fill="#fff"
        />
        <path
          id="Path_11842"
          data-name="Path 11842"
          d="M352.911,293.838a2.472,2.472,0,0,1-1.671-.648c-1.789-1.641-17.588-16.234-20.391-23.139a2.473,2.473,0,0,1,4.582-1.863c2.022,4.983,14.513,17.1,19.155,21.35a2.471,2.471,0,0,1-1.676,4.3Z"
          transform="translate(-323.252 -249.349)"
          fill="#fff"
        />
      </g>
      <path
        id="Path_11843"
        data-name="Path 11843"
        d="M86.372,95.592,54.242,1.671A2.472,2.472,0,0,0,51.9,0H12.358A12.358,12.358,0,0,0,0,12.358V86.506A12.358,12.358,0,0,0,12.358,98.864H84.034a2.472,2.472,0,0,0,2.338-3.272Z"
        fill="#3192f1"
      />
      <g
        id="Group_11733"
        data-name="Group 11733"
        transform="translate(19.808 29.824)"
      >
        <path
          id="Path_11844"
          data-name="Path 11844"
          d="M112.639,168.092a2.471,2.471,0,0,1-2.328-1.641l-10.03-28.087-10.03,28.087A2.472,2.472,0,0,1,85.6,164.79l12.358-34.6a2.57,2.57,0,0,1,4.652,0l12.358,34.6a2.472,2.472,0,0,1-1.483,3.159A2.5,2.5,0,0,1,112.639,168.092Z"
          transform="translate(-85.487 -128.711)"
          fill="#fafafa"
        />
        <path
          id="Path_11845"
          data-name="Path 11845"
          d="M129.691,218.276H119.8a2.472,2.472,0,1,1,0-4.943h9.886a2.472,2.472,0,0,1,0,4.943Z"
          transform="translate(-109.954 -193.725)"
          fill="#fafafa"
        />
      </g>
    </svg>
  );
}
