/** @jsxImportSource theme-ui */

import TextareaAutosize, {
  TextareaAutosizeProps,
} from 'react-textarea-autosize';
import React from 'react';
import { Field } from '../form-elements';
import { Colors } from '../../theming/foundations/colors';

type TextareaProps = TextareaAutosizeProps & {
  isLabelAlwaysLifted: boolean;
  label: string;
  name: string;
  isError: boolean;
  backgroundColor?: Colors;
};

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      isLabelAlwaysLifted,
      label,
      name,
      isError,
      backgroundColor,
      placeholder,
      onFocus,
      onBlur,
      onChange,
      ...delegated
    },
    ref,
  ) => {
    const [isActive, setIsActive] = React.useState(false);

    const isLabelLifted = label && (isLabelAlwaysLifted || isActive || delegated.value);
    const isPlaceholderVisible = !label || isLabelLifted;

    return (
      <Field
        name={name}
        label={label}
        isError={isError}
        isLabelAlwaysLifted={isLabelAlwaysLifted}
        hasValue={Boolean(delegated.value)}
        required={Boolean(delegated.required)}
        isActive={isActive}
        backgroundColor={backgroundColor}
      >
        <TextareaAutosize
          minRows={2}
          {...delegated}
          ref={ref}
          name={name}
          required={Boolean(delegated.required)}
          placeholder={isPlaceholderVisible ? placeholder : ''}
          onFocus={(event) => {
            if (onFocus) {
              onFocus(event);
            }
            setIsActive(true);
          }}
          onBlur={(event) => {
            if (onBlur) {
              onBlur(event);
            }
            setIsActive(false);
          }}
          onChange={onChange}
          sx={{
            resize: 'none',
            border: 'none',
            width: '100%',
            height: '1.1876em',
            padding: '18.5px 14px',
            display: 'block',
            minWidth: 0,
            background: 'none',
            boxSizing: 'content-box',
            letterSpacing: 'inherit',
            outline: 'none',
            fontSize: '1rem',
          }}
        />
      </Field>
    );
  },
);
