import React from 'react';
import * as ReactRouter from 'react-router-dom';
import * as ReactIntl from 'lib/intl';

import { WorkspaceService } from 'services/workspace';
import { breakpoints } from 'utils/breakpoints';
import { WorkspaceLayout } from 'components/workspace-layout';
import { Container, Flex } from 'components/layout';
import CreateWorkspaceSuccessImg from 'assets/images/create-workspace-success.svg';
import { Authorization } from 'components/authorization/Authorization';

export function Congratulations() {
  const history = ReactRouter.useHistory();
  const { isWorkspaceReady } = WorkspaceService.useGetWorkspace();

  return (
    <Container
      sx={{
        p: 4,
        height: '100%',
        overflow: 'hidden',
      }}
      variant="page"
    >
      <Flex
        sx={{
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <WorkspaceLayout sx={{ px: 4 }}>
          <WorkspaceLayout.Logo />
          <WorkspaceLayout.Headline
            intlId={
              `workspace.congratulations${
                isWorkspaceReady ? '.ready' : ''
              }.title` as const
            }
          />
          <WorkspaceLayout.Headline
            intlId={
              `workspace.congratulations${
                isWorkspaceReady ? '.ready' : ''
              }.title2` as const
            }
          />
          <WorkspaceLayout.Paragraph
            variant="body1"
            intlId={
              `workspace.congratulations${
                isWorkspaceReady ? '.ready' : ''
              }.description` as const
            }
            sx={{ mt: 4 }}
          />
          {!isWorkspaceReady && (
            <Authorization roles={['user']}>
              <WorkspaceLayout.Paragraph
                variant="body1"
                intlId="workspace.congratulations.nonAdmin.message"
                sx={{ mt: 4 }}
              />
            </Authorization>
          )}
        </WorkspaceLayout>

        <WorkspaceLayout.Image
          src={CreateWorkspaceSuccessImg}
          alt="people setting up the furniture"
          sx={{
            mb: 12,
            mt: breakpoints({ _: 10, sm: 'auto' }),
          }}
        />

        {isWorkspaceReady && (
          <WorkspaceLayout.Button
            sx={{
              width: '100%',
              mx: 'auto',
              px: 10,
            }}
            onClick={() => history.replace('/')}
          >
            <ReactIntl.FormattedMessage id="generic.button.continue" />
          </WorkspaceLayout.Button>
        )}
      </Flex>
    </Container>
  );
}
