import React from 'react';
import * as Utils from 'utils';
import { generateContext } from 'utils/context';
import { Environment } from 'global/environment';

export const [useWebsocketsContext, WebsocketsContextProvider] = generateContext<{
    webSocket: WebSocket | null;
  }>();

export function WebsocketsProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [webSocket, setWebSocket] = React.useState<WebSocket | null>(null);

  const { currentUser } = Utils.Auth.useCurrentAuthenticatedUser();
  const idToken = currentUser?.signInUserSession.idToken?.jwtToken;

  React.useEffect(() => {
    if (!idToken) {
      return () => {};
    }

    const url = Environment.BACKEND_API?.replace('https://', 'wss://');
    const query = `Authorization=${idToken}`;
    const socket = new WebSocket([url, query].join('?'));

    socket.onclose = () => {
      setWebSocket(null);
    };

    socket.onopen = () => {
      setWebSocket(socket);
    };

    return () => {
      socket.close();
    };
  }, [idToken]);

  return (
    <WebsocketsContextProvider value={{ webSocket }}>
      {children}
    </WebsocketsContextProvider>
  );
}
