import Joi from 'joi';

export const FIRST_NAME = Joi.string().required().max(20).messages({
  'string.empty': 'createAccount.form.input.firstName.error.required',
  'any.required': 'createAccount.form.input.firstName.error.required',
  'string.max':
    'createAccount.form.input.firstName.error.maxCharacters.message',
});

export const LAST_NAME = Joi.string().required().max(20).messages({
  'string.empty': 'createAccount.form.input.lastName.error.required',
  'any.required': 'createAccount.form.input.lastName.error.required',
  'string.max': 'createAccount.form.input.lastName.error.maxCharacters.message',
});

export const PASSWORD = Joi.string()
  .min(6)
  .required()
  .label('Password')
  .messages({
    'string.empty': 'createAccount.form.input.password.error.required',
    'any.required': 'createAccount.form.input.password.error.required',
    'string.pattern.base': 'errors.password.validation',
    'string.min':
      'createAccount.form.input.password.error.minCharacters.message',
  });
