import { FieldProps } from '@rjsf/core';
import * as Icons from 'components/icons';
import React from 'react';
import { IconButton, IconButtonProps } from 'theme-ui';
import { Box } from '../../../components/layout';
import { Timer } from '../../../components/Timer/Timer';
import { Text } from '../../../components/typography';
import { useModal } from '../../../store/modals';
import { ConfirmationDialog } from '../../../components/confirmation-dialog/ConfirmationDialog';
import { DocumentSectionWrapper } from '../../document/components/DocumentSectionWrapper/DocumentSectionWrapper';
import { ReliningServices } from '../index';
import { useReliningFormContext } from '../routes';
import { Label } from '../../../components/form-elements';
import { Spacer } from '../../../components/Spacer/Spacer';

type HardeningTimeData = {
  start: number;
  end: number;
};

export function HardeningTimer(props: FieldProps<HardeningTimeData>) {
  const { onTimerReset } = useReliningFormContext();

  const { timerStart } = ReliningServices.useTimerStartApi({
    onSuccess: (data) => {
      onPreserveChange({
        start: data.timestamp,
        end: undefined,
      });
    },
  });
  const { timerStop } = ReliningServices.useTimerStopApi({
    onSuccess: (data) => {
      onPreserveChange({
        end: data.timestamp,
      });
    },
  });
  const { timerReset } = ReliningServices.useTimerResetApi({
    onSuccess: (data) => {
      if (data.documentData) {
        onTimerReset(data.documentData);
      }
    },
  });

  function getTimerState() {
    if (!props.formData.start && !props.formData.end) return 'no-data';
    if (props.formData.start && !props.formData.end) return 'running';
    return 'finished';
  }

  function onPreserveChange(data: Partial<HardeningTimeData>) {
    props.onChange({
      ...props.formData,
      ...data,
    });
  }

  function onReset() {
    timerReset({
      documentId: props.formContext.documentId,
      templateId: props.formContext.templateId,
    });
  }

  function onStart() {
    timerStart({
      timestamp: Date.now(),
      documentId: props.formContext.documentId,
      templateId: props.formContext.templateId,
    });
  }

  function onStop() {
    timerStop({
      timestamp: Date.now(),
      documentId: props.formContext.documentId,
      templateId: props.formContext.templateId,
    });
  }

  if (getTimerState() === 'no-data') {
    return (
      <Wrapper>
        <Timer
          startTime={new Date()} endTime={new Date()}
          isIdle
        />

        <TimerControls disabled={props.disabled}>
          <StartButton onClick={() => onStart()} />
        </TimerControls>
      </Wrapper>
    );
  }

  if (getTimerState() === 'running') {
    return (
      <Wrapper>
        <Timer
          key={props.formData.start}
          startTime={new Date(props.formData.start)}
        />
        <TimerControls disabled={props.disabled}>
          <RestartButton onClick={() => onReset()} />
          <StopButton onClick={() => onStop()} />
        </TimerControls>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Timer
        variant="done"
        startTime={new Date(props.formData.start)}
        endTime={new Date(props.formData.end)}
      />

      <TimerControls disabled={props.disabled}>
        <RestartButton onClick={() => onReset()} />
      </TimerControls>
    </Wrapper>
  );
}

function Wrapper({ children }: { children: React.ReactNode }) {
  return (
    <DocumentSectionWrapper>
      <Label
        variant="jsonInputLabel"
        labelIntlId="relining.hardeningTime"
        sx={{
          textTransform: 'capitalize',
          textAlign: 'center',
          display: 'block',
        }}
      />

      <Spacer y={3} />

      {children}
    </DocumentSectionWrapper>
  );
}

function StartButton({
  onClick,
  disabled,
}: Pick<IconButtonProps, 'onClick' | 'disabled'>) {
  return (
    <IconButton
      type="button"
      variant="iconLabel"
      onClick={onClick}
      disabled={disabled}
    >
      <IconWrapper>
        <Icons.PlayCircle width={42} color="tertiary1.300" />
      </IconWrapper>
      <Text intlId="relining.startButton" />
    </IconButton>
  );
}

function RestartButton({
  onClick,
  disabled,
}: Pick<IconButtonProps, 'onClick' | 'disabled'>) {
  const { openModal } = useModal();

  return (
    <IconButton
      type="button"
      variant="iconLabel"
      onClick={() => {
        if (!onClick) return;

        openModal({
          modalType: 'componentModal',
          modalProps: {
            children: (
              <ConfirmationDialog onProceed={onClick}>
                <Text
                  as="p"
                  sx={{ textAlign: 'center' }}
                  intlId="generic.confirm.areYouSure"
                />
              </ConfirmationDialog>
            ),
          },
        });
      }}
      disabled={disabled}
      sx={{
        color: '#9E9EAF',
      }}
    >
      <IconWrapper>
        <Icons.Refresh color="currentColor" />
      </IconWrapper>
      <Text intlId="relining.restartButton" />
    </IconButton>
  );
}

function StopButton({ onClick }: Pick<IconButtonProps, 'onClick'>) {
  return (
    <IconButton
      type="button" variant="iconLabel"
      onClick={onClick}
    >
      <IconWrapper>
        <Icons.Stop color="#000" />
      </IconWrapper>
      <Text intlId="relining.stopButton" />
    </IconButton>
  );
}

function IconWrapper({ children }: { children: React.ReactNode }) {
  return (
    <Box
      sx={{
        display: 'inline-flex',
        width: 42,
        height: 42,
        alignItems: 'center',
        justifyContent: 'center',
        mb: 1,
      }}
    >
      {children}
    </Box>
  );
}

function TimerControls({
  children,
  disabled,
}: {
  children: React.ReactNode;
  disabled: boolean;
}) {
  if (disabled) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        mt: 3,
        backgroundColor: '#f8f8f8',
        borderRadius: 1,
        py: 3,
      }}
    >
      {children}
    </Box>
  );
}
