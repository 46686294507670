import * as ReactQuery from 'react-query';
import { signInApi } from 'services/auth/signIn/api';
import { InferMutationOptions } from 'utils/types';
import { ErrorType } from 'utils/http';
import { QUERY_KEY_CURRENT_USER } from 'utils/auth/use-current-authenticated-user';
import { QUERY_KEY_LOGGED_IN_USER } from 'services/users/get-logged-in-user/hook';
import { QUERY_KEY_WORKSPACE } from 'services/workspace/get-workspace';
import * as Utils from 'utils';
import { WorkspaceQueryData } from 'services/workspace/get-workspace/types';
import { Routing } from 'global/routing';

export const QUERY_KEY_SIGN_IN = ['signIn'];

export function useSignIn(options?: InferMutationOptions<typeof signInApi>) {
  const queryClient = ReactQuery.useQueryClient();
  const { onReferrerRedirect } = Utils.Hooks.useReferrerRedirect();

  const {
    mutate: signIn,
    error,
    ...rest
  } = ReactQuery.useMutation(QUERY_KEY_SIGN_IN, signInApi, {
    ...options,
    onSuccess: async (data, variables, context) => {
      await Promise.all([
        queryClient.invalidateQueries(QUERY_KEY_CURRENT_USER),
        queryClient.invalidateQueries(QUERY_KEY_LOGGED_IN_USER),
        queryClient.invalidateQueries(QUERY_KEY_WORKSPACE),
      ]);

      const workspaceData = queryClient.getQueryData<WorkspaceQueryData>(QUERY_KEY_WORKSPACE);

      if (workspaceData == null) {
        onReferrerRedirect(Routing.CREATE_ORGANISATION.getPath());
      } else if (!workspaceData.ready) {
        onReferrerRedirect(Routing.TRANSITIONS.getCreateWorkspaceSuccessPath());
      } else {
        onReferrerRedirect(Routing.HOME.getPath());
      }

      if (options?.onSuccess) {
        await options.onSuccess(data, variables, context);
      }
    },
  });

  return {
    signIn,
    error: error as ErrorType,
    ...rest,
  };
}
