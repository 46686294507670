import React, { Fragment, useEffect, useState } from 'react';
import ShareIcon from './ShareIcon';
import styles from './PWAPrompt.module.scss';

const PWAPrompt = ({
  delay,
  copyTitle,
  copyBody,
  copyClosePrompt,
  copyIosChrome,
  permanentlyHideOnDismiss,
  promptData,
  onClose,
  isIOS,
}) => {
  const [isVisible, setVisibility] = useState(!delay);

  useEffect(() => {
    if (delay) {
      const id = setTimeout(() => {
        // Prevent keyboard appearing over the prompt if a text input has autofocus set
        if (document.activeElement) {
          document.activeElement.blur();
        }

        setVisibility(true);
      }, delay);

      return () => clearTimeout(id);
    }

    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isVisible) {
      document.body.classList.add(styles.noScroll);
    }
  }, [isVisible]);

  const isiOS13AndUp = /OS (13|14)/.test(window.navigator.userAgent);
  const visibilityClass = isVisible ? styles.visible : styles.hidden;
  const iOSClass = isiOS13AndUp ? styles.modern : 'legacy';

  const isIosChrome = window.navigator.userAgent.match('CriOS') != null;

  const dismissPrompt = (evt) => {
    document.body.classList.remove(styles.noScroll);
    setVisibility(false);

    if (permanentlyHideOnDismiss) {
      localStorage.setItem(
        'iosPwaPrompt',
        JSON.stringify({
          ...promptData,
          visited: true,
        }),
      );
    }

    if (typeof onClose === 'function') {
      onClose(evt);
    }
  };

  const onTransitionOut = (evt) => {
    if (!isVisible) {
      evt.currentTarget.style.display = 'none';
    }
  };

  return (
    <Fragment>
      <div
        className={`${styles.pwaPromptOverlay} ${visibilityClass} ${iOSClass} iOSPWA-overlay`}
        aria-label="Close"
        role="button"
        onClick={dismissPrompt}
        onTransitionEnd={onTransitionOut}
      />
      <div
        className={`${styles.pwaPrompt} ${visibilityClass} ${iOSClass} iOSPWA-container`}
        aria-describedby="pwa-prompt-description"
        aria-labelledby="pwa-prompt-title"
        role="dialog"
        onTransitionEnd={onTransitionOut}
      >
        <div className={`${styles.pwaPromptHeader} iOSPWA-header`}>
          <p
            id="pwa-prompt-title"
            className={`${styles.pwaPromptTitle} iOSPWA-title`}
          >
            {copyTitle}
          </p>
          <button
            className={`${styles.pwaPromptCancel} iOSPWA-cancel`}
            onClick={dismissPrompt}
          >
            {copyClosePrompt}
          </button>
        </div>
        <div className={`${styles.pwaPromptInstructionStep}`}>
          <div className={`${styles.pwaPromptBody} iOSPWA-body`}>
            {isIOS && (
              <ShareIcon
                className={`${styles.pwaPromptShareIcon} iOSPWA-step1-icon`}
                modern={isiOS13AndUp}
              />
            )}
            <p
              id="pwa-prompt-description"
              className={`${styles.pwaPromptCopy} iOSPWA-description-copy`}
            >
              {isIosChrome ? copyIosChrome : copyBody}
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PWAPrompt;
