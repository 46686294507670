import { useQuery } from 'react-query';
import { identify, makeRequest, Service } from '../../../utils/http';
import { DocumentRequestBase } from '../../../services/documents';
import { InferQueryOptions } from '../../../utils/types';

type Data = Pick<DocumentRequestBase, 'templateId'>;

async function getAllUsersWithoutPermissionApi({ templateId }: Data) {
  return makeRequest(
    Service.getAllUsersWithoutPermission(templateId, await identify()),
  );
}

const QUERY_KEY_USERS_WITHOUT_PERMISSION = ({ templateId }: Data) => [
  'users',
  'permissions',
  templateId,
  'without',
];

function useGetAllUsersWithoutPermission(
  { templateId }: Data,
  options?: InferQueryOptions<typeof getAllUsersWithoutPermissionApi>,
) {
  return useQuery(
    QUERY_KEY_USERS_WITHOUT_PERMISSION({ templateId }),
    () => getAllUsersWithoutPermissionApi({ templateId }),
    options,
  );
}

export { useGetAllUsersWithoutPermission, QUERY_KEY_USERS_WITHOUT_PERMISSION };
