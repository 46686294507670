import * as ReactQuery from 'react-query';
import { identify, makeRequest, Service } from '../../../utils/http';
import { InferQueryOptions } from '../../../utils/types';

async function getActivePaymentMethodApi() {
  return makeRequest(Service.getActivePaymentMethod(await identify()));
}

const QUERY_KEY_ACTIVE_PAYMENT_METHOD = ['activePaymentMethod'];

function useGetActivePaymentMethod(
  options?: InferQueryOptions<typeof getActivePaymentMethodApi>,
) {
  return ReactQuery.useQuery(
    QUERY_KEY_ACTIVE_PAYMENT_METHOD,
    getActivePaymentMethodApi,
    options,
  );
}

export { useGetActivePaymentMethod, QUERY_KEY_ACTIVE_PAYMENT_METHOD };
