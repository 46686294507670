import { identify, makeRequest, Service } from 'utils/http';
import { DocumentRequestBase } from 'services/documents/types';

type Data = DocumentRequestBase & {
  imageName: string;
};

export async function addDocumentImageApi(data: Data) {
  return makeRequest(
    Service.addDocumentImage(
      data.documentId,
      data.imageName,
      data.templateId,
      await identify(),
    ),
  );
}
