import { Box, Flex } from 'components/layout';
import * as Icons from 'components/icons';
import React from 'react';
import { Text } from 'components/typography';
import { AvatarCircle } from 'components/avatar';

export function Message({
  title,
  isExternalUserMessage,
  children,
  footerText,
  Description,
}: {
  title: string;
  isExternalUserMessage: boolean;
  footerText: string;
  /* TODO: Find a more suitable way to represent the external/internal user */
  children?: React.ReactNode;
  Description: React.ReactNode;
}) {
  return (
    <Box sx={{ boxShadow: '1dp' }}>
      <MessageBody>
        {isExternalUserMessage ? (
          <AvatarCircle backgroundColor="secondary.500" size="large">
            <Icons.EyeOpen
              width={26}
              sx={{
                color: 'white.50',
                fill: 'white.50',
                stroke: 'white.50',
              }}
            />
          </AvatarCircle>
        ) : (
          children
        )}

        <Box>
          <MessageTitle>{title}</MessageTitle>
          {Description}
        </Box>
      </MessageBody>

      <MessageFooter>{footerText}</MessageFooter>
    </Box>
  );
}

export function MessageDescription({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Text
      as="p"
      sx={{
        fontSize: 'md',
        fontWeight: 300,
        color: 'darkgrey.800',
      }}
    >
      {children}
    </Text>
  );
}

export function MessageTitle({ children }: { children: React.ReactNode }) {
  return (
    <Text
      as="h2"
      sx={{
        fontWeight: 500,
        fontSize: 'lg',
        color: 'darkgrey.800',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      }}
    >
      {children}
    </Text>
  );
}

export function MessageFooter({ children }: { children: React.ReactNode }) {
  return (
    <Flex
      sx={{
        py: 2,
        justifyContent: 'flex-end',
        px: 5,
        backgroundColor: 'white.50',
        backgroundImage:
          'linear-gradient(89deg, rgba(255,255,255,1) 0%, rgba(241,243,246,1) 100%)',
        fontSize: 'sm',
        color: '#8686A1',
        fontWeight: 300,
      }}
    >
      {children}
    </Flex>
  );
}

export function MessageBody({ children }: { children: React.ReactNode }) {
  return (
    <Flex
      sx={{
        px: 5,
        py: 3,
        backgroundColor: 'white.50',
        minHeight: 80,
        alignItems: 'center',

        '& > *:not(:last-child)': {
          mr: 7,
        },
      }}
    >
      {children}
    </Flex>
  );
}
