import { WorkspaceStatusEnum } from 'trace-backend-sdk';
import React from 'react';
import { WorkspaceService } from '../../../services/workspace';
import { Authorization } from '../../../components/authorization/Authorization';
import { Box } from '../../../components/layout';
import { Text } from '../../../components/typography';
import { Link } from '../../../components/links';
import { Routing } from '../../../global/routing';

export function WorkspacePastDueBanner() {
  const { workspaceData } = WorkspaceService.useGetWorkspace();

  if (workspaceData?.status !== WorkspaceStatusEnum.PastDue) {
    return null;
  }

  return (
    <Authorization roles={['admin']}>
      <Box sx={{ backgroundColor: 'error', color: 'white.50', py: 2, px: 6 }}>
        <Text
          intlId="payments.pastDue.unpaidInvoices"
          as="p"
          variant="body2"
          sx={{ color: 'inherit' }}
        />

        <Box as="ul" sx={{ color: 'white.50' }}>
          <Box as="li">
            <Text variant="body2">
              <Link
                sx={{ color: 'white.50' }}
                to={Routing.PAYMENTS.Billing.path}
                intlId="payments.pastDue.viewInvoices"
              />
            </Text>
          </Box>

          <Box as="li">
            <Text variant="body2">
              <Link
                sx={{ color: 'white.50' }}
                to={Routing.PAYMENTS.ManagePaymentInfo.path}
                intlId="payments.pastDue.manageInfo"
              />
            </Text>
          </Box>
        </Box>
      </Box>
    </Authorization>
  );
}
