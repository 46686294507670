/** @jsxImportSource theme-ui */
import * as ReactIntl from 'react-intl';
import React from 'react';

function useIntl(): ReturnType<typeof ReactIntl.useIntl> {
  const intl = ReactIntl.useIntl();

  const customFormatMessage = React.useCallback<typeof intl.formatMessage>(
    // @ts-expect-error Fix react node type
    (descriptor, values, opts) => {
      return intl.formatMessage(
        descriptor,
        {
          bold: (parts) => <span sx={{ fontWeight: 'bold' }}>{parts}</span>,
          small: (parts) => <span sx={{ fontSize: '0.8em' }}>{parts}</span>,
          ...values,
        },
        opts,
      );
    },
    [intl],
  );

  return React.useMemo(
    () => ({
      ...intl,
      formatMessage: customFormatMessage,
    }),
    [intl, customFormatMessage],
  );
}

export * from 'react-intl';
export { useIntl };
